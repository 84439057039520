<template>
  <div class="Assessment">
    <div class='loading' v-if="isshoww">
      <div class='loading1'>
        <van-loading color="#1989fa" type="spinner"/>
      </div>
    </div>
    <van-tabs :active="active" @click="updateActive" color="skyblue" sticky swipe-threshold="4">
      <van-tab style="padding: 15px 0" title="诚信/满意度">
        <div class="goodBox">
          <div>评估对象姓名：{{value.xm}}</div>
          <div>身份证号码：{{value.sfzh}}</div>
          <div>评估日期：{{value.pgdate}}</div>
          <div class="upimg">
            <div class="imgli" id="image1">
              <van-uploader :after-read="uploadFfile" :max-count="1" accept="image/*" multiple v-model="image1"/>
              <div class="imgli-title">评估对象照片</div>
            </div>
            <div class="imgli" id="image2">
              <van-uploader :after-read="uploadFfile2" :max-count="1" accept="image/*" multiple v-model="image2"/>
              <div class="imgli-title">评估对象签名</div>

            </div>
            <div class="imgli" id="image3">
              <van-uploader :after-read="uploadFfile3" :max-count="1" accept="image/*" multiple v-model="image3"/>
              <div class="imgli-title">病例、药物等记录</div>

            </div>
            <div class="imgli" id="image4">
              <van-uploader :after-read="uploadFfile4" :max-count="1" accept="image/*" multiple v-model="image4"/>
              <div class="imgli-title">病例、药物等记录</div>
            </div>
            <div class="imgli" id="image5">
              <van-uploader :after-read="uploadFfile5" :max-count="1" accept="video/*" multiple v-model="image5"/>
              <div class="imgli-title">上半身活动情况</div>

              <div @click="isBof(image5[0].url)" class="ck">查看</div>
            </div>
            <div class="imgli" id="image6">
              <van-uploader :after-read="uploadFfile6" :max-count="1" accept="video/*" multiple v-model="image6"/>
              <div class="imgli-title">下半身活动情况（坐立行）</div>

              <div @click="isBof(image6[0].url)" class="ck">查看</div>
            </div>
          </div>
        </div>
        <van-button
          @click="updateImages"
          block
          class="btn"
          round
          size="small"
          type="primary"
          v-if="isWc == false"
        >开始评估
        </van-button
        >
        <div class="bottom" v-if="isWc">
          <div @click="updateStatus(1)" class="bottom1">同意</div>
          <div @click="updateStatus(0)" class="bottom2">拒绝</div>
        </div>
        <div class="goodBox">
          <div>
            1、本调查表用于本市长期护理险试点过程中对老年医疗护理服务申请者进行需求评估。
          </div>
          <div>2、本调查所获得的信息仅作为本市长期护理险需求评级的基础。</div>
          <div>
            3、为了反映被调查者的真实情况，各个调查项目结果的填写均基于被调查者相应动作的确认、被调查者自身的回答、家属和/或身边照护人员所反映的被调查者日常生活的一贯情况以及专门调查员的现场观察。
          </div>
          <div>
            4、调查回答项中，“□”表示备选项可多选，请打“√”表示选择；“①”等表示选项为单选，只能选择一项，请打“√”表示选择；用下划线留出空栏时，请填写合适的内容。
          </div>
          <div>5、关于发生频数的选项，请选择最接近实际的选择项。</div>
          <div>
            6、关于金额、次数等项目，如无精确数字，请估计一个合适的值填写。
          </div>
          <div>7、本调查全过程约需要三十分钟。</div>
          <div>8、本调查表所涉及的隐私受到法律保护。</div>
          <div>9、本调查表由认定的评估机构指派专门调查员完成。</div>
        </div>
      </van-tab>

      <van-tab style="padding: 0px 3% 60px 3%" title="居家信息">
        <!-- <div class="tianxie"> -->
        <div class="first" id="F12456"></div>
        <div class="Q">
          <van-field
            :label-class="isNotNull(collect.F12456)?'':'fieldF11673'"
            input-align="right"
            label="协助评估人员姓名"
            label-width="8rem"
            placeholder="..."
            v-model="collect.F12456"
          />
        </div>
        <div class="first" id="F12451"></div>
        <div class="Q">
          <div :style="isNotNull(collect.F12451)?'':'color:red'" class="q-title">
            申请目的(请选择日常生活中碰到的困难，最需要接受帮助的项目；最多选择4项)
          </div>
          <div class="q-content">
            <van-checkbox-group
              :max="4"
              direction="horizontal"
              v-model="collect.F12451"
            >
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12451,1)"
              >协助进食
              </van-checkbox
              >
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12451,2)"
              >排泄照护
              </van-checkbox
              >
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12451,3)"
              >穿衣洗漱
              </van-checkbox
              >
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12451,4)"
              >身体洗浴
              </van-checkbox
              >
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12451,5)"
              >翻身坐起
              </van-checkbox
              >
              <van-checkbox :name="6" class="checkbox" shape="square" v-if="isShow(collect.F12451,6)"
              >室内行立
              </van-checkbox
              >
              <van-checkbox :name="7" class="checkbox" shape="square" v-if="isShow(collect.F12451,7)"
              >室外移动
              </van-checkbox
              >
              <van-checkbox :name="8" class="checkbox" shape="square" v-if="isShow(collect.F12451,8)"
              >聊天解闷
              </van-checkbox
              >
              <van-checkbox :name="9" class="checkbox" shape="square" v-if="isShow(collect.F12451,9)"
              >煮菜热饭
              </van-checkbox
              >
              <van-checkbox :name="10" class="checkbox" shape="square" v-if="isShow(collect.F12451,10)"
              >衣物清洗
              </van-checkbox
              >
              <van-checkbox :name="11" class="checkbox" shape="square" v-if="isShow(collect.F12451,11)"
              >房间整理
              </van-checkbox
              >
              <van-checkbox :name="12" class="checkbox" shape="square" v-if="isShow(collect.F12451,12)"
              >代办服务
              </van-checkbox
              >
              <van-checkbox :name="13" class="checkbox" shape="square" v-if="isShow(collect.F12451,13)"
              >陪同就医
              </van-checkbox
              >
              <van-checkbox :name="14" class="checkbox" shape="square" v-if="isShow(collect.F12451,14)"
              >代为配药
              </van-checkbox
              >
              <van-checkbox :name="15" class="checkbox" shape="square" v-if="isShow(collect.F12451,15)"
              >提供辅助工具
              </van-checkbox
              >
              <van-checkbox :name="16" class="checkbox" shape="square" v-if="isShow(collect.F12451,16)"
              >提供居家改造
              </van-checkbox
              >
              <van-checkbox :name="17" class="checkbox" shape="square" v-if="isShow(collect.F12451,17)"
              >疾病治疗
              </van-checkbox
              >
              <van-checkbox :name="18" class="checkbox" shape="square" v-if="isShow(collect.F12451,18)"
              >医疗护理
              </van-checkbox
              >
              <van-checkbox :name="19" class="checkbox" shape="square" v-if="isShow(collect.F12451,19)"
              >医疗康复
              </van-checkbox
              >
              <van-checkbox
                :name="20"
                class="checkbox"
                shape="square"
                v-if="isShow(collect.F12451,20)"
              >异常行为监管，安全
              </van-checkbox
              >
              <van-checkbox :name="21" class="checkbox" shape="square" v-if="isShow(collect.F12451,21)"
              >白天上门照护
              </van-checkbox
              >
              <van-checkbox :name="22" class="checkbox" shape="square" v-if="isShow(collect.F12451,22)"
              >夜间上门照护
              </van-checkbox
              >
              <van-checkbox :name="23" class="checkbox" shape="square" v-if="isShow(collect.F12451,23)"
              >白天机构照护
              </van-checkbox
              >
              <van-checkbox :name="24" class="checkbox" shape="square" v-if="isShow(collect.F12451,24)"
              >夜间机构照护
              </van-checkbox
              >
              <van-checkbox :name="25" class="checkbox" shape="square" v-if="isShow(collect.F12451,25)"
              >住养老院
              </van-checkbox
              >
              <van-checkbox :name="26" class="checkbox" shape="square" v-if="isShow(collect.F12451,26)"
              >住护理院
              </van-checkbox
              >
            </van-checkbox-group>
          </div>
        </div>
        <div class="first" id="F12452"></div>
        <div class="Q">
          <div :style="isNotNull(collect.F12452)?'':'color:red'" class="q-title">平时使用的辅助工具</div>
          <div class="q-content">
            <van-checkbox-group

              direction="horizontal"
              v-model="collect.F12452"
            >
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12452,1)"
              >眼镜
              </van-checkbox
              >
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12452,2)"
              >放大镜
              </van-checkbox
              >
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12452,3)"
              >拐杖
              </van-checkbox
              >
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12452,4)"
              >助步器
              </van-checkbox
              >
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12452,5)"
              >轮椅
              </van-checkbox
              >
              <van-checkbox :name="6" class="checkbox" shape="square" v-if="isShow(collect.F12452,6)"
              >假肢
              </van-checkbox
              >
              <van-checkbox :name="7" class="checkbox" shape="square" v-if="isShow(collect.F12452,7)"
              >假牙
              </van-checkbox
              >
              <van-checkbox :name="8" class="checkbox" shape="square" v-if="isShow(collect.F12452,8)"
              >助听器
              </van-checkbox
              >
              <van-checkbox :name="9" class="checkbox" shape="square" v-if="isShow(collect.F12452,9)"
              >尿/便垫
              </van-checkbox
              >
              <van-checkbox :name="10" class="checkbox" shape="square" v-if="isShow(collect.F12452,10)"
              >造瘘术后便袋
              </van-checkbox
              >
              <van-checkbox :name="11" class="checkbox" shape="square" v-if="isShow(collect.F12452,11)"
              >防褥疮床垫
              </van-checkbox
              >
              <van-checkbox :name="12" class="checkbox" shape="square" v-if="isShow(collect.F12452,12)"
              >防跌倒鞋
              </van-checkbox
              >
              <van-checkbox :name="13" class="checkbox" shape="square" v-if="isShow(collect.F12452,13)"
              >食物粉碎机
              </van-checkbox
              >
              <van-checkbox :name="14" class="checkbox" shape="square" v-if="isShow(collect.F12452,14)"
              >其他
              </van-checkbox
              >
            </van-checkbox-group>
          </div>
        </div>
        <div class="Q" v-if="include(collect.F12452,14)">
          <van-field
            input-align="right"
            label="其他"
            placeholder="..."
            v-model="collect.F12453"
          />
        </div>
        <!-- 1 -->
        <div class="first" id="F11663"></div>
        <div class="Q">
          <van-field
            :label-class="isNotNull(collect.F11663)?'':'fieldF11673'"
            input-align="right"
            label="1. 姓名"
            placeholder="..."
            v-model="collect.F11663"
          />
        </div>
        <div class="first" id="F11664"></div>
        <div class="Q">
          <van-field :label-class="isNotNull(collect.F11664)?'':'fieldF11673'" label="2. 性别" name="checkboxGroup">
            <template #input>
              <van-radio-group disabled direction="horizontal" v-model="collect.F11664">
                <van-radio :name="1" v-if="isShow(collect.F11664,1)">男</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11664,2)">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <!-- 3 -->
        <div class="first" id="F11665"></div>
        <div class="Q">
          <van-field
            :label-class="isNotNull(collect.F11665)?'':'fieldF11673'"
            input-align="right"
            label="3. 年龄"
            name="checkboxGroup"
            placeholder="..."

            type="number"
            v-model="collect.F11665"
          >
          </van-field>
        </div>
        <!-- 4 -->
        <div class="first" id="F11666"></div>
        <div class="Q">
          <van-field
            :label-class="isNotNull(collect.F11666)?'':'fieldF11673'"
            input-align="right"
            label="4. 身高"
            name="checkboxGroup"
            placeholder="...(cm)"

            type="number"
            v-model="collect.F11666"
          >
          </van-field>
        </div>
        <!-- 5 -->
        <div class="first" id="F11667"></div>
        <div class="Q">
          <van-field
            :label-class="isNotNull(collect.F11667)?'':'fieldF11673'"
            input-align="right"
            label="5. 体重"
            name="checkboxGroup"

            placeholder="...(kg)"
            type="number"
            v-model="collect.F11667"
          >
          </van-field>
        </div>
        <!-- 6 -->
        <div class="first" id="F11668"></div>
        <div class="Q">
          <van-field
            :label-class="isNotNull(collect.F11668)?'':'fieldF11673'"
            input-align="right"
            label="6. 民族"

            name="checkboxGroup"
            placeholder="..."
            v-model="collect.F11668"
          >
          </van-field>
        </div>
        <!-- 7 -->
        <div class="first" id="F11673"></div>
        <div class="Q" ref="F11673">
          <van-field
            :label-class="isNotNull(collect.F11673)?'':'fieldF11673'"
            input-align="right"
            label="7. 是否为“失独”家庭"
            label-color="red"
            label-width="8rem"
            name="checkboxGroup"
          >
            <template #input>
              <van-radio-group direction="horizontal" v-model="collect.F11673">
                <van-radio :name="1" v-if="isShow(collect.F11673,1)">否</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11673,2)">是</van-radio>
                <van-radio :name="3" v-if="isShow(collect.F11673,3)">其他</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="first" id="F11674"></div>
        <!-- 8 -->
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F11674)?'':'color:red'">
            8.文化程度
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F11674">
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F11674,1)">文盲</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F11674,2)">小学</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F11674,3)">初中</van-radio>
              <van-radio :name="4" class="checkbox" v-if="isShow(collect.F11674,4)">高中</van-radio>
              <van-radio :name="5" class="checkbox" v-if="isShow(collect.F11674,5)">大学及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 9 -->
        <div class="first" id="F11675"></div>
        <div class="Q">
          <div :style="isNotNull(collect.F11675)?'':'color:red'" class="q-title">9.主要经济来源</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F11675">
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F11675,1)">退休金</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F11675,2)">子女补贴</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F11675,3)">亲友资助</van-radio>
              <van-radio :name="4" class="checkbox" v-if="isShow(collect.F11675,4)">其他补贴</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 10 -->
        <div class="first" id="F11678"></div>
        <div class="Q">
          <div :style="isNotNull(collect.F11678)?'':'color:red'" class="q-title">10.居住地址
          </div
          >
          <div class="q-title1">
            <input class="q-input" placeholder="..." type="text" v-model="collect.F11678">省（市）
            <input class="q-input" placeholder="..." type="text" v-model="collect.F11679">区（县）
            <input class="q-input" placeholder="..." type="text" v-model="collect.F11680">街道（镇）
            <input class="q-input" placeholder="..." type="text" v-model="collect.F11681">（村）委
            <input class="q-input" placeholder="..." type="text" v-model="collect.F11682">小区
            <input class="q-input" placeholder="..." type="text" v-model="collect.F11683">路
            <input class="q-input" placeholder="..." type="text" v-model="collect.F11684">弄
            <input class="q-input" placeholder="..." type="text" v-model="collect.F11685">号
            <input class="q-input" placeholder="..." type="text" v-model="collect.F11686">室
          </div>
        </div>
        <!-- 11 -->
        <div class="first" id="F11687"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F11687)?'':'color:red'">11.现住场所</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F11687">
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F11687,1)">自有产权房，私房等</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F11687,2)">各类租赁房等</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F11687,3)">养老院或类似机构</van-radio>
              <van-radio :name="4" class="checkbox" v-if="isShow(collect.F11687,4)">护理院或类似机构</van-radio>
              <van-radio :name="5" class="checkbox" v-if="isShow(collect.F11687,5)">社区卫生服务中心</van-radio>
              <van-radio :name="6" class="checkbox" v-if="isShow(collect.F11687,6)">医院或类似机构</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="first" id="F11688"></div>
        <!-- 12 -->
        <div class="Q">
          <van-field
            input-align="right"
            label="12. 语言使用"
            name="checkboxGroup"
            :label-class="isNotNull(collect.F11688)?'':'fieldF11673'"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"
                shape="shape"
                v-model="collect.F11688"

              >
                <van-radio :name="1" v-if="isShow(collect.F11688,1)">使用语言</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11688,2)">不使用语言等</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="first" id="F11689" v-if="collect.F11688 == 1"></div>
        <div class="Q" v-if="collect.F11688 == 1">
<!--          <div class="first" id="F11689"></div>-->
          <van-field
            input-align="right"
            label="使用语言"
            name="checkboxGroup"
            :label-class="isNotNull(collect.F11689)?'':'fieldF11673'"
          >
            <template #input>
              <van-checkbox-group
                direction="horizontal"
                shape="shape"
                v-model="collect.F11689"

              >
                <van-checkbox :name="1" shape="square" v-if="isShow(collect.F11689,1)">普通话</van-checkbox>
                <van-checkbox :name="2" shape="square" v-if="isShow(collect.F11689,2)">方言</van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>
        </div>
        <div class="Q" v-if="include(collect.F11689,2)  && collect.F11688 == 1">
          <div class="first" id="F11690"></div>
          <van-field
            input-align="right"
            label="方言"
            name="checkboxGroup"
            v-model="collect.F11690"
            :label-class="isNotNull(collect.F11690)?'':'fieldF11673'"
          >
          </van-field>
        </div>
        <!-- 13 -->
        <div class="first" id="F12431"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12431)?'':'color:red'">13.饮食习惯</div>
          <div class="q-content">
            <van-checkbox-group v-model="collect.F12431">
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12431,1)">素食</van-checkbox>
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12431,2)">清真</van-checkbox>
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12431,3)">低糖或无糖饮食
              </van-checkbox>
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12431,4)">低盐饮食
              </van-checkbox>
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12431,5)">其他</van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
        <div class="Q" v-if="include(collect.F12431,5)">
          <div class="first" id="F12432"></div>
          <van-field
            input-align="right"
            label="其他"
            placeholder="..."
            v-model="collect.F12432"
          />
        </div>
        <div class="first" id="F12433"></div>
        <div class="Q">
          <van-field
            input-align="right"
            label="14.有无宗教信仰"
            name="checkboxGroup"
            :label-class="isNotNull(collect.F12433) && ((collect.F12433 == 1 && isNotNull(collect.F12434)) || collect.F12433 == 2)?'':'fieldF11673'"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"
                shape="shape"
                v-model="collect.F12433"

              >
                <van-radio :name="1" v-if="isShow(collect.F12433,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F12433,2)">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="Q" v-if="collect.F12433 !=null && collect.F12433 == 1">
          <div class="first" id="F12434"></div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12434">
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F12434,1)">佛教</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F12434,2)">道教</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F12434,3)">基督教</van-radio>
              <van-radio :name="4" class="checkbox" v-if="isShow(collect.F12434,4)">伊斯兰教</van-radio>
              <van-radio :name="5" class="checkbox" v-if="isShow(collect.F12434,5)">其他</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 14 -->
        <div class="first" id="F11691"></div>
        <div class="Q">
          <van-field
            input-align="right"
            label="15. 有无配偶"
            name="checkboxGroup"
            :label-class="isNotNull(collect.F11691)?'':'fieldF11673'"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"
                shape="shape"
                v-model="collect.F11691"

              >
                <van-radio :name="1" v-if="isShow(collect.F11691,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11691,2)">无或者丧偶等</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="Q" v-if="collect.F11691!=null">
          <div class="first" id="F11692" v-if="collect.F11691 == 1"></div>
          <van-field
            input-align="right"
            label="配偶姓名"
            label-width="9rem"
            name="checkboxGroup"
            placeholder="..."
            v-if="collect.F11691 == 1"
            v-model="collect.F11692"
            :label-class="isNotNull(collect.F11692)?'':'fieldF11673'"
          >
          </van-field>
          <div class="first" id="F12436" v-if="collect.F11691 == 1"></div>
          <van-field
            input-align="right"
            label="身体状况"
            name="checkboxGroup"
            placeholder="..."
            v-if="collect.F11691 == 1"
            :label-class="isNotNull(collect.F12436)?'':'fieldF11673'"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"
                shape="shape"
                v-model="collect.F12436"
              >
                <van-radio :name="1" v-if="isShow(collect.F12436,1)">好</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F12436,2)">中</van-radio>
                <van-radio :name="3" v-if="isShow(collect.F12436,3)">差</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="first" id="F12437" v-if="collect.F11691 == 1"></div>
          <van-field
            input-align="right"
            label="身份证号"
            name="checkboxGroup"
            placeholder="..."
            v-if="collect.F11691 == 1"
            v-model="collect.F12437"
            :label-class="isNotNull(collect.F12437)?'':'fieldF11673'"
          >
          </van-field>
          <van-field
            input-align="right"
            label="尚在父母兄弟姐妹人数"
            label-width="9rem"
            name="checkboxGroup"
            placeholder="..."
            type="number"
            v-model="collect.F11693"
            :label-class="isNotNull(collect.F11693)?'':'fieldF11673'"
          >
          </van-field>
          <div class="first" id="F11694"></div>
          <van-field
            input-align="right"

            label="子女人数"
            name="checkboxGroup"
            placeholder="..."
            type="number"
            v-model="collect.F11694"
            :label-class="isNotNull(collect.F11694)?'':'fieldF11673'"
          >
          </van-field>
          <div class="first" id="F11695"></div>
          <van-field
            input-align="right"

            label="孙辈人数"
            name="checkboxGroup"
            placeholder="..."
            type="number"
            v-model="collect.F11695"
            :label-class="isNotNull(collect.F11695)?'':'fieldF11673'"
          >
          </van-field>
        </div>
        <!-- 16 -->
        <div class="first" id="F11696"></div>
        <div class="Q">
          <van-field
            input-align="right"
            label="16. 和谁在一起居住"
            label-width="8rem"
            name="checkboxGroup"
            :label-class="isNotNull(collect.F11696)?'':'fieldF11673'"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"
                shape="shape"
                v-model="collect.F11696"

              >
                <van-radio :name="1" v-if="isShow(collect.F11696,1)">独居</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11696,2)">非独居</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="Q" v-if="collect.F11696===2" id="F11697">
          <div class="q-title" :style="isNotNull(collect.F11697)?'':'color:red'">一起居住的有</div>
          <div class="q-content">
            <van-checkbox-group v-model="collect.F11697">
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F11697,1)">配偶</van-checkbox>
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F11697,2)">子女</van-checkbox>
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F11697,3)">孙辈等</van-checkbox>
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F11697,4)">保姆等</van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
        <!-- 17 -->
        <div class="first" id="F11700"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F11700)?'':'color:red'">17.所有亲属中，与评估对象距离最近的居住在</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F11700">
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F11700,1)">同一街道（镇）</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F11700,2)">同一个区（县）</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F11700,3)">同一省市</van-radio>
              <van-radio :name="4" class="checkbox" v-if="isShow(collect.F11700,4)">国内</van-radio>
              <van-radio :name="5" class="checkbox" v-if="isShow(collect.F11700,5)">国外等其他原因</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 18 -->
        <div class="first" id="F12438"></div>
        <div class="Q">
          <van-field
            input-align="right"
            label="18. 评估对象身体是否需要照护情况"
            label-width="8rem"
            name="checkboxGroup"
            :label-class="(collect.F12438 ==1 || (collect.F12438 ==2 && isNotNull(collect.F12439)))?'':'fieldF11673'"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"

                shape="shape"
                v-model="collect.F12438"
              >
                <van-radio :name="1" v-if="isShow(collect.F12438,1)">不需要</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F12438,2)">需要</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="first" id="F12439" v-if="collect.F12438 == 2"></div>
        <div class="Q" v-if="collect.F12438 == 2">
<!--          <div class="first" id="F12439"></div>-->
          <div class="q-content">
            <van-checkbox-group v-model="collect.F12439">
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12439,1)">自己</van-checkbox>
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12439,2)">配偶</van-checkbox>
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12439,3)">子女</van-checkbox>
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12439,4)">孙辈等</van-checkbox>
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12439,5)">钟点工等
              </van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
        <!-- 19 -->
        <div class="first" id="F12440"></div>
        <div class="Q">
          <van-field
            input-align="right"
            label="19. 评估对象身体衣着干净整洁情况"
            label-width="8rem"
            name="checkboxGroup"
            :label-class="isNotNull(collect.F12440)?'':'fieldF11673'"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"
                shape="shape"

                v-model="collect.F12440"
              >
                <van-radio :name="1" v-if="isShow(collect.F12440,1)">干净整洁</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F12440,2)">尚可</van-radio>
                <van-radio :name="3" v-if="isShow(collect.F12440,3)">不干净不整洁</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <!-- 20 -->
        <div class="first" id="F12441"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12441)?'':'color:red'">20.买菜做饭洗衣等家务活由谁来完成</div>
          <div class="q-content">
            <van-checkbox-group v-model="collect.F12441">
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12441,1)">自己</van-checkbox>
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12441,2)">配偶</van-checkbox>
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12441,3)">子女</van-checkbox>
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12441,4)">孙辈等</van-checkbox>
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12441,5)">钟点工等
              </van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
        <!-- 21 -->
        <div class="first" id="F12442"></div>
        <div class="Q">
          <van-field
            input-align="right"
            label="21. 居住环境干净整洁情况"
            label-width="8rem"
            name="checkboxGroup"
            :label-class="isNotNull(collect.F12442)?'':'fieldF11673'"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"

                shape="shape"
                v-model="collect.F12442"
              >
                <van-radio :name="1" v-if="isShow(collect.F12442,1)">干净整洁</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F12442,2)">尚可</van-radio>
                <van-radio :name="3" v-if="isShow(collect.F12442,3)">不干净不整洁</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <!-- 22 -->
        <div class="first" id="F11701"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F11701) && isNotNull(collect.F11702)?'':'color:red'">22.居住楼层</div>
          <div class="q-title1">
            第<input class="q-input" placeholder="..." type="number" v-model="collect.F11701">层，共<input class="q-input"
                                                                                                       placeholder="..."
                                                                                                       type="number"
                                                                                                       v-model="collect.F11702">层
          </div>
          <div class="first" id="F11703"></div>
          <van-field
            input-align="right"
            label="楼层有无电梯"
            label-width="8rem"
            name="checkboxGroup"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"
                shape="shape"

                v-model="collect.F11703"
              >
                <van-radio :name="1" v-if="isShow(collect.F11703,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11703,2)">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="first" id="F11704"></div>
          <van-field
            input-align="right"
            label="室内有无台阶"
            label-width="8rem"
            name="checkboxGroup"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"

                shape="shape"
                v-model="collect.F11704"
              >
                <van-radio :name="1" v-if="isShow(collect.F11704,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11704,2)">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="first" id="F11705"></div>
          <van-field
            input-align="right"
            label="室内有无扶手"
            label-width="8rem"
            name="checkboxGroup"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"

                shape="shape"
                v-model="collect.F11705"
              >
                <van-radio :name="1" v-if="isShow(collect.F11705,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11705,2)">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="first" id="F11706"></div>
          <van-field
            input-align="right"
            label="厕所内有无扶手"
            label-width="8rem"
            name="checkboxGroup"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"
                shape="shape"

                v-model="collect.F11706"
              >
                <van-radio :name="1" v-if="isShow(collect.F11706,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11706,2)">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="first" id="F11707"></div>
          <van-field
            input-align="right"
            label="浴室内有无扶手"
            label-width="8rem"
            name="checkboxGroup"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"
                shape="shape"

                v-model="collect.F11707"
              >
                <van-radio :name="1" v-if="isShow(collect.F11707,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11707,2)">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="first" id="F12443"></div>
          <div class="q-title" :style="isNotNull(collect.F12443) ?'':'color:red'">居住地1公里左右有如下设施
          </div
          >
          <div class="q-content">
            <van-checkbox-group v-model="collect.F12443">
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12443,1)">助餐点</van-checkbox>
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12443,2)">便利店/超市
              </van-checkbox>
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12443,3)">菜场</van-checkbox>
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12443,4)">社区卫生服务点
              </van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
        <!-- 23 -->
        <div class="first" id="F11710"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F11710) && isNotNull(collect.F11711)?'':'color:red'">23.过去3个月，每月不支付费用照护情况（不包括非服务时间）
          </div
          >
          <div class="q-title1">
            各类非付费照护提供次数:<input class="q-input" placeholder="..." type="number" v-model="collect.F11710">次/月；
          </div>
          <div class="q-title1">
            累计时间:<input class="q-input" placeholder="..." type="number" v-model="collect.F11711">小时/月；
          </div>
        </div>
        <!-- 24 -->
        <div class="first" id="F11712"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F11712) && isNotNull(collect.F11713) && isNotNull(collect.F11714)?'':'color:red'">24.过去3个月，每月支付费用照护情况（不包括非服务时间）
          </div
          >
          <div class="q-title1">
            各类要付费照护提供人数:<input class="q-input" placeholder="..." type="number" v-model="collect.F11712">次/月；
          </div>
          <div class="q-title1">
            累计时间:<input class="q-input" placeholder="..." type="number" v-model="collect.F11713">小时/月；
          </div>
          <div class="q-title1">
            支付费用:<input class="q-input" placeholder="..." type="number" v-model="collect.F11714">元/月；
          </div>
        </div>
        <!-- 25 -->
        <div class="first" id="F12445"></div>
        <div class="Q">
          <div class="q-title"
               :style="isNotNull(collect.F12444) &&
                      isNotNull(collect.F12445) &&
                      isNotNull(collect.F12446) &&
                      isNotNull(collect.F12448) &&
                      isNotNull(collect.F12449) &&
                      isNotNull(collect.F12450)
                      ? '':'color:red'"
          >25.在过去的12个月
          </div
          >
          <div class="q-title1">
            评估对象门诊就医<input class="q-input" placeholder="..." type="number" v-model="collect.F12444">次，
          </div>
          <div class="q-title1">
            代配药<input class="q-input" placeholder="..." type="number" v-model="collect.F12445">次，
          </div>
          <div class="q-title1">
            每次门诊（从出门到回到家门，扣除其他活动的时间）大约需要<input class="q-input" placeholder="..." type="number" v-model="collect.F12446">分钟。
          </div>
        </div>
        <div class="first" id="F12447"></div>
        <div class="Q">
          <van-field
            input-align="right"
            label="26.就诊是否有人陪同"
            label-width="8rem"
            name="checkboxGroup"
            :label-class="isNotNull(collect.F12447)?'':'fieldF11673'"
          >
            <template #input>
              <van-radio-group
                direction="horizontal"
                shape="shape"

                v-model="collect.F12447"
              >
                <van-radio :name="1" v-if="isShow(collect.F12447,1)">是</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F12447,2)">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="Q">
          <div class="first" id="F12448"></div>
          <div class="q-title1">
            评估对象住院就诊<input class="q-input" placeholder="..." type="number" v-model="collect.F12448">次，
          </div>
          <div class="q-title1">
            共<input class="q-input" placeholder="..." type="number" v-model="collect.F12449">天；
          </div>
          <div class="q-title1">
            最近1次出院在<input class="q-input" placeholder="..." style="width:100px" type="text" v-model="collect.F12450">（年/月/日）；
          </div>

        </div>
        <!-- </div> -->
        <div class="bottom" v-if="isWc == false">
          <div @click="add1(null)" class="bottom1">保存</div>
          <div @click="xiayixiang1" class="bottom2">下一项</div>
        </div>
        <div class="bottom" v-if="isWc">
          <div @click="updateStatus(1)" class="bottom1">同意</div>
          <div @click="updateStatus(0)" class="bottom2">拒绝</div>
        </div>
      </van-tab>

      <van-tab style="padding: 0px 3% 60px 3%" title="基本信息">
        <div class="B">
          <div class="q-title3">请仔细观察老人的情况，在保证安全的情况下尽量当场确认，同时结合照护人员提供的信息填写。</div>
          <div class="q-title3"></div>
          <div class="q-title3">如无特别说明，指以往7天的情况。</div>
        </div>
        <!-- 1 -->
        <div class="first" id="F12017"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12017)?'':'color:red'">
            卧位状态中左右翻身是否需要帮助
            <div class="q-title4">
              第一项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12017">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12017,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12017,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12017,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12017,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12017,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="first" id="F12018"></div>
        <!-- 2 -->
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12018)?'':'color:red'">
            卧位状态中坐起是否需要帮助
            <div class="q-title4">
              第二项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12018">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12018,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12018,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12018,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12018,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12018,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="first" id="F12019"></div>
        <!-- 3 -->
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12019)?'':'color:red'">
            坐凳椅是否需要帮助
            <div class="q-title4">
              第三项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12019">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12019,1)">1.&nbsp;能够正常完成</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12019,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12019,3)">3.&nbsp;依靠或抓住固定物体等时，可以完成
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12019,4)">4.&nbsp;借助固定物体，且在他人照看下才能完成
              </van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12019,5)">5.&nbsp;即使依靠且抓住固定物体等，也不能完成
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 4 -->
        <div class="first" id="F12020"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12020)?'':'color:red'">
            从坐立位状态起身至站立位状态是否需要帮助
            <div class="q-title4">
              第四项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12020">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12020,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12020,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12020,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12020,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12020,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 5 -->
        <div class="first" id="F12021"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12021)?'':'color:red'">
            能否完成平地双足站立10秒钟动作
            <div class="q-title4">
              第五项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12021">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12021,1)">1.&nbsp;能够完成</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12021,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12021,3)">3.&nbsp;必须借助于手扶其他物体才能完成</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12021,4)">4.&nbsp;即使手扶其他物体，也只能站立1~2秒钟
              </van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12021,5)">5.&nbsp;即使手扶其他物体，也不能双足站立
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 6 -->
        <div class="first" id="F12022"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12022)?'':'color:red'">
            能否完成平地连续步行或移动5米
            <div class="q-title4">
              第六项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12022">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12022,1)">1.&nbsp;不需要借助任何工具，能够完成</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12022,2)">2.&nbsp;需要借助拐杖等才能完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12022,3)">3.&nbsp;需要借助轮椅等才能完成</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12022,4)">4.&nbsp;需要他人动手帮助才能完成</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12022,5)">5.&nbsp;完全需要他人帮助才能完成</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 7 -->
        <div class="first" id="F12023"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12023)?'':'color:red'">
            坐立行等动作相关联的体位变换时是否需要帮助
            <div class="q-title4">
              第七项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12023">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12023,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12023,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12023,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12023,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12023,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 8 -->
        <div class="first" id="F12024"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12024)?'':'color:red'">
            食物摄取是否需要帮助
            <div class="q-title4">
              第八项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12024">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12024,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12024,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12024,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12024,4)">4.&nbsp;需要喂食，喂食量超过一半</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12024,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 9 -->
        <div class="first" id="F12025"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12025)?'':'color:red'">
            刷牙/漱口是否需要帮助
            <div class="q-title4">
              第九项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12025">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12025,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12025,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12025,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12025,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12025,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 10 -->
        <div class="first" id="F12026"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12026)?'':'color:red'">
            洗脸/洗手是否需要帮助
            <div class="q-title4">
              第十项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12026">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12026,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12026,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12026,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12026,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12026,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 11 -->
        <div class="first" id="F12027"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12027)?'':'color:red'">
            梳头/简单化妆是否需要帮助
            <div class="q-title4">
              第十一项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12027">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12027,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12027,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12027,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12027,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12027,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="first" id="F12028"></div>
        <!-- 12 -->
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12028)?'':'color:red'">
            穿/脱上衣是否需要帮助
            <div class="q-title4">
              第十二项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12028">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12028,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12028,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12028,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12028,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12028,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="first" id="F12029"></div>
        <!-- 13 -->
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12029)?'':'color:red'">
            穿/脱裤子是否需要帮助
            <div class="q-title4">
              第十三项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12029">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12029,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12029,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12029,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12029,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12029,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="first" id="F12030"></div>
        <!-- 14 -->
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12030)?'':'color:red'">
            身体洗浴是否需要帮助（不限于以往7天）
            <div class="q-title4">
              第十四项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12030">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12030,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12030,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12030,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12030,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12030,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 15 -->
        <div class="first" id="F12032"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12032)?'':'color:red'">
            小便是否失禁：
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12032">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12032,1)">1.&nbsp;不失禁</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12032,2)">2.&nbsp;偶尔失禁</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12032,3)">3.&nbsp;失禁</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12031">
          </div>
          <div class="q-title" :style="isNotNull(collect.F12031)?'':'color:red'">
            排尿是否需要帮助：
            <div class="q-title4">
              第十五项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12031">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12031,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12031,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12031,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12031,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12031,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="first" id="F12034"></div>
        <!-- 16 -->
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12034)?'':'color:red'">
            大便：
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12034">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12034,1)">1.&nbsp;不失禁</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12034,2)">2.&nbsp;偶尔失禁</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12034,3)">3.&nbsp;失禁</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12455">
          </div>
          <div class="q-title" :style="isNotNull(collect.F12455)?'':'color:red'">
            有无便秘：
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12455">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12455,1)">1.&nbsp;有</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12455,2)">2.&nbsp;无</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12033"></div>
          <div class="q-title" :style="isNotNull(collect.F12033)?'':'color:red'">
            排便是否需要帮助：
            <div class="q-title4">
              第十六项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12033">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12033,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12033,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12033,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12033,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12033,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 17 -->
        <div class="first" id="F12035"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12035)?'':'color:red'">
            视力
            <div class="q-title4">
              第十七项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12035">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12035,1)">1.&nbsp;视力基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12035,2)">2.&nbsp;视力有障碍，在一定程度上影响日常生活
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12035,3)">3.&nbsp;视力有障碍，严重影响日常生活</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12035,4)">4.&nbsp;有光感，在1米以内能够辨认手的形状
              </van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12035,5)">5.&nbsp;双目失明，无光感等</van-radio>
            </van-radio-group>
          </div>

        </div>
        <!-- 18 -->
        <div class="first" id="F12037"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12037)?'':'color:red'">
            电话使用情况：
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12037">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12037,1)">1.&nbsp;会拨打和接听</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12037,2)">2.&nbsp;会接听</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12037,3)">3.&nbsp;不使用电话</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12036">
          </div>
          <div class="q-title" :style="isNotNull(collect.F12036)?'':'color:red'">
            听力
            <div class="q-title4">
              第十八项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12036">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12036,1)">1.&nbsp;基本能够听清日常生活会话</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12036,2)">2.&nbsp;只能够听清较大的声音，或者在耳边正常说话声
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12036,3)">3.&nbsp;只能够听清大声在耳边说的声音</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12036,4)">4.&nbsp;只能够听到非常大的声音</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12036,5)">5.&nbsp;无听力等</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 19 -->
        <div class="first" id="F12038"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12038)?'':'color:red'">
            有无上下肢瘫痪的情况
            <div class="q-title4">
              第十九项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox-group
              direction="horizontal"

              v-model="collect.F12038"
            >
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12038,1)"
              >无
              </van-checkbox
              >
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12038,2)"
              >左上肢
              </van-checkbox
              >
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12038,3)"
              >右上肢
              </van-checkbox
              >
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12038,4)"
              >左下肢
              </van-checkbox
              >
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12038,5)"
              >右下肢
              </van-checkbox
              >

            </van-checkbox-group>
          </div>
        </div>
        <!-- 20 -->
        <div class="first" id="F12039"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12039)?'':'color:red'">
            有无关节活动受限范围大于50%的情况
            <div class="q-title4">
              第二十项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox-group
              direction="horizontal"

              v-model="collect.F12039"
            >
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12039,1)"
              >无
              </van-checkbox
              >
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12039,2)"
              >颈椎关节
              </van-checkbox
              >
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12039,3)"
              >肩关节
              </van-checkbox
              >
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12039,4)"
              >肘关节
              </van-checkbox
              >
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12039,5)"
              >腰椎关节
              </van-checkbox
              >
              <van-checkbox :name="6" class="checkbox" shape="square" v-if="isShow(collect.F12039,6)"
              >髋关节
              </van-checkbox
              >
              <van-checkbox :name="7" class="checkbox" shape="square" v-if="isShow(collect.F12039,7)"
              >膝关节
              </van-checkbox
              >

            </van-checkbox-group>
          </div>
        </div>

        <div class="B">
          <div class="q-title3">以下项目，如果调查对象无回答能力，</div>
          <div class="q-title3">则该部分不作调查，得分填写“0”.</div>
        </div>

        <!-- 21 -->
        <div class="first" id="F12040"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12040)?'':'color:red'">
            能否知道自己的姓名？
            <div class="q-title4">
              第二十一项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12040">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12040,1)">1.&nbsp;能够</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12040,2)">2.&nbsp;不能</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12041">
          </div>
        </div>
        <!-- 22 -->
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12041)?'':'color:red'">
            能否知道自己的年龄？
            <div class="q-title4">
              第二十二项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12041">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12041,1)">1.&nbsp;能够</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12041,2)">2.&nbsp;不能</van-radio>
            </van-radio-group>
          </div>

        </div>
        <!-- 23 -->
        <div class="first" id="F12042"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12042)?'':'color:red'">
            能否知道现在的季节？
            <div class="q-title4">
              第二十三项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12042">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12042,1)">1.&nbsp;能够</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12042,2)">2.&nbsp;不能</van-radio>
            </van-radio-group>
          </div>

        </div>
        <!-- 24 -->
        <div class="first" id="F12043"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12043)?'':'color:red'">评估人员说出3样东西：【皮球】【国旗】【树木】，一秒说一项。请评估对象记住这3样东西，过30秒钟，请评估对象重复一遍。
            <div class="q-title4">
              第二十四项
            </div>
          </div
          >
          <div class="q-title1">
            记住数量：
            <van-radio-group v-model="collect.F12043" style="display:inline-flex;width:100%">
              <van-radio :name="0" class="checkbox" v-if="isShow(collect.F12043,0)">0</van-radio>
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F12043,1)">1</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F12043,2)">2</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F12043,3)">3</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 25 -->
        <div class="first" id="F12044"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12044)?'':'color:red'">现在是几几年？几月？几号？几点钟？星期几？
            <div class="q-title4">
              第二十五项
            </div>
          </div
          >
          <div class="q-title1">
            正确项数：
            <van-radio-group v-model="collect.F12044" style="display:inline-flex;width:100%">
              <van-radio :name="0" class="checkbox" v-if="isShow(collect.F12044,0)">0</van-radio>
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F12044,1)">1</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F12044,2)">2</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F12044,3)">3</van-radio>
              <van-radio :name="4" class="checkbox" v-if="isShow(collect.F12044,4)">4</van-radio>
              <van-radio :name="5" class="checkbox" v-if="isShow(collect.F12044,5)">5</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 26 -->
        <div class="first" id="F12045"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12045)?'':'color:red'">现在在哪里，省？市？医院/街道？科室/小区？第几层楼？
            <div class="q-title4">
              第二十六项
            </div>
          </div
          >
          <div class="q-title1">
            正确项数：
            <van-radio-group v-model="collect.F12045" style="display:inline-flex;width:100%">
              <van-radio :name="0" class="checkbox" v-if="isShow(collect.F12045,0)">0</van-radio>
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F12045,1)">1</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F12045,2)">2</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F12045,3)">3</van-radio>
              <van-radio :name="4" class="checkbox" v-if="isShow(collect.F12045,4)">4</van-radio>
              <van-radio :name="5" class="checkbox" v-if="isShow(collect.F12045,5)">5</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 27 -->
        <div class="first" id="F12046"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12046)?'':'color:red'">用100减去7，询问被评估人员差数，用正确的差数再减去7，连续5次
            <div class="q-title4">
              第二十七项
            </div>
          </div
          >
          <div class="q-title1">
            正确次数：
            <van-radio-group v-model="collect.F12046" style="display:inline-flex;width:100%">
              <van-radio :name="0" class="checkbox" v-if="isShow(collect.F12046,0)">0</van-radio>
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F12046,1)">1</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F12046,2)">2</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F12046,3)">3</van-radio>
              <van-radio :name="4" class="checkbox" v-if="isShow(collect.F12046,4)">4</van-radio>
              <van-radio :name="5" class="checkbox" v-if="isShow(collect.F12046,5)">5</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 28 -->
        <div class="first" id="F12047"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12047)?'':'color:red'">（与24题完成相隔3分钟）请您把刚才我们说过的3样东西说一遍？
            <div class="q-title4">
              第二十八项
            </div>
          </div
          >
          <div class="q-title1">
            正确项数：
            <van-radio-group v-model="collect.F12047" style="display:inline-flex;width:100%">
              <van-radio :name="0" class="checkbox" v-if="isShow(collect.F12047,0)">0</van-radio>
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F12047,1)">1</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F12047,2)">2</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F12047,3)">3</van-radio>
            </van-radio-group>
          </div>
        </div>

<div class="B">

  <div class="q-title3">以下项目，请调查对象根据最近7天的状态回答，
          </div>

  <div class="q-title3">可适当参照照护人员提供的信息。
          </div>

        </div>
        <!-- 29 -->
        <div class="first" id="F12048"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12048)?'':'color:red'">
            您觉得自己是个有用的人，有人需要您？
            <div class="q-title4">
              第二十九项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12048">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12048,1)">1.&nbsp;没有或很少时间</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12048,2)">2.&nbsp;每月1次左右</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12048,3)">3.&nbsp;每周1次左右</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12048,4)">4.&nbsp;每天1次左右</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12048,5)">5.&nbsp;每天多次</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 30 -->
        <div class="first" id="F12049"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12049)?'':'color:red'">
            您会无缘无故地感到疲乏？
            <div class="q-title4">
              第三十项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12049">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12049,1)">1.&nbsp;没有或很少时间</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12049,2)">2.&nbsp;每月1次左右</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12049,3)">3.&nbsp;每周1次左右</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12049,4)">4.&nbsp;每天1次左右</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12049,5)">5.&nbsp;每天多次</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 31 -->
        <div class="first" id="F12050"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12050)?'':'color:red'">
            平常感兴趣的事，您仍然感兴趣？
            <div class="q-title4">
              第三十一项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12050">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12050,1)">1.&nbsp;没有或很少时间</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12050,2)">2.&nbsp;小部分时间</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12050,3)">3.&nbsp;相当多时间</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12050,4)">4.&nbsp;绝大部分时间</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12050,5)">5.&nbsp;全部时间</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 32 -->
        <div class="first" id="F12051"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12051)?'':'color:red'">
            比平常更容易生气、激动？
            <div class="q-title4">
              第三十二项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12051">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12051,1)">1.&nbsp;没有或很少时间</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12051,2)">2.&nbsp;每月1次左右</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12051,3)">3.&nbsp;每周1次左右</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12051,4)">4.&nbsp;每天1次左右</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12051,5)">5.&nbsp;每天多次</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 33 -->
        <div class="first" id="F12052"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12052)?'':'color:red'">
            您晚上睡眠状况如何？
            <div class="q-title4">
              第三十三项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12052">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12052,1)">1.&nbsp;很差</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12052,2)">2.&nbsp;差</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12052,3)">3.&nbsp;一般</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12052,4)">4.&nbsp;好</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12052,5)">5.&nbsp;很好</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 34 -->
        <div class="first" id="F12053"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12053)?'':'color:red'">
            您认为您的身体健康状况如何？
            <div class="q-title4">
              第三十四项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12053">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12053,1)">1.&nbsp;很好</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12053,2)">2.&nbsp;好</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12053,3)">3.&nbsp;一般</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12053,4)">4.&nbsp;差</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12053,5)">5.&nbsp;很差</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12053,6)">6.其他</van-radio>
            </van-radio-group>
          </div>
        </div>

        <div class="B">
          <div class="q-title3">以下项目，请主要根据照护人员提供的信息填写。</div>
          <div class="q-title3">如无特别说明，指以往30天的情况。</div>
        </div>
        <!-- 35 -->
        <div class="first" id="F12054"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12054)?'':'color:red'">
            食物烹煮是否需要帮助
            <div class="q-title4">
              第三十五项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12054">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12054,1)">1.&nbsp;能够计划、烹煮和摆放一顿适当的饭菜
              </van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12054,2)">2.&nbsp;如果准备好材料，会做一顿适当的饭菜
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12054,3)">3.&nbsp;在他人协助下，能够做一顿适当的饭菜
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12054,4)">4.&nbsp;只能够将做好的饭菜加热</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12054,5)">5.&nbsp;需要别人把饭菜蒸煮、摆放好</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 36 -->
        <div class="first" id="F12055"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12055)?'':'color:red'">
            做家务活是否需要帮助
            <div class="q-title4">
              第三十六项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12055">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12055,1)">1.&nbsp;能做重的家务，如擦地板，偶尔需要帮助
              </van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12055,2)">2.&nbsp;只能做简单的家务活，如洗衣、洗碗、铺床
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12055,3)">3.&nbsp;虽能做家务活，但不能达到可接受的程度
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12055,4)">4.&nbsp;所有的家务活都需要别人较多的协助
              </van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12055,5)">5.&nbsp;完全不能做家务活</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 38 -->
        <div class="first" id="F12056"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12056)?'':'color:red'">
            上下楼是否需要帮助:
            <div class="q-title4">
              第三十七项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12056">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12056,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12056,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12056,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12056,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12056,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12058">
          </div>
        </div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12058)?'':'color:red'">
            外出后找不到回家的情况：
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12058">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12058,1)">1.&nbsp;没有或极少</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12058,2)">2.&nbsp;1个月1次左右</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12058,3)">3.&nbsp;1周1次左右或更多</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12057">
          </div>
          <div class="q-title" :style="isNotNull(collect.F12057)?'':'color:red'">
            外出频次;
            <div class="q-title4">
              第三十八项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12057">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12057,1)">1.&nbsp;每天外出多次</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12057,2)">2.&nbsp;每天外出1次左右</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12057,3)">3.&nbsp;每周外出1次左右</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12057,4)">4.&nbsp;每月外出1次左右</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12057,5)">5.&nbsp;每月外出少于1次</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 39 -->

        <div class="first" id="F12059"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12059)?'':'color:red'">
            外出3公里左右是否需要帮助
            <div class="q-title4">
              第三十九项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12059">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12059,1)">1.&nbsp;能够自己步行、骑车、开车外出</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12059,2)">2.&nbsp;能够自己搭乘公共交通外出</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12059,3)">3.&nbsp;只能够搭乘出租车等交通工具外出</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12059,4)">4.&nbsp;搭乘交通工具需要有人陪同</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12059,5)">5.&nbsp;外出完全需要帮助</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 40 -->
        <div class="first" id="F12060"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12060)?'':'color:red'">
            购物是否需要帮助
            <div class="q-title4">
              第四十项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12060">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12060,1)">1.&nbsp;能够独立完成所有购物需求</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12060,2)">2.&nbsp;能够独立购买一般的日常生活用品</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12060,3)">3.&nbsp;购物时，有时需要陪同</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12060,4)">4.&nbsp;每次购物都需要有人陪同</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12060,5)">5.&nbsp;完全不能购物</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 41 -->
        <div class="first" id="F12061"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12061)?'':'color:red'">
            金钱管理方面是否需要帮助
            <div class="q-title4">
              第四十一项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12061">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12061,1)">1.&nbsp;独立完成金钱的管理，支配，使用</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12061,2)">2.&nbsp;因担心算错等，每月管理约1000元
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12061,3)">3.&nbsp;因担心算错等，每月管理约300元
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12061,4)">4.&nbsp;接触金钱机会少，主要由家属等代管
              </van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12061,5)">5.&nbsp;完全不接触金钱等</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 42 -->
        <div class="first" id="F12062"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12062)?'':'color:red'">
            服用口服药物是否需要帮助
            <div class="q-title4">
              第四十二项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12062">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12062,1)">1.&nbsp;能够按时正确地服用药物</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12062,2)">2.&nbsp;在他人指导或照看下能够完成</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12062,3)">3.&nbsp;如果事先准备好药物，可自行服用</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12062,4)">4.&nbsp;即使事先准备好药物，也不一定能够完成
              </van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12062,5)">5.&nbsp;不能自己服药</van-radio>
            </van-radio-group>
          </div>
        </div>

        <div class="B">
          <div class="q-title3">以下项目，请根据照护人员提供的信息填写，</div>
          <div class="q-title3">可适当参照评估对象的回答。指最近14天的情况。</div>
        </div>

        <!-- 43 -->
        <div class="first" id="F12063"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12063)?'':'color:red'">
            评估对象强迫行为，如洗手、关门、上厕所等的次数
            <div class="q-title4">
              第四十三项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12063">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12063,1)">1.&nbsp;没有</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12063,2)">2.&nbsp;1次</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12063,3)">3.&nbsp;2~3次</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12063,4)">4.&nbsp;4~7次</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12063,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 44 -->
        <div class="first" id="F12064"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12064)?'':'color:red'">
            评估对象破坏衣物、家具等的次数
            <div class="q-title4">
              第四十四项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12064">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12064,1)">1.&nbsp;没有</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12064,2)">2.&nbsp;1次</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12064,3)">3.&nbsp;2~3次</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12064,4)">4.&nbsp;4~7次</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12064,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 45 -->
        <div class="first" id="F12065"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12065)?'':'color:red'">
            评估对象对周边人员行为攻击的次数
            <div class="q-title4">
              第四十五项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12065">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12065,1)">1.&nbsp;没有</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12065,2)">2.&nbsp;1次</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12065,3)">3.&nbsp;2~3次</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12065,4)">4.&nbsp;4~7次</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12065,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 46 -->
        <div class="first" id="F12066"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12066)?'':'color:red'">
            如被限制外出，评估对象擅自外出的次数
            <div class="q-title4">
              第四十六项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12066">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12066,1)">1.&nbsp;没有</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12066,2)">2.&nbsp;1次</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12066,3)">3.&nbsp;2~3次</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12066,4)">4.&nbsp;4~7次</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12066,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 47 -->
        <div class="first" id="F12067"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12067)?'':'color:red'">
            抵抗照护的次数
            <div class="q-title4">
              第四十七项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12067">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12067,1)">1.&nbsp;没有</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12067,2)">2.&nbsp;1次</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12067,3)">3.&nbsp;2~3次</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12067,4)">4.&nbsp;4~7次</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12067,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 48 -->
        <div class="first" id="F12068"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12068)?'':'color:red'">
            无缘无故地哭泣的次数
            <div class="q-title4">
              第四十八项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12068">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12068,1)">1.&nbsp;没有</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12068,2)">2.&nbsp;1次</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12068,3)">3.&nbsp;2~3次</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12068,4)">4.&nbsp;4~7次</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12068,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 49 -->
        <div class="first" id="F12069"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12069)?'':'color:red'">
            不切实际的害怕或过度担心的次数
            <div class="q-title4">
              第四十九项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12069">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12069,1)">1.&nbsp;没有</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12069,2)">2.&nbsp;1次</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12069,3)">3.&nbsp;2~3次</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12069,4)">4.&nbsp;4~7次</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12069,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 50 -->
        <div class="first" id="F12070"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12070)?'':'color:red'">
            看到或听到别人感觉不到的东西的次数
            <div class="q-title4">
              第五十项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12070">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12070,1)">1.&nbsp;没有</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12070,2)">2.&nbsp;1次</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12070,3)">3.&nbsp;2~3次</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12070,4)">4.&nbsp;4~7次</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12070,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="bottom" v-if="isWc == false">
          <div @click="add2(null)" class="bottom1">保存</div>
          <div @click="xiayixiang2" class="bottom2">下一项</div>
        </div>
        <div class="bottom" v-if="isWc">
          <div @click="updateStatus(1)" class="bottom1">同意</div>
          <div @click="updateStatus(0)" class="bottom2">拒绝</div>
        </div>
      </van-tab>

      <van-tab style="padding: 0px 3% 60px 3%" title="总体状况">
        <div class="B">
          <div class="q-title3">以下项目，请调查员主要根据自己的判断填写，</div>
          <div class="q-title3">可适当参照照护人员提供的信息。</div>
        </div>
        <!-- 1 -->
        <div class="first" id="F12071"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12071)?'':'color:red'">
            评估对象日常生活的基本判断能力（包括吃饭、穿衣、活动、睡觉）
            <div class="q-title4">
              第一项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12071">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12071,1)">1.&nbsp;判断能力较好，判断安全、合理</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12071,2)">2.&nbsp;判断能力尚可，日常生活判断基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12071,3)">3.&nbsp;判断能力一般，日常判断有时需要提示</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12071,4)">4.&nbsp;判断能力较差，日常判断经常出现失误</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12071,5)">5.&nbsp;判断能力极差或丧失等</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 2 -->
        <div class="first" id="F12072"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12072)?'':'color:red'">
            评估对象自身意愿 / 信息的表达能力
            <div class="q-title4">
              第二项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12072">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12072,1)">1.&nbsp;能够被一般人理解</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12072,2)">2.&nbsp;仅能够被家属或者身边的照护人员理解</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12072,3)">3.&nbsp;即使家属或者身边的照护人员也难以理解</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12072,4)">4.&nbsp;即使家属或者身边的照护人员也不能理解</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12072,5)">5.&nbsp;因为意识障碍等，不能够传达意愿 / 信息</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 3 -->
        <div class="first" id="F12073"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12073)?'':'color:red'">
            评估对象对他人所表达的意愿 / 信息的理解能力
            <div class="q-title4">
              第三项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12073">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12073,1)">1.&nbsp;能够理解一般大众所表达的意愿 / 信息</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12073,2)">2.&nbsp;通过家属或者身边的照护人员的帮助，能够理解一般大众所表达的意愿 / 信息</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12073,3)">3.&nbsp;即使是家属或者身边的照护人员所表达的意愿 / 信息也很难被理解</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12073,4)">4.&nbsp;即使是家属或者身边的照护人员所表达的意愿 / 信息也不能够被理解</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12073,5)">5.&nbsp;因为意识障碍等，接受不到意愿 / 信息</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 4 -->
        <div class="first" id="F12074"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12074)?'':'color:red'">
            与人相处的相容性
            <div class="q-title4">
              第四项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12074">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12074,1)">1.&nbsp;喜欢与人相处，并能相处好</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12074,2)">2.&nbsp;能够与10名及以上的家人朋友相处好</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12074,3)">3.&nbsp;只能够与4~9人相处好</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12074,4)">4.&nbsp;只能够与1~3人相处好</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12074,5)">5.&nbsp;不能够与任何人相处好</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 5 -->
        <div class="first" id="F12075"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12075)?'':'color:red'">
            评估对象日常生活自理程度
            <div class="q-title4">
              第五项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12075">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12075,1)">1.&nbsp;够独自外出，日常生活自理</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12075,2)">2.&nbsp;以室内为主，日常生活基本自理，外出需要照护</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12075,3)">3.&nbsp;移乘不需要帮助，日常生活需要帮助，不在床上吃饭、排泄</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12075,4)">4.&nbsp;移乘需要帮助，日常生活需要帮助，吃饭或者排泄需要照护</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12075,5)">5.&nbsp;整天卧床，吃饭、穿衣、排泄等需要全面照护</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 6 -->

        <div class="Q">
          <div class="first" id="buchong">
          </div>
          <div class="q-title1" :style="isNotNull(value.buchong)?'':'color:red'">情况描述</div>
          <div class="q-title2">请详细描述“能力打分”判断依据，并辅以照片录音说明情况。</div>
          <div class="q-content">
            <textarea cols="30" rows="10" v-model="value.buchong"></textarea>
          </div>
          <div class="q-title1">我确认，本调查表以上填写的内容，均是基于我所观察到的被调查对象的实际情况、被调查对象回答的情况以及家属和/或照护人员所反映的情况。 特此声明。</div>
          <div class="q-title1" style="font-size:12px">调查员编号：<span style="color:blue">{{pgyObj.pgy1}}</span>&nbsp;&nbsp;&nbsp;调查日期：<span></span>
          </div>

        </div>
        <div class="bottom" v-if="isWc == false">
          <div @click="add3(null)" class="bottom1">保存</div>
          <div @click="xiayixiang3" class="bottom2">完成</div>
        </div>
        <div class="bottom" v-if="isWc">
          <div @click="updateStatus(1)" class="bottom1">同意</div>
          <div @click="updateStatus(0)" class="bottom2">拒绝</div>
        </div>
      </van-tab>

      <van-tab style="padding: 0px 3% 60px 3%" title="疾病总体信息">
        <div class="first" id="F12076"></div>
        <!-- 1 -->
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12076)?'':'color:red'">1.&nbsp;意识</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12076">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12076,1)">1.&nbsp;清醒或基本清醒</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12076,2)">2.&nbsp;意识模糊</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12076,3)">3.&nbsp;昏迷</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 2 -->
        <div class="first" id="F12077"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12077)?'':'color:red'">2.&nbsp;心跳</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12077">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12077,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12077,2)">2.&nbsp;40~60次/分或100~120次/分</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12077,3)">3.&nbsp;&lt;40次/分或&gt;120次/分</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 3 -->
        <div class="first" id="F12078"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12078)?'':'color:red'">3.&nbsp;呼吸</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12078">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12078,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12078,2)">2.&nbsp;急促，费力</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12078,3)">3.&nbsp;端鸣，窘迫，窒息感</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 4 -->

        <div class="first" id="F12079"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12079)?'':'color:red'">4.&nbsp;血压</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12079">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12079,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12079,2)">2.&nbsp;收缩压141~160mmHg或舒张压91~110mmHg</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12079,3)">3.&nbsp;舒张压&lt;60mmHg或&gt;110mmHg或收缩压&gt;160mmHg</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 5 -->
        <div class="first" id="F12080"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12080)?'':'color:red'">5.&nbsp;体温</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12080">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12080,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12080,2)">2.&nbsp;37.5°C~38.9°C</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12080,3)">3.&nbsp;&lt;36°C或&gt;39°C</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 6 -->
        <div class="first" id="F12081"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12081)?'':'color:red'">6.&nbsp;营养</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12081">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12081,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12081,2)">2.&nbsp;肌肉消耗显著，三头肌皮褶厚度显著减少，踝部水肿明显</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12081,3)">3.&nbsp;肌肉消耗严重，三头肌皮褶厚度严重消失，踝部水肿显著</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 7 -->
        <div class="first" id="F12083"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12083)?'':'color:red'">7.&nbsp;疼痛</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12083">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12083,1)">1.&nbsp;不痛或基本不痛</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12083,2)">2.&nbsp;间歇或持续性疼痛，影响休息，需用药</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12083,3)">3.&nbsp;持续剧烈疼痛，用药才能缓解</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 8 -->
        <div class="first" id="F12084"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12084)?'':'color:red'">8.&nbsp;情绪</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12084">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12084,1)">1.&nbsp;稳定或基本稳定</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12084,2)">2.&nbsp;焦虑、消极、悲观、忧伤、厌世、自私多疑</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12084,3)">3.&nbsp;哭泣、抑郁、暴躁、易怒</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 9 -->
        <div class="first" id="F12085"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12085)?'':'color:red'">9.&nbsp;便秘</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12085">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12085,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12085,2)">2.&nbsp;偶有便秘</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12085,3)">3.&nbsp;严重便秘</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 10 -->
        <div class="first" id="F12086"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12086)?'':'color:red'">10.&nbsp;肌力</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12086">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12086,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12086,2)">2.&nbsp;肢体活动严重受限</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12086,3)">3.&nbsp;肢体活动丧失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 11 -->
        <div class="first" id="F12087"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12087)?'':'color:red'">11.&nbsp;睡眠</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12087">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12087,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12087,2)">2.&nbsp;难以入眠、频繁觉醒</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12087,3)">3.&nbsp;非恢复性睡眠</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 12 -->
        <div class="first" id="F12088"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12088)?'':'color:red'">12.&nbsp;脱水</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12088">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12088,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12088,2)">2.&nbsp;严重口渴、头痛、尿少，皮肤弹性明显降低</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12088,3)">3.&nbsp;体重明显降低、皮肤弹性严重消失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 13 -->
        <div class="first" id="F12089"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12089)?'':'color:red'">13.&nbsp;咀嚼功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12089">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12089,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12089,2)">2.&nbsp;能咀嚼细软食物</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12089,3)">3.&nbsp;不能咀嚼</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 14 -->
        <div class="first" id="F12090"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12090)?'':'color:red'">14.&nbsp;感觉功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12090">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12090,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12090,2)">2.&nbsp;有痛觉、触觉、温度觉功能</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12090,3)">3.&nbsp;无痛、触、温、运动、味觉功能</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 15 -->
        <div class="first" id="F12091"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12091)?'':'color:red'">15.&nbsp;认知功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12091">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12091,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12091,2)">2.&nbsp;有简单的认知功能</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12091,3)">3.&nbsp;认知功能丧失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 16 -->
        <div class="first" id="F12092"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12092)?'':'color:red'">16.&nbsp;运动功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12092">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12092,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12092,2)">2.&nbsp;需借助工具、他人帮助</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12092,3)">3.&nbsp;认知功能丧失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 17 -->
        <div class="first" id="F12093"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12093)?'':'color:red'">17.&nbsp;社交功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12093">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12093,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12093,2)">2.&nbsp;严重受限</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12093,3)">3.&nbsp;功能丧失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 18-->
        <div class="first" id="F12094"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12094)?'':'color:red'">18.&nbsp;吞咽功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12094">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12094,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12094,2)">2.&nbsp;严重受限</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12094,3)">3.&nbsp;功能丧失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 19-->
        <div class="first" id="F12095"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12095)?'':'color:red'">19.&nbsp;语言功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12095">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12095,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12095,2)">2.&nbsp;简单有限交谈</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12095,3)">3.&nbsp;失语</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 20-->
        <div class="first" id="F12096"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12096)?'':'color:red'">20.&nbsp;记忆功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12096">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12096,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12096,2)">2.&nbsp;简单记忆且不连贯</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12096,3)">3.&nbsp;功能丧失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 21-->
        <div class="first" id="F12097"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12097)?'':'color:red'">21.&nbsp;情感功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12097">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12097,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12097,2)">2.&nbsp;情感淡漠或轻度躁狂</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12097,3)">3.&nbsp;无欲望、严重抑郁或躁狂</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 22-->
        <div class="first" id="F12098"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12098)?'':'color:red'">22.&nbsp;自杀风险</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12098">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12098,1)">1.&nbsp;无自杀倾向</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12098,2)">2.&nbsp;有严重自杀倾向</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12098,3)">3.&nbsp;有自杀行为发生</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 23 -->
        <div class="first" id="F12099"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12099)?'':'color:red'">23.&nbsp;跌倒风险</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12099">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12099,1)">1.&nbsp;最近90天无跌倒</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12099,2)">2.&nbsp;最近30天无跌倒，但31~90天有</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12099,3)">3.&nbsp;最近30天有跌倒发生</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 24 -->
        <div class="first" id="F12100"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12100)?'':'color:red'">24.&nbsp;所患疾病情况</div>
          <div class="q-content">
            <van-checkbox-group
              direction="horizontal"
              v-model="collect.F12100"
              >
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12100,1)"
              >高血压
              </van-checkbox
              >
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12100,2)"
              >冠心病
              </van-checkbox
              >
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12100,3)"
              >慢性肺炎
              </van-checkbox
              >
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12100,4)"
              >慢性阻塞性肺病
              </van-checkbox
              >
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12100,5)"
              >脑出血
              </van-checkbox
              >
              <van-checkbox :name="6" class="checkbox" shape="square" v-if="isShow(collect.F12100,6)"
              >脑梗死
              </van-checkbox
              >
              <van-checkbox :name="7" class="checkbox" shape="square" v-if="isShow(collect.F12100,7)"
              >糖尿病
              </van-checkbox
              >
              <van-checkbox :name="8" class="checkbox" shape="square" v-if="isShow(collect.F12100,8)"
              >帕金森病
              </van-checkbox
              >
              <van-checkbox :name="9" class="checkbox" shape="square" v-if="isShow(collect.F12100,9)"
              >晚期肿瘤
              </van-checkbox
              >
              <van-checkbox :name="10" class="checkbox" shape="square" v-if="isShow(collect.F12100,10)"
              >下肢骨折
              </van-checkbox
              >
              <van-checkbox :name="11" class="checkbox" shape="square" v-if="isShow(collect.F12100,11)"
              >认知障碍
              </van-checkbox
              >
              <van-checkbox :name="12" class="checkbox" shape="square" v-if="isShow(collect.F12100,12)"
              >慢性肾功能衰竭
              </van-checkbox
              >
              <van-checkbox :name="13" class="checkbox" shape="square" v-if="isShow(collect.F12100,13)"
              >癫痫
              </van-checkbox
              >
              <van-checkbox :name="14" class="checkbox" shape="square" v-if="isShow(collect.F12100,14)"
              >肝硬化
              </van-checkbox
              >
              <van-checkbox :name="15" class="checkbox" shape="square" v-if="isShow(collect.F12100,15)"
              >甲亢/甲减
              </van-checkbox
              >
              <van-checkbox :name="16" class="checkbox" shape="square" v-if="isShow(collect.F12100,16)"
              >其他消化系统疾病
              </van-checkbox
              >
              <van-checkbox :name="17" class="checkbox" shape="square" v-if="isShow(collect.F12100,17)"
              >类风湿性关节炎
              </van-checkbox
              >
              <van-checkbox :name="18" class="checkbox" shape="square" v-if="isShow(collect.F12100,18)"
              >病毒性肝炎
              </van-checkbox
              >
              <van-checkbox :name="19" class="checkbox" shape="square" v-if="isShow(collect.F12100,19)"
              >性病
              </van-checkbox
              >
              <van-checkbox
                :name="20"
                class="checkbox"
                shape="square"
                v-if="isShow(collect.F12100,20)"
              >其它
              </van-checkbox
              >
            </van-checkbox-group>
          </div>
        </div>
        <div class="Q" v-if="include(collect.F12100,20)">
          <div class="first" id="F12454">
          </div>
          <van-field
            input-align="right"
            label="其他"
            placeholder="..."
            v-model="collect.F12454"
            :label-class="isNotNull(collect.F12454)?'':'fieldF11673'"
          />
        </div>

        <div class="first" id="F12101"></div>
        <!-- 25 -->
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12101)?'':'color:red'">25.最近14天连续接受的医疗和康复治疗</div>
          <div class="q-content">
            <van-checkbox-group
              direction="horizontal"
              v-model="collect.F12101"
            >
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12101,1)"
              >口服药物
              </van-checkbox
              >
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12101,2)"
              >肌肉注射
              </van-checkbox
              >
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12101,3)"
              >静脉注射
              </van-checkbox
              >
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12101,4)"
              >康复治疗
              </van-checkbox
              >
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12101,5)"
              >吸氧治疗
              </van-checkbox
              >
              <van-checkbox :name="6" class="checkbox" shape="square" v-if="isShow(collect.F12101,6)"
              >鼻饲营养
              </van-checkbox
              >
              <van-checkbox :name="7" class="checkbox" shape="square" v-if="isShow(collect.F12101,7)"
              >肠外营养
              </van-checkbox
              >
              <van-checkbox :name="8" class="checkbox" shape="square" v-if="isShow(collect.F12101,8)"
              >中心静脉营养
              </van-checkbox
              >
              <van-checkbox :name="9" class="checkbox" shape="square" v-if="isShow(collect.F12101,9)"
              >疼痛护理
              </van-checkbox
              >
              <van-checkbox :name="10" class="checkbox" shape="square" v-if="isShow(collect.F12101,10)"
              >压伤护理
              </van-checkbox
              >
              <van-checkbox :name="11" class="checkbox" shape="square" v-if="isShow(collect.F12101,11)"
              >血透治疗
              </van-checkbox
              >
              <van-checkbox :name="12" class="checkbox" shape="square" v-if="isShow(collect.F12101,12)"
              >腹透治疗
              </van-checkbox
              >
              <van-checkbox :name="13" class="checkbox" shape="square" v-if="isShow(collect.F12101,13)"
              >生命体征监测
              </van-checkbox
              >
              <van-checkbox :name="14" class="checkbox" shape="square" v-if="isShow(collect.F12101,14)"
              >留置导尿管
              </van-checkbox
              >
              <van-checkbox :name="15" class="checkbox" shape="square" v-if="isShow(collect.F12101,15)"
              >人工呼吸器
              </van-checkbox
              >
              <van-checkbox :name="16" class="checkbox" shape="square" v-if="isShow(collect.F12101,16)"
              >气管切开护理
              </van-checkbox
              >
              <van-checkbox :name="17" class="checkbox" shape="square" v-if="isShow(collect.F12101,17)"
              >无
              </van-checkbox
              >
            </van-checkbox-group>
          </div>
        </div>
        <!-- 26 -->
        <div class="first" id="F12103"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12103)?'':'color:red'">26.疾病对生存的影响</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12103">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12103,1)">1.&nbsp;没有疾病，或者疾病对健康基本没有影响</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12103,2)">2.&nbsp;患有疾病，较大程度地影响患者的基本生活</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12103,3)">3.&nbsp;疾病或者症状严重但病情稳定，不威胁生命</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12103,4)">4.&nbsp;疾病或者症状严重且病情时常恶化，可能会威胁生命</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12103,5)">5.&nbsp;疾病或者症状非常严重，处于生命的终末期</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 27 -->
        <div class="first" id="buchong2"></div>
        <div class="Q">
          <div class="q-title1" :style="isNotNull(value.buchong2)?'':'color:red'">情况描述</div>
          <div class="q-title2">请详细描述“能力打分”判断依据，并辅以照片录音说明情况。</div>
          <div class="q-content">
            <textarea cols="30" id="" rows="10" v-model="value.buchong2"></textarea>
          </div>
        </div>
        <div class="bottom" v-if="isWc == false">
          <div @click="add4(null)" class="bottom1">保存</div>
          <div @click="xiayixiang4" class="bottom2">下一项</div>
        </div>
        <div class="bottom" v-if="isWc">
          <div @click="updateStatus(1)" class="bottom1">同意</div>
          <div @click="updateStatus(0)" class="bottom2">拒绝</div>
        </div>
      </van-tab>

      <van-tab class="specific-diseases" style="padding: 0px 3% 60px 3%" title="特定疾病信息">

        <div class="B">
          <div class="q-title3">以下项目由医务人员，根据病人主诉或经查体后记录。</div>
          <div class="q-title3">相关检查指标，可参照门诊病历、出院小结等。</div>
        </div>
        <div class="Q" v-if="isShow(collect.F12108,1)">
          <div class="first" id="F12108"></div>
          <div class="q-title">慢性阻塞性肺疾病
            <div class="q-title4">
              第一项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" class="checkbox" shape="square" v-model="collect.F12108"
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12108 == 1">
          <div class="first" id="F12109">
          </div>
          <div class="q-title5" :style="isNotNull(collect.F12109)?'':'color:red'">局部症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12109">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12109,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12109,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12109,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12109,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12109,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12110"></div>
          <div class="q-title6" :style="isNotNull(collect.F12110)?'':'color:red'">1.&nbsp;慢性咳嗽
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12110">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12110,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12110,2)">2.&nbsp;偶尔咳嗽</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12110,3)">3.&nbsp;反复咳嗽</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12110,4)">4.&nbsp;持续咳嗽</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12111"></div>
          <div class="q-title6" :style="isNotNull(collect.F12111)?'':'color:red'">2.&nbsp;咳痰
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12111">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12111,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12111,2)">2.&nbsp;偶尔咳白或黏痰</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12111,3)">3.&nbsp;反复咳脓痰</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12111,4)">4.&nbsp;血痰</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12112"></div>
          <div class="q-title6" :style="isNotNull(collect.F12112)?'':'color:red'"> 3.&nbsp;气短
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12112">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12112,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12112,2)">2.&nbsp;劳力时气短</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12112,3)">3.&nbsp;日常活动时气短</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12112,4)">4.&nbsp;静息时气短</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12113"></div>
          <div class="q-title6" :style="isNotNull(collect.F12113)?'':'color:red'"> 4.&nbsp;呼吸困难
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12113">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12113,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12113,2)">2.&nbsp;劳力性呼吸困难</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12113,3)">3.&nbsp;夜间阵发性呼吸困难</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12113,4)">4.&nbsp;端坐呼吸</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12114"></div>
          <div class="q-title6" :style="isNotNull(collect.F12114)?'':'color:red'"> 5.&nbsp;喘息
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12114">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12114,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12114,2)">2.&nbsp;轻度喘息</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12114,3)">3.&nbsp;中度喘息</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12114,4)">4.&nbsp;重度喘息、喘憋</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12115"></div>
          <div class="q-title6" :style="isNotNull(collect.F12115)?'':'color:red'"> 6.&nbsp;胸闷
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12115">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12115,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12115,2)">2.&nbsp;阵发性胸闷</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12115,3)">3.&nbsp;持续性胸闷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12116"></div>
          <div class="q-title5" :style="isNotNull(collect.F12116)?'':'color:red'">全身症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12116">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12116,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12116,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12116,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12116,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12116,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12117"></div>
          <div class="q-title6" :style="isNotNull(collect.F12117)?'':'color:red'">1.&nbsp;食欲
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12117">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12117,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12117,2)">2.&nbsp;减退</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12117,3)">3.&nbsp;拒绝进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12118"></div>
          <div class="q-title6" :style="isNotNull(collect.F12118)?'':'color:red'">2.&nbsp;体重
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12118">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12118,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12118,2)">2.&nbsp;消瘦</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12118,3)">3.&nbsp;恶病质</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12119"></div>
          <div class="q-title6" :style="isNotNull(collect.F12119)?'':'color:red'"> 3.&nbsp;外周肌力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12119">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12119,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12119,2)">2.&nbsp;萎缩</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12119,3)">3.&nbsp;功能障碍</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12120"></div>
          <div class="q-title6" :style="isNotNull(collect.F12120)?'':'color:red'"> 4.&nbsp;精神状态
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12120">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12120,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12120,2)">2.&nbsp;抑郁和（或）焦虑</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12121"></div>
          <div class="q-title5" :style="isNotNull(collect.F12121)?'':'color:red'">体征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12121">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12121,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12121,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12121,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12121,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12121,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12122"></div>
          <div class="q-title6" :style="isNotNull(collect.F12122)?'':'color:red'">1.&nbsp;胸廓形状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12122">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12122,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12122,2)">2.&nbsp;前后径增大</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12122,3)">3.&nbsp;桶状胸</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12123"></div>
          <div class="q-title6" :style="isNotNull(collect.F12123)?'':'color:red'">2.&nbsp;紫绀
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12123">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12123,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12123,2)">2.&nbsp;口唇紫绀</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12123,3)">3.&nbsp;肢体末端紫绀</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12123,4)">4.&nbsp;全身性紫绀</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12124"></div>
          <div class="q-title6" :style="isNotNull(collect.F12124)?'':'color:red'"> 3.&nbsp;水肿
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12124">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12124,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12124,2)">2.&nbsp;下肢水肿</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12124,3)">3.&nbsp;肝脏肿大</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12124,4)">4.&nbsp;全身水肿</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12125"></div>
          <div class="q-title6" :style="isNotNull(collect.F12125)?'':'color:red'"> 4.&nbsp;肺叩诊
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12125">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12125,1)">1.&nbsp;清音</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12125,2)">2.&nbsp;过清音</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12126"></div>
          <div class="q-title6" :style="isNotNull(collect.F12126)?'':'color:red'"> 5.&nbsp;呼吸音
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12126">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12126,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12126,2)">2.&nbsp;减弱</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12126,3)">3.&nbsp;干和（或）湿啰音</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12126,4)">4.&nbsp;呼吸音未及</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12127"></div>
          <div class="q-title6" :style="isNotNull(collect.F12127)?'':'color:red'"> 6.&nbsp;心音
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12127">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12127,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12127,2)">2.&nbsp;低钝</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12127,3)">3.&nbsp;遥远</van-radio>
            </van-radio-group>
          </div>
          <!-- 辅助检查 -->
          <div class="first" id="F12128"></div>
          <div class="q-title5" :style="isNotNull(collect.F12128)?'':'color:red'">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12128">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12128,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12128,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12128,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12128,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12128,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12129"></div>
          <div class="q-title6" :style="isNotNull(collect.F12129)?'':'color:red'">1.&nbsp;血象
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12129">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12129,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12129,2)">2.&nbsp;10×109/L &lt; WBC &lt;
                15×109/L
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12129,3)">3.&nbsp;WBC &gt; 15×109/L 或 &lt;
                4×109/L
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12130"></div>
          <div class="q-title6" :style="isNotNull(collect.F12130)?'':'color:red'">2.&nbsp;影像学
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12130">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12130,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12130,2)">2.&nbsp;肺纹理增粗、紊乱</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12130,3)">3.&nbsp;肺气肿</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12130,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12131"></div>
          <div class="q-title6" :style="isNotNull(collect.F12131)?'':'color:red'"> 3.&nbsp;血气
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12131">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12131,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12131,2)">2.&nbsp;PaO2低，PaCO2正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12131,3)">3.&nbsp;PaO2低，伴PaCO2升高</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12131,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12132"></div>
          <div class="q-title6" :style="isNotNull(collect.F12132)?'':'color:red'"> 4.&nbsp;肺功能
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12132">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12132,1)">1.&nbsp;FEV1/FVC&gt;70%,FEV1&gt;=80%</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12132,2)">2.&nbsp;FEV1/FVC&lt;=70%,FEV1&gt;=80%</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12132,3)">3.&nbsp;FEV1/FVC&lt;=70%,FEV1在50%~80%</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12132,4)">4.&nbsp;FEV1/FVC&lt;=70%,FEV1在30%~50%</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12132,5)">5.&nbsp;FEV1/FVC&lt;=70%,FEV1&lt;30%</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12132,6)">6.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12135"></div>
          <div class="q-title5" :style="isNotNull(collect.F12135)?'':'color:red'">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12135">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12135,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12135,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12135,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12135,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12135,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12136"></div>
          <div class="q-title6" :style="isNotNull(collect.F12136)?'':'color:red'">1.&nbsp;肺心病
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12136">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12136,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12136,2)">2.&nbsp;有，心功能不全</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12136,3)">3.&nbsp;心力衰竭</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12137"></div>
          <div class="q-title6" :style="isNotNull(collect.F12137)?'':'color:red'">2.&nbsp;肺性脑病
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12137">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12137,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12137,2)">2.&nbsp;有，典型精神症状，但未昏迷</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12137,3)">3.&nbsp;有，昏迷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12138"></div>
          <div class="q-title6" :style="isNotNull(collect.F12138)?'':'color:red'"> 3.&nbsp;呼吸衰竭
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12138">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12138,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12138,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12139"></div>
          <div class="q-title6" :style="isNotNull(collect.F12139)?'':'color:red'"> 4.&nbsp;肺部感染
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12139">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12139,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12139,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12140"></div>
          <div class="q-title5" :style="isNotNull(collect.F12140)?'':'color:red'">疾病的治疗强度需求
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12140">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12140,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12140,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12140,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12140,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12140,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12141"></div>
          <div class="q-title5" :style="isNotNull(collect.F12141)?'':'color:red'">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12141">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12141,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12141,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12141,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12141,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12141,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12141,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 糖尿病 -->
        <div class="Q">
          <div class="first" id="F12142"></div>
          <div class="q-title" v-if="isShow(collect.F12142,1)">糖尿病
            <div class="q-title4">
              第二项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12142,1)"
                          v-model="collect.F12142"
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12142 == 1">
          <div class="first" id="F12143">
          </div>
          <div class="q-title5" :style="isNotNull(collect.F12143)?'':'color:red'">症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12143">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12143,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12143,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12143,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12143,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12143,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12144"></div>
          <div class="q-title6" :style="isNotNull(collect.F12144)?'':'color:red'">1.&nbsp;口渴
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12144">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12144,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12144,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12144,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12145"></div>
          <div class="q-title6" :style="isNotNull(collect.F12145)?'':'color:red'">2.&nbsp;多饮
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12145">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12145,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12145,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12145,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12146"></div>
          <div class="q-title6" :style="isNotNull(collect.F12146)?'':'color:red'"> 3.&nbsp;多尿
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12146">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12146,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12146,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12146,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12147"></div>
          <div class="q-title6" :style="isNotNull(collect.F12147)?'':'color:red'"> 4.&nbsp;多食
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12147">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12147,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12147,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12147,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12148"></div>
          <div class="q-title6" :style="isNotNull(collect.F12148)?'':'color:red'"> 5.&nbsp;消瘦
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12148">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12148,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12148,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12148,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12149"></div>
          <div class="q-title6" :style="isNotNull(collect.F12149)?'':'color:red'"> 6.&nbsp;乏力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12149">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12149,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12149,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12149,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12150"></div>
          <div class="q-title5" :style="isNotNull(collect.F12150)?'':'color:red'">体征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12150">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12150,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12150,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12150,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12150,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12150,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12151"></div>
          <div class="q-title6" :style="isNotNull(collect.F12151)?'':'color:red'">1.&nbsp;神志
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12151">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12151,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12151,2)">2.&nbsp;嗜睡或淡漠</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12151,3)">3.&nbsp;昏迷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12152"></div>
          <div class="q-title6" :style="isNotNull(collect.F12152)?'':'color:red'">2.&nbsp;脱水症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12152">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12152,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12152,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12152,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12153"></div>
          <div class="q-title6" :style="isNotNull(collect.F12153)?'':'color:red'"> 3.&nbsp;血压
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12153">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12153,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12153,2)">2.&nbsp;80~89/50~59mmHg</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12153,3)">3.&nbsp;&lt;80mmHg/50mmHg
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12153,4)">4.&nbsp;其他数据</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12154"></div>
          <div class="q-title6" :style="isNotNull(collect.F12154)?'':'color:red'"> 4.&nbsp;心率
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12154">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12154,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12154,2)">2.&nbsp;110~120次/分</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12154,3)">3.&nbsp;&gt;120次/分</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12155"></div>
          <div class="q-title6" :style="isNotNull(collect.F12155)?'':'color:red'"> 5.&nbsp;糖尿病足
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12155">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12155,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12155,2)">2.&nbsp;1~2级</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12155,3)">3.&nbsp;3~4级</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12155,4)">4.&nbsp;5级</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12156"></div>
          <div class="q-title5" :style="isNotNull(collect.F12156)?'':'color:red'">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12156">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12156,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12156,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12156,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12156,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12156,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12157"></div>
          <div class="q-title6" :style="isNotNull(collect.F12157)?'':'color:red'">1.&nbsp;血糖
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12157">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12157,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12157,2)">2.&nbsp;8~11.1mmol/L</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12157,3)">3.&nbsp;11.2~16.7mmol/L</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12157,4)">4.&nbsp;16.8~33.3mmol/L</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12157,5)">5.&nbsp;&gt;33.3mmol/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12158"></div>
          <div class="q-title6" :style="isNotNull(collect.F12158)?'':'color:red'">2.&nbsp;糖化血红蛋白
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12158">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12158,1)">1.&nbsp;&lt;7%</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12158,2)">2.&nbsp;7%~8%</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12158,3)">3.&nbsp;&gt;8%</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12158,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12159"></div>
          <div class="q-title6" :style="isNotNull(collect.F12159)?'':'color:red'"> 3.&nbsp;尿酮
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12159">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12159,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12159,2)">2.&nbsp;阳性或（增高）</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12159,3)">3.&nbsp;强阳性或（明显增高）</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12160"></div>
          <div class="q-title6" :style="isNotNull(collect.F12160)?'':'color:red'">4.&nbsp;血肌酐
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12160">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12160,1)">1.&nbsp;&lt;133μ mol/L</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12160,2)">2.&nbsp;133~221μ mol/L</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12160,3)">3.&nbsp;222~442μ mol/L</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12160,4)">4.&nbsp;&gt;442μ mol/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12161"></div>
          <div class="q-title6" :style="isNotNull(collect.F12161)?'':'color:red'"> 5.&nbsp;血浆渗透压
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12161">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12161,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12161,2)">2.&nbsp;300~320mOsm/KgH2O
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12161,3)">3.&nbsp;&gt;320mOsm/KgH2O
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12161,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12162"></div>
          <div class="q-title5" :style="isNotNull(collect.F12162)?'':'color:red'">并发症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12162">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12162,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12162,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12162,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12162,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12162,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12163"></div>
          <div class="q-title6" :style="isNotNull(collect.F12163)?'':'color:red'">1.&nbsp;急慢性感染
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12163">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12163,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12163,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12164"></div>
          <div class="q-title6" :style="isNotNull(collect.F12164)?'':'color:red'">2.&nbsp;肾病
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12164">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12164,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12164,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12165"></div>
          <div class="q-title6" :style="isNotNull(collect.F12165)?'':'color:red'"> 3.&nbsp;视网膜病变
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12165">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12165,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12165,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12166"></div>
          <div class="q-title5" :style="isNotNull(collect.F12166)?'':'color:red'">疾病的治疗强度需求
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12166">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12166,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12166,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12166,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12166,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12166,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12167"></div>
          <!-- 六个月内病情稳定性 -->
          <div class="q-title5" :style="isNotNull(collect.F12167)?'':'color:red'">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12167">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12167,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12167,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12167,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12167,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12167,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12167,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="Q" v-if="isShow(collect.F12168,1)">
          <div class="first" id="F12168"></div>
          <div class="q-title">慢性肺炎
            <div class="q-title4">
              第三项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12168,1)"
                          v-model="collect.F12168"
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12168 == 1">
          <div class="first" id="F12169">
          </div>
          <div class="q-title5" :style="isNotNull(collect.F12169)?'':'color:red'">症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12169">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12169,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12169,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12169,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12169,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12169,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12170"></div>
          <div class="q-title6" :style="isNotNull(collect.F12170)?'':'color:red'">1.&nbsp;咳嗽
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12170">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12170,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12170,2)">2.&nbsp;阵发性咳嗽</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12170,3)">3.&nbsp;反复咳嗽</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12170,4)">4.&nbsp;持续咳嗽</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12171"></div>
          <div class="q-title6" :style="isNotNull(collect.F12171)?'':'color:red'">2.&nbsp;咳痰
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12171">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12171,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12171,2)">2.&nbsp;偶尔咳白或黏痰</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12171,3)">3.&nbsp;反复咳黄、脓痰</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12171,4)">4.&nbsp;大量黄黏稠痰</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12172"></div>
          <div class="q-title6" :style="isNotNull(collect.F12172)?'':'color:red'"> 3.&nbsp;呼吸频率
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12172">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12172,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12172,2)">2.&nbsp;浅快20~25次/分</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12172,3)">3.&nbsp;&gt;25次/分</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12172,4)">4.&nbsp;呼吸节律紊乱</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12173"></div>
          <div class="q-title6" :style="isNotNull(collect.F12173)?'':'color:red'"> 4.&nbsp;呼吸困难
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12173">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12173,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12173,2)">2.&nbsp;劳力性呼吸困难</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12173,3)">3.&nbsp;夜间阵发性呼吸困难</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12173,4)">4.&nbsp;端坐呼吸</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12174"></div>
          <div class="q-title6" :style="isNotNull(collect.F12174)?'':'color:red'"> 5.&nbsp;喘息
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12174">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12174,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12174,2)">2.&nbsp;轻度喘息</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12174,3)">3.&nbsp;中度喘息</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12174,4)">4.&nbsp;重度喘息、喘憋</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12175"></div>
          <div class="q-title6" :style="isNotNull(collect.F12175)?'':'color:red'">6.&nbsp;胸闷
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12175">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12175,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12175,2)">2.&nbsp;阵发性胸闷</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12175,3)">3.&nbsp;持续性胸闷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12176"></div>
          <div class="q-title5" :style="isNotNull(collect.F12176)?'':'color:red'">全身症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12176">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12176,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12176,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12176,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12176,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12176,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12177"></div>
          <div class="q-title6" :style="isNotNull(collect.F12177)?'':'color:red'">1.&nbsp;食欲
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12177">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12177,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12177,2)">2.&nbsp;恶心、呕吐</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12177,3)">3.&nbsp;进食困难</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12178"></div>
          <div class="q-title6" :style="isNotNull(collect.F12178)?'':'color:red'">2.&nbsp;体重
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12178">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12178,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12178,2)">2.&nbsp;消瘦</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12178,3)">3.&nbsp;恶病质</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12179"></div>
          <div class="q-title6" :style="isNotNull(collect.F12179)?'':'color:red'"> 3.&nbsp;发热情况
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12179">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12179,1)">1.&nbsp;≤37.3°C</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12179,2)">2.&nbsp;37.4~39°C</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12179,3)">3.&nbsp;&gt;39°C</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12180"></div>
          <div class="q-title6" :style="isNotNull(collect.F12180)?'':'color:red'"> 4.&nbsp;精神状态
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12180">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12180,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12180,2)">2.&nbsp;萎软、嗜睡</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12180,3)">3.&nbsp;抑郁和（或）焦虑</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12181"></div>
          <div class="q-title5" :style="isNotNull(collect.F12181)?'':'color:red'">体征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12181">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12181,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12181,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12181,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12181,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12181,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12182"></div>
          <div class="q-title6" :style="isNotNull(collect.F12182)?'':'color:red'">1.&nbsp;呼吸运动
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12182">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12182,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12182,2)">2.&nbsp;受限</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12183"></div>
          <div class="q-title6" :style="isNotNull(collect.F12183)?'':'color:red'">2.&nbsp;紫绀
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12183">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12183,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12183,2)">2.&nbsp;口唇紫绀</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12183,3)">3.&nbsp;肢体末端紫绀</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12183,4)">4.&nbsp;全身性紫绀</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12184"></div>
          <div class="q-title6" :style="isNotNull(collect.F12184)?'':'color:red'"> 3.&nbsp;触诊
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12184">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12184,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12184,2)">2.&nbsp;语颤增强</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12185"></div>
          <div class="q-title6" :style="isNotNull(collect.F12185)?'':'color:red'">4.&nbsp;叩诊
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12185">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12185,1)">1.&nbsp;清音</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12185,2)">2.&nbsp;浊音或实音</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12186"></div>
          <div class="q-title6" :style="isNotNull(collect.F12186)?'':'color:red'"> 5.&nbsp;听诊
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12186">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12186,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12186,2)">2.&nbsp;多肺段干、湿啰音</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12186,3)">3.&nbsp;局限干、湿啰音</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12186,4)">4.&nbsp;两肺野广泛干、湿啰音</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12187"></div>
          <div class="q-title6" :style="isNotNull(collect.F12187)?'':'color:red'"> 6.&nbsp;心音
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12187">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12187,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12187,2)">2.&nbsp;低钝</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12187,3)">3.&nbsp;遥远</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12188"></div>
          <!-- 辅助检查 -->
          <div class="q-title5" :style="isNotNull(collect.F12188)?'':'color:red'">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12188">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12188,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12188,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12188,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12188,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12188,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12189"></div>
          <div class="q-title6" :style="isNotNull(collect.F12189)?'':'color:red'">1.&nbsp;血象
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12189">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12189,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12189,2)">2.&nbsp;4×109/L&lt;WBC&lt;15×109/L,中性粒细胞50%~85%</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12189,3)">3.&nbsp;WBC&gt;15×109/L或&lt;4×109/L,中性粒细胞&gt;85%</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12190"></div>
          <div class="q-title6" :style="isNotNull(collect.F12190)?'':'color:red'">2.&nbsp;影像学
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12190">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12190,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12190,2)">2.&nbsp;局限肺纹理增粗、斑片状阴影</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12190,3)">3.&nbsp;大片模糊浸润阴影</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12190,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12191"></div>
          <div class="q-title6" :style="isNotNull(collect.F12191)?'':'color:red'"> 3.&nbsp;血气
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12191">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12191,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12191,2)">2.&nbsp;PaO2低，PaCO2正常，PH
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12192"></div>
          <div class="q-title6" :style="isNotNull(collect.F12192)?'':'color:red'"> 4.&nbsp;肺功能
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12192">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12192,1)">1.&nbsp;FEV1/FVC&gt;70%,FEV1&gt;=80%
              </van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12192,2)">2.&nbsp;FEV1/FVC&lt;=70%,
                FEV1在50%~80%
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12192,3)">3.&nbsp;FEV1/FVC&lt;=70%,FEV1&lt;50%
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12192,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12193"></div>
          <div class="q-title6" :style="isNotNull(collect.F12193)?'':'color:red'"> 5.&nbsp;痰培养
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12193">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12193,1)">1.&nbsp;阴性</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12193,2)">2.&nbsp;阳性致病菌</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12193,3)">3.&nbsp;未检测
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12194"></div>
          <div class="q-title5" :style="isNotNull(collect.F12194)?'':'color:red'">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12194">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12194,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12194,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12194,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12194,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12194,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12195"></div>
          <div class="q-title6" :style="isNotNull(collect.F12195)?'':'color:red'">1.&nbsp;心力衰竭
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12195">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12195,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12195,2)">2.&nbsp;轻度心功能不全</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12195,3)">3.&nbsp;中、重度心衰</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12196"></div>
          <div class="q-title6" :style="isNotNull(collect.F12196)?'':'color:red'">2.&nbsp;肺性脑病
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12196">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12196,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12196,2)">2.&nbsp;嗜睡、谵妄、震颤</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12196,3)">3.&nbsp;昏迷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12197"></div>
          <div class="q-title6" :style="isNotNull(collect.F12197)?'':'color:red'">3.&nbsp;呼吸衰竭
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12197">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12197,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12197,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12198"></div>
          <div class="q-title5" :style="isNotNull(collect.F12198)?'':'color:red'">疾病的治疗强度需求
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12198">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12198,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12198,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12198,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12198,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12198,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12199"></div>
          <div class="q-title5" :style="isNotNull(collect.F12199)?'':'color:red'">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12199">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12199,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12199,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12199,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12199,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12199,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12199,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 下肢骨折 -->
        <div class="Q" v-if="isShow(collect.F12200,1)">
          <div class="first" id="F12200"></div>
          <div class="q-title">下肢骨折
            <div class="q-title4">
              第四项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" class="checkbox" shape="square" v-model="collect.F12200"
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12200 == 1">
          <div class="first" id="F12201">
          </div>
          <div class="q-title5" :style="isNotNull(collect.F12201)?'':'color:red'">局部症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12201">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12201,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12201,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12201,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12201,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12201,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12202"></div>
          <div class="q-title6" :style="isNotNull(collect.F12202)?'':'color:red'">1.&nbsp;疼痛
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12202">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12202,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12202,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12202,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12203"></div>
          <div class="q-title6" :style="isNotNull(collect.F12203)?'':'color:red'">2.&nbsp;肿胀
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12203">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12203,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12203,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12203,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12204"></div>
          <div class="q-title6" :style="isNotNull(collect.F12204)?'':'color:red'">3.&nbsp;伤口感染
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12204">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12204,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12204,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12204,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12205"></div>
          <div class="q-title5" :style="isNotNull(collect.F12205)?'':'color:red'">全身症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12205">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12205,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12205,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12205,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12205,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12205,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12206"></div>
          <div class="q-title6" :style="isNotNull(collect.F12206)?'':'color:red'">1.&nbsp;休克
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12206">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12206,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12206,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12207"></div>
          <div class="q-title6" :style="isNotNull(collect.F12207)?'':'color:red'">2.&nbsp;发热
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12207">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12207,1)">1.&nbsp;体温正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12207,2)">2.&nbsp;37.5°C~39°C</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12207,3)">3.&nbsp;&gt;39°C</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12208"></div>
          <div class="q-title5" :style="isNotNull(collect.F12208)?'':'color:red'">体征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12208">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12208,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12208,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12208,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12208,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12208,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12209"></div>
          <div class="q-title6" :style="isNotNull(collect.F12209)?'':'color:red'">1.&nbsp;畸形
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12209">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12209,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12209,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12209,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12210"></div>
          <div class="q-title6" :style="isNotNull(collect.F12210)?'':'color:red'">2.&nbsp;活动异常
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12210">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12210,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12210,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12210,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12211"></div>
          <div class="q-title6" :style="isNotNull(collect.F12211)?'':'color:red'"> 3.&nbsp;骨擦音或骨擦感
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12211">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12211,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12211,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12211,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12212"></div>
          <div class="q-title6" :style="isNotNull(collect.F12212)?'':'color:red'">4.&nbsp;运动障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12212">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12212,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12212,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12212,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12213"></div>
          <div class="q-title6" :style="isNotNull(collect.F12213)?'':'color:red'"> 5.&nbsp;感觉功能障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12213">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12213,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12213,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12213,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12214"></div>
          <div class="q-title6" :style="isNotNull(collect.F12214)?'':'color:red'">6.&nbsp;肌萎缩
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12214">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12214,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12214,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12214,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12215"></div>
          <div class="q-title5" :style="isNotNull(collect.F12215)?'':'color:red'">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12215">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12215,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12215,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12215,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12215,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12215,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12216"></div>
          <div class="q-title6" :style="isNotNull(collect.F12216)?'':'color:red'">1.&nbsp;X线检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12216">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12216,1)">1.&nbsp;骨折线消失，对位对线良好</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12216,2)">2.&nbsp;骨折线模糊，对位对线尚可</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12216,3)">3.&nbsp;骨折线模糊，对位对线欠佳</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12216,4)">4.&nbsp;骨折线明显，对位对线欠佳</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12216,5)">5.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12217"></div>
          <div class="q-title5" :style="isNotNull(collect.F12217)?'':'color:red'">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12217">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12217,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12217,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12217,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12217,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12217,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12218"></div>
          <div class="q-title6" :style="isNotNull(collect.F12218)?'':'color:red'">1.&nbsp;重要血管损伤
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12218">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12218,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12218,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12219"></div>
          <div class="q-title6" :style="isNotNull(collect.F12219)?'':'color:red'">2.&nbsp;周围神经损伤
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12219">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12219,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12219,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12220"></div>
          <div class="q-title6" :style="isNotNull(collect.F12220)?'':'color:red'">3.&nbsp;骨不连
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12220">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12220,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12220,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12221"></div>
          <div class="q-title6" :style="isNotNull(collect.F12221)?'':'color:red'">4.&nbsp;下肢静脉血栓形成
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12221">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12221,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12221,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12222"></div>
          <div class="q-title5" :style="isNotNull(collect.F12222)?'':'color:red'">疾病的治疗强度需求
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12222">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12222,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12222,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12222,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12222,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12222,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12223"></div>
          <div class="q-title5" :style="isNotNull(collect.F12223)?'':'color:red'">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12223">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12223,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12223,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12223,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12223,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12223,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12223,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 帕金森病 -->
        <div class="Q" v-if="isShow(collect.F12224,1)">
          <div class="first" id="F12224"></div>
          <div class="q-title">帕金森病
            <div class="q-title4">
              第五项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12224,1)"
                          v-model="collect.F12224"
            >是
            </van-checkbox>
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12224 == 1">
          <!-- 局部症状 -->
          <div class="first" id="F12225">
          </div>
          <div class="q-title5" :style="isNotNull(collect.F12225)?'':'color:red'">局部症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12225">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12225,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12225,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12225,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12225,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12225,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12226"></div>
          <div class="q-title6" :style="isNotNull(collect.F12226)?'':'color:red'">1.&nbsp;静止性震颤
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12226">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12226,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12226,2)">2.&nbsp;单个肢体</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12226,3)">3.&nbsp;双个肢体</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12226,4)">4.&nbsp;多个肢体</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12227"></div>
          <div class="q-title6" :style="isNotNull(collect.F12227)?'':'color:red'">2.&nbsp;肌强直
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12227">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12227,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12227,2)">2.&nbsp;单个肢体</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12227,3)">3.&nbsp;双个肢体</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12227,4)">4.&nbsp;多个肢体</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12227,5)">5.&nbsp;全身僵硬</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12228"></div>
          <div class="q-title6" :style="isNotNull(collect.F12228)?'':'color:red'">3.&nbsp;运动迟缓
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12228">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12228,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12228,2)">2.&nbsp;随意动作减少</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12228,3)">3.&nbsp;主动运动缓慢</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12228,4)">4.&nbsp;表情呆板</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12228,5)">5.&nbsp;步态异常</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12229"></div>
          <div class="q-title6" :style="isNotNull(collect.F12229)?'':'color:red'">4.&nbsp;姿势步态异常
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12229">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12229,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12229,2)">2.&nbsp;行走拖步或小步态</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12229,3)">3.&nbsp;行走困难</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12229,4)">4.&nbsp;能坐但起立困难</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12229,5)">5.&nbsp;卧床不起</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12230"></div>
          <div class="q-title6" :style="isNotNull(collect.F12230)?'':'color:red'">5.&nbsp;吞咽困难
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12230">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12230,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12230,2)">2.&nbsp;极少呛咳</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12230,3)">3.&nbsp;偶尔呛咳</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12230,4)">4.&nbsp;反复呛咳</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12230,5)">5.&nbsp;不能进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12231"></div>
          <div class="q-title6" :style="isNotNull(collect.F12231)?'':'color:red'">6.&nbsp;言语障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12231">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12231,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12231,2)">2.&nbsp;语速减慢</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12231,3)">3.&nbsp;发音过弱</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12231,4)">4.&nbsp;讲话时流涎</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12232"></div>
          <div class="q-title5" :style="isNotNull(collect.F12232)?'':'color:red'">全身症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12232">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12232,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12232,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12232,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12232,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12232,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12233"></div>
          <div class="q-title6" :style="isNotNull(collect.F12233)?'':'color:red'">1.&nbsp;食欲
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12233">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12233,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12233,2)">2.&nbsp;减退</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12233,3)">3.&nbsp;不思饮食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12234"></div>
          <div class="q-title6" :style="isNotNull(collect.F12234)?'':'color:red'">2.&nbsp;体重
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12234">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12234,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12234,2)">2.&nbsp;消瘦</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12234,3)">3.&nbsp;恶病质</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12235"></div>
          <div class="q-title6" :style="isNotNull(collect.F12235)?'':'color:red'">3.&nbsp;外周肌力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12235">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12235,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12235,2)">2.&nbsp;萎缩</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12235,3)">3.&nbsp;功能障碍</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12236"></div>
          <div class="q-title5" :style="isNotNull(collect.F12236)?'':'color:red'">体征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12236">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12236,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12236,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12236,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12236,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12236,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12237"></div>
          <div class="q-title6" :style="isNotNull(collect.F12237)?'':'color:red'">1.&nbsp;静止性震颤
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12237">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12237,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12237,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12238"></div>
          <div class="q-title6" :style="isNotNull(collect.F12238)?'':'color:red'">2.&nbsp;肌力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12238">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12238,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12238,2)">2.&nbsp;Ⅳ级</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12238,3)">3.&nbsp;Ⅲ级</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12238,4)">4.&nbsp;Ⅱ级</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12238,5)">5.&nbsp;Ⅰ级</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12238,6)">6.&nbsp;0级</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12239"></div>
          <div class="q-title6" :style="isNotNull(collect.F12239)?'':'color:red'"> 3.&nbsp;肌力张
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12239">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12239,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12239,2)">2.&nbsp;增高</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12239,3)">3.&nbsp;严重增高</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12240"></div>
          <div class="q-title6" :style="isNotNull(collect.F12240)?'':'color:red'">4.&nbsp;直立性低血压
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12240">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12240,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12240,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12241"></div>
          <div class="q-title6" :style="isNotNull(collect.F12241)?'':'color:red'"> 5.&nbsp;Myerson征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12241">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12241,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12241,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12242"></div>
          <div class="q-title5" :style="isNotNull(collect.F12242)?'':'color:red'">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12242">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12242,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12242,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12242,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12242,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12242,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12243"></div>
          <div class="q-title6" :style="isNotNull(collect.F12243)?'':'color:red'">1.&nbsp;Hoehn-Yahr分级
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12243">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12243,1)">1.&nbsp;Ⅰ期</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12243,2)">2.&nbsp;Ⅱ~Ⅲ期</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12243,3)">3.&nbsp;Ⅳ期</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12243,4)">4.&nbsp;Ⅴ期</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12243,5)">5.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12244"></div>
          <div class="q-title5" :style="isNotNull(collect.F12244)?'':'color:red'">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12244">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12244,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12244,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12244,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12244,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12244,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12245"></div>
          <div class="q-title6" :style="isNotNull(collect.F12245)?'':'color:red'">1.&nbsp;肺部炎症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12245">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12245,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12245,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12246"></div>
          <div class="q-title6" :style="isNotNull(collect.F12246)?'':'color:red'">2.&nbsp;骨折
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12246">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12246,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12246,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12247"></div>
          <div class="q-title6" :style="isNotNull(collect.F12247)?'':'color:red'">3.&nbsp;认知障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12247">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12247,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12247,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12248"></div>
          <div class="q-title5" :style="isNotNull(collect.F12248)?'':'color:red'">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12248">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12248,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12248,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12248,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12248,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12248,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12249"></div>
          <div class="q-title5" :style="isNotNull(collect.F12249)?'':'color:red'">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12249">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12249,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12249,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12249,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12249,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12249,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12249,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 脑出血 -->
        <div class="Q" v-if="isShow(collect.F12250,1)">
          <div class="first" id="F12250"></div>
          <div class="q-title">脑出血
            <div class="q-title4">
              第六项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" class="checkbox" shape="square" v-model="collect.F12250"
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12250 == 1">
          <!-- 局部症状 -->
          <div class="first" id="F12251">
          </div>
          <div class="q-title5" :style="isNotNull(collect.F12251)?'':'color:red'">局部症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12251">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12251,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12251,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12251,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12251,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12251,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12252"></div>
          <div class="q-title6" :style="isNotNull(collect.F12252)?'':'color:red'">1.&nbsp;意识水平
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12252">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12252,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12252,2)">2.&nbsp;嗜睡</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12252,3)">3.&nbsp;意识模糊</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12252,4)">4.&nbsp;浅昏迷</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12252,5)">5.&nbsp;深昏迷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12253"></div>
          <div class="q-title6" :style="isNotNull(collect.F12253)?'':'color:red'">2.&nbsp;语言功能障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12253">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12253,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12253,2)">2.&nbsp;基本可以交谈</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12253,3)">3.&nbsp;交谈费力</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12253,4)">4.&nbsp;只能发单音节</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12253,5)">5.&nbsp;失语</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12254"></div>
          <div class="q-title6" :style="isNotNull(collect.F12254)?'':'color:red'">3.&nbsp;吞咽功能障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12254">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12254,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12254,2)">2.&nbsp;极少呛咳</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12254,3)">3.&nbsp;偶尔呛咳</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12254,4)">4.&nbsp;反复呛咳</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12254,5)">5.&nbsp;不能进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12255"></div>
          <div class="q-title6" :style="isNotNull(collect.F12255)?'':'color:red'">4.&nbsp;视觉功能
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12255">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12255,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12255,2)">2.&nbsp;短暂偏盲</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12255,3)">3.&nbsp;持续性偏盲或视野缺损</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12256"></div>
          <div class="q-title6" :style="isNotNull(collect.F12256)?'':'color:red'">5.&nbsp;感觉功能
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12256">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12256,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12256,2)">2.&nbsp;浅感觉障碍</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12256,3)">3.&nbsp;深感觉障碍</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12257"></div>
          <div class="q-title6" :style="isNotNull(collect.F12257)?'':'color:red'">6.&nbsp;伴随症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12257">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12257,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12257,2)">2.&nbsp;轻度头痛或呕吐</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12257,3)">3.&nbsp;剧烈头痛或喷射性呕吐</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12258"></div>
          <div class="q-title5" :style="isNotNull(collect.F12258)?'':'color:red'">全身症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12258">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12258,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12258,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12258,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12258,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12258,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12259"></div>
          <div class="q-title6" :style="isNotNull(collect.F12259)?'':'color:red'">1.&nbsp;食欲
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12259">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12259,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12259,2)">2.&nbsp;减退</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12259,3)">3.&nbsp;不思饮食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12260"></div>
          <div class="q-title6" :style="isNotNull(collect.F12260)?'':'color:red'">2.&nbsp;体重
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12260">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12260,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12260,2)">2.&nbsp;消瘦</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12260,3)">3.&nbsp;恶病质</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12261"></div>
          <div class="q-title6" :style="isNotNull(collect.F12261)?'':'color:red'">3.&nbsp;外周肌力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12261">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12261,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12261,2)">2.&nbsp;萎缩</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12261,3)">3.&nbsp;功能障碍</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12262"></div>
          <div class="q-title5" :style="isNotNull(collect.F12262)?'':'color:red'">体征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12262">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12262,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12262,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12262,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12262,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12262,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12263"></div>
          <div class="q-title6" :style="isNotNull(collect.F12263)?'':'color:red'">1.&nbsp;肌力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12263">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12263,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12263,2)">2.&nbsp;Ⅳ级</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12263,3)">3.&nbsp;Ⅲ级</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12263,4)">4.&nbsp;Ⅱ级</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12263,5)">5.&nbsp;Ⅰ级</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12263,6)">6.&nbsp;0级</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12264"></div>
          <div class="q-title6" :style="isNotNull(collect.F12264)?'':'color:red'">2.&nbsp;肌张力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12264">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12264,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12264,2)">2.&nbsp;轻度增高或降低</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12264,3)">3.&nbsp;明显增高或降低</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12265"></div>
          <div class="q-title6" :style="isNotNull(collect.F12265)?'':'color:red'"> 3.&nbsp;瞳孔
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12265">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12265,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12265,2)">2.&nbsp;缩小，对光反射迟钝</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12265,3)">3.&nbsp;针尖样瞳孔</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12266"></div>
          <div class="q-title6" :style="isNotNull(collect.F12266)?'':'color:red'">4.&nbsp;脑膜刺激征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12266">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12266,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12266,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <!-- 辅助检查 -->
          <div class="first" id="F12267"></div>
          <div class="q-title5" :style="isNotNull(collect.F12267)?'':'color:red'">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12267">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12267,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12267,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12267,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12267,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12267,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12268"></div>
          <div class="q-title6" :style="isNotNull(collect.F12268)?'':'color:red'">1.&nbsp;影像学（脑CT或MRI)
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12268">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12268,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12268,2)">2.&nbsp;密度增高</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12268,3)">3.&nbsp;多发性病灶</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12268,4)">4.&nbsp;未检测、其他</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12269"></div>
          <div class="q-title5" :style="isNotNull(collect.F12269)?'':'color:red'">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12269">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12269,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12269,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12269,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12269,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12269,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12270"></div>
          <div class="q-title6" :style="isNotNull(collect.F12270)?'':'color:red'">1.&nbsp;血管性痴呆
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12270">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12270,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12270,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12271"></div>
          <div class="q-title6" :style="isNotNull(collect.F12271)?'':'color:red'">2.&nbsp;癫痫性发作
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12271">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12271,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12271,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12272"></div>
          <div class="q-title6" :style="isNotNull(collect.F12272)?'':'color:red'">3.&nbsp;肺部感染
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12272">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12272,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12272,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12273"></div>
          <div class="q-title6" :style="isNotNull(collect.F12273)?'':'color:red'">4.&nbsp;尿路感染
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12273">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12273,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12273,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12274"></div>
          <div class="q-title6" :style="isNotNull(collect.F12274)?'':'color:red'">5.&nbsp;压疮
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12274">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12274,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12274,2)">2.&nbsp;Ⅰ~Ⅱ期</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12274,3)">3.&nbsp;Ⅲ~Ⅳ期</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12275"></div>
          <div class="q-title6" :style="isNotNull(collect.F12275)?'':'color:red'">6.&nbsp;应激性溃疡
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12275">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12275,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12275,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12276"></div>
          <div class="q-title5" :style="isNotNull(collect.F12276)?'':'color:red'">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12276">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12276,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12276,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12276,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12276,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12276,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12277"></div>
          <div class="q-title5" :style="isNotNull(collect.F12277)?'':'color:red'">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12277">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12277,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12277,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12277,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12277,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12277,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12277,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>

        <!-- 高血压 -->
        <div class="Q" v-if="isShow(collect.F12278,1)">
          <div class="first" id="F12278"></div>
          <div class="q-title">高血压
            <div class="q-title4">
              第七项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" class="checkbox" shape="square" v-model="collect.F12278"
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12278 == 1">
          <!-- 局部症状 -->
          <div class="first" id="F12279">
          </div>
          <div class="q-title5" :style="isNotNull(collect.F12279)?'':'color:red'">局部症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12279">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12279,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12279,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12279,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12279,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12279,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12280"></div>
          <div class="q-title6" :style="isNotNull(collect.F12280)?'':'color:red'">1.&nbsp;头晕
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12280">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12280,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12280,2)">2.&nbsp;短暂</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12280,3)">3.&nbsp;持续</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12281"></div>
          <div class="q-title6" :style="isNotNull(collect.F12281)?'':'color:red'">2.&nbsp;头痛
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12281">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12281,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12281,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12281,3)">3.&nbsp;剧烈</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12282"></div>
          <div class="q-title6" :style="isNotNull(collect.F12282)?'':'color:red'">3.&nbsp;心悸
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12282">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12282,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12282,2)">2.&nbsp;短暂</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12282,3)">3.&nbsp;持续</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12283"></div>
          <div class="q-title6" :style="isNotNull(collect.F12283)?'':'color:red'">4.&nbsp;气促
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12283">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12283,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12283,2)">2.&nbsp;劳力时</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12283,3)">3.&nbsp;日常活动时</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12283,4)">4.&nbsp;静息时</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12284"></div>
          <div class="q-title5" :style="isNotNull(collect.F12284)?'':'color:red'">全身症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12284">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12284,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12284,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12284,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12284,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12284,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12285"></div>
          <div class="q-title6" :style="isNotNull(collect.F12285)?'':'color:red'">1.&nbsp;恶心呕吐
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12285">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12285,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12285,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12285,3)">3.&nbsp;剧烈</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12286"></div>
          <div class="q-title6" :style="isNotNull(collect.F12286)?'':'color:red'">2.&nbsp;乏力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12286">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12286,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12286,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12286,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12287"></div>
          <div class="q-title5" :style="isNotNull(collect.F12287)?'':'color:red'">体征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12287">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12287,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12287,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12287,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12287,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12287,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12288"></div>
          <div class="q-title6" :style="isNotNull(collect.F12288)?'':'color:red'">1.&nbsp;神志
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12288">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12288,1)">1.&nbsp;清醒</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12288,2)">2.&nbsp;嗜睡</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12288,3)">3.&nbsp;神志不清</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12289"></div>
          <div class="q-title6" :style="isNotNull(collect.F12289)?'':'color:red'">2.&nbsp;血压
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12289">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12289,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12289,2)">2.&nbsp;141~159mmHg/91~99mmHg
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12289,3)">3.&nbsp;160~180mmHg/100~110mmHg
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12289,4)">4.&nbsp;&gt;180mmHg/110mmHg
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12290"></div>
          <div class="q-title6" :style="isNotNull(collect.F12290)?'':'color:red'"> 3.&nbsp;心脏增大
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12290">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12290,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12290,2)">2.&nbsp;向左扩大</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12291"></div>
          <div class="q-title6" :style="isNotNull(collect.F12291)?'':'color:red'"> 4.&nbsp;两肺啰音
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12291">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12291,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12291,2)">2.&nbsp;少许湿啰音</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12291,2)">3.&nbsp;满布湿啰音</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12292"></div>
          <div class="q-title5" :style="isNotNull(collect.F12292)?'':'color:red'">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12292">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12292,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12292,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12292,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12292,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12292,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12293"></div>
          <div class="q-title6" :style="isNotNull(collect.F12293)?'':'color:red'">1.&nbsp;血肌酐
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12293">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12293,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12293,2)">2.&nbsp;133~220 μ mol/L</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12293,3)">3.&nbsp;≥221 μ mol/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12294"></div>
          <div class="q-title6" :style="isNotNull(collect.F12294)?'':'color:red'">2.&nbsp;心电图
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12294">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12294,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12294,2)">2.&nbsp;左心室增大伴劳损</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12295"></div>
          <div class="q-title6" :style="isNotNull(collect.F12295)?'':'color:red'">3.&nbsp;X线胸片
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12295">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12295,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12295,2)">2.&nbsp;左心室增大</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12295,3)">3.&nbsp;肺水肿表现</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12295,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12296"></div>
          <div class="q-title6" :style="isNotNull(collect.F12296)?'':'color:red'">4.&nbsp;超声心动图
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12296">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12296,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12296,2)">2.&nbsp;左心室肥厚</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12296,3)">3.&nbsp;伴LVEF&lt;50%</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12296,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12297"></div>
          <div class="q-title6" :style="isNotNull(collect.F12297)?'':'color:red'">5.&nbsp;眼底检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12297">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12297,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12297,2)">2.&nbsp;视网膜动脉Ⅱ级改变</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12297,3)">3.&nbsp;≥Ⅲ级改变</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12297,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12298"></div>
          <div class="q-title5" :style="isNotNull(collect.F12298)?'':'color:red'">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12298">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12298,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12298,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12298,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12298,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12298,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12299"></div>
          <div class="q-title6" :style="isNotNull(collect.F12299)?'':'color:red'">1.&nbsp;心力衰竭或高血压脑病或脑血管病
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12299">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12299,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12299,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12300"></div>
          <div class="q-title6" :style="isNotNull(collect.F12300)?'':'color:red'">2.&nbsp;肾功能不全
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12300">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12300,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12300,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12301"></div>
          <div class="q-title5" :style="isNotNull(collect.F12301)?'':'color:red'">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12301">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12301,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12301,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12301,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12301,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12301,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12302"></div>
          <div class="q-title5" :style="isNotNull(collect.F12302)?'':'color:red'">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12302">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12302,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12302,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12302,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12302,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12302,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12302,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 晚期肿瘤 -->
        <div class="Q" v-if="isShow(collect.F12303,1)">
          <div class="first" id="F12303"></div>
          <div class="q-title">晚期肿瘤
            <div class="q-title4">
              第八项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12303,1)"
                          v-model="collect.F12303"
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12303 == 1">
          <div class="first" id="F12304">
          </div>
          <div class="q-title5" :style="isNotNull(collect.F12304)?'':'color:red'">症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12304">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12304,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12304,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12304,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12304,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12304,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12305"></div>
          <div class="q-title6" :style="isNotNull(collect.F12305)?'':'color:red'">1.&nbsp;意识
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12305">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12305,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12305,2)">2.&nbsp;嗜睡或淡漠</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12305,3)">3.&nbsp;谵妄</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12305,4)">4.&nbsp;昏迷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12306"></div>
          <div class="q-title6" :style="isNotNull(collect.F12306)?'':'color:red'">2.&nbsp;呼吸困难
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12306">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12306,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12306,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12306,3)">3.&nbsp;中度</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12306,4)">4.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12307"></div>
          <div class="q-title6" :style="isNotNull(collect.F12307)?'':'color:red'">3.&nbsp;进食困难
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12307">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12307,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12307,2)">2.&nbsp;流质</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12307,3)">3.&nbsp;鼻饲</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12307,4)">4.&nbsp;无法进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12308"></div>
          <div class="q-title6" :style="isNotNull(collect.F12308)?'':'color:red'">4.&nbsp;排便困难
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12308">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12308,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12308,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12309"></div>
          <div class="q-title6" :style="isNotNull(collect.F12309)?'':'color:red'">5.&nbsp;排尿困难
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12309">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12309,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12309,2)">2.&nbsp;有</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12309,3)">3.&nbsp;导尿</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12310"></div>
          <div class="q-title6" :style="isNotNull(collect.F12310)?'':'color:red'">6.&nbsp;出血
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12310">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12310,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12310,2)">2.&nbsp;皮肤黏膜出血</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12310,3)">3.&nbsp;咯血、呕血、便血、颅内出血等</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12311"></div>
          <div class="q-title6" :style="isNotNull(collect.F12311)?'':'color:red'">7.&nbsp;疼痛
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12311">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12311,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12311,2)">2.&nbsp;疼痛评分3~6分</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12311,3)">3.&nbsp;疼痛评分≥7分</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12312"></div>
          <div class="q-title6" :style="isNotNull(collect.F12312)?'':'color:red'">8.&nbsp;营养不良
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12312">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12312,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12312,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12312,3)">3.&nbsp;中度</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12312,4)">4.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12313"></div>
          <div class="q-title6" :style="isNotNull(collect.F12313)?'':'color:red'">9.&nbsp;乏力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12313">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12313,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12313,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12313,3)">3.&nbsp;中度</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12313,4)">4.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12314"></div>
          <div class="q-title5" :style="isNotNull(collect.F12314)?'':'color:red'">体征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12314">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12314,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12314,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12314,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12314,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12314,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12315"></div>
          <div class="q-title6" :style="isNotNull(collect.F12315)?'':'color:red'">1.&nbsp;发热
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12315">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12315,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12315,2)">2.&nbsp;37.5°C~38°C</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12315,3)">3.&nbsp;38.1°C~39°C</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12315,4)">4.&nbsp;&lt;39°C</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12316"></div>
          <div class="q-title6" :style="isNotNull(collect.F12316)?'':'color:red'">2.&nbsp;黄疸
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12316">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12316,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12316,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12316,3)">3.&nbsp;明显</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12316,4)">4.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12317"></div>
          <div class="q-title6" :style="isNotNull(collect.F12317)?'':'color:red'">3.&nbsp;血压
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12317">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12317,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12317,2)">2.&nbsp;80~89mmHg/50~59mmHg
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12317,3)">3.&nbsp;&lt; 80mmHg/50mmHg
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12318"></div>
          <div class="q-title6" :style="isNotNull(collect.F12318)?'':'color:red'">4.&nbsp;心率
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12318">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12318,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12318,2)">2.&nbsp;100~120次/分</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12318,3)">3.&nbsp;&gt; 120次/分</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12319"></div>
          <div class="q-title6" :style="isNotNull(collect.F12319)?'':'color:red'">5.&nbsp;胸水或腹水
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12319">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12319,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12319,2)">2.&nbsp;少量</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12319,3)">3.&nbsp;大量</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12320"></div>
          <div class="q-title6" :style="isNotNull(collect.F12320)?'':'color:red'">6.&nbsp;水肿
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12320">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12320,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12320,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12320,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12321"></div>
          <div class="q-title6" :style="isNotNull(collect.F12321)?'':'color:red'">7.&nbsp;浅表淋巴结
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12321">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12321,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12321,2)">2.&nbsp;有肿大淋巴结</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12322"></div>
          <div class="q-title5" :style="isNotNull(collect.F12322)?'':'color:red'">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12322">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12322,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12322,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12322,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12322,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12322,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12323"></div>
          <div class="q-title6" :style="isNotNull(collect.F12323)?'':'color:red'">1.&nbsp;血常规
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12323">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12323,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12323,2)">2.&nbsp;Hb91~110g/L 或
                WBC2.1~4.0×109/L 或
                PLT51~100×109/L
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12323,3)">3.&nbsp;Hb60~90g/L 或
                WBC0.5~2.0×109/L 或
                PLT20~50×109/L
              </van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12323,4)">4.&nbsp;Hb &lt; 60g/L 或 WBC &lt;
                0.5×109/L 或 PLT&lt; 20×109/L
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12324"></div>
          <div class="q-title6" :style="isNotNull(collect.F12324)?'':'color:red'">2.&nbsp;血肌酐
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12324">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12324,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12324,2)">2.&nbsp;133~221 μ mol/L</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12324,3)">3.&nbsp;222~442 μ mol/L</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12324,4)">4.&nbsp;&gt;442 μ mol/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12325"></div>
          <div class="q-title6" :style="isNotNull(collect.F12325)?'':'color:red'"> 3.&nbsp;白蛋白
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12325">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12325,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12325,2)">2.&nbsp;28~35g/L</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12325,3)">3.&nbsp;&lt; 28g/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12326"></div>
          <div class="q-title6" :style="isNotNull(collect.F12326)?'':'color:red'"> 4.&nbsp;ALT
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12326">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12326,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12326,2)">2.&nbsp;41~100U/L</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12326,3)">3.&nbsp;&gt; 100U/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12327"></div>
          <div class="q-title6" :style="isNotNull(collect.F12327)?'':'color:red'"> 5.&nbsp;胆红素
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12327">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12327,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12327,2)">2.&nbsp;17~35mmol/L</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12327,3)">3.&nbsp;36~51mmol/L</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12327,4)">4.&nbsp;&gt; 51mmol/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12328"></div>
          <div class="q-title6" :style="isNotNull(collect.F12328)?'':'color:red'"> 6.&nbsp;血气
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12328">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12328,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12328,2)">2.&nbsp;PaO2降低，PaCO2正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12328,3)">3.&nbsp;PaO2降低，PaCO2升高</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12328,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12329"></div>
          <div class="q-title6" :style="isNotNull(collect.F12329)?'':'color:red'"> 7.&nbsp;电解质
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12329">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12329,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12329,2)">2.&nbsp;轻度紊乱</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12329,3)">3.&nbsp;中度紊乱</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12329,4)">4.&nbsp;重度紊乱</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12329,5)">5.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12330"></div>
          <div class="q-title6" :style="isNotNull(collect.F12330)?'':'color:red'"> 8.&nbsp;超声
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12330">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12330,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12330,2)">2.&nbsp;少量胸水或腹水</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12330,3)">3.&nbsp;大量胸水或腹水</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12330,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12331"></div>
          <div class="q-title5" :style="isNotNull(collect.F12331)?'':'color:red'">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12331">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12331,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12331,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12331,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12331,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12331,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12332"></div>
          <div class="q-title6" :style="isNotNull(collect.F12332)?'':'color:red'">1.&nbsp;心力衰竭
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12332">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12332,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12332,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12333"></div>
          <div class="q-title6" :style="isNotNull(collect.F12333)?'':'color:red'">2.&nbsp;继发感染
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12333">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12333,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12333,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12334"></div>
          <div class="q-title5" :style="isNotNull(collect.F12334)?'':'color:red'">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12334">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12334,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12334,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12334,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12334,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12334,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12335"></div>
          <div class="q-title5" :style="isNotNull(collect.F12335)?'':'color:red'">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12335">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12335,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12335,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12335,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12335,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12335,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12335,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 脑梗死 -->
        <div class="Q" v-if="isShow(collect.F12336,1)">
          <div class="first" id="F12336"></div>
          <div class="q-title">脑梗死
            <div class="q-title4">
              第九项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" class="checkbox" shape="square" v-model="collect.F12336"
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12336 == 1">
          <div class="first" id="F12337">
          </div>
          <div class="q-title5" :style="isNotNull(collect.F12337)?'':'color:red'">局部症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12337">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12337,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12337,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12337,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12337,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12337,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12338"></div>
          <div class="q-title6" :style="isNotNull(collect.F12338)?'':'color:red'">1.&nbsp;意识水平
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12338">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12338,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12338,2)">2.&nbsp;嗜睡</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12338,3)">3.&nbsp;意识模糊</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12338,4)">4.&nbsp;浅昏迷</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12338,5)">5.&nbsp;深昏迷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12339"></div>
          <div class="q-title6" :style="isNotNull(collect.F12339)?'':'color:red'">2.&nbsp;语言功能障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12339">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12339,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12339,2)">2.&nbsp;基本可以交谈</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12339,3)">3.&nbsp;交谈费力</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12339,4)">4.&nbsp;只能发单音节</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12339,5)">5.&nbsp;失语</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12340"></div>
          <div class="q-title6" :style="isNotNull(collect.F12340)?'':'color:red'">3.&nbsp;吞咽功能障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12340">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12340,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12340,2)">2.&nbsp;极少呛咳</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12340,3)">3.&nbsp;偶尔呛咳</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12340,4)">4.&nbsp;反复呛咳</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12340,5)">5.&nbsp;不能进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12341"></div>
          <div class="q-title6" :style="isNotNull(collect.F12341)?'':'color:red'">4.&nbsp;视觉功能
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12341">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12341,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12341,2)">2.&nbsp;短暂偏盲</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12341,3)">3.&nbsp;持续性偏盲或视野缺损</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12342"></div>
          <div class="q-title6" :style="isNotNull(collect.F12342)?'':'color:red'">5.&nbsp;感觉功能
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12342">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12342,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12342,2)">2.&nbsp;浅感觉障碍</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12342,3)">3.&nbsp;深感觉障碍</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12343"></div>
          <div class="q-title6" :style="isNotNull(collect.F12343)?'':'color:red'">6.&nbsp;伴随症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12343">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12343,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12343,2)">2.&nbsp;轻度头痛或呕吐</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12343,3)">3.&nbsp;剧烈头痛或喷射性呕吐</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12344"></div>
          <div class="q-title5" :style="isNotNull(collect.F12344)?'':'color:red'">全身症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12344">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12344,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12344,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12344,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12344,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12344,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12345"></div>
          <div class="q-title6" :style="isNotNull(collect.F12345)?'':'color:red'">1.&nbsp;食欲
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12345">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12345,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12345,2)">2.&nbsp;减退</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12345,3)">3.&nbsp;不思饮食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12346"></div>
          <div class="q-title6" :style="isNotNull(collect.F12346)?'':'color:red'">2.&nbsp;体重
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12346">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12346,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12346,2)">2.&nbsp;消瘦</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12346,3)">3.&nbsp;恶病质</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12347"></div>
          <div class="q-title6" :style="isNotNull(collect.F12347)?'':'color:red'">3.&nbsp;外周肌力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12347">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12347,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12347,2)">2.&nbsp;萎缩</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12347,3)">3.&nbsp;功能障碍</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12348"></div>
          <div class="q-title5" :style="isNotNull(collect.F12348)?'':'color:red'">体征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12348">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12348,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12348,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12348,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12348,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12348,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12349"></div>
          <div class="q-title6" :style="isNotNull(collect.F12349)?'':'color:red'">1.&nbsp;肌力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12349">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12349,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12349,2)">2.&nbsp;Ⅳ级</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12349,3)">3.&nbsp;Ⅲ级</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12349,4)">4.&nbsp;Ⅱ级</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12349,5)">5.&nbsp;Ⅰ级</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12349,6)">6.&nbsp;0级</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12350"></div>
          <div class="q-title6" :style="isNotNull(collect.F12350)?'':'color:red'">2.&nbsp;肌张力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12350">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12350,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12350,2)">2.&nbsp;轻度增高或降低</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12350,3)">3.&nbsp;明显增高或降低</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12351"></div>
          <div class="q-title6" :style="isNotNull(collect.F12351)?'':'color:red'"> 3.&nbsp;瞳孔
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12351">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12351,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12351,2)">2.&nbsp;缩小，对光反射迟钝</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12351,3)">3.&nbsp;针尖样瞳孔</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12352"></div>
          <div class="q-title6" :style="isNotNull(collect.F12352)?'':'color:red'"> 4.&nbsp;脑膜刺激征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12352">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12352,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12352,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12353"></div>
          <div class="q-title5" :style="isNotNull(collect.F12353)?'':'color:red'">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12353">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12353,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12353,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12353,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12353,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12353,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12354"></div>
          <div class="q-title6" :style="isNotNull(collect.F12354)?'':'color:red'">1.&nbsp;影像学（脑CT或MRI)
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12354">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12354,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12354,2)">2.&nbsp;密度增高</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12354,3)">3.&nbsp;多发性病灶</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12354,4)">4.&nbsp;未检测、其他</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12355"></div>
          <div class="q-title5" :style="isNotNull(collect.F12355)?'':'color:red'">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12355">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12355,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12355,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12355,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12355,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12355,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12356"></div>
          <div class="q-title6" :style="isNotNull(collect.F12356)?'':'color:red'">1.&nbsp;血管性痴呆
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12356">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12356,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12356,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12357"></div>
          <div class="q-title6" :style="isNotNull(collect.F12357)?'':'color:red'">2.&nbsp;继发性癫痫
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12357">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12357,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12357,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12358"></div>
          <div class="q-title6" :style="isNotNull(collect.F12358)?'':'color:red'">3.&nbsp;肺部感染
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12358">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12358,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12358,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12359"></div>
          <div class="q-title6" :style="isNotNull(collect.F12359)?'':'color:red'">4.&nbsp;尿路感染
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12359">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12359,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12359,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12360"></div>
          <div class="q-title6" :style="isNotNull(collect.F12360)?'':'color:red'">5.&nbsp;压疮
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12360">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12360,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12360,2)">2.&nbsp;Ⅰ~Ⅱ期</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12360,3)">3.&nbsp;Ⅲ~Ⅳ期</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12361"></div>
          <div class="q-title5" :style="isNotNull(collect.F12361)?'':'color:red'">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12361">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12361,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12361,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12361,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12361,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12361,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12362"></div>
          <div class="q-title5" :style="isNotNull(collect.F12362)?'':'color:red'">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12362">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12362,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12362,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12362,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12362,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12362,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12362,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 冠状动脉粥样硬化性心脏病 -->
        <div class="Q" v-if="isShow(collect.F12363,1)">
          <div class="first" id="F12363"></div>
          <div class="q-title">冠状动脉粥样硬化性心脏病
            <div class="q-title4">
              第十项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" class="checkbox" shape="square" v-model="collect.F12363"
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12363 == 1">
          <div class="first" id="F12364">
          </div>
          <div class="q-title5" :style="isNotNull(collect.F12364)?'':'color:red'">局部症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12364">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12364,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12364,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12364,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12364,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12364,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12365"></div>
          <div class="q-title6" :style="isNotNull(collect.F12365)?'':'color:red'">1.&nbsp;心绞痛
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12365">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12365,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12365,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12366"></div>
          <div class="q-title6" :style="isNotNull(collect.F12366)?'':'color:red'">2.&nbsp;呼吸困难
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12366">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12366,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12366,2)">2.&nbsp;劳力性呼吸困难</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12366,3)">3.&nbsp;夜间阵发性呼吸困难</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12366,4)">4.&nbsp;端坐呼吸</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12367"></div>
          <div class="q-title6" :style="isNotNull(collect.F12367)?'':'color:red'">3.&nbsp;咳痰
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12367">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12367,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12367,2)">2.&nbsp;泡沫样痰</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12367,3)">3.&nbsp;粉红色泡沫样痰</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12368"></div>
          <div class="q-title6" :style="isNotNull(collect.F12368)?'':'color:red'">4.&nbsp;心悸
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12368">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12368,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12368,2)">2.&nbsp;短暂</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12368,3)">3.&nbsp;持续</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12369"></div>
          <div class="q-title5" :style="isNotNull(collect.F12369)?'':'color:red'">全身症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12369">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12369,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12369,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12369,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12369,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12369,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12370"></div>
          <div class="q-title6" :style="isNotNull(collect.F12370)?'':'color:red'">1.&nbsp;乏力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12370">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12370,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12370,2)">2.&nbsp;轻度</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12370,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12371"></div>
          <div class="q-title5" :style="isNotNull(collect.F12371)?'':'color:red'">体征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12371">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12371,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12371,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12371,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12371,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12371,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12372"></div>
          <div class="q-title6" :style="isNotNull(collect.F12372)?'':'color:red'">1.&nbsp;紫绀
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12372">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12372,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12372,2)">2.&nbsp;口唇紫绀</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12372,3)">3.&nbsp;全身性紫绀</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12373"></div>
          <div class="q-title6" :style="isNotNull(collect.F12373)?'':'color:red'">2.&nbsp;颈静脉充盈
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12373">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12373,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12373,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12374"></div>
          <div class="q-title6" :style="isNotNull(collect.F12374)?'':'color:red'"> 3.&nbsp;心脏增大
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12374">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12374,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12374,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12375"></div>
          <div class="q-title6" :style="isNotNull(collect.F12375)?'':'color:red'"> 4.&nbsp;心率增快或减慢
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12375">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12375,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12375,2)">2.&nbsp;&gt; 100次/分 或 &lt; 60次/分
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12376"></div>
          <div class="q-title6" :style="isNotNull(collect.F12376)?'':'color:red'"> 5.&nbsp;心律不齐
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12376">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12376,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12376,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12377"></div>
          <div class="q-title6" :style="isNotNull(collect.F12377)?'':'color:red'"> 6.&nbsp;奔马律
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12377">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12377,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12377,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12378"></div>
          <div class="q-title6" :style="isNotNull(collect.F12378)?'':'color:red'"> 7.&nbsp;肺部啰音
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12378">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12378,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12378,2)">2.&nbsp;少许湿啰音</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12378,3)">3.&nbsp;满布湿啰音或伴哮鸣音</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12379"></div>
          <div class="q-title6" :style="isNotNull(collect.F12379)?'':'color:red'">8.&nbsp;胸水或伴腹水
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12379">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12379,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12379,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12380"></div>
          <div class="q-title6" :style="isNotNull(collect.F12380)?'':'color:red'"> 9.&nbsp;肝肿大
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12380">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12380,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12380,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12381"></div>
          <div class="q-title6" :style="isNotNull(collect.F12381)?'':'color:red'"> 10.&nbsp;水肿
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12381">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12381,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12381,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12382"></div>
          <div class="q-title5" :style="isNotNull(collect.F12382)?'':'color:red'">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12382">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12382,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12382,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12382,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12382,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12382,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12383"></div>
          <div class="q-title6" :style="isNotNull(collect.F12383)?'':'color:red'">1.&nbsp;BNP
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12383">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12383,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12383,2)">2.&nbsp;900~1800ng/L</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12383,3)">3.&nbsp;&gt; 1800ng/L</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12383,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12384"></div>
          <div class="q-title6" :style="isNotNull(collect.F12384)?'':'color:red'">2.&nbsp;心电图
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12384">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12384,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12384,2)">2.&nbsp;左心室肥大伴劳损</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12384,3)">3.&nbsp;伴心律失常</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12385"></div>
          <div class="q-title6" :style="isNotNull(collect.F12385)?'':'color:red'">3.&nbsp;X线胸片
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12385">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12385,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12385,2)">2.&nbsp;心脏增大</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12385,3)">3.&nbsp;肺水肿</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12385,4)">4.&nbsp;伴肺泡水肿表现</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12385,5)">5.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12386"></div>
          <div class="q-title6" :style="isNotNull(collect.F12386)?'':'color:red'">4.&nbsp;超声心动图
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12386">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12386,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12386,2)">2.&nbsp;左心室增大伴室壁节段性运动异常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12386,3)">3.&nbsp;伴LVEF41%~50%</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12386,4)">4.&nbsp;伴LVEF &lt; 40%</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12386,5)">5.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12387"></div>
          <div class="q-title6" :style="isNotNull(collect.F12387)?'':'color:red'">5.&nbsp;肘静脉压测定
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12387">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12387,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12387,2)">2.&nbsp;&gt; 14cmH2O</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12387,3)">3.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12388"></div>
          <div class="q-title5" :style="isNotNull(collect.F12388)?'':'color:red'">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12388">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12388,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12388,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12388,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12388,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12388,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12389"></div>
          <div class="q-title6" :style="isNotNull(collect.F12389)?'':'color:red'">1.&nbsp;严重心率失常
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12389">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12389,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12389,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12390"></div>
          <div class="q-title5" :style="isNotNull(collect.F12390)?'':'color:red'">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12390">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12390,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12390,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12390,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12390,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12390,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12391"></div>
          <div class="q-title5" :style="isNotNull(collect.F12391)?'':'color:red'">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12391">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12391,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12391,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12391,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12391,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12391,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12391,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 认知障碍 -->
        <div class="Q" v-if="isShow(collect.F12392,1)">
          <div class="first" id="F12392"></div>
          <div class="q-title">认知障碍
            <div class="q-title4">
              第十一项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12392,1)"
                          v-model="collect.F12392"
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12392 == 1">
          <div class="first" id="F12393">
          </div>
          <div class="q-title5" :style="isNotNull(collect.F12393)?'':'color:red'">全身症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12393">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12393,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12393,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12393,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12393,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12393,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12394"></div>
          <div class="q-title6" :style="isNotNull(collect.F12394)?'':'color:red'">1.&nbsp;记忆障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12394">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12394,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12394,2)">2.&nbsp;近事记忆减退</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12394,3)">3.&nbsp;远事记忆减退</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12394,4)">4.&nbsp;远近事记忆障碍加重，学习新知识和社会接触能力减退，原掌握的知识、技巧明显衰退</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12395"></div>
          <div class="q-title6" :style="isNotNull(collect.F12395)?'':'color:red'">2.&nbsp;计算障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12395">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12395,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12395,2)">2.&nbsp;逻辑思维、综合分析能力减退、计算力下降
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12395,3)">3.&nbsp;丧失了部分简单日常生活计算能力</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12395,4)">4.&nbsp;上述症状加重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12396"></div>
          <div class="q-title6" :style="isNotNull(collect.F12396)?'':'color:red'">3.&nbsp;定向障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12396">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12396,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12396,2)">2.&nbsp;空间障碍</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12396,3)">3.&nbsp;视空间障碍</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12396,4)">4.&nbsp;丧失目标行动能力</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12397"></div>
          <div class="q-title6" :style="isNotNull(collect.F12397)?'':'color:red'">4.&nbsp;情感障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12397">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12397,1)">1.&nbsp;易疲乏、焦虑、消极</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12397,2)">2.&nbsp;空间障碍</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12397,3)">3.&nbsp;悲观、忧伤、厌世</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12397,4)">4.&nbsp;木僵状态</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12398"></div>
          <div class="q-title6" :style="isNotNull(collect.F12398)?'':'color:red'">5.&nbsp;人格障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12398">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12398,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12398,2)">2.&nbsp;不爱清洁，不修边幅，自私多疑</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12398,3)">3.&nbsp;哭泣，抑郁，暴躁，易怒</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12398,4)">4.&nbsp;偏执幻觉妄想，谩骂或打人，暴力或其他攻击行为
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12399"></div>
          <div class="q-title5" :style="isNotNull(collect.F12399)?'':'color:red'">伴随症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12399">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12399,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12399,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12399,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12399,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12399,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12400"></div>
          <div class="q-title6" :style="isNotNull(collect.F12400)?'':'color:red'">1.&nbsp;语言功能障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12400">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12400,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12400,2)">2.&nbsp;基本可以交谈</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12400,3)">3.&nbsp;交谈费力</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12400,4)">4.&nbsp;只能发单音节</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12400,5)">5.&nbsp;失语</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12401"></div>
          <div class="q-title6" :style="isNotNull(collect.F12401)?'':'color:red'">2.&nbsp;吞咽功能障碍
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12401">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12401,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12401,2)">2.&nbsp;极少呛咳</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12401,3)">3.&nbsp;偶尔呛咳</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12401,4)">4.&nbsp;反复呛咳</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12401,5)">5.&nbsp;不能进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12402"></div>
          <div class="q-title6" :style="isNotNull(collect.F12402)?'':'color:red'">3.&nbsp;食欲
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12402">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12402,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12402,2)">2.&nbsp;减退</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12402,3)">3.&nbsp;拒绝进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12403"></div>
          <div class="q-title6" :style="isNotNull(collect.F12403)?'':'color:red'">4.&nbsp;体重
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12403">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12403,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12403,2)">2.&nbsp;消瘦</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12403,3)">3.&nbsp;恶病质</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12404"></div>
          <div class="q-title6" :style="isNotNull(collect.F12404)?'':'color:red'">5.&nbsp;外周肌力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12404">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12404,1)">正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12404,2)">萎缩</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12404,3)">功能障碍</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12405"></div>
          <div class="q-title5" :style="isNotNull(collect.F12405)?'':'color:red'">体征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12405">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12405,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12405,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12405,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12405,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12405,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12406"></div>
          <div class="q-title6" :style="isNotNull(collect.F12406)?'':'color:red'">1.&nbsp;肌力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12406">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12406,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12406,2)">2.&nbsp;Ⅳ级</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12406,3)">3.&nbsp;Ⅲ级</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12406,4)">4.&nbsp;Ⅱ级</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12406,5)">5.&nbsp;Ⅰ级</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12406,6)">6.&nbsp;0级</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12407"></div>
          <div class="q-title6" :style="isNotNull(collect.F12407)?'':'color:red'">2.&nbsp;肌张力
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12407">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12407,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12407,2)">2.&nbsp;轻度增高或降低</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12407,3)">3.&nbsp;明显增高或降低</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12408"></div>
          <div class="q-title5" :style="isNotNull(collect.F12408)?'':'color:red'">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12408">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12408,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12408,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12408,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12408,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12408,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12409"></div>
          <div class="q-title6" :style="isNotNull(collect.F12409)?'':'color:red'">1.&nbsp;脑脊液检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12409">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12409,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12409,2)">2.&nbsp;Aβ42水平降低、总Tau蛋白和磷酸化Tau蛋白增高
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12409,3)">3.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12410"></div>
          <div class="q-title6" :style="isNotNull(collect.F12410)?'':'color:red'">2.&nbsp;脑电图
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12410">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12410,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12410,2)">2.&nbsp;α波减少，θ波增高，平均频率降低
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12410,3)">3.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12411"></div>
          <div class="q-title6" :style="isNotNull(collect.F12411)?'':'color:red'">3.&nbsp;影像学（脑CT、MRI）
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12411">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12411,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12411,2)">2.&nbsp;脑皮质萎缩明显（尤其海马及内侧颞叶）、脑室扩大
              </van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12411,3)">3.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12412"></div>
          <div class="q-title5" :style="isNotNull(collect.F12412)?'':'color:red'">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12412">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12412,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12412,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12412,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12412,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12412,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12413"></div>
          <div class="q-title6" :style="isNotNull(collect.F12413)?'':'color:red'">1.&nbsp;营养不良
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12413">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12413,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12413,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12414"></div>
          <div class="q-title6" :style="isNotNull(collect.F12414)?'':'color:red'">2.&nbsp;肺部感染
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12414">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12414,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12414,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12415"></div>
          <div class="q-title6" :style="isNotNull(collect.F12415)?'':'color:red'">3.&nbsp;尿路感染
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12415">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12415,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12415,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12416"></div>
          <div class="q-title6" :style="isNotNull(collect.F12416)?'':'color:red'">4.&nbsp;压疮
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12416">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12416,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12416,2)">2.&nbsp;Ⅰ~Ⅱ期</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12416,3)">3.&nbsp;Ⅲ~Ⅳ期</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12417"></div>
          <div class="q-title6" :style="isNotNull(collect.F12417)?'':'color:red'">5.&nbsp;脑血管意外
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12417">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12417,1)">1.&nbsp;无</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12417,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12418"></div>
          <div class="q-title5" :style="isNotNull(collect.F12418)?'':'color:red'">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12418">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12418,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12418,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12418,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12418,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12418,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12419"></div>
          <div class="q-title5" :style="isNotNull(collect.F12419)?'':'color:red'">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12419">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12419,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12419,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12419,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12419,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12419,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12419,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 其它疾病 -->
        <div class="Q" v-if="isShow(collect.F12420,1)">
          <div class="first" id="F12420"></div>
          <div class="q-title">其它疾病
            <div class="q-title4">
              第十二项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12420,1)"
                          v-model="collect.F12420"
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12420 == 1">
          <div class="first" id="F12457">
          </div>
          <div class="q-title5" :style="isNotNull(collect.F12457)?'':'color:red'">疾病名称
          </div>
          <div class="q-content">
            <input class="ipt" placeholder="..." type="text" v-model="collect.F12457">
          </div>
          <div class="first" id="F12421"></div>
          <div class="q-title5" :style="isNotNull(collect.F12421)?'':'color:red'">症状
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12421">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12421,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12421,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12421,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12421,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12421,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12422"></div>
          <div class="q-title5" :style="isNotNull(collect.F12422)?'':'color:red'">体征
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12422">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12422,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12422,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12422,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12422,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12422,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12423"></div>
          <div class="q-title5" :style="isNotNull(collect.F12423)?'':'color:red'">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12423">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12423,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12423,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12423,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12423,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12423,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12424"></div>
          <div class="q-title5" :style="isNotNull(collect.F12424)?'':'color:red'">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12424">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12424,1)">1.&nbsp;正常</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12424,2)">2.&nbsp;基本正常</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12424,3)">3.&nbsp;较重</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12424,4)">4.&nbsp;严重</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12424,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12425"></div>
          <div class="q-title5" :style="isNotNull(collect.F12425)?'':'color:red'">疾病的治疗强度需求
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12425">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12425,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12425,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12425,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12425,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12425,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12426"></div>
          <div class="q-title5" :style="isNotNull(collect.F12426)?'':'color:red'">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group direction="horizontal" icon-size='16px' v-model="collect.F12426">
              <van-radio :name="1" class="checkbox1" v-if="isShow(collect.F12426,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio :name="2" class="checkbox1" v-if="isShow(collect.F12426,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio :name="3" class="checkbox1" v-if="isShow(collect.F12426,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio :name="4" class="checkbox1" v-if="isShow(collect.F12426,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio :name="5" class="checkbox1" v-if="isShow(collect.F12426,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio :name="6" class="checkbox1" v-if="isShow(collect.F12426,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="Q">
          <div class="q-title7" style="font-size:12px" v-if="value.status===0
                                                             &&!isShow(collect.F12108,1)
                                                             &&!isShow(collect.F12142,1)
                                                             &&!isShow(collect.F12168,1)
                                                             &&!isShow(collect.F12200,1)
                                                             &&!isShow(collect.F12224,1)
                                                             &&!isShow(collect.F12250,1)
                                                             &&!isShow(collect.F12278,1)
                                                             &&!isShow(collect.F12303,1)
                                                             &&!isShow(collect.F12336,1)
                                                             &&!isShow(collect.F12363,1)
                                                             &&!isShow(collect.F12392,1)
                                                             &&!isShow(collect.F12420,1)"
          >该老人无特定疾病
          </div>
          <div class="q-title7" style="font-size:12px">调查员编号：<span style="color:blue">{{pgyObj.pgy2}}</span>&nbsp;&nbsp;&nbsp;调查日期：<span></span>
          </div>
        </div>
        <div class="bottom" v-if="isWc == false">
          <div @click=" add5(null)" class="bottom1">保存</div>
          <div @click="xiayixiang5" class="bottom2">提交</div>
        </div>
        <div class="bottom" v-if="isWc">
          <div @click="updateStatus(1)" class="bottom1">同意</div>
          <div @click="updateStatus(0)" class="bottom2">拒绝</div>
        </div>
      </van-tab>

      <van-tab style="padding: 15px 0;height:100vh" title="签字">
        <div class="bottom" v-if="isWc">
          <div @click="updateStatus(1)" class="bottom1">同意</div>
          <div @click="updateStatus(0)" class="bottom2">拒绝</div>
        </div>
        <div class="goodBox">
          <div>一.诚信声明</div>
          <div>
            在以下评估调查中，我（们）所表现和回答的关于评估对象的身体状况等，均为我近期日常生活中的一贯情形，无任何虚假的情况。作为协助评估人员，我所提供的关于评估对象的日常生活种的身体状况等信息，均为我平时直接观察和了解到的。我（们）所提供的材料，也均真实有效。
            如有任何虚假的情况，我（们）愿意放弃相应的资格，并承担由此带来的相应后果。
            特此声明
          </div>
          <div>1.确认.现场两位评估员同时上门；</div>
          <div>2.确认评估员现场已佩戴评估员工作证；</div>
          <div>3.确认此次评估时长满足20分钟；</div>
          <div>4.确认评估员现场已查看被评估者病例；</div>
          <div>5.确认评估员未向被评估者作任何服务承诺，未收受评估对象的任何礼物、馈赠。</div>
          <div>6.对此次现场评估工作是否满意。</div>
          <div>
            <van-radio-group direction="horizontal" icon-size='16px' v-model="value.manyi">
              <van-radio :name="1" class="checkbox1">满意</van-radio>
              <van-radio :name="0" class="checkbox1">不满意</van-radio>
            </van-radio-group>
          </div>
          <div :style="(value.manyi == 1 && !isNotNull(value.manyiYuanyin)) ?'':'color:red'">
            原因：<input class="ipt1" placeholder="..." type="text" v-model="value.manyiYuanyin">
          </div>
          <div class="upimg">
            <div class="imgli">
              <van-uploader :after-read="uploadFfileQz" :max-count="1" accept="image/*" multiple v-model="qianzi"/>
            </div>
          </div>
        </div>
        <van-button
          @click="verifyB"
          block
          class="btn"
          round
          size="small"
          type="primary"
          v-if="isWc == false"
        >提交
        </van-button
        >
        <!-- <div class="goodBox">
          <div>
            1、本调查表用于本市长期护理险试点过程中对老年医疗护理服务申请者进行需求评估。
          </div>
          <div>2、本调查所获得的信息仅作为本市长期护理险需求评级的基础。</div>
          <div>
            3、为了反映被调查者的真实情况，各个调查项目结果的填写均基于被调查者相应动作的确认、被调查者自身的回答、家属和/或身边照护人员所反映的被调查者日常生活的一贯情况以及专门调查员的现场观察。
          </div>
          <div>
            4、调查回答项中，“□”表示备选项可多选，请打“√”表示选择；“①”等表示选项为单选，只能选择一项，请打“√”表示选择；用下划线留出空栏时，请填写合适的内容。
          </div>
          <div>5、关于发生频数的选项，请选择最接近实际的选择项。</div>
          <div>
            6、关于金额、次数等项目，如无精确数字，请估计一个合适的值填写。
          </div>
          <div>7、本调查全过程约需要三十分钟。</div>
          <div>8、本调查表所涉及的隐私受到法律保护。</div>
          <div>9、本调查表由认定的评估机构指派专门调查员完成。</div>
        </div> -->
      </van-tab>

    </van-tabs>
    <van-dialog :cancel="videoCancel" :confirm="videoConfirm" show-cancel-button v-model="showVideo">
      <video :src="videoSrc" controls="controls" id="video" style="height:30vh;width:100%" v-if="showVideo"/>
    </van-dialog>
  </div>
</template>

<script>
import {
  uploadImage, updaeImage, getformsParticularsById, addFormsVals, updateFormsInfo, submitA, submitB
} from '../request/http'
import { Dialog, Toast } from 'vant'

export default {
  data () {
    return {
      isshoww: false,
      showVideo: false,
      videoSrc: null,
      isWc: false,
      bofang: false,
      themeVars: {
        fieldLabelColor: 'red'
      },
      image1: [],
      image2: [],
      image3: [],
      image4: [],
      image5: [],
      image6: [],
      text: null,
      qianzi: [],
      fileList: [],
      active: 0,
      radio: ' ',
      pgyObj: {
        pgy1: null,
        pgy2: null
      },
      value: {},
      collect: {
        F12456: '',
        F12451: [],
        F12452: [],
        F11663: '',
        F11664: '',
        F11665: '',
        F11666: '',
        F11667: '',
        F11668: '',
        F11673: '',
        F11674: '',
        F11675: '',
        F11678: '',
        F11687: '',
        F11688: '',
        F11689: [],
        F11690: '',
        F12431: [],
        F12433: '',
        F12434: '',
        F11691: '',
        F11692: '',
        F12436: '',
        F12437: '',
        F11693: '',
        F11694: '',
        F11695: '',
        F11696: '',
        F11697: [],
        F11700: '',
        F12438: '',
        F12439: [],
        F12440: '',
        F12441: [],
        F12442: '',
        F11701: '',
        F11703: '',
        F11704: '',
        F11705: '',
        F11706: '',
        F11707: '',
        F12443: [],
        F11710: '',
        F11712: '',
        F12445: '',
        F12447: '',
        F12448: '',
        F12017: '',
        F12018: '',
        F12019: '',
        F12020: '',
        F12021: '',
        F12022: '',
        F12023: '',
        F12024: '',
        F12025: '',
        F12026: '',
        F12027: '',
        F12028: '',
        F12029: '',
        F12030: '',
        F12032: '',
        F12031: '',
        F12034: '',
        F12455: '',
        F12033: '',
        F12035: '',
        F12037: '',
        F12036: '',
        F12038: [],
        F12039: [],
        F12040: '',
        F12041: '',
        F12042: '',
        F12043: '',
        F12044: '',
        F12045: '',
        F12046: '',
        F12047: '',
        F12048: '',
        F12049: '',
        F12050: '',
        F12051: '',
        F12052: '',
        F12053: '',
        F12054: '',
        F12055: '',
        F12056: '',
        F12058: '',
        F12057: '',
        F12059: '',
        F12060: '',
        F12061: '',
        F12062: '',
        F12063: '',
        F12064: '',
        F12065: '',
        F12066: '',
        F12067: '',
        F12068: '',
        F12069: '',
        F12070: '',
        F12071: '',
        F12072: '',
        F12073: '',
        F12074: '',
        F12075: '',
        F12076: '',
        F12077: '',
        F12078: '',
        F12079: '',
        F12080: '',
        F12081: '',
        F12083: '',
        F12084: '',
        F12085: '',
        F12086: '',
        F12087: '',
        F12088: '',
        F12089: '',
        F12090: '',
        F12091: '',
        F12092: '',
        F12093: '',
        F12094: '',
        F12095: '',
        F12096: '',
        F12097: '',
        F12098: '',
        F12099: '',
        F12100: [],
        F12101: [],
        F12103: '',
        F12108: '',
        F12109: '',
        F12110: '',
        F12111: '',
        F12112: '',
        F12113: '',
        F12114: '',
        F12115: '',
        F12116: '',
        F12117: '',
        F12118: '',
        F12119: '',
        F12120: '',
        F12121: '',
        F12122: '',
        F12123: '',
        F12124: '',
        F12125: '',
        F12126: '',
        F12127: '',
        F12128: '',
        F12129: '',
        F12130: '',
        F12131: '',
        F12132: '',
        F12135: '',
        F12136: '',
        F12137: '',
        F12138: '',
        F12139: '',
        F12140: '',
        F12141: '',
        F12142: '',
        F12143: '',
        F12144: '',
        F12145: '',
        F12146: '',
        F12147: '',
        F12148: '',
        F12149: '',
        F12150: '',
        F12151: '',
        F12152: '',
        F12153: '',
        F12154: '',
        F12155: '',
        F12156: '',
        F12157: '',
        F12158: '',
        F12159: '',
        F12160: '',
        F12161: '',
        F12162: '',
        F12163: '',
        F12164: '',
        F12165: '',
        F12166: '',
        F12167: '',
        F12168: '',
        F12169: '',
        F12170: '',
        F12171: '',
        F12172: '',
        F12173: '',
        F12174: '',
        F12175: '',
        F12176: '',
        F12177: '',
        F12178: '',
        F12179: '',
        F12180: '',
        F12181: '',
        F12182: '',
        F12183: '',
        F12184: '',
        F12185: '',
        F12186: '',
        F12187: '',
        F12188: '',
        F12189: '',
        F12190: '',
        F12191: '',
        F12192: '',
        F12193: '',
        F12194: '',
        F12195: '',
        F12196: '',
        F12197: '',
        F12198: '',
        F12199: '',
        F12200: '',
        F12201: '',
        F12202: '',
        F12203: '',
        F12204: '',
        F12205: '',
        F12206: '',
        F12207: '',
        F12208: '',
        F12209: '',
        F12210: '',
        F12211: '',
        F12212: '',
        F12213: '',
        F12214: '',
        F12215: '',
        F12216: '',
        F12217: '',
        F12218: '',
        F12219: '',
        F12220: '',
        F12221: '',
        F12222: '',
        F12223: '',
        F12224: '',
        F12225: '',
        F12226: '',
        F12227: '',
        F12228: '',
        F12229: '',
        F12230: '',
        F12231: '',
        F12232: '',
        F12233: '',
        F12234: '',
        F12235: '',
        F12236: '',
        F12237: '',
        F12238: '',
        F12239: '',
        F12240: '',
        F12241: [],
        F12242: '',
        F12243: '',
        F12244: '',
        F12245: '',
        F12246: '',
        F12247: '',
        F12248: '',
        F12249: '',
        F12250: '',
        F12251: '',
        F12252: '',
        F12253: '',
        F12254: '',
        F12255: '',
        F12256: '',
        F12257: '',
        F12258: '',
        F12259: '',
        F12260: '',
        F12261: '',
        F12262: '',
        F12263: '',
        F12264: '',
        F12265: '',
        F12266: '',
        F12267: '',
        F12268: '',
        F12269: '',
        F12270: '',
        F12271: '',
        F12272: '',
        F12273: '',
        F12274: '',
        F12275: '',
        F12276: '',
        F12277: '',
        F12278: '',
        F12279: '',
        F12280: '',
        F12281: '',
        F12282: '',
        F12283: '',
        F12284: '',
        F12285: '',
        F12286: '',
        F12287: '',
        F12288: '',
        F12289: '',
        F12290: '',
        F12291: '',
        F12292: '',
        F12293: '',
        F12294: '',
        F12295: '',
        F12296: '',
        F12297: '',
        F12298: '',
        F12299: '',
        F12300: '',
        F12301: '',
        F12302: '',
        F12303: '',
        F12304: '',
        F12305: '',
        F12306: '',
        F12307: '',
        F12308: '',
        F12309: '',
        F12310: '',
        F12311: '',
        F12312: '',
        F12313: '',
        F12314: '',
        F12315: '',
        F12316: '',
        F12317: '',
        F12318: '',
        F12319: '',
        F12320: '',
        F12321: '',
        F12322: '',
        F12323: '',
        F12324: '',
        F12325: '',
        F12326: '',
        F12327: '',
        F12328: '',
        F12329: '',
        F12330: '',
        F12331: '',
        F12332: '',
        F12333: '',
        F12334: '',
        F12335: '',
        F12336: '',
        F12337: '',
        F12338: '',
        F12339: '',
        F12340: '',
        F12341: '',
        F12342: '',
        F12343: '',
        F12344: '',
        F12345: '',
        F12346: '',
        F12347: '',
        F12348: '',
        F12349: '',
        F12350: '',
        F12351: '',
        F12352: '',
        F12353: '',
        F12354: '',
        F12355: '',
        F12356: '',
        F12357: '',
        F12358: '',
        F12359: '',
        F12360: '',
        F12361: '',
        F12362: '',
        F12363: '',
        F12364: '',
        F12365: '',
        F12366: '',
        F12367: '',
        F12368: '',
        F12369: '',
        F12370: '',
        F12371: '',
        F12372: '',
        F12373: '',
        F12374: '',
        F12375: '',
        F12376: '',
        F12377: '',
        F12378: '',
        F12379: '',
        F12380: '',
        F12381: '',
        F12382: '',
        F12383: '',
        F12384: '',
        F12385: '',
        F12386: '',
        F12387: '',
        F12388: '',
        F12389: '',
        F12390: '',
        F12391: '',
        F12392: '',
        F12393: '',
        F12394: '',
        F12395: '',
        F12396: '',
        F12397: '',
        F12398: '',
        F12399: '',
        F12400: '',
        F12401: '',
        F12402: '',
        F12403: '',
        F12404: '',
        F12405: '',
        F12406: '',
        F12407: '',
        F12408: '',
        F12409: '',
        F12410: '',
        F12411: '',
        F12412: '',
        F12413: '',
        F12414: '',
        F12415: '',
        F12416: '',
        F12417: '',
        F12418: '',
        F12419: '',
        F12420: '',
        F12457: '',
        F12421: '',
        F12422: '',
        F12423: '',
        F12424: '',
        F12425: '',
        F12426: ''
      },
      errField: ''
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    /**
     * 查看视频
     * */
    videoConfirm () {
      this.showVideo = false
      document.getElementById('video').pause()
    },
    /**
     * 取消查看视频
     * */
    videoCancel () {
      this.showVideo = false
      document.getElementById('video').pause()
    },
    /**
     * 查看视频信息
     * */
    isBof (url) {
      if (url === undefined || url === null || url === '') {
        Dialog.alert({
          title: '提示',
          message: '请先上传图片'
        }).then(() => {
          // on close
        })
        return
      }
      this.videoSrc = url
      this.showVideo = true
    },
    /**
     * 获取信息
     * */
    getInfo () {
      // 跳转tab1
      this.active = 0
      getformsParticularsById({ id: this.$route.query.id }).catch((res) => {
        Toast.fail(res)
      }).then(({ data }) => {
        // 字段数据
        if (data.collect !== undefined && data.collect != null) {
          Object.assign(this.collect, data.collect)
        }
        // 评估员1
        if (data.pgy1 != null && data.pgy1 !== undefined) {
          this.pgyObj.pgy1 = data.pgy1
        }
        // 评估员2
        if (data.pgy2 != null && data.pgy2 !== undefined) {
          this.pgyObj.pgy2 = data.pgy2
        }
        if (data.senForms != null) {
          // 待评估 待确认
          if (data.senForms.status == 0 || data.senForms.status == 5) {
            // 待确认 isWc === false 可以评估
            if (data.senForms.status == 5) {
              const userid = localStorage.getItem('userId')
              const lasteditUid = data.senForms.lasteditUid
              if (lasteditUid !== '' && lasteditUid != null) {
                // 是否本人评估
                if (lasteditUid !== userid) {
                  this.isWc = true
                } else {
                  this.$router.push({ path: '/assessment', query: { id: data.senForms.id } })
                }
              } else {
                this.$router.push({ path: '/assessment', query: { id: data.senForms.id } })
              }
            } else {
              // 待评估
              this.isWc = false
            }
          } else {
            // 其他状态，仅查看
            // this.$router.push({ path: '/assessment', query: { id: data.senForms.id } })
          }
          // form信息
          this.value = data.senForms

          // 图片地址
          const senForms = data.senForms
          if (senForms.image1 !== null) { this.image1 = [{ url: senForms.image1 }] }
          if (senForms.image2 !== null) { this.image2 = [{ url: senForms.image2 }] }
          if (senForms.image3 !== null) { this.image3 = [{ url: senForms.image3 }] }
          if (senForms.image4 !== null) { this.image4 = [{ url: senForms.image4 }] }
          if (senForms.image5 !== null) { this.image5 = [{ url: senForms.image5 }] }
          if (senForms.image6 !== null) { this.image6 = [{ url: senForms.image6 }] }
          if (senForms.qianzi !== null) { this.qianzi = [{ url: senForms.qianzi }] }

          /**
           * 基本信息
           * */
          // 姓名
          if (data.senForms.xm != null && (this.collect.F11663 == null || this.collect.F11663 === '')) {
            this.collect.F11663 = data.senForms.xm
          }
          // 性别
          this.collect.F11664 = data.senForms.xb
          // 地址
          const address = data.senForms.sxdz
          if (data.senForms.sxdz != null) {
            if (this.collect.F11678 == null || this.collect.F11678 === '') {
              this.collect.F11678 = '上海市'
            }
            if (this.collect.F11679 == null || this.collect.F11679 === '') {
              const value = data.senForms.sxdz.substring(0, address.indexOf('区'))
              if (value != null && value !== '') {
                this.collect.F11679 = value
              } else {
                this.collect.F11679 = '-'
              }
            }
            if (this.collect.F11680 == null || this.collect.F11680 === '') {
              let value = data.senForms.sxdz.substring(address.indexOf('区') + 1, address.indexOf('街道'))
              if (value == null || value === '') {
                value = data.senForms.sxdz.substring(address.indexOf('区') + 1, address.indexOf('镇'))
              }
              if (value != null && value !== '') {
                this.collect.F11680 = value
              } else {
                this.collect.F11680 = '-'
              }
            }
            if (this.collect.F11681 == null || this.collect.F11681 === '') {
              let value = data.senForms.sxdz.substring(address.indexOf('街道') + 1, address.indexOf('村'))
              if (value == null || value === '') {
                value = data.senForms.sxdz.substring(address.indexOf('镇') + 1, address.indexOf('村'))
              }
              if (value != null && value !== '') {
                this.collect.F11681 = value
              } else {
                this.collect.F11681 = '-'
              }
            }
            if (this.collect.F11682 == null || this.collect.F11682 === '') {
              this.collect.F11682 = '-'
            }
            if (this.collect.F11683 == null || this.collect.F11683 === '') {
              this.collect.F11683 = '-'
            }
            if (this.collect.F11684 == null || this.collect.F11684 === '') {
              this.collect.F11684 = '-'
            }
            if (this.collect.F11685 == null || this.collect.F11685 === '') {
              const value = data.senForms.sxdz.substring(address.indexOf('弄') + 1, address.indexOf('号'))
              if (value != null && value !== '') {
                this.collect.F11685 = value
              } else {
                this.collect.F11685 = '-'
              }
            }
            if (this.collect.F11686 == null || this.collect.F11686 === '') {
              const value = data.senForms.sxdz.substring(address.indexOf('号') + 1, address.indexOf('室'))
              if (value != null && value !== '') {
                this.collect.F11686 = value
              } else {
                this.collect.F11686 = '-'
              }
            }
          } else {
            this.collect.F11678 = '-'
            this.collect.F11679 = '-'
            this.collect.F11680 = '-'
            this.collect.F11681 = '-'
            this.collect.F11682 = '-'
            this.collect.F11683 = '-'
            this.collect.F11684 = '-'
            this.collect.F11685 = '-'
            this.collect.F11686 = '-'
          }

          // 出生年月日
          if (data.senForms.csny != null) {
            this.collect.F11665 = parseInt(new Date().getFullYear() - parseInt(data.senForms.csny / 10000))
          }
        }
      })
    },
    /**
     * 开始评估 =》跳转居家信息
     * */
    updateActive (value) {
      this.active = Number(value)
    },
    updateImages () {
      const job = { id: this.$route.query.id }
      if (this.image1 != null && this.image1.length > 0) {
        job.image1 = this.image1[0].url
      }
      if (this.image2 != null && this.image2.length > 0) {
        job.image2 = this.image2[0].url
      }
      if (this.image3 != null && this.image3.length > 0) {
        job.image3 = this.image3[0].url
      }
      if (this.image4 != null && this.image4.length > 0) {
        job.image4 = this.image4[0].url
      }
      if (this.image5 != null && this.image5.length > 0) {
        job.image5 = this.image5[0].url
      }
      if (this.image6 != null && this.image6.length > 0) {
        job.image6 = this.image6[0].url
      }

      try {
        this.checkRequire(job.image1, 'image1', '请上传评估对象照片', 0)
        this.checkRequire(job.image2, 'image2', '请上传评估对象签名', 0)
        this.checkRequire(job.image3, 'image3', '请上传病例、药物等记录', 0)
        this.checkRequire(job.image4, 'image4', '请上传病例、药物等记录', 0)
        this.checkRequire(job.image5, 'image5', '请上传上半身活动情况', 0)
        this.checkRequire(job.image6, 'image6', '请上传下半身活动情况（坐立行）', 0)
      } catch (e) {
        return false
      }

      updaeImage(job).then(({ data }) => {
        this.active++
      })
    },
    /**
     * 是否展示
     * */
    isShow (value, index) {
      if (!this.isWc) {
        // 待确认
        if (this.value.status == 5) return true
        // 待评估
        if (this.value.status == 0) return true

        if (value == null || index == null) return false

        return (value.constructor === Array) ? (value.indexOf(index) !== -1) : (value === index)
      } else {
        if (this.value.status == 5) {
          return true
        }
        return true
      }
    },
    /**
     * 是否包含数据
     * */
    include (value, index) {
      if (value == null || index == null) {
        return false
      }
      return (value.constructor === Array) ? (value.indexOf(index) !== -1) : (value === index)
    },
    uploadFfile (file) {
      this.isshoww = true
      const forms = new FormData()
      forms.append('id', this.value.id)
      forms.append('type', 'image1')
      forms.append('file', file.file) // 获取上传图片信息
      uploadImage(forms).then(({ data }) => {
        this.image1 = [{ url: data }]
        Dialog({ message: '图片上传成功' })
        this.isshoww = false
      })
    },
    uploadFfile2 (file) {
      this.isshoww = true
      const forms = new FormData()
      forms.append('file', file.file) // 获取上传图片信息
      forms.append('id', this.value.id)
      forms.append('type', 'image2')
      uploadImage(forms).then(({ data }) => {
        this.image2 = [{ url: data }]
        Dialog({ message: '图片上传成功' })
        this.isshoww = false
      })
    },
    uploadFfile3 (file) {
      this.isshoww = true
      const forms = new FormData()
      forms.append('id', this.value.id)
      forms.append('type', 'image3')
      forms.append('file', file.file) // 获取上传图片信息
      uploadImage(forms).then(({ data }) => {
        this.image3 = [{ url: data }]
        Dialog({ message: '图片上传成功' })
        this.isshoww = false
      })
    },
    uploadFfile4 (file) {
      this.isshoww = true
      const forms = new FormData()
      forms.append('id', this.value.id)
      forms.append('type', 'image4')
      forms.append('file', file.file) // 获取上传图片信息
      uploadImage(forms).then(({ data }) => {
        this.image4 = [{ url: data }]
        this.isshoww = false
      })
    },
    uploadFfile5 (file) {
      this.isshoww = true
      const forms = new FormData()
      forms.append('id', this.value.id)
      forms.append('type', 'image5')
      forms.append('file', file.file) // 获取上传图片信息
      uploadImage(forms).then(({ data }) => {
        this.image5 = [{ url: data }]
        Dialog({ message: '视频上传成功' })
        this.isshoww = false
      })
    },
    uploadFfile6 (file) {
      this.isshoww = true
      const forms = new FormData()
      forms.append('id', this.value.id)
      forms.append('type', 'image6')
      forms.append('file', file.file) // 获取上传图片信息
      uploadImage(forms).then(({ data }) => {
        this.image6 = [{ url: data }]
        Dialog({ message: '视频上传成功' })
        this.isshoww = false
      })
    },
    uploadFfileQz (file) {
      this.isshoww = true
      const forms = new FormData()
      forms.append('id', this.value.id)
      forms.append('type', 'qianzi')
      forms.append('file', file.file) // 获取上传图片信息
      uploadImage(forms).then(({ data }) => {
        this.qianzi = [{ url: data }]


        if (this.qianzi != null && this.qianzi.length > 0) {
          const update = {
            id: this.value.id,
            qianzi: this.qianzi[0].url
          }

          updateFormsInfo(update).then(({ res }) => {
            Dialog({ message: '图片上传成功' })
          }).catch(({ res }) => {
            Dialog({ message: '图片上传失败' })
          }).finally(() => {
            this.isshoww = false
          })
        } else {
          Dialog({ message: '图片上传失败' })
          this.isshoww = false
        }
      })
    },
    handleChange () {

    },
    isNotNull (value) {
      if ((value === null || value === undefined || value === '' || (value.constructor === Array && value.length === 0))) {
        return false
      } else {
        return true
      }
    },
    // 设定值
    setFormValue (key, insert) {
      const obj = {}
      if (this.collect[key] != null && this.collect[key] !== undefined && this.collect[key] !== '') {
        if (this.collect[key].constructor === Array) {
          if (this.collect[key].length > 0) {
            obj[key] = this.collect[key]
          } else { return }
        } else {
          obj[key] = this.collect[key]
        }
      } else {
        return
      }

      if (obj != {}) {
        const value = {}
        value.formid = this.$route.query.id
        value.fieldid = key.replaceAll('F', '')
        if (obj[key].constructor === Array) {
          value.val = String(obj[key]).replaceAll(',', '{s}')
          value.vtype = 1
        } else {
          if (obj[key] === 'true' || obj[key] === true) {
            obj[key] = 1
            value.vtype = 2
          } else if (obj[key] === 'false' || obj[key] === false) {
            obj[key] = 0
            value.vtype = 2
          } else {
            if (typeof obj[key] === 'number') {
              value.vtype = 2
            } else {
              value.vtype = 1
            }
          }
          value.val = obj[key]
        }
        insert.push(value)
      }
    },
    // 检查未填项目
    checkRequire (item, name, msg = '', active = 0) {

      if ((item === null || item === undefined || item === '' || (item.constructor === Array && item.length === 0))) {
        this.errField = name
        if (msg !== '') {
          Toast.fail(msg)
          this.active = active
        }
        const ele = document.getElementById(name)
        if (ele !== null && ele !== undefined) {
          ele.scrollIntoView({
            block: 'start',
            inline: 'nearest',
            behavior: 'smooth',
            alignTo: false
          })
        }

        throw new Error('check error：' + name)
      }

      return true
    },
    /**
     * 保存tab1数据
     * */
    add1 (show) {
      const insert = []

      this.setFormValue('F11663', insert)
      this.setFormValue('F11664', insert)
      this.setFormValue('F11665', insert)
      this.setFormValue('F11666', insert)
      this.setFormValue('F11667', insert)
      this.setFormValue('F11668', insert)
      this.setFormValue('F11673', insert)
      this.setFormValue('F11674', insert)
      this.setFormValue('F11675', insert)
      this.setFormValue('F11678', insert)
      this.setFormValue('F11679', insert)
      this.setFormValue('F11680', insert)
      this.setFormValue('F11681', insert)
      this.setFormValue('F11682', insert)
      this.setFormValue('F11683', insert)
      this.setFormValue('F11684', insert)
      this.setFormValue('F11685', insert)
      this.setFormValue('F11686', insert)
      this.setFormValue('F11687', insert)
      this.setFormValue('F11688', insert)
      this.setFormValue('F11689', insert)
      this.setFormValue('F11690', insert)
      this.setFormValue('F11690', insert)
      this.setFormValue('F11691', insert)
      this.setFormValue('F11692', insert)
      this.setFormValue('F11693', insert)
      this.setFormValue('F11694', insert)
      this.setFormValue('F11695', insert)
      this.setFormValue('F11696', insert)
      this.setFormValue('F11697', insert)
      this.setFormValue('F11700', insert)
      this.setFormValue('F11701', insert)
      this.setFormValue('F11702', insert)
      this.setFormValue('F11703', insert)
      this.setFormValue('F11704', insert)
      this.setFormValue('F11705', insert)
      this.setFormValue('F11706', insert)
      this.setFormValue('F11707', insert)
      this.setFormValue('F11710', insert)
      this.setFormValue('F11711', insert)
      this.setFormValue('F11712', insert)
      this.setFormValue('F11713', insert)
      this.setFormValue('F11714', insert)
      this.setFormValue('F12431', insert)
      this.setFormValue('F12432', insert)
      this.setFormValue('F12433', insert)
      this.setFormValue('F12434', insert)
      this.setFormValue('F12436', insert)
      this.setFormValue('F12437', insert)
      this.setFormValue('F12438', insert)
      this.setFormValue('F12439', insert)
      this.setFormValue('F12440', insert)
      this.setFormValue('F12441', insert)
      this.setFormValue('F12442', insert)
      this.setFormValue('F12443', insert)
      this.setFormValue('F12444', insert)
      this.setFormValue('F12445', insert)
      this.setFormValue('F12446', insert)
      this.setFormValue('F12447', insert)
      this.setFormValue('F12448', insert)
      this.setFormValue('F12449', insert)
      this.setFormValue('F12450', insert)
      this.setFormValue('F12451', insert)
      this.setFormValue('F12452', insert)
      this.setFormValue('F12453', insert)
      this.setFormValue('F12456', insert)

      if (insert.length === 0) {
        return
      }

      // 保存数据
      this.isshoww = true
      addFormsVals({ list: insert, formsId: this.$route.query.id, str: '保存居家信息' }).then(({ data }) => {
        console.log('add1 ---------- ' + data)
        if (show === null || show === undefined) {
          if (data === '操作成功') {
            Toast.success('保存成功')
          } else {
            Toast.fail('保存失败，请联系管理员')
          }
        }
      }).finally(() => {
        this.isshoww = false
      })
    },
    add2 (show) {
      const insert = []

      this.setFormValue('F12455', insert)
      const objkeys = Object.keys(this.collect).filter(keys => (keys <= 'F12070' && keys >= 'F12017')).sort()

      objkeys.forEach(key => {
        if (key <= 'F12070' && key >= 'F12017') {
          this.setFormValue(key, insert)
        }
      })

      if (insert.length === 0) {
        return
      }

      // 保存数据
      this.isshoww = true
      addFormsVals({ list: insert, formsId: this.$route.query.id, str: '保存基本信息' }).then(({ data }) => {
        console.log('add2 ---------- ' + data)
        if (show === null || show === undefined) {
          if (data === '操作成功') {
            Toast.success('保存成功')
          } else {
            Toast.fail('保存失败，请联系管理员')
          }
        }
      }).finally(() => {
        this.isshoww = false
      })
    },
    add3 (show) {
      const insert = []
      const objkeys = Object.keys(this.collect).filter(keys => (keys <= 'F12075' && keys >= 'F12071')).sort()

      objkeys.forEach(key => {
        this.setFormValue(key, insert)
      })

      if (insert.length === 0) {
        return
      }

      // 保存数据
      const update = { id: this.value.id, buchong: this.value.buchong }
      const userid = localStorage.getItem('userId')
      if (this.value.status == 0 && userid != null) {
        update.lasteditUid = userid
        update.lastSavetimeA = new Date().getTime() / 1000
      }

      this.isshoww = true
      Promise.all([
        addFormsVals({ list: insert, formsId: this.$route.query.id, str: '保存总体状况' }),
        updateFormsInfo(update)

      ]).then((result) => {
        if (result[0].code === 200 && result[1].code === 200) {
          console.log('add3 ---------- ' + result)

          if (show !== null && show !== undefined) {
            submitA({ formsId: this.value.id }).then(({ data }) => {
              (data === '操作成功') ? Toast.success('提交A成功') : Toast.fail('提交A失败，请联系管理员')
            }).catch(err => {
              console.log('add3 ---------- ' + err)
              Toast.fail('提交A失败，请联系管理员')
            }).finally(() => {
              this.isshoww = false
            })
          } else {
            this.isshoww = false
            Toast.success('保存成功')
          }
        } else {
          this.isshoww = false
          Toast.fail('保存失败，请联系管理员')
        }
      }).catch(error => {
        console.log('add3 ---------- ' + error)
        this.isshoww = false
        Toast.fail('保存失败，请联系管理员')
      })
    },
    add4 (show) {
      const insert = []
      const objkeys = Object.keys(this.collect).filter(keys => (keys <= 'F12103' && keys >= 'F12076')).sort()

      this.setFormValue('F12454', insert)
      objkeys.forEach(key => {
        if (key === 'F12082') {
          return
        }
        this.setFormValue(key, insert)
      })

      if (insert.length === 0) {
        return
      }

      let update = { id: this.value.id, buchong2: this.value.buchong2 }
      const userid = localStorage.getItem('userId')
      if (this.value.status == 0 && userid != null) {
        update.lasteditUid = userid
        update.lastSavetimeB = new Date().getTime() / 1000
      }

      this.isshoww = true
      Promise.all([
        addFormsVals({ list: insert, formsId: this.$route.query.id, str: '疾病总体信息' }),
        updateFormsInfo(update)
      ]).then((result) => {
        if (result[0].code === 200 && result[1].code === 200) {
          console.log('add4 ---------- ' + result)
          if (show === null || show === undefined) {
            Toast.success('保存成功')
          }
        } else {
          Toast.fail('保存失败，请联系管理员')
        }
      }).catch(error => {
        console.log('add4 ---------- ' + error)
        Toast.fail('保存失败，请联系管理员')
      }).finally(() => {
        this.isshoww = false
      })
    },
    add5 (show) {
      const insert = []
      const objkeys = Object.keys(this.collect).filter(keys => (keys <= 'F12426' && keys >= 'F12108')).sort()
      // 慢性阻塞性肺疾病
      this.setFormValue('F12108', insert)
      if (this.collect.F12108 == 1) {
        objkeys.forEach(key => {
          if (key <= 'F12141' && key >= 'F12109') {
            if (key === 'F12133' || key === 'F12134') {
              return
            }
            this.setFormValue(key, insert)
          }
        })
      }
      // 糖尿病
      this.setFormValue('F12142', insert)
      if (this.collect.F12142 == 1) {
        Object.keys(this.collect).forEach(key => {
          if (key <= 'F12167' && key >= 'F12143') {
            this.setFormValue(key, insert)
          }
        })
      }
      // 慢性肺炎
      this.setFormValue('F12168', insert)
      if (this.collect.F12168 == 1) {
        objkeys.forEach(key => {
          if (key <= 'F12199' && key >= 'F12169') {
            this.setFormValue(key, insert)
          }
        })
      }
      // 下肢骨折
      this.setFormValue('F12200', insert)
      if (this.collect.F12200 == 1) {
        objkeys.forEach(key => {
          if (key <= 'F12223' && key >= 'F12201') {
            this.setFormValue(key, insert)
          }
        })
      }
      // 帕金森病
      this.setFormValue('F12224', insert)
      if (this.collect.F12224 == 1) {
        objkeys.forEach(key => {
          if (key <= 'F12249' && key >= 'F12225') {
            this.setFormValue(key, insert)
          }
        })
      }
      // 脑出血
      this.setFormValue('F12250', insert)
      if (this.collect.F12250 == 1) {
        objkeys.forEach(key => {
          if (key <= 'F12277' && key >= 'F12251') {
            this.setFormValue(key, insert)
          }
        })
      }
      // 高血压
      this.setFormValue('F12278', insert)
      if (this.collect.F12278 == 1) {
        objkeys.forEach(key => {
          if (key <= 'F12302' && key >= 'F12279') {
            this.setFormValue(key, insert)
          }
        })
      }
      // 晚期肿瘤
      this.setFormValue('F12303', insert)
      if (this.collect.F12303 == 1) {
        debugger
        objkeys.forEach(key => {
          if (key <= 'F12335' && key >= 'F12304') {
            this.setFormValue(key, insert)
          }
        })
      }
      // 脑梗死
      this.setFormValue('F12336', insert)
      if (this.collect.F12336 == 1) {
        objkeys.forEach(key => {
          if (key <= 'F12362' && key >= 'F12337') {
            this.setFormValue(key, insert)
          }
        })
      }
      // 冠状动脉粥样硬化性心脏病
      this.setFormValue('F12363', insert)
      if (this.collect.F12363 == 1) {
        objkeys.forEach(key => {
          if (key <= 'F12391' && key >= 'F12364') {
            this.setFormValue(key, insert)
          }
        })
      }
      // 认知障碍
      this.setFormValue('F12392', insert)
      if (this.collect.F12392 == 1) {
        objkeys.forEach(key => {
          if (key <= 'F12419' && key >= 'F12393') {
            this.setFormValue(key, insert)
          }
        })
      }
      // 其它疾病
      this.setFormValue('F12420', insert)
      if (this.collect.F12420 == 1) {
        objkeys.forEach(key => {
          if (key <= 'F12426' && key >= 'F12421') {
            this.setFormValue(key, insert)
          }
        })
        this.setFormValue('F12457', insert)
      }

      let update = { id: this.value.id, buchong2: this.value.buchong2 }
      const userid = localStorage.getItem('userId')
      if (this.value.status == 0 && userid != null) {
        update.lasteditUid = userid
        update.lastSavetimeB = new Date().getTime() / 1000
      }

      this.isshoww = true
      if (insert.length === 0) {
        Promise.all([
          updateFormsInfo(update)
        ]).then((result) => {
          if (result[0].code === 200) {
            console.log('add5 ---------- ' + result)

            if (show !== null && show !== undefined) {
              submitB({ formsId: this.value.id }).then(({ data }) => {
                (data === '操作成功') ? Toast.success('提交B成功') : Toast.fail('提交B失败，请联系管理员')
              }).catch(err => {
                console.log('add5 ---------- ' + err)
                Toast.fail('提交B失败，请联系管理员')
              }).finally(() => {
                this.isshoww = false
              })
            } else {
              this.isshoww = false
              Toast.success('保存成功')
            }
          } else {
            this.isshoww = false
            Toast.fail('保存失败，请联系管理员')
          }
        }).catch(error => {
          this.isshoww = false
          console.log('add5 ---------- ' + error)
          Toast.fail('保存失败，请联系管理员')
        })
      } else {
        Promise.all([
          addFormsVals({ list: insert, formsId: this.$route.query.id, str: '保存特定疾病信息' }),
          updateFormsInfo(update)
        ]).then((result) => {
          if (result[0].code === 200 && result[1].code === 200) {
            console.log('add5 ---------- ' + result)

            if (show !== null && show !== undefined) {
              submitB({ formsId: this.value.id }).then(({ data }) => {
                (data === '操作成功') ? Toast.success('提交B成功') : Toast.fail('提交B失败，请联系管理员')
              }).catch(err => {
                console.log('add5 ---------- ' + err)
                Toast.fail('提交B失败，请联系管理员')
              }).finally(() => {
                this.isshoww = false
              })
            } else {
              this.isshoww = false
              Toast.success('保存成功')
            }
          } else {
            this.isshoww = false
            Toast.fail('保存失败，请联系管理员')
          }
        }).catch(error => {
          this.isshoww = false
          console.log('add5 ---------- ' + error)
          Toast.fail('保存失败，请联系管理员')
        })
      }
    },
    /**
     * 下一项1
     * */
    xiayixiang1 () {
      try {
        this.checkRequire(this.collect.F12456, 'F12456')
        this.checkRequire(this.collect.F12451, 'F12451')
        this.checkRequire(this.collect.F12452, 'F12452')
        if (this.collect.F12452 == 14) {
          this.checkRequire(this.collect.F12453, 'F12453')
        }
        this.checkRequire(this.collect.F11663, 'F11663')
        this.checkRequire(this.collect.F11664, 'F11664')
        this.checkRequire(this.collect.F11666, 'F11666')
        this.checkRequire(this.collect.F11667, 'F11667')
        this.checkRequire(this.collect.F11668, 'F11668')
        this.checkRequire(this.collect.F11673, 'F11673')
        this.checkRequire(this.collect.F11674, 'F11674')
        this.checkRequire(this.collect.F11675, 'F11675')
        this.checkRequire(this.collect.F11678, 'F11678')
        this.checkRequire(this.collect.F11687, 'F11687')
        this.checkRequire(this.collect.F11688, 'F11688')
        if (this.collect.F11688 == 1) {
          this.checkRequire(this.collect.F11689, 'F11689')
        }
        // 方言
        if (this.collect.F11688 == 1 && this.collect.F11689 == 2) {
          this.checkRequire(this.collect.F11690, 'F11690')
        }
        this.checkRequire(this.collect.F12431, 'F12431')
        if (this.collect.F12431 == 5) {
          this.checkRequire(this.collect.F12432, 'F12432')
        }
        // 信仰
        this.checkRequire(this.collect.F12433, 'F12433')
        if (this.collect.F12433 == 1) {
          this.checkRequire(this.collect.F12434, 'F12434')
        }
        // 配偶
        this.checkRequire(this.collect.F11691, 'F11691')
        if (this.collect.F11691 == 1) {
          this.checkRequire(this.collect.F11692, 'F11692')
          this.checkRequire(this.collect.F12436, 'F12436')
          this.checkRequire(this.collect.F12437, 'F12437')
        }
        this.checkRequire(this.collect.F11693, 'F11693')
        this.checkRequire(this.collect.F11694, 'F11694')
        this.checkRequire(this.collect.F11695, 'F11695')
        this.checkRequire(this.collect.F11696, 'F11696')
        // 和谁一起居住
        if (this.collect.F11696 == 2) {
          this.checkRequire(this.collect.F11697, 'F11697')
        }
        this.checkRequire(this.collect.F11700, 'F11700')
        // 评估对象身体是否需要照护情况
        this.checkRequire(this.collect.F12438, 'F12438')
        if (this.collect.F12438 == 2) {
          this.checkRequire(this.collect.F12439, 'F12439')
        }

        this.checkRequire(this.collect.F12440, 'F12440')
        this.checkRequire(this.collect.F12441, 'F12441')
        this.checkRequire(this.collect.F12442, 'F12442')
        this.checkRequire(this.collect.F11701, 'F11701')
        this.checkRequire(this.collect.F11703, 'F11703')
        this.checkRequire(this.collect.F11704, 'F11704')
        this.checkRequire(this.collect.F11705, 'F11705')
        this.checkRequire(this.collect.F11706, 'F11706')
        this.checkRequire(this.collect.F11707, 'F11707')
        this.checkRequire(this.collect.F12443, 'F12443')
        this.checkRequire(this.collect.F11710, 'F11710')
        this.checkRequire(this.collect.F11711, 'F11711')
        this.checkRequire(this.collect.F11712, 'F11712')
        this.checkRequire(this.collect.F11714, 'F11714')

        this.checkRequire(this.collect.F12444, 'F12444')
        this.checkRequire(this.collect.F12445, 'F12445')
        this.checkRequire(this.collect.F12446, 'F12446')
        this.checkRequire(this.collect.F12447, 'F12447')
        this.checkRequire(this.collect.F12448, 'F12448')
        this.checkRequire(this.collect.F12448, 'F12449')
        this.checkRequire(this.collect.F12448, 'F12450')


      } catch (e) {
        return false
      }

      this.add1(false)
      this.active = 2
    },
    xiayixiang2 () {
      const objkeys = Object.keys(this.collect).filter(keys => (keys <= 'F12070' && keys >= 'F12017')).sort()

      try {
        objkeys.forEach(key => {
          this.checkRequire(this.collect[key], key)
        })
      } catch (e) {return false}

      this.add2(false)
      this.active = 3
    },
    xiayixiang3 () {
      if (!this.checkA()) {
        return
      }

      this.add1(false)
      this.add2(false)
      this.add3(false)
      this.active = 4
    },
    checkA () {
      const message1 = '请将诚信/满意度信息补充完整'
      // if (this.image1 === null || this.image1.length === 0) {
      //   this.active = 0
      //   Toast.fail(message1)
      //   return false
      // }
      // if (this.image2 === null || this.image2.length === 0) {
      //   this.active = 0
      //   Toast.fail(message1)
      //   return false
      // }
      // if (this.image3 === null || this.image3.length === 0) {
      //   this.active = 0
      //   Toast.fail(message1)
      //   return false
      // }
      // if (this.image4 === null || this.image4.length === 0) {
      //   this.active = 0
      //   Toast.fail(message1)
      //   return false
      // }
      // if (this.image5 === null || this.image5.length === 0) {
      //   this.active = 0
      //   Toast.fail(message1)
      //   return false
      // }
      // if (this.image6 === null || this.image6.length === 0) {
      //   this.active = 0
      //   Toast.fail(message1)
      //   return false
      // }

      const message2 = '请将【居家信息】补充完整'

      try {
        this.checkRequire(this.collect.F12456, 'F12456', message2, 1)
        this.checkRequire(this.collect.F12451, 'F12451', message2, 1)
        this.checkRequire(this.collect.F12452, 'F12452', message2, 1)
        if (this.collect.F12452 == 14) {
          this.checkRequire(this.collect.F12453, 'F12453', message2, 1)
        }
        this.checkRequire(this.collect.F11663, 'F11663', message2, 1)
        this.checkRequire(this.collect.F11664, 'F11664', message2, 1)
        this.checkRequire(this.collect.F11666, 'F11666', message2, 1)
        this.checkRequire(this.collect.F11667, 'F11667', message2, 1)
        this.checkRequire(this.collect.F11668, 'F11668', message2, 1)
        this.checkRequire(this.collect.F11673, 'F11673', message2, 1)
        this.checkRequire(this.collect.F11674, 'F11674', message2, 1)
        this.checkRequire(this.collect.F11675, 'F11675', message2, 1)
        this.checkRequire(this.collect.F11678, 'F11678', message2, 1)
        this.checkRequire(this.collect.F11687, 'F11687', message2, 1)
        this.checkRequire(this.collect.F11688, 'F11688', message2, 1)
        if (this.collect.F11688 == 1) {
          this.checkRequire(this.collect.F11689, 'F11689', message2, 1)
        }
        // 方言
        if (this.collect.F11688 == 1 &&this.collect.F11689 == 2) {
          this.checkRequire(this.collect.F11690, 'F11690', message2, 1)
        }
        // 信仰
        this.checkRequire(this.collect.F12433, 'F12433', message2, 1)
        if (this.collect.F12434 == 1) {
          this.checkRequire(this.collect.F12434, 'F12434', message2, 1)
        }
        // 配偶
        this.checkRequire(this.collect.F11691, 'F11691', message2, 1)
        if (this.collect.F11691 == 1) {
          this.checkRequire(this.collect.F11692, 'F11692', message2, 1)
          this.checkRequire(this.collect.F12436, 'F12436', message2, 1)
          this.checkRequire(this.collect.F12437, 'F12437', message2, 1)
        }
        this.checkRequire(this.collect.F11693, 'F11693', message2, 1)
        this.checkRequire(this.collect.F11694, 'F11694', message2, 1)
        this.checkRequire(this.collect.F11695, 'F11695', message2, 1)
        this.checkRequire(this.collect.F11696, 'F11696', message2, 1)
        // 和谁一起居住
        if (this.collect.F11696 == 2) {
          this.checkRequire(this.collect.F11697, 'F11697', message2, 1)
        }
        this.checkRequire(this.collect.F11700, 'F11700', message2, 1)
        // 评估对象身体是否需要照护情况
        this.checkRequire(this.collect.F12438, 'F12438', message2, 1)
        if (this.collect.F12438 == 2) {
          this.checkRequire(this.collect.F12439, 'F12439', message2, 1)
        }

        this.checkRequire(this.collect.F12440, 'F12440', message2, 1)
        this.checkRequire(this.collect.F12441, 'F12441', message2, 1)
        this.checkRequire(this.collect.F12442, 'F12442', message2, 1)
        this.checkRequire(this.collect.F11701, 'F11701', message2, 1)
        this.checkRequire(this.collect.F11703, 'F11703', message2, 1)
        this.checkRequire(this.collect.F11704, 'F11704', message2, 1)
        this.checkRequire(this.collect.F11705, 'F11705', message2, 1)
        this.checkRequire(this.collect.F11706, 'F11706', message2, 1)
        this.checkRequire(this.collect.F11707, 'F11707', message2, 1)
        this.checkRequire(this.collect.F12443, 'F12443', message2, 1)
        this.checkRequire(this.collect.F11710, 'F11710', message2, 1)
        this.checkRequire(this.collect.F11711, 'F11711', message2, 1)
        this.checkRequire(this.collect.F11712, 'F11712', message2, 1)
        this.checkRequire(this.collect.F11714, 'F11714', message2, 1)

        this.checkRequire(this.collect.F12444, 'F12444', message2, 1)
        this.checkRequire(this.collect.F12445, 'F12445', message2, 1)
        this.checkRequire(this.collect.F12446, 'F12446', message2, 1)
        this.checkRequire(this.collect.F12447, 'F12447', message2, 1)
        this.checkRequire(this.collect.F12448, 'F12448', message2, 1)
        this.checkRequire(this.collect.F12448, 'F12449', message2, 1)
        this.checkRequire(this.collect.F12448, 'F12450', message2, 1)

        this.checkRequire(this.collect.F12431, 'F12431', message2, 1)
        if (this.collect.F12431 == 5) {
          this.checkRequire(this.collect.F12432, 'F12432', message2, 1)
        }
      } catch (e) {
        return false
      }

      const message3 = '请将【基本信息】补充完整'
      const objkeys = Object.keys(this.collect).filter(keys => (keys <= 'F12070' && keys >= 'F12017')).sort()

      try {
        objkeys.forEach(key => {
          if (key <= 'F12070' && key >= 'F12017') {
            this.checkRequire(this.collect[key], key, message3, 2)
          }
        })
      } catch (e) {
        return false
      }

      const message4 = '请将【总体情况】补充完整'
      const objkeys2 = Object.keys(this.collect).filter(keys => (keys <= 'F12075' && keys >= 'F12071')).sort()

      try {
        objkeys2.forEach(key => {
          this.checkRequire(this.collect[key], key, message4, 3)
        })

        this.checkRequire(this.value.buchong, 'buchong', message4, 3)
      } catch (e) {
        return false
      }

      return true
    },
    xiayixiang4 () {
      const objkeys = Object.keys(this.collect).filter(keys => (keys <= 'F12103' && keys >= 'F12076')).sort()

      try {
        if (this.collect.F12100 == 20) {
          this.checkRequire(this.collect.F12454, 'F12454')
        }

        objkeys.forEach(key => {
          if (key === 'F12082') {
            return false
          }
          this.checkRequire(this.collect[key], key)
        })
        this.checkRequire(this.value.buchong2, 'buchong2')
      } catch (e) {
        return false
      }

      if (this.value.buchong2.length <= 10) {
        document.getElementById('buchong2').scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'smooth',
          alignTo: false
        })
        Toast.fail('情况描述不能为空，并且不能低于10个字')
        return false
      }

      this.add4(false)
      this.active = 5
    },
    xiayixiang5 () {
      if (!this.checkB()) {
        return
      }

      this.add4(false)
      this.add5(false)
      this.active = 6
    },
    checkB () {
      const message1 = '请将【疾病总体信息】补充完整'
      const objkeys = Object.keys(this.collect).filter(keys => (keys <= 'F12103' && keys >= 'F12076')).sort()

      try {
        if (this.collect.F12100 == 20) {
          this.checkRequire(this.collect.F12454, 'F12454', message1, 4)
        }
        objkeys.forEach(key => {
          if (key === 'F12082') {
            return
          }
          this.checkRequire(this.collect[key], key, message1, 4)
        })
      } catch (e) {
        return false
      }

      if (this.collect.F12108 == 1) {
        const message2 = '请将【慢性阻塞性肺疾病】补充完整'
        const objkeys2 = Object.keys(this.collect).filter(keys => (keys <= 'F12141' && keys >= 'F12109')).sort()
        try {
          if (objkeys2.length === 0 || objkeys2.length !== 31) {
            this.checkRequire(null, 'F12108', message2, 5)
            return false
          }
          objkeys2.forEach(key => {
            if (key === 'F12133' || key === 'F12134') {
              return
            }
            this.checkRequire(this.collect[key], key, message2, 5)
          })
        } catch (e) {
          return false
        }
      }

      if (this.collect.F12142 == 1) {
        const message2 = '请将【糖尿病】补充完整'
        const objkeys2 = Object.keys(this.collect).filter(keys => (keys <= 'F12167' && keys >= 'F12143')).sort()
        try {
          if (objkeys2.length === 0 || objkeys2.length !== 25) {
            this.checkRequire(null, 'F12142', message2, 5)
            return false
          }
          objkeys2.forEach(key => {
            this.checkRequire(this.collect[key], key, message2, 5)
          })
        } catch (e) {
          return false
        }
      }

      if (this.collect.F12168 == 1) {
        const message2 = '请将【慢性肺炎】补充完整'
        const objkeys2 = Object.keys(this.collect).filter(keys => (keys <= 'F12199' && keys >= 'F12169')).sort()
        try {
          if (objkeys2.length === 0 || objkeys2.length !== 31) {
            this.checkRequire(null, 'F12168', message2, 5)
            return false
          }
          objkeys2.forEach(key => {
            this.checkRequire(this.collect[key], key, message2, 5)
          })
        } catch (e) {
          return false
        }
      }

      if (this.collect.F12200 == 1) {
        const message2 = '请将【下肢骨折】补充完整'
        const objkeys2 = Object.keys(this.collect).filter(keys => (keys <= 'F12223' && keys >= 'F12201')).sort()
        try {
          if (objkeys2.length === 0 || objkeys2.length !== 23) {
            this.checkRequire(null, 'F12200', message2, 5)
            return false
          }
          objkeys2.forEach(key => {
            this.checkRequire(this.collect[key], key, message2, 5)
          })
        } catch (e) {
          return false
        }
      }

      if (this.collect.F12224 == 1) {
        const message2 = '请将【帕金森病】补充完整'
        const objkeys2 = Object.keys(this.collect).filter(keys => (keys <= 'F12249' && keys >= 'F12225')).sort()
        try {
          if (objkeys2.length === 0 || objkeys2.length !== 25) {
            this.checkRequire(null, 'F12224', message2, 5)
          }
          objkeys2.forEach(key => {
            this.checkRequire(this.collect[key], key, message2, 5)
          })
        } catch (e) {
          return false
        }
      }

      if (this.collect.F12250 == 1) {
        const message2 = '请将【脑出血】补充完整'
        const objkeys2 = Object.keys(this.collect).filter(keys => (keys <= 'F12277' && keys >= 'F12251')).sort()
        try {
          if (objkeys2.length === 0 || objkeys2.length !== 27) {
            this.checkRequire(null, 'F12250', message2, 5)
            return false
          }
          objkeys2.forEach(key => {
            this.checkRequire(this.collect[key], key, message2, 5)
          })
        } catch (e) {
          return false
        }
      }

      if (this.collect.F12278 == 1) {
        const message2 = '请将【高血压】补充完整'
        const objkeys2 = Object.keys(this.collect).filter(keys => (keys <= 'F12302' && keys >= 'F12279')).sort()
        try {
          if (objkeys2.length === 0 || objkeys2.length !== 24) {
            this.checkRequire(null, 'F12278', message2, 5)
            return false
          }
          objkeys2.forEach(key => {
            this.checkRequire(this.collect[key], key, message2, 5)
          })
        } catch (e) {
          return false
        }
      }

      if (this.collect.F12303 == 1) {
        const message2 = '请将【晚期肿瘤】补充完整'
        const objkeys2 = Object.keys(this.collect).filter(keys => (keys <= 'F12335' && keys >= 'F12304')).sort()
        try {
          if (objkeys2.length === 0 || objkeys2.length !== 32) {
            this.checkRequire(null, 'F12303', message2, 5)
            return false
          }
          objkeys2.forEach(key => {
            this.checkRequire(this.collect[key], key, message2, 5)
          })
        } catch (e) {
          return false
        }
      }

      if (this.collect.F12336 == 1) {
        const message2 = '请将【脑梗死】补充完整'
        const objkeys2 = Object.keys(this.collect).filter(keys => (keys <= 'F12362' && keys >= 'F12337')).sort()
        try {
          if (objkeys2.length === 0 || objkeys2.length !== 26) {
            this.checkRequire(null, 'F12336', message2, 5)
            return false
          }
          objkeys2.forEach(key => {
            this.checkRequire(this.collect[key], key, message2, 5)
          })
        } catch (e) {
          return false
        }
      }

      if (this.collect.F12363 == 1) {
        const message2 = '请将【冠状动脉粥样硬化性心脏病】补充完整'
        const objkeys2 = Object.keys(this.collect).filter(keys => (keys <= 'F12391' && keys >= 'F12364')).sort()
        try {
          if (objkeys2.length === 0 || objkeys2.length !== 28) {
            this.checkRequire(null, 'F12363', message2, 5)
            return false
          }
          objkeys2.forEach(key => {
            this.checkRequire(this.collect[key], key, message2, 5)
          })
        } catch (e) {
          return false
        }
      }

      if (this.collect.F12392 == 1) {
        const message2 = '请将【认知障碍】补充完整'
        const objkeys2 = Object.keys(this.collect).filter(keys => (keys <= 'F12419' && keys >= 'F12393')).sort()
        try {
          if (objkeys2.length === 0 || objkeys2.length !== 27) {
            this.checkRequire(null, 'F12392', message2, 5)
            return false
          }
          objkeys2.forEach(key => {
            this.checkRequire(this.collect[key], key, message2, 5)
          })
        } catch (e) {
          return false
        }
      }

      if (this.collect.F12420 == 1) {
        const message2 = '请将【其它疾病】补充完整'
        const objkeys2 = Object.keys(this.collect).filter(keys => (keys <= 'F12426' && keys >= 'F12421')).sort()
        try {
          if (objkeys2.length === 0 || objkeys2.length !== 6) {
            this.checkRequire(null, 'F12420', message2, 5)
            return false
          }
          objkeys2.forEach(key => {
            this.checkRequire(this.collect[key], key, message2, 5)
          })
          this.checkRequire(this.collect.F12457, 'F12457', message2, 5)
        } catch (e) {
          return false
        }
      }
      return true
    },
    verifyB () {
      if (this.value.manyi == 0 && (this.value.manyiYuanyin == null || this.value.manyiYuanyin === undefined || this.value.manyiYuanyin === '')) {
        Toast.fail('请请输入原因')
        return
      }
      if (this.qianzi == null || this.qianzi === undefined || this.qianzi === '') {
        Toast.fail('请上传签字图片')
        return
      }
      const message1 = '请将诚信/满意度信息补充完整'

      if (this.image1 === null || this.image1.length === 0 || this.image1[0].url === '') {
        this.active = 0
        Toast.fail(message1)
        return false
      }
      if (this.image2 === null || this.image2.length === 0|| this.image2[0].url === '') {
        this.active = 0
        Toast.fail(message1)
        return false
      }
      if (this.image3 === null || this.image3.length === 0|| this.image3[0].url === '') {
        this.active = 0
        Toast.fail(message1)
        return false
      }
      if (this.image4 === null || this.image4.length === 0|| this.image4[0].url === '') {
        this.active = 0
        Toast.fail(message1)
        return false
      }
      if (this.image5 === null || this.image5.length === 0|| this.image5[0].url === '') {
        this.active = 0
        Toast.fail(message1)
        return false
      }
      if (this.image6 === null || this.image6.length === 0|| this.image6[0].url === '') {
        this.active = 0
        Toast.fail(message1)
        return false
      }
      // a部分是否完成
      if (!this.checkA()) {
        return
      }
      // b部分是否完成
      if (!this.checkB()) {
        return
      }

      if (this.active == 6) {
        const insert = []
        for (const key in this.collect) {
          const obj = {}
          if (this.collect[key] != null && this.collect[key]) {
            obj.formid = this.$route.query.id
            obj.fieldid = key.replaceAll('F', '')
            if (this.collect[key].constructor === Array) {
              obj.val = String(this.collect[key]).replaceAll(',', '{s}')
              obj.vtype = 1
            } else {
              if (this.collect[key] === 'true' || this.collect[key] === true) {
                this.collect[key] = 1
                obj.vtype = 2
              } else if (this.collect[key] === 'false' || this.collect[key] === false) {
                this.collect[key] = 0
                obj.vtype = 2
              } else {
                if (typeof this.collect[key] === 'number') {
                  obj.vtype = 2
                } else {
                  obj.vtype = 1
                }
              }
              obj.val = this.collect[key]
            }
            insert.push(obj)
          }
        }

        const update = {
          id: this.value.id,
          status: 5,
          manyiYuanyin: this.value.manyiYuanyin,
          buchong: this.value.buchong,
          buchong2: this.value.buchong2,
          submtime: new Date().getTime() / 1000
        }
        if (this.qianzi != null && this.qianzi.length > 0) {
          update.qianzi = this.qianzi[0].url
        }

        this.isshoww = true
        Promise.all([
          addFormsVals({ list: insert, formsId: this.$route.query.id, str: '提交签字'  }),
          updateFormsInfo(update)
        ]).then((result) => {
          console.log('verifyB ---------- ' + result)
          if (result[0].code === 200 && result[1].code === 200) {
            Toast.success('提交成功')
            this.$router.push('/homePage')
          } else {
            Toast.fail('保存失败，请联系管理员')
          }
        }).catch(error => {
          console.log('verifyB ---------- ' + error)
          Toast.fail('保存失败，请联系管理员')
        }).finally(() => {
          this.isshoww = false
        })
      }
    },
    updateStatus(value){
      if(value == 0){
        this.isWc = false;
      }else{
        let obj = {
          id:this.value.id,
          status:value,
          submtime: new Date().getTime() / 1000
        };
        updateFormsInfo(obj).then(({data})=>{
          Toast.success('评估完成');
          this.$router.push("/homePage");
        });
      }

    },
  }
}
</script>

<style lang="stylus" scoped>
  /* 第一个tab页面样式 */
  .goodBox {
    width: 88%;
    /* height: 150px; */
    padding: 15px 3%;
    background-color: #fff;
    margin: 0 auto;
  }

  .upimg {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .imgli {
    position: relative;
    width: 80px;
    margin: 25px 0;
  }

  .imgli .imgli-title {
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    color: #bbb;
    text-align: center;
  }

  .ck {
    float: left;
  }

  .dt {
    float: right;
  }

  .btn {
    width: 95%;
    margin: 15px auto;
  }

  .Assessment {
    background-color: #f3f3f3;
  }

  /* 填写样式 */
  .Q {
    margin: 8px 0;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
    min-height: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .bottom {
    display: flex;
    position: fixed;
    bottom: 0;
    font-size: 18px;
    line-height: 60px;
    text-align: center;
    width: 100%;

    height: 60px;
  }

  .bottom1 {
    background: #fff;
    width: 50%;
    height: 100%;
  }

  .bottom2 {
    background-color: skyblue;
    width: 50%;
    height: 100%;
    color: #fff;
  }

  .B {
    margin: 15px 0;
    border-radius: 5px;
    background-color: #fff9ec;
    overflow: hidden;
    padding: 15px 8px;
    min-height: 30px;
  }

  .q-title1 {
    width: 92%;
    margin: 0 auto;
    font-size: 18px;
    padding: 5px 0;
  }

  .q-title3 {
    width: 92%;
    margin: 0 auto;
    font-size: 18px;
  }

  .q-title4 {
    width: 100%;
    margin: 0 auto;
    font-size: 18px;
    color: black;
  }

  .q-input {
    /* border: 0; */
    border: 0;
    text-align: center;
    border-bottom: 1px solid #000;
    width: 40px;
  }

  .q-title {
    /* height: 40px; */
    width: 94%;
    // margin: 0 auto;
    font-size: 18px;
    padding: 2% 16px;

    border-bottom: 1px solid #bbb;
  }

  .q-title2 {
    width: 92%;
    // margin: 0 auto;
    font-size: 16px;
    color: black;
  }

  .q-content {
    /*width: 100%;*/
    color: black;
    padding: 10px 16px;
    font-size: 16px;
    // margin: 2px auto;
  }

  .checkbox {
    margin: 5px 0;
    width: 50%;
    font-size: 16px;
  }

  .checkbox1 {
    margin: 5px 0;
    font-size: 16px;
    // width: 100%;
  }

  .video-js .vjs-icon-placeholder {
    width: 100%;
    height: 100%;
    display: block;
  }

  .q-content textarea {
    width: 98%;
    display: block;
    margin: 2px auto;
    height: 150px;
    min-width: 98%;
    max-width: 98%;
    min-height: 150px;
    max-height: 150px;
  }

  .scrollIntoView {
    height: 15px
  }

  ::v-deep .fieldF11673 {
    color: #ff2200 ;
  }

  .asss {
    --van-field-label-color: red
  }

  .first {
    padding-top: 60px; /* 60px是导航栏高度 */
    margin-top: -60px;
  }

  .loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 9999;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .loading1 {
    width: 50px;
    height: 50px;
    margin: 50vh auto;
  }

  ::v-deep .specific-diseases {
    .q-title6 {
      padding-left: 16px;
      font-size: 18px;

      & + .q-content {
        padding-left: 34px;
      }
    }

    .q-title5 {
      font-weight: bold;
    }
  }

  ::v-deep .van-field__label {
    color:black;
    font-size: 18px;
  }
  ::v-deep .van-checkbox__label, ::v-deep .van-radio__label, ::v-deep .van-field__value,::v-deep .el-input--mini {
    color:black;
    font-size: 16px;
  }
  ::v-deep .van-radio--horizontal {
    margin-bottom 5px;
    margin-right : 5px;
  }

</style>
