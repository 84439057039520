<template>
  <div class="home">
    <div class="Info">
      <div class="Infoli">
        <div class="title">被评估人:</div>
        <div class="content">{{senForms.xm}}</div>
      </div>
      <div class="Infoli">
        <div class="title">申请单号:</div>
        <div class="content">{{senForms.sqh}}</div>
      </div>
      <div class="Infoli">
        <div class="title">评估类型:</div>
        <div class="content">{{getPglx(senForms.pglx)}}</div>
      </div>
      <div class="Infoli">
        <div class="title">社保类型：</div>
        <div class="content">{{getSblx(senForms.sblx)}}</div>
      </div>
      <div class="Infoli">
        <div class="title">预约评估日期：</div>
        <div class="content">一二三四五/全天</div>
      </div>
      <div class="Infoli">
        <div class="title">所属街道：</div>
        <div class="content">{{senForms.zzjd == null || senForms.zzjd == ""?"-":senForms.zzjd}}</div>
      </div>
      <div class="Infoli">
        <div class="title">所属居委：</div>
        <div class="content">{{senForms.zzjw}}</div>
      </div>
      <div class="Infoli">
        <div class="title">评估地点：</div>
        <div class="content">{{senForms.sxdz}}</div>
      </div>
      <div class="Infoli">
        <div class="title">手机号码：</div>
        <div class="content" v-if="senForms.sjhm == null || senForms.sjhm == ''">-</div>
        <div class="content" @click="callphone(senForms.sjhm)" v-else>{{senForms.sjhm}}</div>

      </div>
      <div class="Infoli">
        <div class="title">电话号码：</div>
        <div class="content" v-if="senForms.dhhm == null || senForms.dhhm == ''">-</div>
        <div class="content" @click="callphone(senForms.dhhm)" v-else>{{senForms.dhhm}}</div>

      </div>
      <div class="Infoli">
        <div class="title">其他联系人：</div>
        <!-- <div class="content">{{senForms.dbrxm == null || senForms.dbrxm == ""?"-": senForms.dbrxm}}</div> -->
        <div class="content" v-if="senForms.dbrxm == null || senForms.dbrxm == ''">-</div>
        <div class="content" @click="callphone(senForms.dbrxm)" v-else>{{senForms.dbrxm}}</div>

      </div>
      <div class="Infoli">
        <div class="title">金额：</div>
        <div class="content">{{senForms.pglx==2|| senForms.sblx==2 || senForms.sblx ==5?"200":senForms.sblx ==
          3?"0":"40"}}元
        </div>
      </div>
      <div class="Infoli">
        <div class="title">预约时间：</div>
        <div class="content">{{stringToTime(senForms.yytime) == null?"-":stringToTime(senForms.yytime)}} &nbsp;
          <van-button type="primary" size="small" @click="show =true" v-if="senForms.status == 0">预约时间</van-button>
        </div>
      </div>
      <div class="line"></div>

      <div class="Infoli">
        <div class="title">评估员</div>
        <div class="content"></div>
      </div>
      <div class="Infoli" v-if="pgy1!=null">
        <div class="title">姓　　名：</div>
        <div class="content">{{pgy1.xm}}</div>
      </div>
      <!-- <div class="Infoli" v-if="pgy1!=null">
        <div class="title">联系电话：</div>
        <div class="content">-</div>
      </div> -->
      <div class="Infoli" v-if="pgy1!=null">
        <div class="title">隶属评估机构：</div>
        <div class="content">{{pgy1.sbdw}}</div>
      </div>
      <div class="Infoli" v-if="pgy2!=null">
        <div class="title">姓　　名：</div>
        <div class="content">{{pgy2.xm}}</div>
      </div>
      <!-- <div class="Infoli" v-if="pgy2!=null">
        <div class="title">联系电话：</div>
        <div class="content">-</div>
      </div> -->
      <div class="Infoli" v-if="pgy2!=null">
        <div class="title">隶属评估机构：</div>
        <div class="content">{{pgy2.sbdw}}</div>
      </div>
      <div class="line"></div>
      <div class="title1">请确认以上信息正确，评估完成将计入以上人员绩效！</div>
      <div class="title1">如果因个人原因无法评估，请及时联系管理员。</div>
      <div class="title1">联系电话：{{this.type == "云养"?"云养 021-55896301":"领航 021-65041656"}}</div>
    </div>
    <div v-if="senForms.status == 0">
      <van-button class="btn" round @click="GoAssessment" type="primary" block size='small' v-if="senForms.ispay !=0">
        开始评估
      </van-button>
      <van-button class="btn" round type="primary" block size='small' v-if="senForms.ispay ==0" @click="pay = true">
        确认支付
      </van-button>
    </div>
    <div v-if="senForms.status != 0">
      <van-button class="btn" round @click="GoAssessment" type="primary" block size='small'>查看评估</van-button>
    </div>
    <div style="text-align:center">
    </div>
    <van-dialog
      v-model="show"
      title="请选择时间"
      show-cancel-button
      @confirm="updateInfo"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        :confirm-button-text="' '"
        :cancel-button-text="' '"
        :min-date="minDate"
      />
    </van-dialog>
    <van-dialog
      v-model="pay"
      title="请选择时间"
      show-cancel-button
      @confirm="onConfirm"
    >
      <van-picker
        title="标题"
        :columns="columns"
        @change="onChange"
        :confirm-button-text="''"
        :cancel-button-text="''"
      />
    </van-dialog>
  </div>
</template>

<script>
import { getformsInfoById, updateFormsInfo } from '../request/http'
import { Notify } from 'vant'

export default {
  name: 'HomeView',
  data () {
    return {
      pay: false,
      columns: ['微信', '支付宝', '现金'],
      isPay: 1,
      currentDate: new Date(),
      minDate: new Date(),
      show: false,
      senForms: {},
      pgy1: {},
      pgy2: {},
      userId: null,
      type: ''
    }
  },
  watch: {},
  mounted () {
    this.type = localStorage.getItem('system')
    this.getFormsInfo()
  },
  methods: {
    callphone (phonenumber) {
      window.location.href = 'tel://' + phonenumber
    },
    onChange (values, text) {
      if (text == '微信') {
        this.isPay = 1
      }
      if (text == '支付宝') {
        this.isPay = 2
      }
      if (text == '现金') {
        this.isPay = 3
      }
    },
    onCancel () {
      this.pay = false
    },
    onConfirm () {
      updateFormsInfo({ id: this.$route.query.id, ispay: this.isPay }).then(({ data }) => {
        if (data == '操作成功') {
          Notify({ type: 'primary', message: '保存成功' })
          this.getFormsInfo()
        } else {
          Notify({ type: 'warning', message: '保存失败，请联系管理员' })
        }
      })
    },
    addOrUpdate () {
      if (this.value == null || this.value.length == 0) {
        this.value.push(this.columns[0].text)
        this.value.push(this.columns[0].children[0].text)
      }
      const value = this.value
      if (this.streetList[0].streetName == null || this.streetList[0].streetName == '') {
        this.streetList[0].streetName = value[0]
        if (value.length == 2) {
          this.streetList[0].juWeiName = value[1]
        }
        this.streetList.push({ streetName: null, juWeiName: null })
        return
      }
      const obj = { streetName: null, juWeiName: null }
      if (value != null && value.length > 0) {
        obj.streetName = value[0]
        if (value.length == 2) {
          obj.juWeiName = value[1]
        }
        this.streetList[this.streetList.length - 1] = obj
      }
      this.streetList.push({ streetName: null, juWeiName: null })
    },
    updateInfo () {
      updateFormsInfo({ id: this.$route.query.id, yytime: this.currentDate }).then(({ data }) => {
        if (data == '操作成功') {
          Notify({ type: 'primary', message: '保存成功' })
          this.getFormsInfo()
        } else {
          Notify({ type: 'warning', message: '保存失败，请联系管理员' })
        }
      })
    },
    stringToTime (timestamp) {
      if (timestamp == null || timestamp == '' || timestamp == 0) {
        return
      }
      // 计算年月日时分的函数
      const date = new Date(timestamp)
      let timeStr = date.getFullYear() + '-'
      if (date.getMonth() < 9) {
        // 月份从0开始的
        timeStr += '0'
      }
      timeStr += date.getMonth() + 1 + '-'
      timeStr += date.getDate() < 10 ? ('0' + date.getHours()) : date.getHours()
      timeStr += ' '
      timeStr += date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours()
      timeStr += ':'
      timeStr += date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()
      timeStr += ':'
      timeStr += date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds()
      return timeStr
    },
    timestampToTime (timestamp) {
      if (timestamp == null || timestamp == '' || timestamp == 0) {
        return
      }
      // 计算年月日时分的函数
      const date = new Date(timestamp * 1000)
      let timeStr = date.getFullYear() + '-'
      if (date.getMonth() < 9) {
        // 月份从0开始的
        timeStr += '0'
      }
      timeStr += date.getMonth() + 1 + '-'
      timeStr += date.getDate() < 10 ? ('0' + date.getHours()) : date.getHours()
      timeStr += ' '
      timeStr += date.getHours() < 10 ? ('0' + date.getHours()) : date.getHours()
      timeStr += ':'
      timeStr += date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()
      timeStr += ':'
      timeStr += date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds()
      return timeStr
    },
    tijiao () {
      updateStreetInfo({ sfzh: this.sfzh, list: this.streetList }).then(({ data }) => {
        console.log(data)
      })
    },
    getFormsInfo () {
      getformsInfoById({ id: this.$route.query.id }).then(({ data }) => {
        console.log(data)
        this.pgy1 = data.pgy0
        this.pgy2 = data.pgy1
        this.userId = data.userId
        this.senForms = data.senForms
      })
    },
    getPglx (teyp) {
      if (teyp == 1) {
        return '初次评估'
      }
      if (teyp == 2) {
        return '复核评估'
      }
      if (teyp == 3) {
        return '状态评估'
      }
      if (teyp == 4) {
        return '期末评估'
      }
      if (teyp == 5) {
        return '绿色通道'
      }
      if (teyp == 6) {
        return '终核评估'
      }
    },
    getSblx (teyp) {
      if (teyp == 1) {
        return '职保/人保'
      }
      if (teyp == 2) {
        return '非长护险人员'
      }
      if (teyp == 3) {
        return '本市老红军、离职干部、革命伤残军人'
      }
      if (teyp == 4) {
        return '互助帮困人员'
      }
      if (teyp == 5) {
        return '复评'
      }
    },
    GoAssessment () {
      localStorage.setItem('userId', this.userId)
      if (this.senForms.status == 0 || this.senForms.status == 5) {
        if (this.senForms.status == 0) {
          this.$router.push({ path: '/assessmentCopy', query: { id: this.senForms.id } })
        }
        if (this.senForms.status == 5 && this.senForms.pinguid != null && this.senForms.pinguid != '') {
          const lasteditUid = this.senForms.lasteditUid
          if (this.userId != null && lasteditUid != this.userId) {
            this.$router.push({ path: '/assessmentCopy', query: { id: this.senForms.id } })
          } else {
            this.$router.push({ path: '/assessment', query: { id: this.senForms.id } })
          }
        }
      } else {
        this.$router.push({ path: '/assessment', query: { id: this.senForms.id } })
      }
    }
  }
}
</script>

<style scoped>
  .home {
    overflow: hidden;
    min-height: 100vh;

  }

  .Info {
    width: 88%;
    padding: 15px 3%;
    background-color: #fff;
    margin: 10px auto;
    border-radius: 5px;
  }

  .Infoli {
    display: flex;
    margin: 5px 0;
  }

  .title {
    vertical-align: top;
    color: #999;
    width: 35%;
  }

  .title1 {
    width: 100%;
    vertical-align: top;
    color: #999;
  }

  .content {
    width: 65%;
    vertical-align: top;
  }

  .line {
    border-bottom: 2px solid #bbb;
    margin: 20px 0;
  }

  .btn {
    width: 95%;
    margin: 0 auto;
  }
</style>
