<template>
  <div class="index-main">
    <div class="index-main2">
       <div style="padding-top:5px;padding-bottom:10px;color:#cccccc;font-weight:5;font-size: 15px;">若您的评估中心使用此系统，请提交相关信息。</div>
      <CellGroup center>
          <van-field
        v-model="sfzh"
        label="身份证号："
      />
      </CellGroup>
      <br>
      <van-cell-group>
        <van-cell v-for="(item,index) in streetList" :key="index" :title="index == 0?'街道居委':' '" value="内容"
        ><span>{{(item.streetName!=null?item.streetName:"") + (item.juWeiName !=null && item.juWeiName != ""?"/"+item.juWeiName:"")}}</span> <span style="color:blue" @click="show = true" v-if="index == streetList.length-1">添加</span>
        <van-icon name="clear" @click="deleteStreet(index)" v-if="streetList.length!=1 && index !=streetList.length-1" /> </van-cell>


      </van-cell-group>
      <br>
      <div style="text-align:center">
        <van-button  round  type="primary" size="small" @click="tijiao">提交</van-button>
      </div>
      <van-dialog
        v-model="show"
        title="新增"
        show-cancel-button
        @confirm="addOrUpdate"
      >
      <van-picker :columns="columns"  ref="picker" @change="onChange"
      />
      </van-dialog>
    </div>
  </div>
</template>

<script>
import {
  getStreetInfo,updateStreetInfo,getUserInfoByOpenId
} from "../../request/http";
import {Toast} from "vant";
export default {
  // components:{
  //   Cell,
  //   CellGroup,
  //   Button,
  //   Icon
  // },
  data() {
    return {
      streetList:[{streetName:null,juWeiName:null}],
      columns:[],
      sfzh:null,
      value:[],
      show:false,
      username:''
    };
  },
  created() {
    let openid = this.$route.query.openId;
        if(openid!=null && openid!=""){
            localStorage.setItem("userOpenId",openid);
        }
  },
  mounted() {
     getUserInfoByOpenId({}).then(({data})=>{
      console.log(data)
      if(data.streetList!=null && data.streetList.length>0){
                   this.streetList = data.streetList;
                  this.streetList.push({streetName:null,juWeiName:null});
      }
      if(data.senPgy!=null){
          this.sfzh = data.senPgy.zjhm;
      }
    })
    getStreetInfo({}).then(({data})=>{
      this.columns = data;
    })
  },
  // 页面销毁
  beforeDestroy() {

  },

  methods: {
    tijiao(){
      updateStreetInfo({sfzh:this.sfzh,list:this.streetList}).then(({data})=>{

          if(data == "绑定成功"){
            this.$router.push({ path: '/pilotEvaluate'})
          }else{
            console.log(data)
            Toast.fail(data + '');
          }
      })
    },
    deleteStreet(index){
       this.$delete(this.streetList,index);
    },
     onChange(picker, value, index) {
      this.value = value;
    },
    addOrUpdate(){
      if(this.value == null || this.value.length == 0){
        this.value.push(this.columns[0].text);
        this.value.push(this.columns[0].children[0].text);
      }
      let value = this.value;
      if(this.streetList[0].streetName == null || this.streetList[0].streetName == ''){
        this.streetList[0].streetName = value[0];
        if(value.length == 2){
          this.streetList[0].juWeiName = value[1];
        }
        this.streetList.push({streetName:null,juWeiName:null});
        return;
      }
      let obj = {streetName:null,juWeiName:null};
      if(value!=null && value.length>0){
        obj.streetName = value[0];
        if(value.length == 2){
          obj.juWeiName = value[1];
        }
        this.streetList[this.streetList.length-1] = obj;
      }
      this.streetList.push({streetName:null,juWeiName:null});
    }
  },
};
</script>

<style lang="stylus" scoped>
.index-main {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}
.index-main2 {
  margin: 0 auto;
  width: 90%;
  height: 100%;
  background-color: #f5f5f5;
}
.kaoqing {
  width: 100%;
  position: relative;
  height: 85%;
}

.time {
  position: relative;
  text-align: center;
  margin-top: 10%;
}

.topTime {
  font-weight: 600;
  font-size: 129px;
}

.bottomTime {
  font-weight: 600;
  font-size: 40px;
}

.errPunch {
  position: absolute;
  width: 80%;
  height: 100px;
  left: 10%;
  bottom: 5%;
  .defe {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
  .defend {
    color: red;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
}

.punchClock {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 30%;
}

.place {
  position: relative;
  text-align: center;
  top: 10%;
}

/deep/.van-button--primary {
  width: 90%;
  background-color: #2049a6;
  border-radius: 10px;
}

.calendar {
  font-size: 27px;
  position: absolute;
  width: 100%;

  .mounth {
    height: 80px;
    line-height: 80px;
    text-align: center;
    position: relative;
    width: 100%;
    background-color: #cdcdcd;
  }

  .dateCalendar {
    background-color: white;

    .day {
      display: inline-block;
      width: 14.2%;
      height: 120px;
      // border: 1px solid;
      text-align: center;
      border-top: 7px solid #f5f5f5;
      vertical-align: top;
    }
  }
}

.topYear {
  background-color: white;
  // text-align: center;
  // margin: 0 auto;
  width: 80%;
  height: 70px;
  line-height: 70px;
  display: inline-block;
  margin: 0 auto;
  span {
    color: #1989fa;
    position: absolute;
    width: 100%;
    // right: 3%;
    // text-align: center;
    margin-left: 20px;
  }
}

.maintain {
  color: #1989fa;
}

.queqing {
  color: red;
}

//王丽的样式
/deep/.van-tab--active {
  color: #508ffa;
}

/deep/.van-tabs__line {
  background-color: #508ffa;
}

.weihu {
  background-color: #f7f7f7;
  padding: 20px;

  .item {
    border: 1px solid #ededed;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 10px;
    padding: 40px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item-title {
      font-size: 30px;
      margin-bottom: 10px;
    }

    .item-hour {
      color: #ea5252;
      font-size: 25px;
    }

    .btn {
      padding: 10px 30px;
      background: #508ffa;
      color: #fff;
      border-radius: 26px;
    }
  }
}
#img2 {
  /*display: block;*/
  animation: rotate 500ms linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg); /*从0度开始*/
  }
  100% {
    transform: rotateZ(360deg); /*360度结束*/
  }
}
.tijiao {
  background-color: #f7f7f7;
  padding: 20px;

  .item {
    border: 1px solid #ededed;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 10px;
    padding: 30px 30px;
  }

  .item-children {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #cdcdcd;
    padding-bottom: 30px;
    font-size: 30px;
  }

  .item-date {
    font-size: 25px;
    padding-top: 30px;
  }
}

//
</style>
