<template>
  <div class="index-container">
    <van-tabs v-model="active" animated @change="updateType">
      <van-tab title="待评估">
      </van-tab>
      <van-tab title="已完成"></van-tab>
      <van-tab title="已录入"></van-tab>
    </van-tabs>
    <van-search
      v-model="name"
      placeholder="请输入搜索关键词"
      show-action
    >
      <template #action>
        <div @click="getList">搜索</div>
      </template>
    </van-search>

    <!-- <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
    > -->

    <van-list
      v-model="abcd"
      :finished="finished"
      finished-text="没有更多了"
      :immediate-check="false"
      @load="onLoad"
    >
      <div class="card-wrap" v-for="(item,index) in list" :key="index">
        <div class="card">
          <ul class="info-list">
            <div class="info-item">
              <van-icon size="18" name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"
                        style="display:none"/>
              <span class="item-text">{{item.xm}}</span>&nbsp;<van-button type="danger" size="mini"
                                                                          v-if="item.status == 0"
                                                                          @click="cancel(item.id)">取消申请
            </van-button>
              &nbsp;<van-button type="danger" size="mini" @click="addRemarks(item)" v-if="item.status == 0">备注
            </van-button>&nbsp;
              (自费：{{item.pglx==2|| item.sblx==2 || item.sblx ==5?"200":item.sblx == 3?"0":"40"}}元)
            </div>
            <div class="info-item" @click="tiaozhuan(item)">
              <van-icon size="18" name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"
                        style="display:none"/>
              <span class="item-text">{{item.sqh}}</span>
            </div>
            <div class="info-item" @click="tiaozhuan(item)">
              <van-icon size="18" name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"
                        style="display:none"/>
              发布时间：<span class="item-text">{{timestampToTime(item.paidanTime)}}</span>
            </div>
            <div class="info-item" @click="tiaozhuan(item)">
              <van-icon size="18" name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"
                        style="display:none"/>
              申请时间：<span class="item-text">{{item.sqsj}}</span>
            </div>
            <div class="info-item" @click="tiaozhuan(item)">
              <van-icon size="18" name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"
                        style="display:none"/>
              <span class="item-text">{{item.neighborhoodCommittee}}</span>
            </div>
            <div class="info-item" @click="tiaozhuan(item)">
              <van-icon size="18" name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"
                        style="display:none"/>
              <span class="item-text">{{item.sxdz}}</span>
            </div>
            <div class="info-item" @click="tiaozhuan(item)">
              <van-icon size="18" name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"
                        style="display:none"/>
              <span class="item-text">{{item.sfzh}}</span>
            </div>
          </ul>
          <van-button v-if="item.status == 0" type="info" disabled class="btn" size="mini"
                      :color="'#03cc00'"
                      style="font-size:16px;"
          >待评估
          </van-button>
          <van-button v-if="item.status == 5" type="info" disabled class="btn" size="mini"
                      :color="'#CC0033'"
                      style="font-size:16px;"
          >待确认
          </van-button>
          <van-button v-if="item.status == 2" type="info" disabled class="btn" size="mini"
                      :color="'#CCCC33'"
                      style="font-size:16px;"
          >待取消
          </van-button>
          <van-button v-if="item.status == 1 && item.isluru==1" type="info" disabled class="btn" size="mini"
                      :color="'#cc5b00'"
                      style="font-size:16px;"
          >已录入
          </van-button>
          <van-button v-if="item.status == 1 && item.isluru==0" type="info" disabled class="btn" size="mini"
                      :color="'#99008a'"
                      style="font-size:16px;"
          >已评估
          </van-button>
          <van-button v-if="item.pgdj!=0 && item.pgdj!=8" type="info" disabled class="btn" size="mini"
                      :color="'#0069ff'"
                      style="font-size:16px;"
                      >{{item.pgdj}}
          </van-button>
        </div>
      </div>
    </van-list>
    <!-- </van-pull-refresh> -->
    <van-dialog v-model="show"
                title="备注"
                :showCancelButton="true"

                :showConfirmButton="true"
                @confirm="confirm"
                @cancel="show = false"
    >
      <van-cell-group inset>
        <van-field v-model="newRemark" placeholder="请输入备注"/>
      </van-cell-group>
    </van-dialog>

    <van-dialog v-model="quxiaodingdan"
                title="取消原因"
                :showCancelButton="true"

                :showConfirmButton="true"
                @confirm="confirm2"
                @cancel="quxiaodingdan = false"
    >
      <van-cell-group inset>
        <van-field v-model="newRemark" placeholder="请输入取消原因"/>
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import { addRemark, cancelApplication, determiningUserRights, getformsListByOpenId } from '../../request/http'
import { Notify, Toast } from 'vant'

export default {
  components: {
    Notify,
    Toast
  },
  data () {
    return {
      quxiaodingdan: false,
      loading: false,
      abcd: false,
      pageLoading: false,
      remark: {},
      show: false,
      oldRemark: null,
      newRemark: null,
      username: '',
      active: 0,
      searchVal: '',
      // list: [1,2,3],
      isLoading: false,
      finished: false,
      isShow: true,
      page: 0,
      pageSize: 10,
      pageOld: 1,
      pageSizeOld: 10,
      total: 0,
      type: 0,
      list: [],
      name: null,
      formsId: null,
      isluru: 0,
      statusStr: null
    }
  },
  created () {
    const openid = this.$route.query.openId
    if (openid != null && openid != '') {
      localStorage.setItem('userOpenId', openid)
    }

    this.active = this.$route.query.active === undefined ? 0 : Number(this.$route.query.active)
  },
  mounted () {
    this.determiningUserRightsaa()
  },
  // 页面销毁
  beforeDestroy () {

  },
  watch: {
    // active(){
    //   // this.finished = false;
    // },
  },
  methods: {
    determiningUserRightsaa () {
      determiningUserRights({}).then(({ data }) => {
        if (data != null && data != undefined) {
          if (data.isLuru != null) {
            localStorage.setItem('isLuru', data.isLuru)
          }
          if (data.isPgy != null) {
            localStorage.setItem('isPgy', data.isPgy)
          }
          if (data.senUser != null) {
            localStorage.setItem('userId', data.senUser.id)
          }
          if (data.senUserdata != null) {
            localStorage.setItem('userId', data.senUserdata.id)
          }
          if (data.isAdmin != null) {
            localStorage.setItem('isAdmin', data.isAdmin)
          }
        }
        this.getList()
      })
    },
    formatTimer (value) {
      const date = new Date(value)
      const y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m
    },
    onLoad () {
      // console.log("触发onload")
      //  let isPgy = localStorage.getItem("isPgy");
      // let isLuru = localStorage.getItem("isLuru");
      // if(isPgy == "false" && isLuru  == "false"){
      //   return;
      // }
      this.statusStr = null
      this.type = null
      if (this.active == 0) {
        this.isluru = 0
        this.statusStr = '0,5,2'
      } else if (this.active == 1) {
        this.type = 1
        this.isluru = 0
      } else if (this.active == 2) {
        this.type = 1
        this.isluru = 1
      }
      // this.page = 1;
      this.pageSize = 10
      this.abcd = true
      this.page++
      // this.getList()
      setTimeout(() => {
        getformsListByOpenId({
          xm: this.name,
          status: this.type,
          pageNum: this.page,
          pageSize: this.pageSize,
          statusStr: this.statusStr,
          isluru: this.isluru
        }).then(({ data }) => {
          // console.log(this.page)
          // console.log(data.list.length)

          if (data.list.length != 0) {
            this.abcd = false
            this.list = [...this.list, ...data.list]
            // console.log(this.list.length)
          } else {
            this.abcd = false
            this.finished = true
          }
          this.total = data.total
          // console.log('total=' + this.total)
        })
      }, 1000)
    },
    // onRefresh () {
    //   this.getList();
    // },
    tiaozhuan (value) {
      if (value.status == 2) {
        return
      }
      this.$router.push({ path: '/HomeView', query: { id: value.id } })
    },
    confirm () {
      if ((this.newRemark == null || this.newRemark == '') || this.newRemark == this.oldRemark) {
        this.show = false
        return
      }
      this.remark.remark = this.newRemark
      addRemark(this.remark).then(({ data }) => {
        console.log(data)
      })
      this.getList()
    },
    confirm2 () {
      if ((this.newRemark == null || this.newRemark == '') || this.newRemark == this.oldRemark) {
        this.show = false
        return
      }
      this.remark.id = this.formsId
      this.remark.remark = this.newRemark
      cancelApplication({
        formsId: this.formsId,
        cancelreason: this.newRemark,
        canceltime: new Date().getTime() / 1000
      }).then(({ data }) => {
        Toast.success('取消成功')
        this.newRemark = null
        this.getList()
      })
    },
    cancel (id) {
      this.formsId = id
      this.quxiaodingdan = true
      // cancelApplication({formsId:id}).then(({data})=>{
      // Notify({ type: 'primary', message: '取消成功' });
      // this.getList();
      // })
    },
    addRemarks (item) {
      this.oldRemark = item.remark
      this.newRemark = item.remark
      this.remark.fid = item.id
      this.show = true
    },
    scrollEvent (e) {
      console.log('已经到最底部了')
    },
    updateType () {
      console.log(this.active)
      this.page = 0
      this.finished = false
      this.list = []
      this.onLoad()
    },
    timestampToTime (value, args) {
      if (value == null || value == '') {
        return
      }
      if (args == null || args == '') {
        args = 'yyyy-MM-dd HH:mm:ss'
      }
      const dt = new Date(value * 1000)
      if (args == 'yyyy-M-d') { // yyyy-M-d
        const year = dt.getFullYear()
        const month = dt.getMonth() + 1
        const date = dt.getDate()
        return `${year}-${month}-${date}`
      } else if (args == 'yyyy-M-d H:m:s') { // yyyy-M-d H:m:s
        const year = dt.getFullYear()
        const month = dt.getMonth() + 1
        const date = dt.getDate()
        const hour = dt.getHours()
        const minute = dt.getMinutes()
        const second = dt.getSeconds()
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`
      } else if (args == 'yyyy-MM-dd') { // yyyy-MM-dd
        const year = dt.getFullYear()
        const month = (dt.getMonth() + 1).toString().padStart(2, '0')
        const date = dt.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${date}`
      } else { // yyyy-MM-dd HH:mm:ss
        const year = dt.getFullYear()
        let month = (dt.getMonth() + 1).toString().padStart(2, '0')
        if (month.length > 2) {
          month = month.replace('0', '')
        }
        let date = dt.getDate().toString().padStart(2, '0')
        if (date.length > 2) {
          date = date.replace('0', '')
        }
        const hour = dt.getHours().toString().padStart(2, '0')
        const minute = dt.getMinutes().toString().padStart(2, '0')
        const second = dt.getSeconds().toString().padStart(2, '0')
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`
      }
    },
    getList () {
      // let isPgy = localStorage.getItem("isPgy");
      // let isLuru = localStorage.getItem("isLuru");
      // if(isPgy == "false" && isLuru  == "false"){
      //   return;
      // }
      this.pageLoading = true
      this.statusStr = null
      this.type = null
      if (this.active == 0) {
        this.isluru = 0
        this.statusStr = '0,5,2'
      } else if (this.active == 1) {
        this.type = 1
        this.isluru = 0
      } else if (this.active == 2) {
        this.type = 1
        this.isluru = 1
      }
      this.page = 1
      this.pageSize = 10
      const obj = {
        xm: this.name,
        status: this.type,
        pageNum: this.page,
        pageSize: this.pageSize,
        statusStr: this.statusStr,
        isluru: this.isluru
      }
      getformsListByOpenId(obj).then(({ data }) => {
        this.list = data.list
        this.total = data.total
        this.isLoading = false
      }).finally(_ => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .index-container
    height: 100%;
    background-color: #f3f3f3;

    .van-pull-refresh
      height: calc(100% - 94px);
      overflow: auto;

    .card-wrap
      width: 95%;
      margin: 0.5rem auto;
      border-top: 5px solid orange;
      border-radius: 5px;

    .card
      position: relative;
      padding: .10rem;
      border-top: 3px solid var(--van-danger-color);
      background-color: #fff;

      .info-list
        font-size: 16px;

        .info-item
          // display: flex;
          align-items: center;
          padding: 0 0.8rem;
          // line-height: .3rem;

          .van-icon
            margin-right: .05rem;

      .btn
        position: absolute;
        top: .15rem;
        border-radius: 10px 0px 10px 00px;
        right: .15rem;

</style>
