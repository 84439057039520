import axios from 'axios'
import { Toast } from 'vant'
/*
  * Request(): 请求后台数据，返回成功后，执行callback
  * url: 请求的半路径
  * params: { data：请求参数，methods：请求方式，callback：回调方法 }
*/
export const Request = (url = '', params = {}, responseType = '') => {
  const responseTypes = 'json'
  let contentType = 'application/json'
  let requestData = params.data
  if (responseType == 'blob') {
    requestData = params.data
    contentType = 'multipart/form-data'
  }
  if (params.methods == 'GET') {
    return new Promise((resolve, reject) => {
      axios.get(url, {
        responseType: responseTypes, // Json, blob,
        params: Object.assign(params.data, {}),
        timeout: 600000,
        timeoutErrorMessage: '超时，网络环境受限'
      }).then((res) => {
        return resolve(res.data)
      }).catch(err => {
        if (err.message === 'Network Error') {
          Toast.fail(err.config.timeoutErrorMessage)
          return reject(err.config.timeoutErrorMessage)
        }
        Toast.fail('出问题啦，请联系管理员')
        console.log(err)
        return reject(err)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: 'post',
        responseType: responseTypes, // Json, blob,
        data: requestData,
        headers: {
          'Content-Type': contentType
        },
        timeout: 600000,
        timeoutErrorMessage: '超时，网络环境受限'
      }).then(res => {
        return resolve(res.data)
      }).catch(err => {
        if (err.message === 'Network Error') {
          Toast.fail(err.config.timeoutErrorMessage)
          return reject(err.config.timeoutErrorMessage)
        }
        Toast.fail('出问题啦，请联系管理员')
        console.log(err)
        return reject(err)
      })
    })
  }
}
export function distinct (list = [], distinctValue = '', field = '') {
  if ((list == null || list.length == 0) || (distinctValue == null || distinctValue == '') || field == null || field == '') {
    return false
  }
  for (let i = 0; i < list.length; i++) {
    if (list[i][field] == distinctValue) {
      return true
    }
  }
  return false
}
export function distinctList (list = [], distinctValue = [], field = '') {
  if ((list == null || list.length == 0) || (distinctValue == null || distinctValue.length == 0) || field == null || field == '') {
    return []
  }
  const Fields = list.map(item => item[field])
  const arr = []
  distinctValue.forEach((item, index) => {
    if (Fields.includes(item)) {
      arr.push(item)
    }
  })
  return arr
}
export function getJointContributionList (data) {
  return Request('topTenReport/getJointContributionList', { methods: 'POST', data }, 'json')
}
export function downloadExcel (data) {
  return Request('topTenReport/downloadExcel', { methods: 'POST', data }, 'blob')
}

export function getNoJointContributionList (data) {
  return Request('topTenReport/getNoJointContributionList', { methods: 'POST', data }, 'json')
}

export function noJointDownloadExcel (data) {
  return Request('topTenReport/noJointDownloadExcel', { methods: 'POST', data }, 'blob')
}

export function getLoanPressIndustryList (data) {
  return Request('assetsLiabilitiesReport/getLoanPressIndustryList', { methods: 'POST', data }, 'json')
}

export function loanPressDownloadExcel (data) {
  return Request('assetsLiabilitiesReport/loanPressDownloadExcel', { methods: 'POST', data }, 'blob')
}

export function getLoanDetailList (data) {
  return Request('assetsLiabilitiesReport/getLoanDetailList', { methods: 'POST', data }, 'json')
}

export function loanDetaildownloadExcel (data) {
  return Request('assetsLiabilitiesReport/loanDetaildownloadExcel', { methods: 'POST', data }, 'blob')
}
// 商业银行互联网贷款基本情况表-贷款余额概况表 -- 查询
export function getBalanceSurveyList (data) {
  return Request('commercialBankLoanReport/getBalanceSurveyList', { methods: 'POST', data }, 'json')
}
// 商业银行互联网贷款基本情况表-贷款余额概况表 -- 下载
export function variousDownloadExcel (data) {
  return Request('commercialBankLoanReport/variousDownloadExcel', { methods: 'POST', data }, 'blob')
}

// 商业银行互联网贷款基本情况表-贷款余额明细表 -- 查询
export function getBalanceDetailList (data) {
  return Request('commercialBankLoanReport/getBalanceDetailList', { methods: 'POST', data }, 'json')
}
// 商业银行互联网贷款基本情况表-贷款余额明细表 -- 下载
export function pressBalanceDownloadExcel (data) {
  return Request('commercialBankLoanReport/pressBalanceDownloadExcel', { methods: 'POST', data }, 'blob')
}

// 商业银行互联网贷款基本情况表-贷款五级分类表 -- 查询
export function getFiveLevelsList (data) {
  return Request('commercialBankLoanReport/getFiveLevelsList', { methods: 'POST', data }, 'json')
}
// 商业银行互联网贷款基本情况表-贷款五级分类表 -- 下载
export function fiveDownloadExcel (data) {
  return Request('commercialBankLoanReport/fiveDownloadExcel', { methods: 'POST', data }, 'blob')
}

// 商业银行互联网贷款基本情况表-逾期贷款明细表 -- 查询
export function getOverdueLoanList (data) {
  return Request('commercialBankLoanReport/getOverdueLoanList', { methods: 'POST', data }, 'json')
}
// 商业银行互联网贷款基本情况表-逾期贷款明细表 -- 下载
export function pressOverdueDownloadExcel (data) {
  return Request('commercialBankLoanReport/pressOverdueDownloadExcel', { methods: 'POST', data }, 'blob')
}

// 商业银行互联网贷款基本情况表-担保明细表 -- 查询
export function getGuaranteeDetailList (data) {
  return Request('commercialBankLoanReport/getGuaranteeDetailList', { methods: 'POST', data }, 'json')
}
// 商业银行互联网贷款基本情况表-担保明细表 -- 下载
export function commercialBankLoanReportExcel (data) {
  return Request('commercialBankLoanReport/getGuaranteeDownloadExcel', { methods: 'POST', data }, 'blob')
}

// 商业银行互联网贷款基本情况表-按合同期限明细表 -- 查询
export function getPressContractPeriodList (data) {
  return Request('commercialBankLoanReport/getPressContractPeriodList', { methods: 'POST', data }, 'json')
}
// 商业银行互联网贷款基本情况表-按合同期限明细表 -- 下载
export function pressContractDownloadExcel (data) {
  return Request('commercialBankLoanReport/pressContractDownloadExcel', { methods: 'POST', data }, 'blob')
}

// 商业银行互联网贷款基本情况表-按借款人年龄明细表 -- 查询
export function getPressAgeList (data) {
  return Request('commercialBankLoanReport/getPressAgeList', { methods: 'POST', data }, 'json')
}
// 商业银行互联网贷款基本情况表-按借款人年龄明细表-- 下载
export function pressAgeDownloadExcel (data) {
  return Request('commercialBankLoanReport/pressAgeDownloadExcel', { methods: 'POST', data }, 'blob')
}

// 贷款质量五级分类情况表-各项贷款明细 -- 查询
export function getVariousLoansListFive (data) {
  return Request('loanQualityFiveLeveReport/getVariousLoansList', { methods: 'POST', data }, 'json')
}
// 贷款质量五级分类情况表-各项贷款明细-- 下载
export function fiveDownloadExcelFive (data) {
  return Request('loanQualityFiveLeveReport/fiveDownloadExcel', { methods: 'POST', data }, 'blob')
}

// 贷款质量五级分类情况表-逾期贷款明细 -- 查询
export function getOverdueLoanListFive (data) {
  return Request('loanQualityFiveLeveReport/getOverdueLoanList', { methods: 'POST', data }, 'json')
}
// 贷款质量五级分类情况表-逾期贷款明细-- 下载
export function pressOverdueDownloadExcelFive (data) {
  return Request('loanQualityFiveLeveReport/pressOverdueDownloadExcel', { methods: 'POST', data }, 'blob')
}

// 资产负债项目统计表-各项贷款明细 -- 查询
export function getLoansDetailsList (data) {
  return Request('assetsLiabilitiesReport/getVariousLoansList', { methods: 'POST', data }, 'json')
}
// 贷款质量五级分类情况表-逾期贷款明细-- 下载
export function pressOverdueDownloadExcelInfo (data) {
  return Request('assetsLiabilitiesReport/fiveDownloadExcel', { methods: 'POST', data }, 'blob')
}

// 资产负债项目统计表-各项贷款明细 -- 查询
export function getOverdueLoanListInfo (data) {
  return Request('assetsLiabilitiesReport/getOverdueLoanList', { methods: 'POST', data }, 'json')
}
// 贷款质量五级分类情况表-逾期贷款明细-- 下载
export function pressOverdueDownloadExcelInfoA (data) {
  return Request('assetsLiabilitiesReport/overdueDownloadExcel', { methods: 'POST', data }, 'blob')
}

// 资产负债项目统计表-各项贷款明细 -- 查询
export function getLoanDetailListB (data) {
  return Request('bankAndGuaranteeCompanyReport/getLoanDetailList', { methods: 'POST', data }, 'json')
}
// 资产负债项目统计表-各项贷款明细-- 下载
export function pressBalanceDownloadExcelB (data) {
  return Request('bankAndGuaranteeCompanyReport/pressBalanceDownloadExcel', { methods: 'POST', data }, 'blob')
}

// 资产负债项目统计表-各项贷款明细 -- 查询
export function getLoanSurveyListt (data) {
  return Request('bankAndGuaranteeCompanyReport/getLoanSurveyList', { methods: 'POST', data }, 'json')
}
// 贷款质量五级分类情况表-逾期贷款明细-- 下载
export function pressSurveyDownloadExcel (data) {
  return Request('bankAndGuaranteeCompanyReport/pressSurveyDownloadExcel', { methods: 'POST', data }, 'blob')
}

// 渠道管理
export function getqueryCompanyInformationList (data) {
  return Request('channel/getqueryCompanyInformationList', { methods: 'POST', data }, 'json')
}
// 渠道管理模块-查看公司基本信息表
export function companyInformationList (data) {
  return Request('channel/CompanyInformationList', { methods: 'POST', data }, 'json')
}
// 渠道管理模块-查看公司基本信息表
export function addCompanyInformationList (data) {
  return Request('channel/addCompanyInformationList', { methods: 'POST', data }, 'json')
}
// 渠道管理模块-修改公司基本信息表
export function updateCompanyInformationList (data) {
  return Request('channel/updateCompanyInformationList', { methods: 'POST', data }, 'json')
}
// 渠道管理模块-合作状态按钮
export function updateState (data) {
  return Request('channel/updateState', { methods: 'POST', data }, 'json')
}
export function OperationHistoryList (data) {
  return Request('channel/getOperationHistoryList', { methods: 'POST', data }, 'json')
}

// 字典项查询
export function getDict (data) {
  return Request('dict/getDict', { methods: 'GET', data }, 'json')
}

export function getStreetInfo (data) {
  return Request('officialAccounts/street/getStreetInfo', { methods: 'Post', data }, 'json')
}

export function updateStreetInfo (data) {
  return Request('officialAccounts/street/updateStreetInfo/' + localStorage.getItem('userOpenId'), { methods: 'Post', data }, 'json')
}

export function getUserInfoByOpenId (data) {
  return Request('officialAccounts/user/getUserInfoByOpenId/' + localStorage.getItem('userOpenId'), { methods: 'Post', data }, 'json')
}

export function getformsListByOpenId (data) {
  return Request('officialAccounts/Forms/getformsListByOpenId/' + localStorage.getItem('userOpenId'), { methods: 'Post', data }, 'json')
  // return Request("officialAccounts/Forms/getformsListByOpenId/"+"o9giU5_IuUg0qg8YDzb1aL1xQsLM", { methods: 'Post', data }, 'json')
}

export function cancelApplication (data) {
  return Request('officialAccounts/Forms/cancelApplication/' + localStorage.getItem('userOpenId'), { methods: 'GET', data }, 'json')
}

export function addRemark (data) {
  return Request('officialAccounts/Forms/addRemark/' + localStorage.getItem('userOpenId'), { methods: 'POST', data }, 'json')
}

export function getformsInfoById (data) {
  return Request('officialAccounts/Forms/getformsInfoById/' + localStorage.getItem('userOpenId'), { methods: 'GET', data }, 'json')
}

export function uploadImage (data) {
  return Request('public/forms/image/upload/file', { methods: 'POST', data }, 'blob')
}
export function uploadImageNew (data) {
  return Request('public/forms/image/uploadNew/file', { methods: 'POST', data }, 'blob')
}

export function updaeImage (data) {
  return Request('officialAccounts/Forms/updateImage', { methods: 'POST', data }, 'json')
}

export function getformsParticularsById (data) {
  return Request('officialAccounts/Forms/getformsParticularsById', { methods: 'GET', data }, 'json')
}

export function addFormsVals (data) {
  return Request('officialAccounts/Forms/addFormsVals/' + localStorage.getItem('userOpenId'), { methods: 'POST', data }, 'json')
}
export function addFormsValsNew (data) {
  return Request('officialAccounts/Forms/addFormsValsNew/' + localStorage.getItem('userOpenId'), { methods: 'POST', data }, 'json')
}

export function updateFormsInfo (data) {
  return Request('officialAccounts/Forms/updateFormsInfo', { methods: 'POST', data }, 'json')
}
export function submitA (data) {
  return Request('officialAccounts/Forms/submitA/' + localStorage.getItem('userOpenId'), { methods: 'GET', data }, 'json')
}

export function submitB (data) {
  return Request('officialAccounts/Forms/submitB/' + localStorage.getItem('userOpenId'), { methods: 'GET', data }, 'json')
}
export function determiningUserRights (data) {
  return Request('officialAccounts/user/determiningUserRights/' + localStorage.getItem('userOpenId'), { methods: 'GET', data }, 'json')
}
