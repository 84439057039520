import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'
import assessment from '../views/assessment.vue'
import assessmentCopy from '../views/assessmentCopy.vue'

import homePage from '../views/index/homePage.vue'
import evaluatorBinding from '../views/index/evaluatorBinding.vue'

import pilotEvaluate from '../views/index/pilotEvaluate.vue'

import tiaozhuan from '../views/index/tiaozhuan.vue'

// import evaluatorBinding from '../views/evaluatorBinding.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'pilotEvaluate',
    component: pilotEvaluate
  },
  {
    path: '/assessment',
    name: 'assessment',
    component: assessment
  },
  {
    path: '/HomeView',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/homePage',
    name: 'homePage',
    component: homePage
  },
  {
    path: '/evaluatorBinding',
    name: 'evaluatorBinding',
    component: evaluatorBinding
  },
  {
    path: '/pilotEvaluate',
    name: 'pilotEvaluate',
    component: pilotEvaluate
  },
  {
    path: '/assessmentCopy',
    name: 'assessmentCopy',
    component: assessmentCopy
  },
  {
    path: '/tiaozhuan',
    name: 'tiaozhuan',
    component: tiaozhuan
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
