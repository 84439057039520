<template>
  <div class="container">
      <div class="success-info">
          <van-icon name="success" size="50" color="green" />
          <p class="success-text">绑定成功</p>
          <p class="success-link">
              <span @click="tijiao"><router-link to="" >《修改绑定信息</router-link></span>
              &nbsp;
              <span @click="deleteStreet"><router-link to="" >查看评估》</router-link></span>
          </p>
      </div>
      <div class="user-info">
      </div>
  </div>
</template>

<script>
import {determiningUserRights
} from "../../request/http";
export default {
    created(){
        let openid = this.$route.query.openId;
        if(openid!=null && openid!=""){
            localStorage.setItem("userOpenId",openid);
        }
    },
      mounted() {
        this.determiningUserRights();
    },
methods: {
    determiningUserRights(){
        determiningUserRights({}).then(({data})=>{
            if(data!=null && data !=undefined){
                if(data.isLuru != null){
                    localStorage.setItem("isLuru",data.isLuru);
                }
                if(data.isPgy != null){
                    localStorage.setItem("isPgy",data.isPgy);
                }
                if(data.senUser != null){
                    localStorage.setItem("userId",data.senUser.id);
                }
                if(data.senUserdata != null){
                    localStorage.setItem("userId",data.senUserdata.id);
                }
            }
        })
    },
    tijiao(){
        this.$router.push({ path: '/evaluatorBinding'})
    },
    deleteStreet(){
       this.$router.push({ path: '/homePage'})
    }
  }
}
</script>

<style scoped lang="stylus">
.container {
    height: 100%;

    .success-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
        
        .success-text {
            margin: 1rem 0;
            color: #999;
        }

        .success-link {
            color: blue;
        }
    }

    .user-info {
        padding: 0 1rem;
        color: #666;
    }
}
</style>