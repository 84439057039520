import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import moment from 'moment'

// import 'vue-video-player/src/custom-theme.css'
// import 'video.js/dist/video-js.css'
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

// 全局方法挂载
Vue.prototype.$moment = moment
Vue.filter('dateFormat', (str, partten = 'YYYY-MM-DD HH:mm:ss') => {
  if (str === 0 || str === '' || str === null) {
    return ''
  }
  return moment(str).format(partten)
})
Vue.filter('timestampFormat', (str, partten = 'YYYY-MM-DD HH:mm:ss') => {
  if (str === 0 || str === '' || str === null) {
    return ''
  }
  return moment.unix(str).format(partten)
})
Vue.filter('timestampFormatDate', (str, partten = 'YYYY-MM-DD') => {
  if (str === 0 || str === '' || str === null) {
    return ''
  }
  return moment.unix(str).format(partten)
})
Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(ElementUI)

// 云养
localStorage.setItem('system', '云养')
axios.interceptors.request.use(function (config) {
  const r1 = config.url.startsWith('/')
  if (r1) {
    config.url = 'https://yumyoung.com/api' + config.url
    // config.url = 'http://localhost:38080/' + config.url
  } else {
    config.url = 'https://yumyoung.com/api/' + config.url
    // config.url = 'http://localhost:38080/' + config.url
  }
  return config
}, function (error) {
  return Promise.reject(error)
})
// 领航
// axios.interceptors.request.use(function (config) {
//   const r1 = config.url.startsWith('/')
//   if (r1) {
//     console.log(config.url)
//     config.url = 'http://lh.yumyoung.com/api' + config.url
//     // config.url = 'http://localhost:38080/' + config.url
//     console.log(config.url)
//   } else {
//     config.url = 'http://lh.yumyoung.com/api/' + config.url
//     // config.url = 'http://localhost:38080/' + config.url
//   }
//   return config
// }, function (error) {
//   return Promise.reject(error)
// })

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
