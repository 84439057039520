<template>
  <div class="Assessment">
    <van-tabs :active="active" color="skyblue" swipe-threshold="4" sticky @click="updateActive">
      <van-tab style="padding: 15px 0" title="诚信/满意度">
        <div class="goodBox">
          <div>评估对象姓名：{{value.xm}}</div>
          <div>身份证号码：{{value.sfzh}}</div>
          <div>评估日期：{{value.pgdate}}</div>
          <div class="upimg">
            <div class="imgli">
              <van-uploader disabled v-model="image1" multiple :max-count="1" accept="image/*"
                            :after-read="uploadFfile"/>
              <div class="imgli-title">评估对象照片</div>

            </div>
            <div class="imgli">
              <van-uploader disabled v-model="image2" multiple :max-count="1" accept="image/*"
                            :after-read="uploadFfile2"/>
              <div class="imgli-title">评估对象签名</div>

            </div>
            <div class="imgli">
              <van-uploader disabled v-model="image3" multiple :max-count="1" accept="image/*"
                            :after-read="uploadFfile3"/>
              <div class="imgli-title">病例、药物等记录</div>

            </div>
            <div class="imgli">
              <van-uploader disabled v-model="image4" multiple :max-count="1" accept="image/*"
                            :after-read="uploadFfile4"/>
              <div class="imgli-title">病例、药物等记录</div>
            </div>
            <div class="imgli">
              <van-uploader disabled v-model="image5" multiple :max-count="1" accept="video/*"
                            :after-read="uploadFfile5"/>
              <div class="imgli-title">上半身活动情况</div>

              <div class="ck" @click="isBof(image5[0].url)">查看</div>
            </div>
            <div class="imgli">
              <van-uploader disabled v-model="image6" multiple :max-count="1" accept="video/*"
                            :after-read="uploadFfile6"/>
              <div class="imgli-title">下半身活动情况（坐立行）</div>

              <div class="ck" @click="isBof(image6[0].url)">查看</div>
            </div>
          </div>
        </div>
        <div class="goodBox">
          <div>
            1、本调查表用于本市长期护理险试点过程中对老年医疗护理服务申请者进行需求评估。
          </div>
          <div>2、本调查所获得的信息仅作为本市长期护理险需求评级的基础。</div>
            3、为了反映被调查者的真实情况，各个调查项目结果的填写均基于被调查者相应动作的确认、被调查者自身的回答、家属和/或身边照护人员所反映的被调查者日常生活的一贯情况以及专门调查员的现场观察。
          </div>
        <div>
        <div>
            4、调查回答项中，“□”表示备选项可多选，请打“√”表示选择；“①”等表示选项为单选，只能选择一项，请打“√”表示选择；用下划线留出空栏时，请填写合适的内容。
          </div>
          <div>5、关于发生频数的选项，请选择最接近实际的选择项。</div>
          <div>
            6、关于金额、次数等项目，如无精确数字，请估计一个合适的值填写。
          </div>
          <div>7、本调查全过程约需要三十分钟。</div>
          <div>8、本调查表所涉及的隐私受到法律保护。</div>
          <div>9、本调查表由认定的评估机构指派专门调查员完成。</div>
        </div>
      </van-tab>
      <van-tab style="padding: 0px 3% 60px 3%" title="居家信息">
        <!-- <div class="tianxie"> -->
        <div id="F12456" class="first"></div>
        <div class="Q">
          <van-field disabled
                     :label-class="isNotNull(collect.F12456)?'':'fieldF11673'"
                     v-model="collect.F12456"
                     label="协助评估人员姓名"
                     label-width="8rem"
                     placeholder="..."
                     input-align="right"
          />
        </div>
        <div id="F12451" class="first"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12451)?'':'color:red'">
            申请目的(请选择日常生活中碰到的困难，最需要接受帮助的项目；最多选择4项)
          </div>
          <div class="q-content">
            <van-checkbox-group disabled
                                v-model="collect.F12451"
                                direction="horizontal"
                                :max="4"
            >
              <van-checkbox class="checkbox" shape="square" :name="1" v-if="isShow(collect.F12451,1)"
              >协助进食
              </van-checkbox
              >
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12451,2)"
              >排泄照护
              </van-checkbox
              >
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12451,3)"
              >穿衣洗漱
              </van-checkbox
              >
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12451,4)"
              >身体洗浴
              </van-checkbox
              >
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12451,5)"
              >翻身坐起
              </van-checkbox
              >
              <van-checkbox :name="6" class="checkbox" shape="square" v-if="isShow(collect.F12451,6)"
              >室内行立
              </van-checkbox
              >
              <van-checkbox :name="7" class="checkbox" shape="square" v-if="isShow(collect.F12451,7)"
              >室外移动
              </van-checkbox
              >
              <van-checkbox :name="8" class="checkbox" shape="square" v-if="isShow(collect.F12451,8)"
              >聊天解闷
              </van-checkbox
              >
              <van-checkbox :name="9" class="checkbox" shape="square" v-if="isShow(collect.F12451,9)"
              >煮菜热饭
              </van-checkbox
              >
              <van-checkbox :name="10" class="checkbox" shape="square" v-if="isShow(collect.F12451,10)"
              >衣物清洗
              </van-checkbox
              >
              <van-checkbox :name="11" class="checkbox" shape="square" v-if="isShow(collect.F12451,11)"
              >房间整理
              </van-checkbox
              >
              <van-checkbox :name="12" class="checkbox" shape="square" v-if="isShow(collect.F12451,12)"
              >代办服务
              </van-checkbox
              >
              <van-checkbox :name="13" class="checkbox" shape="square" v-if="isShow(collect.F12451,13)"
              >陪同就医
              </van-checkbox
              >
              <van-checkbox :name="14" class="checkbox" shape="square" v-if="isShow(collect.F12451,14)"
              >代为配药
              </van-checkbox
              >
              <van-checkbox :name="15" class="checkbox" shape="square" v-if="isShow(collect.F12451,15)"
              >提供辅助工具
              </van-checkbox
              >
              <van-checkbox :name="16" class="checkbox" shape="square" v-if="isShow(collect.F12451,16)"
              >提供居家改造
              </van-checkbox
              >
              <van-checkbox :name="17" class="checkbox" shape="square" v-if="isShow(collect.F12451,17)"
              >疾病治疗
              </van-checkbox
              >
              <van-checkbox :name="18" class="checkbox" shape="square" v-if="isShow(collect.F12451,18)"
              >医疗护理
              </van-checkbox
              >
              <van-checkbox :name="19" class="checkbox" shape="square" v-if="isShow(collect.F12451,19)"
              >医疗康复
              </van-checkbox
              >
              <van-checkbox
                :name="20"
                class="checkbox"
                v-if="isShow(collect.F12451,20)"
                shape="square"
              >异常行为监管，安全
              </van-checkbox
              >
              <van-checkbox :name="21" class="checkbox" shape="square" v-if="isShow(collect.F12451,21)"
              >白天上门照护
              </van-checkbox
              >
              <van-checkbox :name="22" class="checkbox" shape="square" v-if="isShow(collect.F12451,22)"
              >夜间上门照护
              </van-checkbox
              >
              <van-checkbox :name="23" class="checkbox" shape="square" v-if="isShow(collect.F12451,23)"
              >白天机构照护
              </van-checkbox
              >
              <van-checkbox :name="24" class="checkbox" shape="square" v-if="isShow(collect.F12451,24)"
              >夜间机构照护
              </van-checkbox
              >
              <van-checkbox :name="25" class="checkbox" shape="square" v-if="isShow(collect.F12451,25)"
              >住养老院
              </van-checkbox
              >
              <van-checkbox :name="26" class="checkbox" shape="square" v-if="isShow(collect.F12451,26)"
              >住护理院
              </van-checkbox
              >
            </van-checkbox-group>
          </div>
        </div>
        <div id="F12452" class="first"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F12452)?'':'color:red'">平时使用的辅助工具</div>
          <div class="q-content">
            <van-checkbox-group
              disabled
              v-model="collect.F12452"
              direction="horizontal"
            >
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12452,1)"
              >眼镜
              </van-checkbox
              >
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12452,2)"
              >放大镜
              </van-checkbox
              >
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12452,3)"
              >拐杖
              </van-checkbox
              >
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12452,4)"
              >助步器
              </van-checkbox
              >
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12452,5)"
              >轮椅
              </van-checkbox
              >
              <van-checkbox :name="6" class="checkbox" shape="square" v-if="isShow(collect.F12452,6)"
              >假肢
              </van-checkbox
              >
              <van-checkbox :name="7" class="checkbox" shape="square" v-if="isShow(collect.F12452,7)"
              >假牙
              </van-checkbox
              >
              <van-checkbox :name="8" class="checkbox" shape="square" v-if="isShow(collect.F12452,8)"
              >助听器
              </van-checkbox
              >
              <van-checkbox :name="9" class="checkbox" shape="square" v-if="isShow(collect.F12452,9)"
              >尿/便垫
              </van-checkbox
              >
              <van-checkbox :name="10" class="checkbox" shape="square" v-if="isShow(collect.F12452,10)"
              >造瘘术后便袋
              </van-checkbox
              >
              <van-checkbox :name="11" class="checkbox" shape="square" v-if="isShow(collect.F12452,11)"
              >防褥疮床垫
              </van-checkbox
              >
              <van-checkbox :name="12" class="checkbox" shape="square" v-if="isShow(collect.F12452,12)"
              >防跌倒鞋
              </van-checkbox
              >
              <van-checkbox :name="13" class="checkbox" shape="square" v-if="isShow(collect.F12452,13)"
              >食物粉碎机
              </van-checkbox
              >
              <van-checkbox :name="14" class="checkbox" shape="square" v-if="isShow(collect.F12452,14)"
              >其他
              </van-checkbox
              >
            </van-checkbox-group>
          </div>
        </div>
        <div class="Q" v-if="include(collect.F12452,14)">
          <van-field disabled
                     v-model="collect.F12453"
                     :label-class="isNotNull(collect.F12453)?'':'fieldF12453'"
                     label="其他"
                     placeholder="..."
                     input-align="right"
          />
        </div>
        <!-- 1 -->
        <div id="F11663" class="first"></div>
        <div class="Q">
          <van-field disabled
                     v-model="collect.F11663"
                     :label-class="isNotNull(collect.F11663)?'':'fieldF11673'"
                     label="1. 姓名"
                     placeholder="..."
                     input-align="right"
          />
        </div>
        <div id="F11664" class="first"></div>
        <!-- 2 -->
        <div class="Q" ref="F11664">
          <van-field disabled name="checkboxGroup" label="2. 性别"
                     :label-class="isNotNull(collect.F11664)?'':'fieldF11673'">
            <template #input>
              <van-radio-group v-model="collect.F11664" direction="horizontal">
                <van-radio :name="1" v-if="isShow(collect.F11664,1)">男</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11664,2)">女</van-radio>
              </van-radio-group>

            </template>
          </van-field>
        </div>
        <!-- 3 -->
        <div id="F11665" class="first"></div>
        <div class="Q">
          <van-field disabled
                     :label-class="isNotNull(collect.F11665)?'':'fieldF11673'"
                     name="checkboxGroup"
                     v-model="collect.F11665"
                     label="3. 年龄"
                     type="number"

                     placeholder="..."
                     input-align="right"
          >
          </van-field>
        </div>
        <!-- 4 -->
        <div id="F11666" class="first"></div>
        <div class="Q">
          <van-field disabled
                     v-model="collect.F11666"
                     :label-class="isNotNull(collect.F11666)?'':'fieldF11673'"
                     name="checkboxGroup"
                     label="4. 身高"
                     type="number"

                     placeholder="...(cm)"
                     input-align="right"
          >
          </van-field>
        </div>
        <!-- 5 -->
        <div id="F11667" class="first"></div>
        <div class="Q">
          <van-field disabled
                     :label-class="isNotNull(collect.F11667)?'':'fieldF11673'"
                     v-model="collect.F11667"
                     name="checkboxGroup"
                     label="5. 体重"

                     type="number"
                     placeholder="...(kg)"
                     input-align="right"
          >
          </van-field>
        </div>
        <!-- 6 -->
        <div id="F11668" class="first"></div>
        <div class="Q">
          <van-field disabled
                     :label-class="isNotNull(collect.F11668)?'':'fieldF11673'"
                     v-model="collect.F11668"
                     name="checkboxGroup"

                     label="6. 民族"
                     placeholder="..."
                     input-align="right"
          >
          </van-field>
        </div>
        <!-- 7 -->
        <div id="F11673" class="first"></div>
        <div class="Q" ref="F11673">
          <van-field disabled
                     :label-class="isNotNull(collect.F11673)?'':'fieldF11673'"
                     name="checkboxGroup"
                     label="7. 是否为“失独”家庭"
                     label-color="red"
                     label-width="8rem"
                     input-align="right"
          >
            <template #input>
              <van-radio-group v-model="collect.F11673" direction="horizontal">
                <van-radio :name="1" v-if="isShow(collect.F11673,1)">否</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11673,2)">是</van-radio>
                <van-radio :name="3" v-if="isShow(collect.F11673,3)">其他</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div id="F11674" class="first"></div>
        <!-- 8 -->
        <div class="Q">
          <div class="q-title">
            8.文化程度
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F11674">
              <van-radio class="checkbox" :name="1" v-if="isShow(collect.F11674,1)">文盲</van-radio>
              <van-radio class="checkbox" :name="2" v-if="isShow(collect.F11674,2)">小学</van-radio>
              <van-radio class="checkbox" :name="3" v-if="isShow(collect.F11674,3)">初中</van-radio>
              <van-radio class="checkbox" :name="4" v-if="isShow(collect.F11674,4)">高中</van-radio>
              <van-radio class="checkbox" :name="5" v-if="isShow(collect.F11674,5)">大学及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 9 -->
        <div id="F11675" class="first"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F11675)?'':'color:red'">9.主要经济来源</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F11675">
              <van-radio class="checkbox" :name="1" v-if="isShow(collect.F11675,1)">退休金</van-radio>
              <van-radio class="checkbox" :name="2" v-if="isShow(collect.F11675,2)">子女补贴</van-radio>
              <van-radio class="checkbox" :name="3" v-if="isShow(collect.F11675,3)">亲友资助</van-radio>
              <van-radio class="checkbox" :name="4" v-if="isShow(collect.F11675,4)">其他补贴</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 10 -->
        <div id="F11678" class="first"></div>
        <div class="Q">
          <div class="q-title" :style="isNotNull(collect.F11678)?'':'color:red'">10.居住地址
          </div
          >
          <div class="q-title1">
            <input disabled class="q-input" v-model="collect.F11678" type="text" placeholder="...">省（市）
            <input disabled class="q-input" v-model="collect.F11679" type="text" placeholder="...">区（县）
            <input disabled class="q-input" v-model="collect.F11680" type="text" placeholder="...">街道（镇）
            <input disabled class="q-input" v-model="collect.F11681" type="text" placeholder="...">（村）委
            <input disabled class="q-input" v-model="collect.F11682" type="text" placeholder="...">小区
            <input disabled class="q-input" v-model="collect.F11683" type="text" placeholder="...">路
            <input disabled class="q-input" v-model="collect.F11684" type="text" placeholder="...">弄
            <input disabled class="q-input" v-model="collect.F11685" type="text" placeholder="...">号
            <input disabled class="q-input" v-model="collect.F11686" type="text" placeholder="...">室
          </div>
        </div>
        <!-- 11 -->
        <div id="F11687" class="first"></div>
        <div class="Q">
          <div class="q-title">11.现住场所</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F11687">
              <van-radio class="checkbox" :name="1" v-if="isShow(collect.F11687,1)">自有产权房，私房等</van-radio>
              <van-radio class="checkbox" :name="2" v-if="isShow(collect.F11687,2)">各类租赁房等</van-radio>
              <van-radio class="checkbox" :name="3" v-if="isShow(collect.F11687,3)">养老院或类似机构</van-radio>
              <van-radio class="checkbox" :name="4" v-if="isShow(collect.F11687,4)">护理院或类似机构</van-radio>
              <van-radio class="checkbox" :name="5" v-if="isShow(collect.F11687,5)">社区卫生服务中心</van-radio>
              <van-radio class="checkbox" :name="6" v-if="isShow(collect.F11687,6)">医院或类似机构</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div id="F11688" class="first"></div>
        <!-- 12 -->
        <div class="Q">
          <van-field disabled
                     name="checkboxGroup"
                     label="12. 语言使用"
                     input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F11688"
                direction="horizontal"
                shape="shape"

              >
                <van-radio :name="1" v-if="isShow(collect.F11688,1)">使用语言</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11688,2)">不使用语言等</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div id="F11689" class="first" v-if="collect.F11688 === 1"></div>
        <div class="Q" v-if="collect.F11688 === 1">
          <van-field disabled
                     name="checkboxGroup"
                     label="使用语言"
                     input-align="right"
          >
            <template #input>
              <van-checkbox-group
                v-model="collect.F11689"
                direction="horizontal"
                shape="shape"

              >
                <van-checkbox :name="1" shape="square" v-if="isShow(collect.F11689,1)">普通话</van-checkbox>
                <van-checkbox shape="square" :name="2" v-if="isShow(collect.F11689,2)">方言</van-checkbox>
              </van-checkbox-group>
            </template>
          </van-field>
        </div>
        <div class="Q" v-if="include(collect.F11689,2) && collect.F11688 == 1">
          <div id="F11690" class="first"></div>
          <van-field disabled
                     v-model="collect.F11690"
                     name="checkboxGroup"
                     label="方言"
                     input-align="right"
          >
          </van-field>
        </div>
        <!-- 13 -->
        <div id="F12431" class="first"></div>
        <div class="Q">
          <div class="q-title">13.饮食习惯</div>
          <div class="q-content">
            <van-checkbox-group disabled v-model="collect.F12431">
              <van-checkbox class="checkbox" :name="1" shape="square" v-if="isShow(collect.F12431,1)">素食</van-checkbox>
              <van-checkbox class="checkbox" shape="square" :name="2" v-if="isShow(collect.F12431,2)">清蒸</van-checkbox>
              <van-checkbox class="checkbox" shape="square" :name="3" v-if="isShow(collect.F12431,3)">低糖或无糖饮食
              </van-checkbox>
              <van-checkbox shape="square" class="checkbox" :name="4" v-if="isShow(collect.F12431,4)">低盐饮食
              </van-checkbox>
              <van-checkbox shape="square" class="checkbox" :name="5" v-if="isShow(collect.F12431,5)">其他</van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
        <div class="Q" v-if="include(collect.F12431,5)">
          <div id="F12432" class="first"></div>
          <van-field disabled
                     v-model="collect.F12432"
                     label="其他"
                     placeholder="..."
                     input-align="right"
          />
        </div>
        <div id="F12433" class="first"></div>
        <div class="Q">
          <van-field disabled
                     name="checkboxGroup"
                     label="14.有无宗教信仰"
                     input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F12433"
                direction="horizontal"
                shape="shape"

              >
                <van-radio :name="1" v-if="isShow(collect.F12433,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F12433,2)">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="Q" v-if="collect.F12433 !=null && collect.F12433 == 1">
          <div id="F12434" class="first"></div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12434">
              <van-radio class="checkbox" :name="1" v-if="isShow(collect.F12434,1)">佛教</van-radio>
              <van-radio class="checkbox" :name="2" v-if="isShow(collect.F12434,2)">道教</van-radio>
              <van-radio class="checkbox" :name="3" v-if="isShow(collect.F12434,3)">基督教</van-radio>
              <van-radio class="checkbox" :name="4" v-if="isShow(collect.F12434,4)">伊斯兰教</van-radio>
              <van-radio class="checkbox" :name="5" v-if="isShow(collect.F12434,5)">其他</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 14 -->
        <div id="F11691" class="first"></div>
        <div class="Q">
          <van-field disabled
                     name="checkboxGroup"
                     label="15. 有无配偶"
                     input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F11691"
                direction="horizontal"
                shape="shape"

              >
                <van-radio :name="1" v-if="isShow(collect.F11691,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11691,2)">无或者丧偶等</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="Q" v-if="collect.F11691!=null">
          <div id="F11692" class="first"></div>
          <van-field
            disabled
            v-if="collect.F11691 == 1"
            v-model="collect.F11692"
            name="checkboxGroup"
            label-width="9rem"
            label="配偶姓名"
            placeholder="..."
            input-align="right"
          >
          </van-field>
          <div id="F12436" class="first"></div>
          <van-field disabled
                     v-if="collect.F11691 == 1"
                     name="checkboxGroup"
                     label="身体状况"
                     placeholder="..."
                     input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F12436"
                direction="horizontal"
                shape="shape"
              >
                <van-radio :name="1" v-if="isShow(collect.F12436,1)">好</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F12436,2)">中</van-radio>
                <van-radio :name="3" v-if="isShow(collect.F12436,3)">差</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="first" id="F12437"></div>
          <van-field disabled
                     v-if="collect.F11691 == 1"
                     v-model="collect.F12437"
                     name="checkboxGroup"
                     label="身份证号"
                     placeholder="..."
                     input-align="right"
          >
          </van-field>
          <van-field disabled
                     v-model="collect.F11693"
                     name="checkboxGroup"
                     label-width="9rem"
                     label="尚在父母兄弟姐妹人数"
                     type="number"
                     placeholder="..."
                     input-align="right"
          >
          </van-field>
          <div id="F11694" class="first"></div>
          <van-field disabled
                     v-model="collect.F11694"

                     name="checkboxGroup"
                     label="子女人数"
                     type="number"
                     placeholder="..."
                     input-align="right"
          >
          </van-field>
          <div class="first" id="F11695"></div>
          <van-field disabled
                     v-model="collect.F11695"

                     name="checkboxGroup"
                     label="孙辈人数"
                     type="number"
                     placeholder="..."
                     input-align="right"
          >
          </van-field>
        </div>
        <!-- 16 -->
        <div class="first" id="F11696"></div>
        <div class="Q">
          <van-field disabled
                     name="checkboxGroup"
                     label="16. 和谁在一起居住"
                     label-width="8rem"
                     input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F11696"
                direction="horizontal"
                shape="shape"

              >
                <van-radio :name="1" v-if="isShow(collect.F11696,1)">独居</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11696,2)">非独居</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="Q" v-if="collect.F11696==2">
          <div class="q-title">一起居住的有</div>
          <div class="q-content">
            <van-checkbox-group disabled v-model="collect.F11697">
              <van-checkbox class="checkbox" :name="1" shape="square" v-if="isShow(collect.F11697,1)">配偶</van-checkbox>
              <van-checkbox class="checkbox" shape="square" :name="2" v-if="isShow(collect.F11697,2)">子女</van-checkbox>
              <van-checkbox class="checkbox" shape="square" :name="3" v-if="isShow(collect.F11697,3)">孙辈等</van-checkbox>
              <van-checkbox shape="square" class="checkbox" :name="4" v-if="isShow(collect.F11697,4)">保姆等</van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
        <!-- 17 -->
        <div class="first" id="F11700"></div>
        <div class="Q">
          <div class="q-title">17.所有亲属中，与评估对象距离最近的居住在</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F11700">
              <van-radio class="checkbox" :name="1" v-if="isShow(collect.F11700,1)">同一街道（镇）</van-radio>
              <van-radio class="checkbox" :name="2" v-if="isShow(collect.F11700,2)">同一个区（县）</van-radio>
              <van-radio class="checkbox" :name="3" v-if="isShow(collect.F11700,3)">同一省市</van-radio>
              <van-radio class="checkbox" :name="4" v-if="isShow(collect.F11700,4)">国内</van-radio>
              <van-radio class="checkbox" :name="5" v-if="isShow(collect.F11700,5)">国外等其他原因</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 18 -->
        <div class="first" id="F12438"></div>
        <div class="Q">
          <van-field disabled
                     name="checkboxGroup"
                     label="18. 评估对象身体是否需要照护情况"
                     label-width="8rem"
                     input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F12438"

                direction="horizontal"
                shape="shape"
              >
                <van-radio :name="1" v-if="isShow(collect.F12438,1)">不需要</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F12438,2)">需要</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <div class="first" id="F12439" v-if="collect.F12438 == 2"></div>
        <div class="Q" v-if="collect.F12438 == 2">
          <div class="q-content">
            <van-checkbox-group disabled v-model="collect.F12439">
              <van-checkbox class="checkbox" shape="square" :name="1" v-if="isShow(collect.F12439,1)">自己</van-checkbox>
              <van-checkbox class="checkbox" shape="square" :name="2" v-if="isShow(collect.F12439,2)">配偶</van-checkbox>
              <van-checkbox class="checkbox" shape="square" :name="3" v-if="isShow(collect.F12439,3)">子女</van-checkbox>
              <van-checkbox class="checkbox" shape="square" :name="4" v-if="isShow(collect.F12439,4)">孙辈等</van-checkbox>
              <van-checkbox class="checkbox" shape="square" :name="5" v-if="isShow(collect.F12439,5)">钟点工等
              </van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
        <!-- 19 -->
        <div class="first" id="F12440"></div>
        <div class="Q">
          <van-field disabled
                     name="checkboxGroup"
                     label="19. 评估对象身体衣着干净整洁情况"
                     label-width="8rem"
                     input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F12440"
                direction="horizontal"

                shape="shape"
              >
                <van-radio :name="1" v-if="isShow(collect.F12440,1)">干净整洁</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F12440,2)">尚可</van-radio>
                <van-radio :name="3" v-if="isShow(collect.F12440,3)">不干净不整洁</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <!-- 20 -->
        <div class="first" id="F12441"></div>
        <div class="Q">
          <div class="q-title">20.买菜做饭洗衣等家务活由谁来完成</div>
          <div class="q-content">
            <van-checkbox-group disabled v-model="collect.F12441">
              <van-checkbox class="checkbox" shape="square" :name="1" v-if="isShow(collect.F12441,1)">自己</van-checkbox>
              <van-checkbox class="checkbox" shape="square" :name="2" v-if="isShow(collect.F12441,2)">配偶</van-checkbox>
              <van-checkbox class="checkbox" shape="square" :name="3" v-if="isShow(collect.F12441,3)">子女</van-checkbox>
              <van-checkbox class="checkbox" shape="square" :name="4" v-if="isShow(collect.F12441,4)">孙辈等</van-checkbox>
              <van-checkbox class="checkbox" shape="square" :name="5" v-if="isShow(collect.F12441,5)">钟点工等
              </van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
        <!-- 21 -->
        <div class="first" id="F12442"></div>
        <div class="Q">
          <van-field disabled
                     name="checkboxGroup"
                     label="21. 居住环境干净整洁情况"
                     label-width="8rem"
                     input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F12442"

                direction="horizontal"
                shape="shape"
              >
                <van-radio :name="1" v-if="isShow(collect.F12442,1)">干净整洁</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F12442,2)">尚可</van-radio>
                <van-radio :name="3" v-if="isShow(collect.F12442,3)">不干净不整洁</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
        <!-- 22 -->
        <div class="first" id="F11701"></div>
        <div class="Q">
          <div class="q-title">22.居住楼层</div>
          <div class="q-title1">
            第<input disabled class="q-input" type="number" v-model="collect.F11701" placeholder="...">层，共<input disabled
                                                                                                                class="q-input"
                                                                                                                v-model="collect.F11702"
                                                                                                                type="number"
                                                                                                                placeholder="...">层
          </div>
          <div class="first" id="F11703"></div>
          <van-field disabled
                     name="checkboxGroup"
                     label="楼层有无电梯"
                     label-width="8rem"
                     input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F11703"
                direction="horizontal"

                shape="shape"
              >
                <van-radio :name="1" v-if="isShow(collect.F11703,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11703,2)">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="first" id="F11704"></div>
          <van-field disabled
                     name="checkboxGroup"
                     label="室内有无台阶"
                     label-width="8rem"
                     input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F11704"

                direction="horizontal"
                shape="shape"
              >
                <van-radio :name="1" v-if="isShow(collect.F11704,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11704,2)">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="first" id="F11705"></div>
          <van-field disabled
                     name="checkboxGroup"
                     label="室内有无扶手"
                     label-width="8rem"
                     input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F11705"

                direction="horizontal"
                shape="shape"
              >
                <van-radio :name="1" v-if="isShow(collect.F11705,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11705,2)">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="first" id="F11706"></div>
          <van-field disabled
                     name="checkboxGroup"
                     label="厕所内有无扶手"
                     label-width="8rem"
                     input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F11706"
                direction="horizontal"

                shape="shape"
              >
                <van-radio :name="1" v-if="isShow(collect.F11706,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11706,2)">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="first" id="F11707"></div>
          <van-field disabled
                     name="checkboxGroup"
                     label="浴室内有无扶手"
                     label-width="8rem"
                     input-align="right"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F11707"
                direction="horizontal"

                shape="shape"
              >
                <van-radio :name="1" v-if="isShow(collect.F11707,1)">有</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F11707,2)">无</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="first" id="F12443"></div>
          <div class="q-title">居住地1公里左右有如下设施
          </div
          >
          <div class="q-content">
            <van-checkbox-group disabled v-model="collect.F12443">
              <van-checkbox shape="square" class="checkbox" :name="1" v-if="isShow(collect.F12443,1)">助餐点</van-checkbox>
              <van-checkbox shape="square" class="checkbox" :name="2" v-if="isShow(collect.F12443,2)">便利店/超市
              </van-checkbox>
              <van-checkbox shape="square" class="checkbox" :name="3" v-if="isShow(collect.F12443,3)">菜场</van-checkbox>
              <van-checkbox class="checkbox" shape="square" :name="4" v-if="isShow(collect.F12443,4)">社区卫生服务点
              </van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
        <!-- 23 -->
        <div class="first" id="F11710"></div>
        <div class="Q">
          <div class="q-title">23.过去3个月，每月不支付费用照护情况（不包括非服务时间）
          </div
          >
          <div class="q-title1">
            各类非付费照护提供次数:<input disabled class="q-input" v-model="collect.F11710" type="number" placeholder="...">次/月；
          </div>
          <div class="q-title1">
            累计时间:<input disabled class="q-input" type="number" v-model="collect.F11711" placeholder="...">小时/月；
          </div>
        </div>
        <!-- 24 -->
        <div class="first" id="F11712"></div>
        <div class="Q">
          <div class="q-title">24.过去3个月，每月支付费用照护情况（不包括非服务时间）
          </div
          >
          <div class="q-title1">
            各类要付费照护提供人数:<input disabled class="q-input" v-model="collect.F11712" type="number" placeholder="...">次/月；
          </div>
          <div class="q-title1">
            累计时间:<input disabled class="q-input" type="number" v-model="collect.F11713" placeholder="...">小时/月；
          </div>
          <div class="q-title1">
            支付费用:<input disabled class="q-input" type="number" v-model="collect.F11714" placeholder="...">元/月；
          </div>
        </div>
        <!-- 25 -->
        <div class="first" id="F12445"></div>
        <div class="Q">
          <div class="q-title">25.在过去的12个月
          </div
          >
          <div class="q-title1">
            评估对象门诊就医<input disabled class="q-input" type="number" placeholder="..." v-model="collect.F12444">次，
          </div>
          <div class="q-title1">
            代配药<input disabled class="q-input" type="number" placeholder="..." v-model="collect.F12445">次，
          </div>
          <div class="q-title1">
            每次门诊（从出门到回到家门，扣除其他活动的时间）大约需要<input disabled class="q-input" type="number" v-model="collect.F12446"
                                               placeholder="...">分钟。
          </div>
          <div class="first" id="F12447"></div>
          <van-field disabled
                     name="checkboxGroup"
                     label="就诊是否有人陪同"
                     label-width="8rem"
          >
            <template #input>
              <van-radio-group
                v-model="collect.F12447"
                direction="horizontal"

                shape="shape"
              >
                <van-radio :name="1" v-if="isShow(collect.F12447,1)">是</van-radio>
                <van-radio :name="2" v-if="isShow(collect.F12447,2)">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div class="first" id="F12448">
          </div>
          <div class="q-title1">
            评估对象住院就诊<input disabled class="q-input" type="number" placeholder="..." v-model="collect.F12448">次，
          </div>
          <div class="q-title1">
            共<input disabled class="q-input" type="number" placeholder="..." v-model="collect.F12449">天；
          </div>
          <div class="q-title1">
            最近1次出院在<input disabled class="q-input" style="width:100px" v-model="collect.F12450" type="text"
                          placeholder="...">（年/月/日）；
          </div>

        </div>
        <!-- </div> -->
      </van-tab>
      <van-tab title="基本信息" style="padding: 0px 3% 60px 3%">
        <div class="B">
          <div class="q-title3">请仔细观察老人的情况，在保证安全的情况下尽量当场确认，</div>
          <div class="q-title3">同时结合照护人员提供的信息填写。</div>
          <div class="q-title3">如无特别说明，指以往7天的情况。</div>
        </div>
        <!-- 1 -->

        <div class="first" id="F12017"></div>
        <div class="Q">
          <div class="q-title">
            卧位状态中左右翻身是否需要帮助
            <div class="q-title4">
              第一项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12017">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12017,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12017,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12017,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12017,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12017,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="first" id="F12018"></div>
        <!-- 2 -->
        <div class="Q">
          <div class="q-title">
            卧位状态中坐起是否需要帮助
            <div class="q-title4">
              第二项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12018">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12018,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12018,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12018,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12018,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12018,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="first" id="F12019"></div>
        <!-- 3 -->
        <div class="Q">
          <div class="q-title">
            坐凳椅是否需要帮助
            <div class="q-title4">
              第三项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12019">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12019,1)">1.&nbsp;能够正常完成</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12019,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12019,3)">3.&nbsp;依靠或抓住固定物体等时，可以完成
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12019,4)">4.&nbsp;借助固定物体，且在他人照看下才能完成
              </van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12019,5)">5.&nbsp;即使依靠且抓住固定物体等，也不能完成
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 4 -->
        <div class="first" id="F12020"></div>
        <div class="Q">
          <div class="q-title">
            从坐立位状态起身至站立位状态是否需要帮助
            <div class="q-title4">
              第四项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12020">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12020,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12020,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12020,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12020,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12020,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 5 -->
        <div class="first" id="F12021"></div>
        <div class="Q">
          <div class="q-title">
            能否完成平地双足站立10秒钟动作
            <div class="q-title4">
              第五项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12021">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12021,1)">1.&nbsp;能够完成</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12021,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12021,3)">3.&nbsp;必须借助于手扶其他物体才能完成</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12021,4)">4.&nbsp;即使手扶其他物体，也只能站立1~2秒钟
              </van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12021,5)">5.&nbsp;即使手扶其他物体，也不能双足站立
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 6 -->
        <div class="first" id="F12022"></div>
        <div class="Q">
          <div class="q-title">
            能否完成平地连续步行或移动5米
            <div class="q-title4">
              第六项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12022">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12022,1)">1.&nbsp;不需要借助任何工具，能够完成</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12022,2)">2.&nbsp;需要借助拐杖等才能完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12022,3)">3.&nbsp;需要借助轮椅等才能完成</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12022,4)">4.&nbsp;需要他人动手帮助才能完成</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12022,5)">5.&nbsp;完全需要他人帮助才能完成</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 7 -->
        <div class="first" id="F12023"></div>
        <div class="Q">
          <div class="q-title">
            坐立行等动作相关联的体位变换时是否需要帮助
            <div class="q-title4">
              第七项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12023">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12023,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12023,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12023,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12023,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12023,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 8 -->
        <div class="first" id="F12024"></div>
        <div class="Q">
          <div class="q-title">
            食物摄取是否需要帮助
            <div class="q-title4">
              第八项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12024">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12024,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12024,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12024,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12024,4)">4.&nbsp;需要喂食，喂食量超过一半</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12024,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 9 -->
        <div class="first" id="F12025"></div>
        <div class="Q">
          <div class="q-title">
            刷牙/漱口是否需要帮助
            <div class="q-title4">
              第九项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12025">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12025,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12025,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12025,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12025,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12025,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 10 -->
        <div class="first" id="F12026"></div>
        <div class="Q">
          <div class="q-title">
            洗脸/洗手是否需要帮助
            <div class="q-title4">
              第十项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12026">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12026,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12026,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12026,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12026,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12026,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 11 -->
        <div class="first" id="F12027"></div>
        <div class="Q">
          <div class="q-title">
            梳头/简单化妆是否需要帮助
            <div class="q-title4">
              第十一项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12027">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12027,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12027,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12027,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12027,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12027,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="first" id="F12028"></div>
        <!-- 12 -->
        <div class="Q">
          <div class="q-title">
            穿/脱上衣是否需要帮助
            <div class="q-title4">
              第十二项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12028">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12028,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12028,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12028,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12028,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12028,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="first" id="F12029"></div>
        <!-- 13 -->
        <div class="Q">
          <div class="q-title">
            穿/脱裤子是否需要帮助
            <div class="q-title4">
              第十三项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12029">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12029,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12029,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12029,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12029,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12029,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="first" id="F12030"></div>
        <!-- 14 -->
        <div class="Q">
          <div class="q-title">
            身体洗浴是否需要帮助（不限于以往7天）
            <div class="q-title4">
              第十四项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12030">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12030,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12030,2)">2.在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12030,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12030,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12030,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 15 -->
        <div class="first" id="F12032"></div>
        <div class="Q">
          <div class="q-title">
            小便是否失禁：
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12032">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12032,1)">1.&nbsp;不失禁</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12032,2)">2.&nbsp;偶尔失禁</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12032,3)">3.&nbsp;失禁</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12031">
          </div>
          <div class="q-title">
            排尿是否需要帮助;
            <div class="q-title4">
              第十五项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12031">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12031,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12031,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12031,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12031,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12031,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="first" id="F12034"></div>
        <!-- 16 -->
        <div class="Q">
          <div class="q-title">
            大便：
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12034">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12034,1)">1.&nbsp;不失禁</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12034,2)">2.&nbsp;偶尔失禁</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12034,3)">3.&nbsp;失禁</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12455">
          </div>
          <div class="q-title">
            有无便秘：
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12455">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12455,1)">1.&nbsp;有</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12455,2)">2.&nbsp;无</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12033"></div>
          <div class="q-title">
            排便是否需要帮助;
            <div class="q-title4">
              第十六项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12033">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12033,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12033,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12033,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12033,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12033,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 17 -->
        <div class="first" id="F12035"></div>
        <div class="Q">
          <div class="q-title">
            视力
            <div class="q-title4">
              第十七项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12035">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12035,1)">1.&nbsp;视力基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12035,2)">2.&nbsp;视力有障碍，在一定程度上影响日常生活
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12035,3)">3.&nbsp;视力有障碍，严重影响日常生活</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12035,4)">4.&nbsp;有光感，在1米以内能够辨认手的形状
              </van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12035,5)">5.&nbsp;双目失明，无光感等</van-radio>
            </van-radio-group>
          </div>

        </div>
        <!-- 18 -->
        <div class="first" id="F12037"></div>
        <div class="Q">
          <div class="q-title">
            电话使用情况：
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12037">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12037,1)">1.&nbsp;会拨打和接听</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12037,2)">2.&nbsp;会接听</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12037,3)">3.&nbsp;不使用电话</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12036">
          </div>
          <div class="q-title">
            听力；
            <div class="q-title4">
              第十八项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12036">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12036,1)">1.&nbsp;基本能够听清日常生活会话</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12036,2)">2.&nbsp;只能够听清较大的声音，或者在耳边正常说话声
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12036,3)">3.&nbsp;只能够听清大声在耳边说的声音</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12036,4)">4.&nbsp;只能够听到非常大的声音</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12036,5)">5.&nbsp;无听力等</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 19 -->
        <div class="first" id="F12038"></div>
        <div class="Q">
          <div class="q-title">
            有无上下肢瘫痪的情况
            <div class="q-title4">
              第十九项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox-group
              v-model="collect.F12038"
              disabled
              direction="horizontal"
            >
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12038,1)"
              >无
              </van-checkbox
              >
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12038,2)"
              >左上肢
              </van-checkbox
              >
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12038,3)"
              >右上肢
              </van-checkbox
              >
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12038,4)"
              >左下肢
              </van-checkbox
              >
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12038,5)"
              >右下肢
              </van-checkbox
              >

            </van-checkbox-group>
          </div>
        </div>
        <!-- 20 -->
        <div class="first" id="F12039"></div>
        <div class="Q">
          <div class="q-title">
            有无关节活动受限范围大于50%的情况
            <div class="q-title4">
              第二十项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox-group
              v-model="collect.F12039"
              disabled
              direction="horizontal"
            >
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12039,1)"
              >无
              </van-checkbox
              >
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12039,2)"
              >颈椎关节
              </van-checkbox
              >
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12039,3)"
              >肩关节
              </van-checkbox
              >
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12039,4)"
              >肘关节
              </van-checkbox
              >
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12039,5)"
              >腰椎关节
              </van-checkbox
              >
              <van-checkbox :name="6" class="checkbox" shape="square" v-if="isShow(collect.F12039,6)"
              >髋关节
              </van-checkbox
              >
              <van-checkbox :name="7" class="checkbox" shape="square" v-if="isShow(collect.F12039,7)"
              >膝关节
              </van-checkbox
              >

            </van-checkbox-group>
          </div>
        </div>

        <div class="B">
          <div class="q-title3">以下项目，如果调查对象无回答能力，</div>
          <div class="q-title3">则该部分不作调查，得分填写“0”.</div>
        </div>

        <!-- 21 -->
        <div class="first" id="F12040"></div>
        <div class="Q">
          <div class="q-title">
            能否知道自己的姓名？
            <div class="q-title4">
              第二十一项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12040">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12040,1)">1.&nbsp;能够</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12040,2)">2.&nbsp;不能</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12041">
          </div>
        </div>
        <!-- 22 -->
        <div class="Q">
          <div class="q-title">
            能否知道自己的年龄？
            <div class="q-title4">
              第二十二项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12041">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12041,1)">1.&nbsp;能够</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12041,2)">2.&nbsp;不能</van-radio>
            </van-radio-group>
          </div>

        </div>
        <!-- 23 -->
        <div class="first" id="F12042"></div>
        <div class="Q">
          <div class="q-title">
            能否知道现在的季节？
            <div class="q-title4">
              第二十三项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12042">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12042,1)">1.&nbsp;能够</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12042,2)">2.&nbsp;不能</van-radio>
            </van-radio-group>
          </div>

        </div>
        <!-- 24 -->
        <div class="first" id="F12043"></div>
        <div class="Q">
          <div class="q-title">评估人员说出3样东西：【皮球】【国旗】【树木】，一秒说一项。请评估对象记住这3样东西，过30秒钟，请评估对象重复一遍。
            <div class="q-title4">
              第二十四项
            </div>
          </div
          >
          <div class="q-title1">
            记住数量：
            <van-radio-group v-model="collect.F12043">
              <van-radio :name="0" class="checkbox" v-if="isShow(collect.F12043,0)">0</van-radio>
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F12043,1)">1</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F12043,2)">2</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F12043,3)">3</van-radio>
            </van-radio-group>
            <input disabled class="q-input" v-model="collect.F12043" type="number" placeholder="...">
          </div>
        </div>
        <!-- 25 -->
        <div class="first" id="F12044"></div>
        <div class="Q">
          <div class="q-title">现在是几几年？几月？几号？几点钟？星期几？
            <div class="q-title4">
              第二十五项
            </div>
          </div
          >
          <div class="q-title1">
            正确项数：
            <van-radio-group v-model="collect.F12044">
              <van-radio :name="0" class="checkbox" v-if="isShow(collect.F12044,0)">0</van-radio>
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F12044,1)">1</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F12044,2)">2</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F12044,3)">3</van-radio>
              <van-radio :name="4" class="checkbox" v-if="isShow(collect.F12044,4)">4</van-radio>
              <van-radio :name="5" class="checkbox" v-if="isShow(collect.F12044,5)">5</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 26 -->
        <div class="first" id="F12045"></div>
        <div class="Q">
          <div class="q-title">现在在哪里，省？市？医院/街道？科室/小区？第几层楼？
            <div class="q-title4">
              第二十六项
            </div>
          </div
          >
          <div class="q-title1">
            正确项数：
            <van-radio-group v-model="collect.F12045">
              <van-radio :name="0" class="checkbox" v-if="isShow(collect.F12045,0)">0</van-radio>
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F12045,1)">1</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F12045,2)">2</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F12045,3)">3</van-radio>
              <van-radio :name="4" class="checkbox" v-if="isShow(collect.F12045,4)">4</van-radio>
              <van-radio :name="5" class="checkbox" v-if="isShow(collect.F12045,5)">5</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 27 -->
        <div class="first" id="F12046"></div>
        <div class="Q">
          <div class="q-title">用100减去7，询问被评估人员差数，用正确的差数再减去7，连续5次
            <div class="q-title4">
              第二十七项
            </div>
          </div
          >
          <div class="q-title1">
            正确次数：
            <van-radio-group v-model="collect.F12046">
              <van-radio :name="0" class="checkbox" v-if="isShow(collect.F12046,0)">0</van-radio>
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F12046,1)">1</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F12046,2)">2</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F12046,3)">3</van-radio>
              <van-radio :name="4" class="checkbox" v-if="isShow(collect.F12046,4)">4</van-radio>
              <van-radio :name="5" class="checkbox" v-if="isShow(collect.F12046,5)">5</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 28 -->
        <div class="first" id="F12047"></div>
        <div class="Q">
          <div class="q-title">（与24题完成相隔3分钟）请您把刚才我们说过的3样东西说一遍？
            <div class="q-title4">
              第二十八项
            </div>
          </div
          >
          <div class="q-title1">
            正确项数：
            <van-radio-group v-model="collect.F12047">
              <van-radio :name="0" class="checkbox" v-if="isShow(collect.F12047,0)">0</van-radio>
              <van-radio :name="1" class="checkbox" v-if="isShow(collect.F12047,1)">1</van-radio>
              <van-radio :name="2" class="checkbox" v-if="isShow(collect.F12047,2)">2</van-radio>
              <van-radio :name="3" class="checkbox" v-if="isShow(collect.F12047,3)">3</van-radio>
            </van-radio-group>
          </div>
        </div>

        <div class="B">

          <div class="q-title3">以下项目，请调查对象根据最近7天的状态回答，</div>

          <div class="q-title3">可适当参照照护人员提供的信息。</div>

        </div>
        <!-- 29 -->
        <div class="first" id="F12048"></div>
        <div class="Q">
          <div class="q-title">
            您觉得自己是个有用的人，有人需要您？
            <div class="q-title4">
              第二十九项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12048">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12048,1)">1.&nbsp;没有或很少时间</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12048,2)">2.&nbsp;每月1次左右</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12048,3)">3.&nbsp;每周1次左右</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12048,4)">4.&nbsp;每天1次左右</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12048,5)">5.&nbsp;每天多次</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 30 -->
        <div class="first" id="F12049"></div>
        <div class="Q">
          <div class="q-title">
            您会无缘无故地感到疲乏？
            <div class="q-title4">
              第三十项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12049">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12049,1)">1.&nbsp;没有或很少时间</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12049,2)">2.&nbsp;每月1次左右</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12049,3)">3.&nbsp;每周1次左右</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12049,4)">4.&nbsp;每天1次左右</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12049,5)">5.&nbsp;每天多次</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 31 -->
        <div class="first" id="F12050"></div>
        <div class="Q">
          <div class="q-title">
            平常感兴趣的事，您仍然感兴趣？
            <div class="q-title4">
              第三十一项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12050">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12050,1)">1.&nbsp;没有或很少时间</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12050,2)">2.&nbsp;小部分时间</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12050,3)">3.&nbsp;相当多时间</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12050,4)">4.&nbsp;绝大部分时间</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12050,5)">5.&nbsp;全部时间</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 32 -->
        <div class="first" id="F12051"></div>
        <div class="Q">
          <div class="q-title">
            比平常更容易生气、激动？
            <div class="q-title4">
              第三十二项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12051">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12051,1)">1.&nbsp;没有或很少时间</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12051,2)">2.&nbsp;每月1次左右</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12051,3)">3.&nbsp;每周1次左右</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12051,4)">4.&nbsp;每天1次左右</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12051,5)">5.&nbsp;每天多次</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 33 -->
        <div class="first" id="F12052"></div>
        <div class="Q">
          <div class="q-title">
            您晚上睡眠状况如何？
            <div class="q-title4">
              第三十三项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12052">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12052,1)">1.&nbsp;很差</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12052,2)">2.&nbsp;差</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12052,3)">3.&nbsp;一般</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12052,4)">4.&nbsp;每天1次左右</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12052,5)">5.&nbsp;很好</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 34 -->
        <div class="first" id="F12053"></div>
        <div class="Q">
          <div class="q-title">
            您认为您的身体健康状况如何？
            <div class="q-title4">
              第三十三项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12053">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12053,1)">1.&nbsp;很好</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12053,2)">2.&nbsp;好</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12053,3)">3.&nbsp;一般</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12053,4)">4.&nbsp;差</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12053,5)">5.&nbsp;很差</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12053,6)">6.其他</van-radio>
            </van-radio-group>
          </div>
        </div>

        <div class="B">
          <div class="q-title3">以下项目，请主要根据照护人员提供的信息填写。</div>
          <div class="q-title3">如无特别说明，指以往30天的情况。</div>
        </div>
        <!-- 35 -->
        <div class="first" id="F12054"></div>
        <div class="Q">
          <div class="q-title">
            食物烹煮是否需要帮助
            <div class="q-title4">
              第三十五项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12054">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12054,1)">1.&nbsp;能够计划、烹煮和摆放一顿适当的饭菜
              </van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12054,2)">2.&nbsp;如果准备好材料，会做一顿适当的饭菜
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12054,3)">3.&nbsp;在他人协助下，能够做一顿适当的饭菜
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12054,4)">4.&nbsp;只能够将做好的饭菜加热</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12054,5)">5.&nbsp;需要别人把饭菜蒸煮、摆放好</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 36 -->
        <div class="first" id="F12055"></div>
        <div class="Q">
          <div class="q-title">
            做家务活是否需要帮助
            <div class="q-title4">
              第三十六项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12055">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12055,1)">1.&nbsp;能做重的家务，如擦地板，偶尔需要帮助
              </van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12055,2)">2.&nbsp;只能做简单的家务活，如洗衣、洗碗、铺床
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12055,3)">3.&nbsp;虽能做家务活，但不能达到可接受的程度
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12055,4)">4.&nbsp;所有的家务活都需要别人较多的协助
              </van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12055,5)">5.&nbsp;完全不能做家务活</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 38 -->
        <div class="first" id="F12056"></div>
        <div class="Q">
          <div class="q-title">
            上下楼是否需要帮助:
            <div class="q-title4">
              第三十七项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12056">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12056,1)">1.&nbsp;不需要帮助</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12056,2)">2.&nbsp;在他人语言指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12056,3)">3.&nbsp;需要他人动手帮助，但以自身完成为主
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12056,4)">4.&nbsp;主要靠帮助，自身只是配合等</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12056,5)">5.&nbsp;完全需要帮助；或更严重的情况</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12058">
          </div>
        </div>
        <div class="Q">
          <div class="q-title">
            外出后找不到回家的情况：
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12058">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12058,1)">1.&nbsp;没有或极少</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12058,2)">2.&nbsp;1个月1次左右</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12058,3)">3.&nbsp;1周1次左右或更多</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12057">
          </div>
          <div class="q-title">
            外出频次;
            <div class="q-title4">
              第三十八项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12057">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12057,1)">1.&nbsp;每天外出多次</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12057,2)">2.&nbsp;每天外出1次左右</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12057,3)">3.&nbsp;每周外出1次左右</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12057,4)">4.&nbsp;每月外出1次左右</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12057,5)">5.&nbsp;每月外出少于1次</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 39 -->

        <div class="first" id="F12059"></div>
        <div class="Q">
          <div class="q-title">
            外出3公里左右是否需要帮助
            <div class="q-title4">
              第三十九项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12059">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12059,1)">1.&nbsp;能够自己步行、骑车、开车外出</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12059,2)">2.&nbsp;能够自己搭乘公共交通外出</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12059,3)">3.&nbsp;只能够搭乘出租车等交通工具外出</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12059,4)">4.&nbsp;搭乘交通工具需要有人陪同</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12059,5)">5.&nbsp;外出完全需要帮助</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 40 -->
        <div class="first" id="F12060"></div>
        <div class="Q">
          <div class="q-title">
            购物是否需要帮助
            <div class="q-title4">
              第四十项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12060">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12060,1)">1.&nbsp;能够独立完成所有购物需求</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12060,2)">2.&nbsp;能够独立购买一般的日常生活用品</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12060,3)">3.&nbsp;购物时，有时需要陪同</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12060,4)">4.&nbsp;每次购物都需要有人陪同</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12060,5)">5.&nbsp;完全不能购物</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 41 -->
        <div class="first" id="F12061"></div>
        <div class="Q">
          <div class="q-title">
            金钱管理方面是否需要帮助
            <div class="q-title4">
              第四十一项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12061">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12061,1)">1.&nbsp;独立完成金钱的管理，支配，使用</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12061,2)">2.&nbsp;因担心算错等，每月管理约1000元
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12061,3)">3.&nbsp;因担心算错等，每月管理约300元
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12061,4)">4.&nbsp;接触金钱机会少，主要由家属等代管
              </van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12061,5)">5.&nbsp;完全不接触金钱等</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 42 -->
        <div class="first" id="F12062"></div>
        <div class="Q">
          <div class="q-title">
            服用口服药物是否需要帮助
            <div class="q-title4">
              第四十二项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12062">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12062,1)">1.&nbsp;能够按时正确地服用药物</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12062,2)">2.&nbsp;在他人指导或照看下能够完成</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12062,3)">3.&nbsp;如果事先准备好药物，可自行服用</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12062,4)">4.&nbsp;即使事先准备好药物，也不一定能够完成
              </van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12062,5)">5.&nbsp;不能自己服药</van-radio>
            </van-radio-group>
          </div>
        </div>

        <div class="B">
          <div class="q-title3">以下项目，请根据照护人员提供的信息填写，</div>
          <div class="q-title3">可适当参照评估对象的回答。指最近14天的情况。</div>
        </div>

        <!-- 43 -->
        <div class="first" id="F12063"></div>
        <div class="Q">
          <div class="q-title">
            评估对象强迫行为，如洗手、关门、上厕所等的次数
            <div class="q-title4">
              第四十三项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12063">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12063,1)">1.&nbsp;没有</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12063,2)">2.&nbsp;1次</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12063,3)">3.&nbsp;2~3次</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12063,4)">4.&nbsp;4~7次</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12063,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 44 -->
        <div class="first" id="F12064"></div>
        <div class="Q">
          <div class="q-title">
            评估对象破坏衣物、家具等的次数
            <div class="q-title4">
              第四十四项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12064">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12064,1)">1.&nbsp;没有</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12064,2)">2.&nbsp;1次</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12064,3)">3.&nbsp;2~3次</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12064,4)">4.&nbsp;4~7次</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12064,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 45 -->
        <div class="first" id="F12065"></div>
        <div class="Q">
          <div class="q-title">
            评估对象对周边人员行为攻击的次数
            <div class="q-title4">
              第四十五项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12065">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12065,1)">1.&nbsp;没有</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12065,2)">2.&nbsp;1次</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12065,3)">3.&nbsp;2~3次</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12065,4)">4.&nbsp;4~7次</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12065,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 46 -->
        <div class="first" id="F12066"></div>
        <div class="Q">
          <div class="q-title">
            如被限制外出，评估对象擅自外出的次数
            <div class="q-title4">
              第四十六项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12066">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12066,1)">1.&nbsp;没有</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12066,2)">2.&nbsp;1次</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12066,3)">3.&nbsp;2~3次</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12066,4)">4.&nbsp;4~7次</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12066,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 47 -->
        <div class="first" id="F12067"></div>
        <div class="Q">
          <div class="q-title">
            抵抗照护的次数
            <div class="q-title4">
              第四十七项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12067">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12067,1)">1.&nbsp;没有</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12067,2)">2.&nbsp;1次</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12067,3)">3.&nbsp;2~3次</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12067,4)">4.&nbsp;4~7次</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12067,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 48 -->
        <div class="first" id="F12068"></div>
        <div class="Q">
          <div class="q-title">
            无缘无故地哭泣的次数
            <div class="q-title4">
              第四十八项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12068">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12068,1)">1.&nbsp;没有</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12068,2)">2.&nbsp;1次</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12068,3)">3.&nbsp;2~3次</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12068,4)">4.&nbsp;4~7次</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12068,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 49 -->
        <div class="first" id="F12069"></div>
        <div class="Q">
          <div class="q-title">
            不切实际的害怕或过度担心的次数
            <div class="q-title4">
              第四十九项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12069">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12069,1)">1.&nbsp;没有</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12069,2)">2.&nbsp;1次</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12069,3)">3.&nbsp;2~3次</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12069,4)">4.&nbsp;4~7次</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12069,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 50 -->
        <div class="first" id="F12070"></div>
        <div class="Q">
          <div class="q-title">
            看到或听到别人感觉不到的东西的次数
            <div class="q-title4">
              第五十项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12070">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12070,1)">1.&nbsp;没有</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12070,2)">2.&nbsp;1次</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12070,3)">3.&nbsp;2~3次</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12070,4)">4.&nbsp;4~7次</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12070,5)">5.&nbsp;8次及以上</van-radio>
            </van-radio-group>
          </div>
        </div>
      </van-tab>
      <van-tab title="总体状况" style="padding: 0px 3% 60px 3%">
        <div class="B">
          <div class="q-title3">以下项目，请调查员主要根据自己的判断填写，</div>
          <div class="q-title3">可适当参照照护人员提供的信息。</div>
        </div>
        <!-- 1 -->
        <div class="first" id="F12071"></div>
        <div class="Q">
          <div class="q-title">
            评估对象日常生活的基本判断能力（包括吃饭、穿衣、活动、睡觉）
            <div class="q-title4">
              第一项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12071">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12071,1)">1.&nbsp;判断能力较好，判断安全、合理</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12071,2)">2.&nbsp;判断能力尚可，日常生活判断基本正常
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12071,3)">3.&nbsp;判断能力一般，日常判断有时需要提示
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12071,4)">4.&nbsp;判断能力较差，日常判断经常出现失误
              </van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12071,5)">5.&nbsp;判断能力极差或丧失等</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 2 -->
        <div class="first" id="F12072"></div>
        <div class="Q">
          <div class="q-title">
            评估对象自身意愿 / 信息的表达能力
            <div class="q-title4">
              第二项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12072">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12072,1)">1.&nbsp;能够被一般人理解</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12072,2)">2.&nbsp;仅能够被家属或者身边的照护人员理解
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12072,3)">3.&nbsp;即使家属或者身边的照护人员也难以理解
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12072,4)">4.&nbsp;即使家属或者身边的照护人员也不能理解
              </van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12072,5)">5.&nbsp;因为意识障碍等，不能够传达意愿 / 信息
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 3 -->
        <div class="first" id="F12073"></div>
        <div class="Q">
          <div class="q-title">
            评估对象对他人所表达的意愿 / 信息的理解能力
            <div class="q-title4">
              第三项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12073">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12073,1)">1.&nbsp;能够理解一般大众所表达的意愿 / 信息
              </van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12073,2)">2.&nbsp;通过家属或者身边的照护人员的帮助，能够理解一般大众所表达的意愿
                / 信息
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12073,3)">3.&nbsp;即使是家属或者身边的照护人员所表达的意愿 /
                信息也很难被理解
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12073,4)">4.&nbsp;即使是家属或者身边的照护人员所表达的意愿 /
                信息也不能够被理解
              </van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12073,5)">5.&nbsp;因为意识障碍等，接受不到意愿 / 信息
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 4 -->
        <div class="first" id="F12074"></div>
        <div class="Q">
          <div class="q-title">
            与人相处的相容性
            <div class="q-title4">
              第四项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12074">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12074,1)">1.&nbsp;喜欢与人相处，并能相处好</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12074,2)">2.&nbsp;能够与10名及以上的家人朋友相处好
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12074,3)">3.&nbsp;只能够与4~9人相处好</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12074,4)">4.&nbsp;只能够与1~3人相处好</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12074,5)">5.&nbsp;不能够与任何人相处好</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 5 -->
        <div class="first" id="F12075"></div>
        <div class="Q">
          <div class="q-title">
            评估对象日常生活自理程度
            <div class="q-title4">
              第五项
            </div>
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12075">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12075,1)">1.&nbsp;够独自外出，日常生活自理</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12075,2)">2.&nbsp;以室内为主，日常生活基本自理，外出需要照护
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12075,3)">3.&nbsp;移乘不需要帮助，日常生活需要帮助，不在床上吃饭、排泄
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12075,4)">4.&nbsp;移乘需要帮助，日常生活需要帮助，吃饭或者排泄需要照护
              </van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12075,5)">5.&nbsp;整天卧床，吃饭、穿衣、排泄等需要全面照护
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 6 -->

        <div class="Q">
          <div class="first" id="buchong">
          </div>
          <div class="q-title1">情况描述</div>
          <div class="q-title2">请详细描述“能力打分”判断依据，并辅以照片录音说明情况。</div>
          <div class="q-content">
            <textarea disabled v-model="value.buchong" id="" cols="30" rows="10"></textarea>
          </div>
          <div class="q-title1">我确认，本调查表以上填写的内容，均是基于我所观察到的被调查对象的实际情况、被调查对象回答的情况以及家属和/或照护人员所反映的情况。 特此声明。</div>
          <div class="q-title1" style="font-size:12px" v-if="isAdmin || isluru">调查员编号：<span style="color:blue">{{pgyObj.pgy1}}</span>&nbsp;&nbsp;&nbsp;调查日期：{{
            value.submtime | timestampFormatDate }}<span></span>
          </div>

        </div>
      </van-tab>
      <van-tab title="疾病总体信息" style="padding: 0px 3% 60px 3%">

        <div class="first" id="F12076"></div>
        <!-- 1 -->
        <div class="Q">
          <div class="q-title">1.&nbsp;意识</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12076">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12076,1)">1.&nbsp;清醒或基本清醒</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12076,2)">2.&nbsp;意识模糊</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12076,3)">3.&nbsp;昏迷</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 2 -->
        <div class="first" id="F12077"></div>
        <div class="Q">
          <div class="q-title">2.&nbsp;心跳</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12077">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12077,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12077,2)">2.&nbsp;40~60次/分或100~120次/分
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12077,3)">3.&nbsp;&lt;40次/分或&gt;120次/分
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 3 -->
        <div class="first" id="F12078"></div>
        <div class="Q">
          <div class="q-title">3.&nbsp;呼吸</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12078">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12078,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12078,2)">2.&nbsp;急促，费力</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12078,3)">3.&nbsp;端鸣，窘迫，窒息感</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 4 -->

        <div class="first" id="F12079"></div>
        <div class="Q">
          <div class="q-title">4.&nbsp;血压</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12079">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12079,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12079,2)">2.&nbsp;收缩压141~160mmHg或舒张压91~110mmHg
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12079,3)">3.&nbsp;舒张压&lt;60mmHg或&gt;110mmHg或收缩压&gt;160mmHg</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 5 -->
        <div class="first" id="F12080"></div>
        <div class="Q">
          <div class="q-title">5.&nbsp;体温</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12080">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12080,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12080,2)">2.&nbsp;37.5°C~38.9°C</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12080,3)">3.&nbsp;&lt;36°C或&gt;39°C
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 6 -->
        <div class="first" id="F12081"></div>
        <div class="Q">
          <div class="q-title">6.营养</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12081">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12081,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12081,2)">2.&nbsp;肌肉消耗显著，三头肌皮褶厚度显著减少，踝部水肿明显
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12081,3)">3.&nbsp;肌肉消耗严重，三头肌皮褶厚度严重消失，踝部水肿显著
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 7 -->
        <div class="first" id="F12083"></div>
        <div class="Q">
          <div class="q-title">7.疼痛</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12083">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12083,1)">1.&nbsp;不痛或基本不痛</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12083,2)">2.&nbsp;间歇或持续性疼痛，影响休息，需用药
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12083,3)">3.&nbsp;持续剧烈疼痛，用药才能缓解</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 8 -->
        <div class="first" id="F12084"></div>
        <div class="Q">
          <div class="q-title">8.情绪</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12084">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12084,1)">1.&nbsp;稳定或基本稳定</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12084,2)">2.&nbsp;焦虑、消极、悲观、忧伤、厌世、自私多疑
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12084,3)">3.&nbsp;哭泣、抑郁、暴躁、易怒</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 9 -->
        <div class="first" id="F12085"></div>
        <div class="Q">
          <div class="q-title">9.便秘</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12085">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12085,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12085,2)">2.&nbsp;偶有便秘</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12085,3)">3.&nbsp;严重便秘</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 10 -->
        <div class="first" id="F12086"></div>
        <div class="Q">
          <div class="q-title">10.肌力</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12086">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12086,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12086,2)">2.&nbsp;肢体活动严重受限</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12086,3)">3.&nbsp;肢体活动丧失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 11 -->
        <div class="first" id="F12087"></div>
        <div class="Q">
          <div class="q-title">11.睡眠</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12087">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12087,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12087,2)">2.&nbsp;难以入眠、频繁觉醒</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12087,3)">3.&nbsp;非恢复性睡眠</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 12 -->
        <div class="first" id="F12088"></div>
        <div class="Q">
          <div class="q-title">12.脱水</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12088">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12088,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12088,2)">2.&nbsp;严重口渴、头痛、尿少，皮肤弹性明显降低
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12088,3)">3.&nbsp;体重明显降低、皮肤弹性严重消失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 13 -->
        <div class="first" id="F12089"></div>
        <div class="Q">
          <div class="q-title">13.咀嚼功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12089">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12089,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12089,2)">2.&nbsp;能咀嚼细软食物</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12089,3)">3.&nbsp;不能咀嚼</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 14 -->
        <div class="first" id="F12090"></div>
        <div class="Q">
          <div class="q-title">14.感觉功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12090">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12090,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12090,2)">2.&nbsp;有痛觉、触觉、温度觉功能</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12090,3)">3.&nbsp;无痛、触、温、运动、味觉功能</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 15 -->
        <div class="first" id="F12091"></div>
        <div class="Q">
          <div class="q-title">15.认知功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12091">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12091,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12091,2)">2.&nbsp;有简单的认知功能</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12091,3)">3.&nbsp;认知功能丧失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 16 -->
        <div class="first" id="F12092"></div>
        <div class="Q">
          <div class="q-title">16.运动功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12092">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12092,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12092,2)">2.&nbsp;需借助工具、他人帮助</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12092,3)">3.&nbsp;认知功能丧失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 17 -->
        <div class="first" id="F12093"></div>
        <div class="Q">
          <div class="q-title">17.社交功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12093">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12093,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12093,2)">2.&nbsp;严重受限</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12093,3)">3.&nbsp;功能丧失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 18-->
        <div class="first" id="F12094"></div>
        <div class="Q">
          <div class="q-title">18.吞咽功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12094">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12094,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12094,2)">2.&nbsp;严重受限</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12094,3)">3.&nbsp;功能丧失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 19-->
        <div class="first" id="F12095"></div>
        <div class="Q">
          <div class="q-title">19.语言功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12095">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12095,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12095,2)">2.&nbsp;简单有限交谈</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12095,3)">3.&nbsp;失语</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 20-->
        <div class="first" id="F12096"></div>
        <div class="Q">
          <div class="q-title">20.记忆功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12096">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12096,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12096,2)">2.&nbsp;简单记忆且不连贯</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12096,3)">3.&nbsp;功能丧失</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 21-->
        <div class="first" id="F12097"></div>
        <div class="Q">
          <div class="q-title">21.情感功能</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12097">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12097,1)">1.&nbsp;正常或基本正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12097,2)">2.&nbsp;情感淡漠或轻度躁狂</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12097,3)">3.&nbsp;无欲望、严重抑郁或躁狂</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 22-->
        <div class="first" id="F12098"></div>
        <div class="Q">
          <div class="q-title">22.自杀风险</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12098">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12098,1)">1.&nbsp;无自杀倾向</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12098,2)">2.&nbsp;有严重自杀倾向</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12098,3)">3.&nbsp;有自杀行为发生</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 23 -->
        <div class="first" id="F12099"></div>
        <div class="Q">
          <div class="q-title">23.跌倒风险</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12099">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12099,1)">1.&nbsp;最近90天无跌倒</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12099,2)">2.&nbsp;最近30天无跌倒，但31~90天有
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12099,3)">3.&nbsp;最近30天有跌倒发生</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 24 -->
        <div class="first" id="F12100"></div>
        <div class="Q">
          <div class="q-title">24.所患疾病情况</div>
          <div class="q-content">
            <van-checkbox-group
              v-model="collect.F12100" disabled
              direction="horizontal"
            >
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12100,1)"
              >高血压
              </van-checkbox
              >
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12100,2)"
              >冠心病
              </van-checkbox
              >
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12100,3)"
              >慢性肺炎
              </van-checkbox
              >
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12100,4)"
              >慢性阻塞性肺病
              </van-checkbox
              >
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12100,5)"
              >脑出血
              </van-checkbox
              >
              <van-checkbox :name="6" class="checkbox" shape="square" v-if="isShow(collect.F12100,6)"
              >脑梗死
              </van-checkbox
              >
              <van-checkbox :name="7" class="checkbox" shape="square" v-if="isShow(collect.F12100,7)"
              >糖尿病
              </van-checkbox
              >
              <van-checkbox :name="8" class="checkbox" shape="square" v-if="isShow(collect.F12100,8)"
              >帕金森病
              </van-checkbox
              >
              <van-checkbox :name="9" class="checkbox" shape="square" v-if="isShow(collect.F12100,9)"
              >晚期肿瘤
              </van-checkbox
              >
              <van-checkbox :name="10" class="checkbox" shape="square" v-if="isShow(collect.F12100,10)"
              >下肢骨折
              </van-checkbox
              >
              <van-checkbox :name="11" class="checkbox" shape="square" v-if="isShow(collect.F12100,11)"
              >认知障碍
              </van-checkbox
              >
              <van-checkbox :name="12" class="checkbox" shape="square" v-if="isShow(collect.F12100,12)"
              >慢性肾功能衰竭
              </van-checkbox
              >
              <van-checkbox :name="13" class="checkbox" shape="square" v-if="isShow(collect.F12100,13)"
              >癫痫
              </van-checkbox
              >
              <van-checkbox :name="14" class="checkbox" shape="square" v-if="isShow(collect.F12100,14)"
              >肝硬化
              </van-checkbox
              >
              <van-checkbox :name="15" class="checkbox" shape="square" v-if="isShow(collect.F12100,15)"
              >甲亢/甲减
              </van-checkbox
              >
              <van-checkbox :name="16" class="checkbox" shape="square" v-if="isShow(collect.F12100,16)"
              >其他消化系统疾病
              </van-checkbox
              >
              <van-checkbox :name="17" class="checkbox" shape="square" v-if="isShow(collect.F12100,17)"
              >类风湿性关节炎
              </van-checkbox
              >
              <van-checkbox :name="18" class="checkbox" shape="square" v-if="isShow(collect.F12100,18)"
              >病毒性肝炎
              </van-checkbox
              >
              <van-checkbox :name="19" class="checkbox" shape="square" v-if="isShow(collect.F12100,19)"
              >性病
              </van-checkbox
              >
              <van-checkbox
                :name="20"
                class="checkbox"
                shape="square"
                v-if="isShow(collect.F12100,20)"
              >其它
              </van-checkbox
              >
            </van-checkbox-group>
          </div>
        </div>
        <div class="Q" v-if="include(collect.F12100,20)">
          <div class="first" id="F12454">
          </div>
          <van-field disabled
                     v-model="collect.F12454"
                     label="其他"
                     placeholder="..."
                     input-align="right"
          />
        </div>

        <div class="first" id="F12101"></div>
        <!-- 25 -->
        <div class="Q">
          <div class="q-title">25.最近14天连续接受的医疗和康复治疗</div>
          <div class="q-content">
            <van-checkbox-group disabled
                                v-model="collect.F12101"
                                direction="horizontal"
            >
              <van-checkbox :name="1" class="checkbox" shape="square" v-if="isShow(collect.F12101,1)"
              >口服药物
              </van-checkbox
              >
              <van-checkbox :name="2" class="checkbox" shape="square" v-if="isShow(collect.F12101,2)"
              >肌肉注射
              </van-checkbox
              >
              <van-checkbox :name="3" class="checkbox" shape="square" v-if="isShow(collect.F12101,3)"
              >静脉注射
              </van-checkbox
              >
              <van-checkbox :name="4" class="checkbox" shape="square" v-if="isShow(collect.F12101,4)"
              >康复治疗
              </van-checkbox
              >
              <van-checkbox :name="5" class="checkbox" shape="square" v-if="isShow(collect.F12101,5)"
              >吸氧治疗
              </van-checkbox
              >
              <van-checkbox :name="6" class="checkbox" shape="square" v-if="isShow(collect.F12101,6)"
              >鼻饲营养
              </van-checkbox
              >
              <van-checkbox :name="7" class="checkbox" shape="square" v-if="isShow(collect.F12101,7)"
              >肠外营养
              </van-checkbox
              >
              <van-checkbox :name="8" class="checkbox" shape="square" v-if="isShow(collect.F12101,8)"
              >中心静脉营养
              </van-checkbox
              >
              <van-checkbox :name="9" class="checkbox" shape="square" v-if="isShow(collect.F12101,9)"
              >疼痛护理
              </van-checkbox
              >
              <van-checkbox :name="10" class="checkbox" shape="square" v-if="isShow(collect.F12101,10)"
              >压伤护理
              </van-checkbox
              >
              <van-checkbox :name="11" class="checkbox" shape="square" v-if="isShow(collect.F12101,11)"
              >血透治疗
              </van-checkbox
              >
              <van-checkbox :name="12" class="checkbox" shape="square" v-if="isShow(collect.F12101,12)"
              >腹透治疗
              </van-checkbox
              >
              <van-checkbox :name="13" class="checkbox" shape="square" v-if="isShow(collect.F12101,13)"
              >生命体征监测
              </van-checkbox
              >
              <van-checkbox :name="14" class="checkbox" shape="square" v-if="isShow(collect.F12101,14)"
              >留置导尿管
              </van-checkbox
              >
              <van-checkbox :name="15" class="checkbox" shape="square" v-if="isShow(collect.F12101,15)"
              >人工呼吸器
              </van-checkbox
              >
              <van-checkbox :name="16" class="checkbox" shape="square" v-if="isShow(collect.F12101,16)"
              >气管切开护理
              </van-checkbox
              >
              <van-checkbox :name="17" class="checkbox" shape="square" v-if="isShow(collect.F12101,17)"
              >无
              </van-checkbox
              >
            </van-checkbox-group>
          </div>
        </div>
        <!-- 26 -->
        <div class="first" id="F12103"></div>
        <div class="Q">
          <div class="q-title">26.疾病对生存的影响</div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12103">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12103,1)">1.&nbsp;没有疾病，或者疾病对健康基本没有影响
              </van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12103,2)">2.&nbsp;患有疾病，较大程度地影响患者的基本生活
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12103,3)">3.&nbsp;疾病或者症状严重但病情稳定，不威胁生命
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12103,4)">4.&nbsp;疾病或者症状严重且病情时常恶化，可能会威胁生命
              </van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12103,5)">5.&nbsp;疾病或者症状非常严重，处于生命的终末期
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 27 -->
        <div class="first" id="buchong2"></div>
        <div class="Q">
          <div class="q-title1">情况描述</div>
          <div class="q-title2">请详细描述“能力打分”判断依据，并辅以照片录音说明情况。</div>
          <div class="q-content">
            <textarea disabled v-model="value.buchong2" cols="30" rows="10"></textarea>
          </div>
        </div>
      </van-tab>
      <van-tab style="padding: 0px 3% 60px 3%" title="特定疾病信息" class="specific-diseases">

        <div class="B">
          <div class="q-title3">以下项目由医务人员，根据病人主诉或经查体后记录。</div>
          <div class="q-title3">相关检查指标，可参照门诊病历、出院小结等。</div>
        </div>
        <div class="Q" v-if="isShow(collect.F12108,1)">
          <div class="q-title">慢性阻塞性肺疾病
            <div class="q-title4">
              第一项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" v-model="collect.F12108" class="checkbox" shape="square" disabled
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12108 == 1">
          <div class="first" id="F12109">
          </div>
          <div class="q-title5">局部症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12109" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12109,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12109,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12109,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12109,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12109,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12110"></div>
          <div class="q-title6">1.&nbsp; 慢性咳嗽
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12110" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12110,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12110,2)">2.&nbsp;偶尔咳嗽</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12110,3)">3.&nbsp;反复咳嗽</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12110,4)">4.&nbsp;持续咳嗽</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12111"></div>
          <div class="q-title6">2.&nbsp; 咳痰
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12111" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12111,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12111,2)">2.&nbsp;偶尔咳白或黏痰</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12111,3)">3.&nbsp;反复咳脓痰</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12111,4)">4.&nbsp;血痰</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12112"></div>
          <div class="q-title6"> 3. 气短
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12112" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12112,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12112,2)">2.&nbsp;劳力时气短</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12112,3)">3.&nbsp;日常活动时气短</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12112,4)">4.&nbsp;静息时气短</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12113"></div>
          <div class="q-title6"> 4. 呼吸困难
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12113" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12113,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12113,2)">2.&nbsp;劳力性呼吸困难</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12113,3)">3.&nbsp;夜间阵发性呼吸困难</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12113,4)">4.&nbsp;端坐呼吸</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12114"></div>
          <div class="q-title6"> 5. 喘息
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12114" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12114,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12114,2)">2.&nbsp;轻度喘息</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12114,3)">3.&nbsp;中度喘息</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12114,4)">4.&nbsp;重度喘息、喘憋</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12115"></div>
          <div class="q-title6"> 6. 胸闷
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12115" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12115,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12115,2)">2.&nbsp;阵发性胸闷</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12115,3)">3.&nbsp;持续性胸闷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12116"></div>
          <div class="q-title5">全身症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12116" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12116,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12116,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12116,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12116,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12116,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12117"></div>
          <div class="q-title6">1.&nbsp; 食欲
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12117" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12117,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12117,2)">2.&nbsp;减退</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12117,3)">3.&nbsp;拒绝进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12118"></div>
          <div class="q-title6">2.&nbsp; 体重
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12118" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12118,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12118,2)">2.&nbsp;消瘦</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12118,3)">3.&nbsp;恶病质</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12119"></div>
          <div class="q-title6"> 3. 外周肌力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12119" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12119,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12119,2)">2.&nbsp;萎缩</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12119,3)">3.&nbsp;功能障碍</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12120"></div>
          <div class="q-title6"> 4. 精神状态
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12120" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12120,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12120,2)">2.&nbsp;抑郁和（或）焦虑</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12121"></div>
          <div class="q-title5">体征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12121" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12121,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12121,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12121,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12121,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12121,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12122"></div>
          <div class="q-title6">1.&nbsp; 胸廓形状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12122" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12122,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12122,2)">2.&nbsp;前后径增大</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12122,3)">3.&nbsp;桶状胸</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12123"></div>
          <div class="q-title6">2.&nbsp; 紫绀
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12123" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12123,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12123,2)">2.&nbsp;口唇紫绀</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12123,3)">3.&nbsp;肢体末端紫绀</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12123,4)">4.&nbsp;全身性紫绀</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12124"></div>
          <div class="q-title6"> 3. 水肿
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12124" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12124,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12124,2)">2.&nbsp;下肢水肿</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12124,3)">3.&nbsp;肝脏肿大</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12124,4)">4.&nbsp;全身水肿</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12125"></div>
          <div class="q-title6"> 4. 肺叩诊
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12125" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12125,1)">1.&nbsp;清音</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12125,2)">2.&nbsp;过清音</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12126"></div>
          <div class="q-title6"> 5. 呼吸音
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12126" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12126,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12126,2)">2.&nbsp;减弱</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12126,3)">3.&nbsp;干和（或）湿啰音</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12126,4)">4.&nbsp;呼吸音未及</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12127"></div>
          <div class="q-title6"> 6. 心音
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12127" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12127,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12127,2)">2.&nbsp;低钝</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12127,3)">3.&nbsp;遥远</van-radio>
            </van-radio-group>
          </div>
          <!-- 辅助检查 -->
          <div class="first" id="F12128"></div>
          <div class="q-title5">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12128" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12128,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12128,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12128,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12128,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12128,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12129"></div>
          <div class="q-title6">1.&nbsp; 血象
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12129" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12129,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12129,2)">2.&nbsp;10×109/L &lt; WBC &lt;
                15×109/L
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12129,3)">3.&nbsp;WBC &gt; 15×109/L 或 &lt;
                4×109/L
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12130"></div>
          <div class="q-title6">2.&nbsp; 影像学
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12130" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12130,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12130,2)">2.&nbsp;肺纹理增粗、紊乱</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12130,3)">3.&nbsp;肺气肿</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12130,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12131"></div>
          <div class="q-title6"> 3. 血气
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12131" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12131,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12131,2)">2.&nbsp;PaO2低，PaCO2正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12131,3)">3.&nbsp;PaO2低，伴PaCO2升高</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12131,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12132"></div>
          <div class="q-title6"> 4. 肺功能
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12132" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12132,1)">1.&nbsp;FEV1/FVC&gt;70%,FEV1&gt;=80%
              </van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12132,2)">2.&nbsp;FEV1/FVC&lt;=70%,FEV1&gt;=80%
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12132,3)">3.&nbsp;FEV1/FVC&lt;=70%,FEV1在50%~80%
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12132,4)">4.&nbsp;FEV1/FVC&lt;=70%,FEV1在30%~50%
              </van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12132,5)">5.&nbsp;FEV1/FVC&lt;=70%,FEV1&lt;30%
              </van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12132,6)">6.未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12135"></div>
          <div class="q-title5">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12135" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12135,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12135,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12135,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12135,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12135,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12136"></div>
          <div class="q-title6">1.&nbsp; 肺心病
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12136" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12136,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12136,2)">2.&nbsp;有，心功能不全</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12136,3)">3.&nbsp;心力衰竭</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12137"></div>
          <div class="q-title6">2.&nbsp; 肺性脑病
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12137" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12137,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12137,2)">2.&nbsp;有，典型精神症状，但未昏迷</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12137,3)">3.&nbsp;有，昏迷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12138"></div>
          <div class="q-title6"> 3. 呼吸衰竭
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12138" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12138,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12138,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12139"></div>
          <div class="q-title6"> 4. 肺部感染
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12139" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12139,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12139,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12140"></div>
          <div class="q-title5">疾病的治疗强度需求
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12140" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12140,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12140,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12140,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12140,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12140,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12141"></div>
          <div class="q-title5">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12141" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12141,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12141,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12141,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12141,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12141,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12141,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 糖尿病 -->
        <div class="Q" v-if="isShow(collect.F12142,1)">
          <div class="q-title">糖尿病
            <div class="q-title4">
              第二项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" v-model="collect.F12142" class="checkbox" shape="square"
                          v-if="isShow(collect.F12142,1)"
                          disabled
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12142 == 1">
          <div class="first" id="F12143">
          </div>
          <div class="q-title5">症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12143" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12143,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12143,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12143,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12143,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12143,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12144"></div>
          <div class="q-title6">1.&nbsp; 口渴
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12144" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12144,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12144,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12144,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12145"></div>
          <div class="q-title6">2.&nbsp; 多饮
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12145" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12145,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12145,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12145,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12146"></div>
          <div class="q-title6"> 3. 多尿
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12146" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12146,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12146,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12146,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12147"></div>
          <div class="q-title6"> 4. 多食
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12147" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12147,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12147,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12147,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12148"></div>
          <div class="q-title6"> 5. 消瘦
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12148" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12148,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12148,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12148,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12149"></div>
          <div class="q-title6"> 6. 乏力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12149" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12149,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12149,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12149,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12150"></div>
          <div class="q-title5">体征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12150" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12150,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12150,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12150,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12150,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12150,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12151"></div>
          <div class="q-title6">1.&nbsp; 神志
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12151" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12151,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12151,2)">2.&nbsp;嗜睡或淡漠</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12151,3)">3.&nbsp;昏迷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12152"></div>
          <div class="q-title6">2.&nbsp; 脱水症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12152" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12152,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12152,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12152,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12153"></div>
          <div class="q-title6"> 3. 血压
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12153" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12153,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12153,2)">2.&nbsp;80~89/50~59mmHg</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12153,3)">3.&nbsp;&lt;80mmHg/50mmHg
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12153,4)">4.&nbsp;其他数据</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12154"></div>
          <div class="q-title6"> 4. 心率
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12154" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12154,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12154,2)">2.&nbsp;110~120次/分</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12154,3)">3.&nbsp;&gt;120次/分</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12155"></div>
          <div class="q-title6"> 5. 糖尿病足
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12155" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12155,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12155,2)">2.&nbsp;1~2级</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12155,3)">3.&nbsp;3~4级</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12155,4)">4.&nbsp;5级</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12156"></div>
          <div class="q-title5">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12156" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12156,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12156,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12156,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12156,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12156,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12157"></div>
          <div class="q-title6">1.&nbsp; 血糖
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12157" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12157,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12157,2)">2.&nbsp;8~11.1mmol/L</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12157,3)">3.&nbsp;11.2~16.7mmol/L</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12157,4)">4.&nbsp;16.8~33.3mmol/L</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12157,5)">5.&nbsp;&gt;33.3mmol/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12158"></div>
          <div class="q-title6">2.&nbsp; 糖化血红蛋白
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12158" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12158,1)">1.&nbsp;&lt;7%</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12158,2)">2.&nbsp;7%~8%</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12158,3)">3.&nbsp;&gt;8%</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12158,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12159"></div>
          <div class="q-title6"> 3. 尿酮
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12159" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12159,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12159,2)">2.&nbsp;阳性或（增高）</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12159,3)">3.&nbsp;强阳性或（明显增高）</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12160"></div>
          <div class="q-title6">4.&nbsp; 血肌酐
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12160" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12160,1)">1.&nbsp;&lt;133μ mol/L</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12160,2)">2.&nbsp;133~221μ mol/L</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12160,3)">3.&nbsp;222~442μ mol/L</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12160,4)">4.&nbsp;&gt;442μ mol/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12161"></div>
          <div class="q-title6"> 5. 血浆渗透压
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12161" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12161,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12161,2)">2.&nbsp;300~320mOsm/KgH2O
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12161,3)">3.&nbsp;&gt;320mOsm/KgH2O
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12161,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12162"></div>
          <div class="q-title5">并发症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12162" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12162,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12162,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12162,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12162,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12162,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12163"></div>
          <div class="q-title6">1.&nbsp; 急慢性感染
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12163" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12163,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12163,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12164"></div>
          <div class="q-title6">2.&nbsp; 肾病
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12164" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12164,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12164,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12165"></div>
          <div class="q-title6"> 3. 视网膜病变
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12165" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12165,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12165,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12166"></div>
          <div class="q-title5">疾病的治疗强度需求
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12166" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12166,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12166,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12166,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12166,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12166,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12167"></div>
          <!-- 六个月内病情稳定性 -->
          <div class="q-title5">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12167" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12167,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12167,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12167,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12167,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12167,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12167,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="Q" v-if="isShow(collect.F12168,1)">
          <div class="q-title">慢性肺炎
            <div class="q-title4">
              第三项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" v-model="collect.F12168" class="checkbox" shape="square"
                          v-if="isShow(collect.F12168,1)"
                          disabled
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12168 == 1">
          <div class="first" id="F12169">
          </div>
          <div class="q-title5">症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12169" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12169,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12169,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12169,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12169,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12169,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12170"></div>
          <div class="q-title6">1.&nbsp; 咳嗽
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12170" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12170,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12170,2)">2.&nbsp;阵发性咳嗽</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12170,3)">3.&nbsp;反复咳嗽</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12170,4)">4.&nbsp;持续咳嗽</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12171"></div>
          <div class="q-title6">2.&nbsp; 咳痰
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12171" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12171,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12171,2)">2.&nbsp;偶尔咳白或黏痰</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12171,3)">3.&nbsp;反复咳黄、脓痰</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12171,4)">4.&nbsp;大量黄黏稠痰</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12172"></div>
          <div class="q-title6"> 3. 呼吸频率
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12172" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12172,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12172,2)">2.&nbsp;浅快20~25次/分</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12172,3)">3.&nbsp;&gt;25次/分</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12172,4)">4.&nbsp;呼吸节律紊乱</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12173"></div>
          <div class="q-title6"> 4. 呼吸困难
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12173" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12173,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12173,2)">2.&nbsp;劳力性呼吸困难</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12173,3)">3.&nbsp;夜间阵发性呼吸困难</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12173,4)">4.&nbsp;端坐呼吸</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12174"></div>
          <div class="q-title6"> 5. 喘息
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12174" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12174,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12174,2)">2.&nbsp;轻度喘息</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12174,3)">3.&nbsp;中度喘息</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12174,4)">4.&nbsp;重度喘息、喘憋</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12175"></div>
          <div class="q-title6">6. 胸闷
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12175" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12175,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12175,2)">2.&nbsp;阵发性胸闷</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12175,3)">3.&nbsp;持续性胸闷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12176"></div>
          <div class="q-title5">全身症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12176" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12176,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12176,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12176,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12176,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12176,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12177"></div>
          <div class="q-title6">1.&nbsp; 食欲
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12177" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12177,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12177,2)">2.&nbsp;恶心、呕吐</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12177,3)">3.&nbsp;进食困难</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12178"></div>
          <div class="q-title6">2.&nbsp; 体重
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12178" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12178,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12178,2)">2.&nbsp;消瘦</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12178,3)">3.&nbsp;恶病质</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12179"></div>
          <div class="q-title6"> 3. 发热情况
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12179" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12179,1)">1.&nbsp;≤37.3℃</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12179,2)">2.&nbsp;37.4~39℃</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12179,3)">3.&nbsp;&gt;39℃</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12180"></div>
          <div class="q-title6"> 4. 精神状态
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12180" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12180,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12180,2)">2.&nbsp;萎软、嗜睡</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12180,3)">3.&nbsp;抑郁和（或）焦虑</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12181"></div>
          <div class="q-title5">体征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12181" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12181,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12181,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12181,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12181,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12181,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12182"></div>
          <div class="q-title6">1.&nbsp; 呼吸运动
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12182" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12182,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12182,2)">2.&nbsp;受限</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12183"></div>
          <div class="q-title6">2.&nbsp; 紫绀
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12183" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12183,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12183,2)">2.&nbsp;口唇紫绀</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12183,3)">3.&nbsp;肢体末端紫绀</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12183,4)">4.&nbsp;全身性紫绀</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12184"></div>
          <div class="q-title6"> 3. 触诊
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12184" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12184,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12184,2)">2.&nbsp;语颤增强</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12185"></div>
          <div class="q-title6">4.&nbsp; 叩诊
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12185" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12185,1)">1.&nbsp;清音</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12185,2)">2.&nbsp;浊音或实音</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12186"></div>
          <div class="q-title6"> 5. 听诊
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12186" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12186,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12186,2)">2.&nbsp;多肺段干、湿啰音</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12186,3)">3.&nbsp;局限干、湿啰音</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12186,4)">4.&nbsp;两肺野广泛干、湿啰音</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12187"></div>
          <div class="q-title6"> 6. 心音
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12187" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12187,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12187,2)">2.&nbsp;低钝</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12187,3)">3.&nbsp;遥远</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12188"></div>
          <!-- 辅助检查 -->
          <div class="q-title5">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12188" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12188,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12188,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12188,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12188,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12188,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12189"></div>
          <div class="q-title6">1.&nbsp; 血象
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12189" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12189,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12189,2)">2.&nbsp;4×109/L&lt;WBC&lt;15×109/L,中性粒细胞50%~85%</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12189,3)">3.&nbsp;WBC&gt;15×109/L或&lt;4×109/L,中性粒细胞&gt;85%</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12190"></div>
          <div class="q-title6">2.&nbsp; 影像学
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12190" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12190,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12190,2)">2.&nbsp;局限肺纹理增粗、斑片状阴影</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12190,3)">3.&nbsp;大片模糊浸润阴影</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12190,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12191"></div>
          <div class="q-title6"> 3. 血气
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12191" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12191,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12191,2)">2.&nbsp;PaO2低，PaCO2正常，PH
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12192"></div>
          <div class="q-title6"> 4. 肺功能
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12192" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12192,1)">1.&nbsp;FEV1/FVC&gt;70%,FEV1&gt;=80%
              </van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12192,2)">2.&nbsp;FEV1/FVC&lt;=70%,
                FEV1在50%~80%
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12192,3)">3.&nbsp;FEV1/FVC&lt;=70%,FEV1&lt;50%
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12192,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12193"></div>
          <div class="q-title6"> 5. 痰培养
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12193" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12193,1)">1.&nbsp;阴性</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12193,2)">2.&nbsp;阳性致病菌</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12193,3)">3.&nbsp;未检测
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12194"></div>
          <div class="q-title5">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12194" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12194,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12194,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12194,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12194,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12194,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12195"></div>
          <div class="q-title6">1.&nbsp; 心力衰竭
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12195" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12195,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12195,2)">2.&nbsp;轻度心功能不全</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12195,3)">3.&nbsp;中、重度心衰</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12196"></div>
          <div class="q-title6">2.&nbsp; 肺性脑病
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12196" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12196,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12196,2)">2.&nbsp;嗜睡、谵妄、震颤</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12196,3)">3.&nbsp;昏迷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12197"></div>
          <div class="q-title6">3.&nbsp; 呼吸衰竭
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12197" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12197,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12197,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12198"></div>
          <div class="q-title5">疾病的治疗强度需求
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12198" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12198,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12198,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12198,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12198,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12198,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12199"></div>
          <div class="q-title5">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12199" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12199,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12199,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12199,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12199,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12199,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12199,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 下肢骨折 -->
        <div class="Q" v-if="isShow(collect.F12200,1)">
          <div class="q-title">下肢骨折
            <div class="q-title4">
              第四项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" v-model="collect.F12200" class="checkbox" shape="square" disabled
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12200 == 1">
          <div class="first" id="F12201">
          </div>
          <div class="q-title5">局部症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12201" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12201,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12201,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12201,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12201,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12201,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12202"></div>
          <div class="q-title6">1.&nbsp; 疼痛
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12202" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12202,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12202,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12202,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12203"></div>
          <div class="q-title6">2.&nbsp; 肿胀
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12203" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12203,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12203,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12203,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12204"></div>
          <div class="q-title6">3.&nbsp; 伤口感染
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12204" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12204,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12204,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12204,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12205"></div>
          <div class="q-title5">全身症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12205" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12205,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12205,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12205,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12205,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12205,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12206"></div>
          <div class="q-title6">1.&nbsp; 休克
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12206" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12206,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12206,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12207"></div>
          <div class="q-title6">2.&nbsp; 发热
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12207" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12207,1)">1.&nbsp;体温正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12207,2)">2.&nbsp;37.5℃~39℃</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12207,3)">3.&nbsp;&gt;39℃</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12208"></div>
          <div class="q-title5">体征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12208" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12208,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12208,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12208,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12208,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12208,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12209"></div>
          <div class="q-title6">1.&nbsp; 畸形
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12209" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12209,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12209,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12209,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12210"></div>
          <div class="q-title6">2.&nbsp; 活动异常
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12210" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12210,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12210,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12210,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12211"></div>
          <div class="q-title6"> 3. 骨擦音或骨擦感
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12211" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12211,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12211,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12211,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12212"></div>
          <div class="q-title6">4.&nbsp; 运动障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12212" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12212,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12212,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12212,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12213"></div>
          <div class="q-title6"> 5. 感觉功能障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12213" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12213,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12213,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12213,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12214"></div>
          <div class="q-title6">6. 肌萎缩
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12214" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12214,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12214,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12214,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12215"></div>
          <div class="q-title5">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12215" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12215,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12215,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12215,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12215,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12215,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12216"></div>
          <div class="q-title6">1.&nbsp; X线检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12216" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12216,1)">1.&nbsp;骨折线消失，对位对线良好</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12216,2)">2.&nbsp;骨折线模糊，对位对线尚可</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12216,3)">3.&nbsp;骨折线模糊，对位对线欠佳</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12216,4)">4.&nbsp;骨折线明显，对位对线欠佳</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12216,5)">5.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12217"></div>
          <div class="q-title5">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12217" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12217,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12217,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12217,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12217,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12217,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12218"></div>
          <div class="q-title6">1.&nbsp; 重要血管损伤
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12218" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12218,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12218,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12219"></div>
          <div class="q-title6">2.&nbsp; 周围神经损伤
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12219" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12219,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12219,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12220"></div>
          <div class="q-title6">3.&nbsp; 骨不连
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12220" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12220,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12220,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12221"></div>
          <div class="q-title6">4.&nbsp; 下肢静脉血栓形成
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12221" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12221,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12221,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12222"></div>
          <div class="q-title5">疾病的治疗强度需求
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12222" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12222,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12222,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12222,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12222,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12222,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12223"></div>
          <div class="q-title5">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12223" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12223,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12223,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12223,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12223,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12223,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12223,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 帕金森病 -->
        <div class="Q" v-if="isShow(collect.F12224,1)">
          <div class="q-title">帕金森病
            <div class="q-title4">
              第五项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" v-model="collect.F12224" class="checkbox" shape="square"
                          v-if="isShow(collect.F12224,1)"
                          disabled
            >是
            </van-checkbox>
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12224 == 1">
          <!-- 局部症状 -->
          <div class="first" id="F12225">
          </div>
          <div class="q-title5">局部症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12225" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12225,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12225,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12225,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12225,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12225,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12226"></div>
          <div class="q-title6">1.&nbsp; 静止性震颤
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12226" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12226,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12226,2)">2.&nbsp;单个肢体</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12226,3)">3.&nbsp;双个肢体</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12226,4)">4.&nbsp;多个肢体</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12227"></div>
          <div class="q-title6">2.&nbsp; 肌强直
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12227" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12227,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12227,2)">2.&nbsp;单个肢体</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12227,3)">3.&nbsp;双个肢体</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12227,4)">4.&nbsp;多个肢体</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12227,5)">5.&nbsp;全身僵硬</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12228"></div>
          <div class="q-title6">3.&nbsp; 运动迟缓
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12228" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12228,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12228,2)">2.&nbsp;随意动作减少</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12228,3)">3.&nbsp;主动运动缓慢</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12228,4)">4.&nbsp;表情呆板</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12228,5)">5.&nbsp;步态异常</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12229"></div>
          <div class="q-title6">4.&nbsp; 姿势步态异常
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12229" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12229,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12229,2)">2.&nbsp;行走拖步或小步态</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12229,3)">3.&nbsp;行走困难</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12229,4)">4.&nbsp;能坐但起立困难</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12229,5)">5.&nbsp;卧床不起</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12230"></div>
          <div class="q-title6">5.&nbsp; 吞咽困难
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12230" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12230,1)">1\.无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12230,2)">2.&nbsp;极少呛咳</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12230,3)">3.&nbsp;偶尔呛咳</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12230,4)">4.&nbsp;反复呛咳</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12230,5)">5.&nbsp;不能进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12231"></div>
          <div class="q-title6">6. 言语障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12231" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12231,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12231,2)">2.&nbsp;语速减慢</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12231,3)">3.&nbsp;发音过弱</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12231,4)">4.&nbsp;讲话时流涎</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12232"></div>
          <div class="q-title5">全身症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12232" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12232,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12232,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12232,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12232,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12232,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12233"></div>
          <div class="q-title6">1.&nbsp; 食欲
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12233" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12233,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12233,2)">2.&nbsp;减退</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12233,3)">3.&nbsp;不思饮食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12234"></div>
          <div class="q-title6">2.&nbsp; 体重
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12234" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12234,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12234,2)">2.&nbsp;消瘦</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12234,3)">3.&nbsp;恶病质</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12235"></div>
          <div class="q-title6">3.&nbsp; 外周肌力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12235" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12235,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12235,2)">2.&nbsp;萎缩</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12235,3)">3.&nbsp;功能障碍</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12236"></div>
          <div class="q-title5">体征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12236" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12236,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12236,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12236,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12236,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12236,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12237"></div>
          <div class="q-title6">1.&nbsp; 静止性震颤
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12237" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12237,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12237,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12238"></div>
          <div class="q-title6">2.&nbsp; 肌力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12238" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12238,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12238,2)">2.&nbsp;Ⅳ级</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12238,3)">3.&nbsp;Ⅲ级</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12238,4)">4.&nbsp;Ⅱ级</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12238,5)">5.&nbsp;Ⅰ级</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12238,6)">6.&nbsp;0级</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12239"></div>
          <div class="q-title6"> 3. 肌力张
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12239" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12239,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12239,2)">2.&nbsp;增高</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12239,3)">3.&nbsp;严重增高</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12240"></div>
          <div class="q-title6">4.&nbsp; 直立性低血压
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12240" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12240,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12240,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12241"></div>
          <div class="q-title6"> 5. Myerson征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12241" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12241,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12241,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12242"></div>
          <div class="q-title5">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12242" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12242,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12242,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12242,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12242,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12242,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12243"></div>
          <div class="q-title6">1.&nbsp; Hoehn-Yahr分级
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12243" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12243,1)">1.&nbsp;Ⅰ期</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12243,2)">2.&nbsp;Ⅱ~Ⅲ期</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12243,3)">3.&nbsp;Ⅳ期</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12243,4)">4.&nbsp;Ⅴ期</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12243,5)">5.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12244"></div>
          <div class="q-title5">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12244" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12244,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12244,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12244,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12244,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12244,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12245"></div>
          <div class="q-title6">1.&nbsp; 肺部炎症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12245" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12245,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12245,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12246"></div>
          <div class="q-title6">2.&nbsp; 骨折
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12246" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12246,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12246,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12247"></div>
          <div class="q-title6">3.&nbsp; 认知障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12247" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12247,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12247,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12248"></div>
          <div class="q-title5">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12248" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12248,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12248,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12248,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12248,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12248,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12249"></div>
          <div class="q-title5">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12249" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12249,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12249,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12249,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12249,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12249,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12249,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 脑出血 -->
        <div class="Q" v-if="isShow(collect.F12250,1)">
          <div class="q-title">脑出血
            <div class="q-title4">
              第六项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" v-model="collect.F12250" class="checkbox" shape="square" disabled
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12250 == 1">
          <!-- 局部症状 -->
          <div class="first" id="F12251">
          </div>
          <div class="q-title5">局部症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12251" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12251,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12251,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12251,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12251,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12251,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12252"></div>
          <div class="q-title6">1.&nbsp; 意识水平
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12252" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12252,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12252,2)">2.&nbsp;嗜睡</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12252,3)">3.&nbsp;意识模糊</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12252,4)">4.&nbsp;浅昏迷</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12252,5)">5.&nbsp;深昏迷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12253"></div>
          <div class="q-title6">2.&nbsp; 语言功能障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12253" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12253,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12253,2)">2.&nbsp;基本可以交谈</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12253,3)">3.&nbsp;交谈费力</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12253,4)">4.&nbsp;只能发单音节</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12253,5)">5.&nbsp;失语</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12254"></div>
          <div class="q-title6">3.&nbsp; 吞咽功能障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12254" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12254,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12254,2)">2.&nbsp;极少呛咳</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12254,3)">3.&nbsp;偶尔呛咳</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12254,4)">4.&nbsp;反复呛咳</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12254,5)">5.&nbsp;不能进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12255"></div>
          <div class="q-title6">4.&nbsp; 视觉功能
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12255" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12255,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12255,2)">2.&nbsp;短暂偏盲</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12255,3)">3.&nbsp;持续性偏盲或视野缺损</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12256"></div>
          <div class="q-title6">5.&nbsp; 感觉功能
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12256" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12256,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12256,2)">2.&nbsp;浅感觉障碍</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12256,3)">3.&nbsp;深感觉障碍</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12257"></div>
          <div class="q-title6">6. 伴随症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12257" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12257,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12257,2)">2.&nbsp;轻度头痛或呕吐</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12257,3)">3.&nbsp;剧烈头痛或喷射性呕吐</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12258"></div>
          <div class="q-title5">全身症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12258" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12258,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12258,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12258,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12258,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12258,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12259"></div>
          <div class="q-title6">1.&nbsp; 食欲
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12259" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12259,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12259,2)">2.&nbsp;减退</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12259,3)">3.&nbsp;不思饮食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12260"></div>
          <div class="q-title6">2.&nbsp; 体重
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12260" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12260,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12260,2)">2.&nbsp;消瘦</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12260,3)">3.&nbsp;恶病质</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12261"></div>
          <div class="q-title6">3.&nbsp; 外周肌力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12261" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12261,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12261,2)">2.&nbsp;萎缩</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12261,3)">3.&nbsp;功能障碍</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12262"></div>
          <div class="q-title5">体征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12262" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12262,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12262,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12262,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12262,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12262,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12263"></div>
          <div class="q-title6">1.&nbsp; 肌力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12263" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12263,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12263,2)">2.&nbsp;Ⅳ级</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12263,3)">3.&nbsp;Ⅲ级</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12263,4)">4.&nbsp;Ⅱ级</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12263,5)">5.&nbsp;Ⅰ级</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12263,6)">6.&nbsp;0级</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12264"></div>
          <div class="q-title6">2.&nbsp; 肌张力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12264" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12264,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12264,2)">2.&nbsp;轻度增高或降低</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12264,3)">3.&nbsp;明显增高或降低</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12265"></div>
          <div class="q-title6"> 3. 瞳孔
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12265" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12265,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12265,2)">2.&nbsp;缩小，对光反射迟钝</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12265,3)">3.&nbsp;针尖样瞳孔</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12266"></div>
          <div class="q-title6">4.&nbsp; 脑膜刺激征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12266" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12266,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12266,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <!-- 辅助检查 -->
          <div class="first" id="F12267"></div>
          <div class="q-title5">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12267" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12267,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12267,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12267,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12267,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12267,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12268"></div>
          <div class="q-title6">1.&nbsp; 影像学（脑CT或MRI)
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12268" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12268,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12268,2)">2.&nbsp;密度增高</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12268,3)">3.&nbsp;多发性病灶</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12268,4)">4.&nbsp;未检测、其他</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12269"></div>
          <div class="q-title5">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12269" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12269,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12269,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12269,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12269,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12269,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12270"></div>
          <div class="q-title6">1.&nbsp; 血管性痴呆
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12270" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12270,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12270,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12271"></div>
          <div class="q-title6">2.&nbsp; 癫痫性发作
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12271" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12271,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12271,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12272"></div>
          <div class="q-title6">3.&nbsp; 肺部感染
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12272" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12272,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12272,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12273"></div>
          <div class="q-title6">4.&nbsp; 尿路感染
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12273" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12273,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12273,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12274"></div>
          <div class="q-title6">5.&nbsp; 压疮
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12274" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12274,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12274,2)">2.&nbsp;Ⅰ~Ⅱ期</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12274,3)">3.&nbsp;Ⅲ~Ⅳ期</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12275"></div>
          <div class="q-title6">6. 应激性溃疡
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12275" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12275,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12275,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12276"></div>
          <div class="q-title5">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12276" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12276,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12276,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12276,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12276,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12276,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12277"></div>
          <div class="q-title5">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12277" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12277,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12277,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12277,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12277,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12277,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12277,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>

        <!-- 高血压 -->
        <div class="Q" v-if="isShow(collect.F12278,1)">
          <div class="q-title">高血压
            <div class="q-title4">
              第七项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" v-model="collect.F12278" class="checkbox" shape="square" disabled
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12278 == 1">
          <!-- 局部症状 -->
          <div class="first" id="F12279">
          </div>
          <div class="q-title5">局部症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12279" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12279,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12279,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12279,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12279,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12279,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12280"></div>
          <div class="q-title6">1.&nbsp; 头晕
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12280" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12280,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12280,2)">2.&nbsp;短暂</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12280,3)">3.&nbsp;持续</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12281"></div>
          <div class="q-title6">2.&nbsp; 头痛
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12281" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12281,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12281,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12281,3)">3.&nbsp;剧烈</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12282"></div>
          <div class="q-title6">3.&nbsp; 心悸
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12282" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12282,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12282,2)">2.&nbsp;短暂</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12282,3)">3.&nbsp;持续</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12283"></div>
          <div class="q-title6">4.&nbsp; 气促
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12283" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12283,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12283,2)">2.&nbsp;劳力时</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12283,3)">3.&nbsp;日常活动时</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12283,4)">4.&nbsp;静息时</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12284"></div>
          <div class="q-title5">全身症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12284" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12284,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12284,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12284,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12284,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12284,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12285"></div>
          <div class="q-title6">1.&nbsp; 恶心呕吐
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12285" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12285,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12285,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12285,3)">3.&nbsp;剧烈</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12286"></div>
          <div class="q-title6">2.&nbsp; 乏力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12286" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12286,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12286,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12286,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12287"></div>
          <div class="q-title5">体征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12287" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12287,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12287,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12287,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12287,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12287,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12288"></div>
          <div class="q-title6">1.&nbsp; 神志
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12288" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12288,1)">1.&nbsp;清醒</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12288,2)">2.&nbsp;嗜睡</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12288,3)">3.&nbsp;神志不清</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12289"></div>
          <div class="q-title6">2.&nbsp; 血压
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12289" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12289,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12289,2)">2.&nbsp;141~159mmHg/91~99mmHg
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12289,3)">3.&nbsp;160~180mmHg/100~110mmHg
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12289,4)">4.&nbsp;&gt;180mmHg/110mmHg
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12290"></div>
          <div class="q-title6"> 3. 心脏增大
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12290" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12290,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12290,2)">2.&nbsp;向左扩大</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12291"></div>
          <div class="q-title6"> 4. 两肺啰音
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12291" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12291,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12291,2)">2.&nbsp;少许湿啰音</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12291,2)">3.&nbsp;满布湿啰音</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12292"></div>
          <div class="q-title5">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12292" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12292,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12292,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12292,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12292,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12292,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12293"></div>
          <div class="q-title6">1.&nbsp; 血肌酐
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12293" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12293,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12293,2)">2.&nbsp;133~220 μ mol/L</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12293,3)">3.&nbsp;≥221 μ mol/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12294"></div>
          <div class="q-title6">2.&nbsp; 心电图
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12294" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12294,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12294,2)">2.&nbsp;左心室增大伴劳损</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12295"></div>
          <div class="q-title6">3.&nbsp; X线胸片
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12295" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12295,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12295,2)">2.&nbsp;左心室增大</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12295,3)">3.&nbsp;肺水肿表现</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12295,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12296"></div>
          <div class="q-title6">4.&nbsp; 超声心动图
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12296" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12296,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12296,2)">2.&nbsp;左心室肥厚</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12296,3)">3.&nbsp;伴LVEF&lt;50%</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12296,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12297"></div>
          <div class="q-title6">5.&nbsp; 眼底检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12297" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12297,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12297,2)">2.&nbsp;视网膜动脉Ⅱ级改变</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12297,3)">3.&nbsp;≥Ⅲ级改变</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12297,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12298"></div>
          <div class="q-title5">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12298" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12298,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12298,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12298,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12298,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12298,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12299"></div>
          <div class="q-title6">1.&nbsp; 心力衰竭或高血压脑病或脑血管病
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12299" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12299,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12299,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12300"></div>
          <div class="q-title6">2.&nbsp; 肾功能不全
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12300" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12300,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12300,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12301"></div>
          <div class="q-title5">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12301" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12301,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12301,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12301,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12301,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12301,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12302"></div>
          <div class="q-title5">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12302" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12302,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12302,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12302,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12302,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12302,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12302,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 晚期肿瘤 -->
        <div class="Q" v-if="isShow(collect.F12303,1)">
          <div class="q-title">晚期肿瘤
            <div class="q-title4">
              第八项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" v-model="collect.F12303" class="checkbox" shape="square"
                          v-if="isShow(collect.F12303,1)"
                          disabled
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12303 == 1">
          <div class="first" id="F12304">
          </div>
          <div class="q-title5">症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12304" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12304,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12304,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12304,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12304,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12304,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12305"></div>
          <div class="q-title6">1.&nbsp; 意识
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12305" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12305,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12305,2)">2.&nbsp;嗜睡或淡漠</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12305,3)">3.&nbsp;谵妄</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12305,4)">4.&nbsp;昏迷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12306"></div>
          <div class="q-title6">2.&nbsp; 呼吸困难
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12306" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12306,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12306,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12306,3)">3.&nbsp;中度</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12306,4)">4.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12307"></div>
          <div class="q-title6">3.&nbsp; 进食困难
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12307" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12307,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12307,2)">2.&nbsp;流质</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12307,3)">3.&nbsp;鼻饲</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12307,4)">4.&nbsp;无法进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12308"></div>
          <div class="q-title6">4.&nbsp; 排便困难
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12308" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12308,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12308,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12309"></div>
          <div class="q-title6">5.&nbsp; 排尿困难
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12309" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12309,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12309,2)">2.&nbsp;有</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12309,3)">3.&nbsp;导尿</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12310"></div>
          <div class="q-title6">6. 出血
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12310" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12310,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12310,2)">2.&nbsp;皮肤黏膜出血</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12310,3)">3.&nbsp;咯血、呕血、便血、颅内出血等</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12311"></div>
          <div class="q-title6">7. 疼痛
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12311" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12311,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12311,2)">2.&nbsp;疼痛评分3~6分</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12311,3)">3.&nbsp;疼痛评分≥7分</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12312"></div>
          <div class="q-title6">8. 营养不良
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12312" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12312,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12312,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12312,3)">3.&nbsp;中度</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12312,4)">4.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12313"></div>
          <div class="q-title6">9. 乏力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12313" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12313,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12313,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12313,3)">3.&nbsp;中度</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12313,4)">4.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12314"></div>
          <div class="q-title5">体征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12314" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12314,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12314,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12314,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12314,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12314,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12315"></div>
          <div class="q-title6">1.&nbsp; 发热
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12315" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12315,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12315,2)">2.&nbsp;37.5℃~38℃</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12315,3)">3.&nbsp;38.1℃~39℃</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12315,4)">4.&nbsp;&lt;39℃</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12316"></div>
          <div class="q-title6">2.&nbsp; 黄疸
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12316" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12316,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12316,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12316,3)">3.&nbsp;明显</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12316,4)">4.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12317"></div>
          <div class="q-title6">3.&nbsp; 血压
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12317" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12317,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12317,2)">2.&nbsp;80~89mmHg/50~59mmHg
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12317,3)">3.&nbsp;&lt; 80mmHg/50mmHg
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12318"></div>
          <div class="q-title6">4.&nbsp; 心率
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12318" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12318,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12318,2)">2.&nbsp;100~120次/分</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12318,3)">3.&nbsp;&gt; 120次/分</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12319"></div>
          <div class="q-title6">5.&nbsp; 胸水或腹水
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12319" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12319,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12319,2)">2.&nbsp;少量</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12319,3)">3.&nbsp;大量</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12320"></div>
          <div class="q-title6">6. 水肿
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12320" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12320,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12320,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12320,3)">3.&nbsp;重度</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12321"></div>
          <div class="q-title6">7. 浅表淋巴结
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12321" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12321,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12321,2)">2.&nbsp;有肿大淋巴结</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12322"></div>
          <div class="q-title5">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12322" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12322,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12322,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12322,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12322,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12322,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12323"></div>
          <div class="q-title6">1.&nbsp; 血常规
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12323" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12323,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12323,2)">2.&nbsp;Hb91~110g/L 或
                WBC2.1~4.0×109/L 或
                PLT51~100×109/L
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12323,3)">3.&nbsp;Hb60~90g/L 或
                WBC0.5~2.0×109/L 或
                PLT20~50×109/L
              </van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12323,4)">4.&nbsp;Hb &lt; 60g/L 或 WBC &lt;
                0.5×109/L 或 PLT&lt; 20×109/L
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12324"></div>
          <div class="q-title6">2.&nbsp; 血肌酐
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12324" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12324,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12324,2)">2.&nbsp;133~221 μ mol/L</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12324,3)">3.&nbsp;222~442 μ mol/L</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12324,4)">4.&nbsp;&gt;442 μ mol/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12325"></div>
          <div class="q-title6"> 3. 白蛋白
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12325" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12325,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12325,2)">2.&nbsp;28~35g/L</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12325,3)">3.&nbsp;&lt; 28g/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12326"></div>
          <div class="q-title6"> 4. ALT
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12326" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12326,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12326,2)">2.&nbsp;41~100U/L</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12326,3)">3.&nbsp;&gt; 100U/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12327"></div>
          <div class="q-title6"> 5. 胆红素
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12327" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12327,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12327,2)">2.&nbsp;17~35mmol/L</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12327,3)">3.&nbsp;36~51mmol/L</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12327,4)">4.&nbsp;&gt; 51mmol/L</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12328"></div>
          <div class="q-title6"> 6. 血气
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12328" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12328,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12328,2)">2.&nbsp;PaO2降低，PaCO2正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12328,3)">3.&nbsp;PaO2降低，PaCO2升高</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12328,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12329"></div>
          <div class="q-title6"> 7. 电解质
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12329" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12329,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12329,2)">2.&nbsp;轻度紊乱</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12329,3)">3.&nbsp;中度紊乱</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12329,4)">4.&nbsp;重度紊乱</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12329,5)">5.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12330"></div>
          <div class="q-title6"> 8. 超声
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12330" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12330,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12330,2)">2.&nbsp;少量胸水或腹水</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12330,3)">3.&nbsp;大量胸水或腹水</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12330,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12331"></div>
          <div class="q-title5">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12331" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12331,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12331,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12331,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12331,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12331,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12332"></div>
          <div class="q-title6">1.&nbsp; 心力衰竭
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12332" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12332,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12332,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12333"></div>
          <div class="q-title6">2.&nbsp; 继发感染
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12333" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12333,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12333,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12334"></div>
          <div class="q-title5">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12334" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12334,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12334,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12334,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12334,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12334,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12335"></div>
          <div class="q-title5">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12335" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12335,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12335,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12335,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12335,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12335,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12335,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 脑梗死 -->
        <div class="Q" v-if="isShow(collect.F12336,1)">
          <div class="q-title">脑梗死
            <div class="q-title4">
              第九项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" v-model="collect.F12336" class="checkbox" shape="square" disabled
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12336 == 1">
          <div class="first" id="F12337">
          </div>
          <div class="q-title5">局部症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12337" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12337,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12337,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12337,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12337,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12337,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12338"></div>
          <div class="q-title6">1.&nbsp; 意识水平
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12338" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12338,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12338,2)">2.&nbsp;嗜睡</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12338,3)">3.&nbsp;意识模糊</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12338,4)">4.&nbsp;浅昏迷</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12338,5)">5.&nbsp;深昏迷</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12339"></div>
          <div class="q-title6">2.&nbsp; 语言功能障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12339" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12339,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12339,2)">2.&nbsp;基本可以交谈</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12339,3)">3.&nbsp;交谈费力</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12339,4)">4.&nbsp;只能发单音节</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12339,5)">5.&nbsp;失语</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12340"></div>
          <div class="q-title6">3.&nbsp; 吞咽功能障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12340" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12340,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12340,2)">2.&nbsp;极少呛咳</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12340,3)">3.&nbsp;偶尔呛咳</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12340,4)">4.&nbsp;反复呛咳</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12340,5)">5.&nbsp;不能进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12341"></div>
          <div class="q-title6">4.&nbsp; 视觉功能
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12341" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12341,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12341,2)">2.&nbsp;短暂偏盲</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12341,3)">3.&nbsp;持续性偏盲或视野缺损</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12342"></div>
          <div class="q-title6">5.&nbsp; 感觉功能
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12342" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12342,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12342,2)">2.&nbsp;浅感觉障碍</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12342,3)">3.&nbsp;深感觉障碍</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12343"></div>
          <div class="q-title6">6. 伴随症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12343" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12343,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12343,2)">2.&nbsp;轻度头痛或呕吐</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12343,3)">3.&nbsp;剧烈头痛或喷射性呕吐</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12344"></div>
          <div class="q-title5">全身症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12344" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12344,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12344,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12344,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12344,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12344,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12345"></div>
          <div class="q-title6">1.&nbsp; 食欲
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12345" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12345,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12345,2)">2.&nbsp;减退</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12345,3)">3.&nbsp;不思饮食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12346"></div>
          <div class="q-title6">2.&nbsp; 体重
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12346" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12346,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12346,2)">2.&nbsp;消瘦</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12346,3)">3.&nbsp;恶病质</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12347"></div>
          <div class="q-title6">3.&nbsp; 外周肌力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12347" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12347,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12347,2)">2.&nbsp;萎缩</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12347,3)">3.&nbsp;功能障碍</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12348"></div>
          <div class="q-title5">体征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12348" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12348,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12348,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12348,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12348,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12348,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12349"></div>
          <div class="q-title6">1.&nbsp; 肌力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12349" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12349,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12349,2)">2.&nbsp;Ⅳ级</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12349,3)">3.&nbsp;Ⅲ级</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12349,4)">4.&nbsp;Ⅱ级</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12349,5)">5.&nbsp;Ⅰ级</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12349,6)">6.&nbsp;0级</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12350"></div>
          <div class="q-title6">2.&nbsp; 肌张力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12350" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12350,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12350,2)">2.&nbsp;轻度增高或降低</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12350,3)">3.&nbsp;明显增高或降低</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12351"></div>
          <div class="q-title6"> 3. 瞳孔
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12351" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12351,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12351,2)">2.&nbsp;缩小，对光反射迟钝</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12351,3)">3.&nbsp;针尖样瞳孔</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12352"></div>
          <div class="q-title6"> 4. 脑膜刺激征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12352" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12352,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12352,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12353"></div>
          <div class="q-title5">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12353" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12353,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12353,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12353,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12353,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12353,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12354"></div>
          <div class="q-title6">1.&nbsp; 影像学（脑CT或MRI)
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12354" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12354,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12354,2)">2.&nbsp;密度增高</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12354,3)">3.&nbsp;多发性病灶</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12354,4)">4.&nbsp;未检测、其他</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12355"></div>
          <div class="q-title5">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12355" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12355,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12355,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12355,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12355,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12355,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12356"></div>
          <div class="q-title6">1.&nbsp; 血管性痴呆
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12356" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12356,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12356,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12357"></div>
          <div class="q-title6">2.&nbsp; 继发性癫痫
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12357" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12357,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12357,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12358"></div>
          <div class="q-title6">3.&nbsp; 肺部感染
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12358" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12358,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12358,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12359"></div>
          <div class="q-title6">4.&nbsp; 尿路感染
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12359" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12359,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12359,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12360"></div>
          <div class="q-title6">5.&nbsp; 压疮
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12360" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12360,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12360,2)">2.&nbsp;Ⅰ~Ⅱ期</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12360,3)">3.&nbsp;Ⅲ~Ⅳ期</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12361"></div>
          <div class="q-title5">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12361" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12361,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12361,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12361,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12361,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12361,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12362"></div>
          <div class="q-title5">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12362" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12362,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12362,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12362,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12362,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12362,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12362,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 冠状动脉粥样硬化性心脏病 -->
        <div class="Q" v-if="isShow(collect.F12363,1)">
          <div class="q-title">冠状动脉粥样硬化性心脏病
            <div class="q-title4">
              第十项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" v-model="collect.F12363" class="checkbox" shape="square" disabled
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12363 == 1">
          <div class="first" id="F12364">
          </div>
          <div class="q-title5">局部症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12364" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12364,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12364,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12364,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12364,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12364,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12365"></div>
          <div class="q-title6">1.&nbsp; 心绞痛
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12365" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12365,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12365,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12366"></div>
          <div class="q-title6">2.&nbsp; 呼吸困难
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12366" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12366,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12366,2)">2.&nbsp;劳力性呼吸困难</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12366,3)">3.&nbsp;夜间阵发性呼吸困难</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12366,4)">4.&nbsp;端坐呼吸</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12367"></div>
          <div class="q-title6">3.&nbsp; 咳痰
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12367" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12367,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12367,2)">2.&nbsp;泡沫样痰</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12367,3)">3.&nbsp;粉红色泡沫样痰</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12368"></div>
          <div class="q-title6">4.&nbsp; 心悸
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12368" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12368,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12368,2)">2.&nbsp;短暂</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12368,3)">3.&nbsp;持续</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12369"></div>
          <div class="q-title5">全身症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12369" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12369,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12369,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12369,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12369,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12369,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12370"></div>
          <div class="q-title6">1.&nbsp; 乏力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12370" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12370,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12370,2)">2.&nbsp;轻度</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12370,3)">3.&nbsp;明显</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12371"></div>
          <div class="q-title5">体征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12371" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12371,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12371,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12371,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12371,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12371,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12372"></div>
          <div class="q-title6">1.&nbsp; 紫绀
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12372" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12372,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12372,2)">2.&nbsp;口唇紫绀</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12372,3)">3.&nbsp;全身性紫绀</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12373"></div>
          <div class="q-title6">2.&nbsp; 颈静脉充盈
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12373" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12373,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12373,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12374"></div>
          <div class="q-title6"> 3. 心脏增大
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12374" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12374,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12374,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12375"></div>
          <div class="q-title6"> 4. 心率增快或减慢
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12375" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12375,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12375,2)">2.&nbsp;&gt; 100次/分 或 &lt; 60次/分
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12376"></div>
          <div class="q-title6"> 5. 心律不齐
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12376" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12376,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12376,2)">2.&nbsp;无</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12377"></div>
          <div class="q-title6"> 6. 奔马律
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12377" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12377,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12377,2)">2.&nbsp;无</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12378"></div>
          <div class="q-title6"> 7. 肺部啰音
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12378" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12378,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12378,2)">2.&nbsp;少许湿啰音</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12378,3)">3.&nbsp;满布湿啰音或伴哮鸣音</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12379"></div>
          <div class="q-title6">8. 胸水或伴腹水
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12379" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12379,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12379,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12380"></div>
          <div class="q-title6"> 9. 肝肿大
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12380" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12380,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12380,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12381"></div>
          <div class="q-title6"> 10. 水肿
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12381" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12381,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12381,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>

          <div class="first" id="F12382"></div>
          <div class="q-title5">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12382" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12382,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12382,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12382,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12382,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12382,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12383"></div>
          <div class="q-title6">1.&nbsp; BNP
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12383" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12383,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12383,2)">2.&nbsp;900~1800ng/L</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12383,3)">3.&nbsp;&gt; 1800ng/L</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12383,4)">4.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12384"></div>
          <div class="q-title6">2.&nbsp; 心电图
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12384" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12384,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12384,2)">2.&nbsp;左心室肥大伴劳损</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12384,3)">3.&nbsp;伴心律失常</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12385"></div>
          <div class="q-title6">3.&nbsp; X线胸片
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12385" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12385,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12385,2)">2.&nbsp;心脏增大</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12385,3)">3.&nbsp;肺水肿</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12385,4)">4.&nbsp;伴肺泡水肿表现</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12385,5)">5.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12386"></div>
          <div class="q-title6">4.&nbsp; 超声心动图
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12386" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12386,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12386,2)">2.&nbsp;左心室增大伴室壁节段性运动异常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12386,3)">3.&nbsp;伴LVEF41%~50%</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12386,4)">4.&nbsp;伴LVEF &lt; 40%</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12386,5)">5.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12387"></div>
          <div class="q-title6">5.&nbsp; 肘静脉压测定
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12387" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12387,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12387,2)">2.&nbsp;&gt; 14cmH2O</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12387,3)">3.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12388"></div>
          <div class="q-title5">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12388" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12388,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12388,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12388,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12388,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12388,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12389"></div>
          <div class="q-title6">1.&nbsp; 严重心率失常
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12389" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12389,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12389,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12390"></div>
          <div class="q-title5">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12390" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12390,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12390,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12390,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12390,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12390,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12391"></div>
          <div class="q-title5">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12391" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12391,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12391,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12391,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12391,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12391,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12391,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 认知障碍 -->
        <div class="Q" v-if="isShow(collect.F12392,1)">
          <div class="q-title">认知障碍
            <div class="q-title4">
              第十一项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" v-model="collect.F12392" class="checkbox" shape="square"
                          v-if="isShow(collect.F12392,1)"
                          disabled
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12392 == 1">
          <div class="first" id="F12393">
          </div>
          <div class="q-title5">全身症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12393" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12393,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12393,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12393,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12393,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12393,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12394"></div>
          <div class="q-title6">1.&nbsp; 记忆障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12394" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12394,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12394,2)">2.&nbsp;近事记忆减退</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12394,3)">3.&nbsp;远事记忆减退</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12394,4)">4.&nbsp;远近事记忆障碍加重，学习新知识和社会接触能力减退，原掌握的知识、技巧明显衰退</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12395"></div>
          <div class="q-title6">2.&nbsp; 计算障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12395" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12395,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12395,2)">2.&nbsp;逻辑思维、综合分析能力减退、计算力下降
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12395,3)">3.&nbsp;丧失了部分简单日常生活计算能力</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12395,4)">4.&nbsp;上述症状加重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12396"></div>
          <div class="q-title6">3.&nbsp; 定向障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12396" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12396,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12396,2)">2.&nbsp;空间障碍</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12396,3)">3.&nbsp;视空间障碍</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12396,4)">4.&nbsp;丧失目标行动能力</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12397"></div>
          <div class="q-title6">4.&nbsp; 情感障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12397" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12397,1)">1.&nbsp;易疲乏、焦虑、消极</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12397,2)">2.&nbsp;空间障碍</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12397,3)">3.&nbsp;悲观、忧伤、厌世</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12397,4)">4.&nbsp;木僵状态</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12398"></div>
          <div class="q-title6">5.&nbsp; 人格障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12398" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12398,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12398,2)">2.&nbsp;不爱清洁，不修边幅，自私多疑</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12398,3)">3.&nbsp;哭泣，抑郁，暴躁，易怒</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12398,4)">4.&nbsp;偏执幻觉妄想，谩骂或打人，暴力或其他攻击行为
              </van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12399"></div>
          <div class="q-title5">伴随症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12399" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12399,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12399,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12399,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12399,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12399,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12400"></div>
          <div class="q-title6">1.&nbsp; 语言功能障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12400" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12400,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12400,2)">2.&nbsp;基本可以交谈</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12400,3)">3.&nbsp;交谈费力</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12400,4)">4.&nbsp;只能发单音节</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12400,5)">5.&nbsp;失语</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12401"></div>
          <div class="q-title6">2.&nbsp; 吞咽功能障碍
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12401" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12401,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12401,2)">2.&nbsp;极少呛咳</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12401,3)">3.&nbsp;偶尔呛咳</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12401,4)">4.&nbsp;反复呛咳</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12401,5)">5.&nbsp;不能进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12402"></div>
          <div class="q-title6">3.&nbsp; 食欲
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12402" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12402,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12402,2)">2.&nbsp;减退</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12402,3)">3.&nbsp;拒绝进食</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12403"></div>
          <div class="q-title6">4.&nbsp; 体重
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12403" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12403,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12403,2)">2.&nbsp;消瘦</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12403,3)">3.&nbsp;恶病质</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12404"></div>
          <div class="q-title6">5.&nbsp; 外周肌力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12404" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12404,1)">正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12404,2)">萎缩</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12404,3)">功能障碍</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12405"></div>
          <div class="q-title5">体征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12405" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12405,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12405,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12405,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12405,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12405,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12406"></div>
          <div class="q-title6">1.&nbsp; 肌力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12406" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12406,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12406,2)">2.&nbsp;Ⅳ级</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12406,3)">3.&nbsp;Ⅲ级</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12406,4)">4.&nbsp;Ⅱ级</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12406,5)">5.&nbsp;Ⅰ级</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12406,6)">6.&nbsp;0级</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12407"></div>
          <div class="q-title6">2.&nbsp; 肌张力
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12407" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12407,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12407,2)">2.&nbsp;轻度增高或降低</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12407,3)">3.&nbsp;明显增高或降低</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12408"></div>
          <div class="q-title5">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12408" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12408,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12408,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12408,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12408,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12408,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12409"></div>
          <div class="q-title6">1.&nbsp; 脑脊液检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12409" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12409,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12409,2)">2.&nbsp;Aβ42水平降低、总Tau蛋白和磷酸化Tau蛋白增高
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12409,3)">3.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12410"></div>
          <div class="q-title6">2.&nbsp; 脑电图
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12410" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12410,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12410,2)">2.&nbsp;α波减少，θ波增高，平均频率降低
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12410,3)">3.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12411"></div>
          <div class="q-title6">3.&nbsp; 影像学（脑CT、MRI）
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12411" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12411,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12411,2)">2.&nbsp;脑皮质萎缩明显（尤其海马及内侧颞叶）、脑室扩大
              </van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12411,3)">3.&nbsp;未检测</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12412"></div>
          <div class="q-title5">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12412" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12412,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12412,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12412,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12412,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12412,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12413"></div>
          <div class="q-title6">1.&nbsp; 营养不良
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12413" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12413,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12413,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12414"></div>
          <div class="q-title6">2.&nbsp; 肺部感染
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12414" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12414,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12414,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12415"></div>
          <div class="q-title6">3.&nbsp; 尿路感染
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12415" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12415,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12415,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12416"></div>
          <div class="q-title6">4.&nbsp; 压疮
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12416" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12416,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12416,2)">2.&nbsp;Ⅰ~Ⅱ期</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12416,3)">3.&nbsp;Ⅲ~Ⅳ期</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12417"></div>
          <div class="q-title6">5.&nbsp; 脑血管意外
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12417" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12417,1)">1.&nbsp;无</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12417,2)">2.&nbsp;有</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12418"></div>
          <div class="q-title5">疾病治疗的强度需求
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12418" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12418,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12418,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12418,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12418,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12418,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12419"></div>
          <div class="q-title5">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12419" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12419,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12419,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12419,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12419,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12419,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12419,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 其它疾病 -->
        <div class="Q" v-if="isShow(collect.F12420,1)">
          <div class="q-title">其它疾病
            <div class="q-title4">
              第十二项
            </div>
          </div>
          <div class="q-content">
            <van-checkbox :name="1" v-model="collect.F12420" class="checkbox" shape="square"
                          v-if="isShow(collect.F12420,1)"
                          disabled
            >是
            </van-checkbox
            >
          </div>
        </div>
        <div class="Q" style="padding: 0px 3% 0px 3%" v-if="collect.F12420 == 1">
          <div class="first" id="F12457">
          </div>
          <div class="q-title5">疾病名称
          </div>
          <div class="q-content">
            <input disabled type="text" class="ipt" placeholder="..." v-model="collect.F12457">
          </div>
          <div class="first" id="F12421"></div>
          <div class="q-title5">症状
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12421" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12421,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12421,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12421,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12421,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12421,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12422"></div>
          <div class="q-title5">体征
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12422" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12422,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12422,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12422,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12422,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12422,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12423"></div>
          <div class="q-title5">辅助检查
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12423" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12423,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12423,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12423,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12423,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12423,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12424"></div>
          <div class="q-title5">并发症/合并症
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12424" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12424,1)">1.&nbsp;正常</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12424,2)">2.&nbsp;基本正常</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12424,3)">3.&nbsp;较重</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12424,4)">4.&nbsp;严重</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12424,5)">5.&nbsp;危重</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12425"></div>
          <div class="q-title5">疾病的治疗强度需求
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12425" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12425,1)">1.&nbsp;无需治疗</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12425,2)">2.&nbsp;门诊治疗</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12425,3)">3.&nbsp;住院治疗</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12425,4)">4.&nbsp;急诊、急救、抢救</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12425,5)">5.&nbsp;无有效治疗</van-radio>
            </van-radio-group>
          </div>
          <div class="first" id="F12426"></div>
          <div class="q-title5">六个月内病情稳定性
          </div>
          <div class="q-content">
            <van-radio-group v-model="collect.F12426" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(collect.F12426,1)">1.&nbsp;显著减轻</van-radio>
              <van-radio class="checkbox1" :name="2" v-if="isShow(collect.F12426,2)">2.&nbsp;有所减轻</van-radio>
              <van-radio class="checkbox1" :name="3" v-if="isShow(collect.F12426,3)">3.&nbsp;无显著变化</van-radio>
              <van-radio class="checkbox1" :name="4" v-if="isShow(collect.F12426,4)">4.&nbsp;有所恶化</van-radio>
              <van-radio class="checkbox1" :name="5" v-if="isShow(collect.F12426,5)">5.&nbsp;显著恶化</van-radio>
              <van-radio class="checkbox1" :name="6" v-if="isShow(collect.F12426,6)">6.&nbsp;无法判断</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="Q">
          <div class="q-title7" style="font-size:12px" v-if="value.status===0
 &&!isShow(collect.F12108,1)
 &&!isShow(collect.F12142,1)
 &&!isShow(collect.F12168,1)
 &&!isShow(collect.F12200,1)
 &&!isShow(collect.F12224,1)
 &&!isShow(collect.F12250,1)
 &&!isShow(collect.F12278,1)
 &&!isShow(collect.F12303,1)
 &&!isShow(collect.F12336,1)
 &&!isShow(collect.F12363,1)
 &&!isShow(collect.F12392,1)
 &&!isShow(collect.F12420,1)"
          >该老人无特定疾病
          </div>
          <div class="q-title7" style="font-size:12px" v-if="isAdmin || isluru">调查员编号：<span style="color:blue">{{pgyObj.pgy2}}</span>&nbsp;&nbsp;&nbsp;调查日期：{{
            value.lasteditTime | timestampFormatDate }} <span></span>
          </div>
        </div>
        <div class="bottom" v-if="value.isluru == 0 && isluru">
          <div class="bottom2" style="width:100%" @click="showluru = true">确认录入</div>
        </div>
      </van-tab>
      <van-tab style="padding: 15px 0;height:100vh" title="签字">
        <div class="goodBox">
          <div>一.诚信声明</div>
          <div>
            在以下评估调查中，我（们）所表现和回答的关于评估对象的身体状况等，均为我近期日常生活中的一贯情形，无任何虚假的情况。作为协助评估人员，我所提供的关于评估对象的日常生活种的身体状况等信息，均为我平时直接观察和了解到的。我（们）所提供的材料，也均真实有效。
            如有任何虚假的情况，我（们）愿意放弃相应的资格，并承担由此带来的相应后果。
            特此声明
          </div>
          <div>1.确认.现场两位评估员同时上门；</div>
          <div>2.确认评估员现场已佩戴评估员工作证；</div>
          <div>3.确认此次评估时长满足20分钟；</div>
          <div>4.确认评估员现场已查看被评估者病例；</div>
          <div>5.确认评估员未向被评估者作任何服务承诺，未收受评估对象的任何礼物、馈赠。</div>
          <div>6.对此次现场评估工作是否满意。</div>
          <div>
            <van-radio-group v-model="value.manyi" icon-size='16px' direction="horizontal">
              <van-radio class="checkbox1" :name="1" v-if="isShow(value.manyi,1)">满意</van-radio>
              <van-radio class="checkbox1" :name="0" v-if="isShow(value.manyi,0)">不满意。</van-radio>
            </van-radio-group>
            原因：<input disabled type="text" v-model="value.manyiYuanyin" class="ipt1" placeholder="...">
          </div>
          <div class="upimg">
            <div class="imgli">
              <van-uploader disabled v-model="qianzi" multiple :max-count="1" accept="image/*"
                            :after-read="uploadFfileQz"/>
            </div>
          </div>
        </div>
        <!-- <div class="goodBox">
          <div>
            1、本调查表用于本市长期护理险试点过程中对老年医疗护理服务申请者进行需求评估。
          </div>
          <div>2、本调查所获得的信息仅作为本市长期护理险需求评级的基础。</div>
          <div>
            3、为了反映被调查者的真实情况，各个调查项目结果的填写均基于被调查者相应动作的确认、被调查者自身的回答、家属和/或身边照护人员所反映的被调查者日常生活的一贯情况以及专门调查员的现场观察。
          </div>
          <div>
            4、调查回答项中，“□”表示备选项可多选，请打“√”表示选择；“①”等表示选项为单选，只能选择一项，请打“√”表示选择；用下划线留出空栏时，请填写合适的内容。
          </div>
          <div>5、关于发生频数的选项，请选择最接近实际的选择项。</div>
          <div>
            6、关于金额、次数等项目，如无精确数字，请估计一个合适的值填写。
          </div>
          <div>7、本调查全过程约需要三十分钟。</div>
          <div>8、本调查表所涉及的隐私受到法律保护。</div>
          <div>9、本调查表由认定的评估机构指派专门调查员完成。</div>
        </div> -->
      </van-tab>
    </van-tabs>
    <van-action-sheet v-model="showluru" :actions="actions" @select="onSelect"/>
    <van-dialog v-model="showVideo" show-cancel-button :confirm="videoConfirm" :cancel="videoCancel">
      <video :src="videoSrc" id="video" controls="controls" v-if="showVideo" style="height:30vh;width:100%"/>
    </van-dialog>
  </div>
</template>

<script>
import { getformsParticularsById, updateFormsInfo, uploadImage } from '../request/http'
import { Dialog, Toast } from 'vant'

export default {
  data () {
    return {
      showluru: false,
      showVideo: null,
      videoSrc: null,
      isWc: false,
      actions: [{ name: '0' }, { name: '1' }, { name: '2' }, { name: '3' }, { name: '4' }, { name: '5' }, { name: '6' }],
      bofang: false,
      themeVars: {
        fieldLabelColor: 'red'
      },
      image1: [],
      image2: [],
      image3: [],
      image4: [],
      image5: [],
      image6: [],
      text: null,
      qianzi: [],
      fileList: [],
      active: 0,
      pgyObj: {
        pgy1: null,
        pgy2: null
      },
      radio: ' ',
      value: {},
      isluru: null,
      isAdmin: null,
      collect: {}
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    onSelect (item) {
      const update = { id: this.value.id, isluru: 1, yupgdj: parseInt(item.name) }
      updateFormsInfo(update).then(({ data }) => {
        Toast.success('录入成功')
        this.$router.push({ path: '/homePage', query: { active: 1 } })
      })
    },
    videoConfirm () {
      this.showVideo = false
      document.getElementById('video').pause()
    },
    videoCancel () {
      this.showVideo = false
      document.getElementById('video').pause()
    },
    getInfo () {
      const isLuru = localStorage.getItem('isLuru')
      if (isLuru != null && isLuru != 'false') {
        this.isluru = true
      }

      const isAdmin = localStorage.getItem('isAdmin')
      if (isAdmin != null && isAdmin != 'false') {
        this.isAdmin = true
      }

      this.active = 0
      getformsParticularsById({ id: this.$route.query.id }).then(({ data }) => {

        console.log(data)
        if (data.collect != null) {
          this.collect = data.collect
          // console.log("其他1"+data.collect.F12453)
          // console.log("其他"+this.collect.F12453)
        }
        if (data.pgy1 != null && data.pgy1 != undefined) {
          this.pgyObj.pgy1 = data.pgy1
        }
        if (data.pgy2 != null && data.pgy2 != undefined) {
          this.pgyObj.pgy2 = data.pgy2
        }
        if (data.senForms != null) {
          this.value = data.senForms
          // this.collect.F11664= data.senForms.xb
          const senForms = data.senForms
          if (senForms.image1 != null && senForms.image1 != '') {
            this.image1 = [{ url: senForms.image1 }]
          }
          if (senForms.image2 != null && senForms.image2 != '') {
            this.image2 = [{ url: senForms.image2 }]
          }
          if (senForms.image3 != null && senForms.image3 != '') {
            this.image3 = [{ url: senForms.image3 }]
          }
          if (senForms.image4 != null && senForms.image4 != '') {
            this.image4 = [{ url: senForms.image4 }]
          }
          if (senForms.image5 != null && senForms.image5 != '') {
            this.image5 = [{ url: senForms.image5 }]
          }
          if (senForms.image6 != null && senForms.image6 != '') {
            this.image6 = [{ url: senForms.image6 }]
          }
          if (senForms.qianzi != null && senForms.qianzi != '') {
            this.qianzi = [{ url: senForms.qianzi }]
          }

          if (data.senForms.xm != null && (this.collect.F11663 == null || this.collect.F11663 == '')) {
            this.collect.F11663 = data.senForms.xm
          }
          if (data.senForms.xb != null && data.senForms.xb === 1 && (this.collect.F11664 === null || this.collect.F11664 === '')) {
            this.collect.F11664 = data.senForms.xb
          } else {
            this.collect.F11664 = 2
          }
          const address = data.senForms.sxdz
          if (data.senForms.sxdz != null) {
            this.collect.F11663 = data.senForms.xm
            if (this.collect.F11678 == null || this.collect.F11678 == '') {
              this.collect.F11678 = '上海市'
            }
            if (this.collect.F11679 == null || this.collect.F11679 == '') {
              const value = data.senForms.sxdz.substring(0, address.indexOf('区'))
              if (value != null && value != '') {
                this.collect.F11679 = value
              }
            }
            if (this.collect.F11680 == null || this.collect.F11680 == '') {
              let value = data.senForms.sxdz.substring(address.indexOf('区') + 1, address.indexOf('街道'))
              if (value == null || value == '') {
                value = data.senForms.sxdz.substring(address.indexOf('区') + 1, address.indexOf('镇'))
              }
              if (value != null && value != '') {
                this.collect.F11680 = value
              }
            }
            if (this.collect.F11680 == null || this.collect.F11680 == '') {
              let value = data.senForms.sxdz.substring(address.indexOf('街道') + 1, address.indexOf('村'))
              if (value == null || value == '') {
                value = data.senForms.sxdz.substring(address.indexOf('镇') + 1, address.indexOf('村'))
                if (value == null || value == '') {
                  value = data.senForms.sxdz.substring(address.indexOf('镇') + 1, address.indexOf('委'))
                }
                if (value == null || value == '') {
                  value = data.senForms.sxdz.substring(address.indexOf('街道') + 1, address.indexOf('委'))
                }
              }
              if (value != null && value != '') {
                this.collect.F11681 = value
              }
            }

            if (this.collect.F11682 == null || this.collect.F11682 == '') {
              let value = data.senForms.sxdz.substring(address.indexOf('村') + 1, address.indexOf('小区'))
              if (value == null || value == '') {
                value = data.senForms.sxdz.substring(address.indexOf('委') + 1, address.indexOf('小区'))
              }
              if (value != null && value != '') {
                this.collect.F11682 = value
              }
            }
            if (this.collect.F11683 == null || this.collect.F11683 == '') {
              const value = data.senForms.sxdz.substring(address.indexOf('小区') + 1, address.indexOf('路'))
              if (value != null && value != '') {
                this.collect.F11683 = value
              }
            }
            if (this.collect.F11684 == null || this.collect.F11684 == '') {
              const value = data.senForms.sxdz.substring(address.indexOf('路') + 1, address.indexOf('弄'))
              if (value != null && value != '') {
                this.collect.F11684 = value
              }
            }
            if (this.collect.F11685 == null || this.collect.F11685 == '') {
              const value = data.senForms.sxdz.substring(address.indexOf('弄') + 1, address.indexOf('号'))
              if (value != null && value != '') {
                this.collect.F11685 = value
              }
            }
            if (this.collect.F11686 == null || this.collect.F11686 == '') {
              const value = data.senForms.sxdz.substring(address.indexOf('号') + 1, address.indexOf('室'))
              if (value != null && value != '') {
                this.collect.F11686 = value
              }
            }
          }

          if (data.senForms.gsdate != null && data.senForms.gsdate != undefined && data.senForms.gsdate != 0) {
            this.value.submtime = data.senForms.gsdate
            this.value.lasteditTime = data.senForms.gsdate
          }

          if (data.senForms.csny != null) {
            console.log(parseInt(data.senForms.csny / 10000))
            console.log(new Date().getFullYear())
            this.collect.F11665 = parseInt(new Date().getFullYear() - parseInt(data.senForms.csny / 10000))
          }
        }
      })
    },
    isBof (url) {
      if (url === null || url === '') {
        Dialog.alert({
          title: '提示',
          message: '请先上传图片'
        }).then(() => {
          // on close
        })
        return
      }
      this.videoSrc = url
      this.showVideo = true
    },
    updateActive (value) {
      this.active = Number(value)
    },
    isShow (value, index) {
      if (value == null || index == null) {
        return false
      }

      if (value.constructor === Array) {
        if (value.indexOf(index) != -1) {
          return true
        } else {
          return false
        }
      } else {
        if (typeof value === 'number') {
          return value === index
        }
      }
    },
    include (value, index) {
      if (value == null || index == null) {
        return false
      }
      if (value == index) {
        return true
      }
      if (value.constructor === Array) {
        if (value.indexOf(index) != -1) {
          return true
        } else {
          return false
        }
      }
    },
    uploadFfileQz (file) {
      const forms = new FormData()
      forms.append('file', file.file) // 获取上传图片信息
      uploadImage(forms).then(({ data }) => {
        this.qianzi = [{ url: data }]
      })
    },
    uploadFfile (file) {
      const forms = new FormData()
      forms.append('file', file.file) // 获取上传图片信息
      uploadImage(forms).then(({ data }) => {
        this.image1 = [{ url: data }]
      })
    },
    uploadFfile2 (file) {
      const forms = new FormData()
      forms.append('file', file.file) // 获取上传图片信息
      uploadImage(forms).then(({ data }) => {
        this.image2 = [{ url: data }]
      })
    },
    uploadFfile3 (file) {
      const forms = new FormData()
      forms.append('file', file.file) // 获取上传图片信息
      uploadImage(forms).then(({ data }) => {
        this.image3 = [{ url: data }]
      })
    },
    uploadFfile4 (file) {
      const forms = new FormData()
      forms.append('file', file.file) // 获取上传图片信息
      uploadImage(forms).then(({ data }) => {
        this.image4 = [{ url: data }]
      })
    },
    isNotNull (value) {
      if (value === null || value === undefined || value === '' || value === 0) {
        return false
      } else {
        return true
      }
    },
    uploadFfile5 (file) {
      const forms = new FormData()
      forms.append('file', file.file) // 获取上传图片信息
      uploadImage(forms).then(({ data }) => {
        this.image5 = [{ url: data }]
        Dialog.alert({
          title: '提示',
          message: '视频上传成功'
        }).then(() => {
          // on close
        })
      })
    },
    uploadFfile6 (file) {
      const forms = new FormData()
      forms.append('file', file.file) // 获取上传图片信息
      uploadImage(forms).then(({ data }) => {
        this.image6 = [{ url: data }]
        Dialog.alert({
          title: '提示',
          message: '视频上传成功'
        }).then(() => {
          // on close
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  /* 第一个tab页面样式 */
  .goodBox {
    width: 88%;
    /* height: 150px; */
    padding: 15px 3%;
    background-color: #fff;
    margin: 0 auto;
  }

  .upimg {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .imgli {
    position: relative;
    width: 80px;
    margin: 25px 0;
  }

  .imgli .imgli-title {
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    color: #bbb;
    text-align: center;
  }

  .ck {
    float: left;
  }

  .dt {
    float: right;
  }

  .btn {
    width: 95%;
    margin: 15px auto;
  }

  .Assessment {
    background-color: #f3f3f3;
  }

  /* 填写样式 */
  .Q {
    margin: 8px 0;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
    min-height: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .bottom {
    display: flex;
    position: fixed;
    bottom: 0;
    font-size: 18px;
    line-height: 60px;
    text-align: center;
    width: 100%;

    height: 60px;
  }

  .bottom1 {
    background: #fff;
    width: 50%;
    height: 100%;
  }

  .bottom2 {
    background-color: skyblue;
    width: 50%;
    height: 100%;
    color: #fff;
  }

  .B {
    margin: 15px 0;
    border-radius: 5px;
    background-color: #fff9ec;
    overflow: hidden;
    padding: 15px 8px;
    min-height: 30px;
  }

  .q-title1 {
    width: 92%;
    margin: 0 auto;
    font-size: 18px;
    padding: 5px 0;
  }

  .q-title3 {
    width: 92%;
    margin: 0 auto;
    font-size: 18px;
  }

  .q-title4 {
    width: 100%;
    margin: 0 auto;
    font-size: 18px;
    color: black;
  }

  .q-input {
    /* border: 0; */
    border: 0;
    text-align: center;
    border-bottom: 1px solid #000;
    width: 40px;
  }

  .q-title {
    /* height: 40px; */
    width: 100%;
    // margin: 0 auto;
    font-size: 18px;
    padding: 10px 16px;
    border-bottom: 1px solid #bbb;
  }

  .q-title2 {
    width: 92%;
    // margin: 0 auto;
    font-size: 16px;
    color: black;
  }

  .q-content {
    /*width: 100%;*/
    color: black;
    padding: 10px 16px;
    font-size: 16px;
    // margin: 2px auto;
  }

  .checkbox {
    margin: 5px 0;
    width: 50%;
    font-size: 16px;
  }

  .checkbox1 {
    margin: 5px 0;
    font-size: 16px;
  }

  .video-js .vjs-icon-placeholder {
    width: 100%;
    height: 100%;
    display: block;
  }

  .q-content textarea {
    width: 98%;
    display: block;
    margin: 2px auto;
    height: 150px;
    min-width: 98%;
    max-width: 98%;
    min-height: 150px;
    max-height: 150px;
  }

  .scrollIntoView {
    height: 15px
  }

  /deep/ .van-radio__label {
    color: #646566;
  }

  /deep/ .fieldF11673 {
    // color: #ff2200 ;
  }

  /deep/ .van-field--disabled .van-field__label {
    color: #646566;
  }

  .asss {
    --van-field-label-color: red
  }

  /deep/ .van-field__control:disabled {
    color: #646566;
  }

  /deep/ .van-checkbox__label--disabled {
    color: #646566;
  }

  /deep/ .van-checkbox__icon--checked .van-icon {
    color: #fff;
    background-color: #1989fa;
    border-color: #1989fa;
  }

  .first {
    padding-top: 60px; /* 60px是导航栏高度 */
    margin-top: -60px;
  }

  ::v-deep .specific-diseases {
    .q-title6 {
      padding-left: 16px;
      font-size: 18px;

      & + .q-content {
        padding-left: 34px;
      }
    }

    .q-title5 {
      font-weight: bold;
    }
  }

  ::v-deep .van-field__label {
    color:black;
    font-size: 18px;
  }
  ::v-deep .van-checkbox__label, ::v-deep .van-radio__label, ::v-deep .van-field__value,::v-deep .el-input--mini {
    color:black;
    font-size: 16px;
  }
  ::v-deep .van-radio--horizontal {
    margin-bottom 5px;
    margin-right : 5px;
  }
</style>
