<template>
  <div class="container">
  </div>
</template>

<script>
import { determiningUserRights } from '../../request/http'
export default {
  created () {
    this.getWxCallbackUrl()
  },
  methods: {
    // 云养
    getWxCallbackUrl () {
      let state = this.$route.query.state
      const userOpenId = localStorage.getItem('userOpenId')
      if (state == null || state == '') {
        state = 'homePage'
      }
      if (userOpenId != null && userOpenId != '') {
        this.$router.push({ path: '/' + state })
        return
      }

      const redirectUri = 'https://www.yumyoung.com/api/officialAccounts/Forms/tiaozhuan'
      const AppId = 'wx1fbdf25bf975deb3'
      const wxurl =
            'https://open.weixin.qq.com/connect/oauth2/authorize?' +
            'appid=' + AppId + '&redirect_uri=' + redirectUri + '&response_type=code&scope=snsapi_userinfo&state=' + state + '#wechat_redirect'
      window.location.href = wxurl
    },
    // 领航
    // getWxCallbackUrl () {
    //   let state = this.$route.query.state
    //   const userOpenId = localStorage.getItem('userOpenId')
    //   if (state == null || state === '') {
    //     state = 'homePage'
    //   }
    //   if (userOpenId != null && userOpenId != '') {
    //     this.$router.push({ path: '/' + state })
    //     return
    //   }
    //
    //   const redirectUri = 'http://lh.yumyoung.com/api/officialAccounts/Forms/tiaozhuan'
    //   const AppId = 'wxd2f55247a44233c7'
    //   const wxurl =
    //         'https://open.weixin.qq.com/connect/oauth2/authorize?' +
    //         'appid=' + AppId + '&redirect_uri=' + redirectUri + '&response_type=code&scope=snsapi_userinfo&state=' + state + '#wechat_redirect'
    //   window.location.href = wxurl
    // },
    determiningUserRights () {
      determiningUserRights({}).then(({ data }) => {
        if (data != null && data !== undefined) {
          if (data.isLuru != null) {
            localStorage.setItem('isLuru', data.isLuru)
          }
          if (data.isPgy != null) {
            localStorage.setItem('isPgy', data.isPgy)
          }
          if (data.senUser != null) {
            localStorage.setItem('userId', data.senUser.id)
          }
          if (data.senUserdata != null) {
            localStorage.setItem('userId', data.senUserdata.id)
          }
          if (data.isAdmin != null) {
            localStorage.setItem('isAdmin', data.isAdmin)
          }
        }
      })
    },
    tijiao () {
      this.$router.push({ path: '/evaluatorBinding' })
    },
    deleteStreet () {
      this.$router.push({ path: '/homePage' })
    }
  }
}
</script>

<style scoped lang="stylus">
.container {
    height: 100%;

    .success-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;

        .success-text {
            margin: 1rem 0;
            color: #999;
        }

        .success-link {
            color: blue;
        }
    }

    .user-info {
        padding: 0 1rem;
        color: #666;
    }
}
</style>
